import useSWR, { mutate } from "swr";
import * as fetcher from "../fetcher";
import { ActivityType, ActivitySubType } from "../../activity";

export interface Activity {
  acted: boolean;
  createdAt: string;
  createdBy: string;
  id: number;
  reminder: boolean;
  subType: ActivitySubType;
  title: string;
  type: ActivityType;
  notificationId?: number;
  updatedAt: string;
  comment?: string;
  appointmentTime?: string;
}

export type UpdateObject = {
  field?: string;
  old_value?: string;
  new_value?: string;
};

export interface History {
  id: string;
  opportunityId: number;
  entryType:
    | "REMARK_UPDATE"
    | "GENERIC"
    | "COMMUNICATION_SENT"
    | "COMMUNICATION_FAILED"
    | "STATUS_UPDATE_ASSIGNED";
  importance: "LOW" | "DEFAULT" | "HIGH";
  title: string;
  body?: string;
  note?: string;
  details?: {
    new_value?: string;
    old_value?: string;
    channel?: string;
    template?: string;
    expected_delivery?: string;
    order_number?: string;
    updates?: UpdateObject[];
  };
  createdBy: string;
  createdAt: string;
}

export const isHistory = (data: Activity | History): data is History =>
  data.hasOwnProperty("entryType");

export function useTimeLog(id: number) {
  return useSWR<Activity[]>(`/opportunities/${id}/timelog`, (url) =>
    fetcher.opportunity.get(`/${id}/timelog`)
  );
}

export const setTaskAsDone = async (id: number, opportunityId: number) => {
  const res = await fetcher.opportunity.update(
    `/${opportunityId}/timelog/${id}`,
    {}
  );
  mutate(`/notifications`);
  mutate(`/opportunities/${opportunityId}/timelog`);
  return res;
};

export function useHistory(id: number) {
  return useSWR<History[]>(`/opportunities/${id}/history`, (url) =>
    fetcher.opportunity.get(`/${id}/history`)
  );
}
