import { countryNames } from "./countryNames";
import { t } from "i18n";

interface Props {
  /** The two letter country code to format */
  children: string | undefined;
}

/**
 * Take a two letter country code and display the full country name
 */
export const CountryName = ({ children }: Props) => {
  if (!children) {
    return null;
  }
  return <>{t(countryNames[children.toUpperCase()]) || children}</>;
};
