import { useState, useEffect } from "react";
import {
  CurrentOpportunityDispatch,
  CurrentOpportunity,
} from "../currentOpportunity.reducer";
import Select, { Option } from "@ingka/select";
import InlineMessage from "@ingka/inline-message";
import infoCircle from "@ingka/ssr-icon/paths/information-circle";
import { Button } from "components";
import {
  Stack,
  Box,
  Text,
  TextAreaFixed,
  DatePicker,
  Grid,
  TimePicker,
  InputField,
  Checkbox,
} from "components";
import { OrderSelect } from "./OrderSelect.component";
import {
  Channel,
  Opportunity,
  Reason,
  useConfig,
  useOpportunityCustomer,
  useOrder,
  useNotifications,
} from "common/api";
import { isDefined, useLocale } from "common";
import { t, T } from "i18n";
import { tracker } from "analytics";
import { useSettings } from "settings/SettingsProvider";
import { useUserLocation } from "common/api/user/userLocation";
import {
  hasCollidingAppointments,
  getRemindersList,
} from "./Appointment/utils";
import { fogActions } from "common/api/opportunity/fog-actions";

interface Props {
  opportunityFromApi: Opportunity;
  currentOpportunity: CurrentOpportunity;
  currentOpportunityDispatch: CurrentOpportunityDispatch;
  isLoading: boolean;
  onSave(): Promise<any>;
  disableEditing: boolean;
}

export const RecommendedActions = ({
  opportunityFromApi,
  onSave,
  currentOpportunityDispatch,
  isLoading,
  disableEditing,
}: Props) => {
  const [selectedAction, setSelectedAction] = useState<
    CurrentOpportunity["actionToCommit"] | null
  >(null);
  const [isOtherActionReminder, setOtherActionReminder] =
    useState<boolean>(false);
  const [waitingForSave, setWaitingForSave] = useState<boolean>(false);
  const { data: customer } = useOpportunityCustomer(opportunityFromApi.id);
  const { data: config } = useConfig();
  const { boka_config } = useSettings();
  const locale = useLocale();
  const location = useUserLocation();
  const { data } = useNotifications();
  const reminders = getRemindersList(data ?? []);

  // Hack to wait for dispatch to complete before saving
  useEffect(() => {
    if (!waitingForSave) {
      return;
    }
    (async () => {
      try {
        await onSave();
        currentOpportunityDispatch({
          type: "INIT_OPPORTUNITY",
          payload: opportunityFromApi,
        });
        setSelectedAction(null);
        setWaitingForSave(false);
      } finally {
        // eslint-disable-next-line no-console
        console.log("Saved");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingForSave]);

  const [selectedOrderNo, setSelectedOrderNo] = useState<string | undefined>(
    opportunityFromApi.orders?.originalOrder?.orderId
  );

  const { data: originalOrder } = useOrder(
    opportunityFromApi.orders?.originalOrder?.orderId
  );

  const newAppointmentDate =
    selectedAction?.date && selectedAction?.time
      ? new Date(
          selectedAction?.date?.getFullYear(),
          selectedAction?.date?.getMonth(),
          selectedAction?.date?.getDate(),
          selectedAction?.time?.getHours(),
          selectedAction?.time?.getMinutes()
        )
      : null;

  const zipcode = originalOrder?.deliveryContact?.address?.zipCode;

  const enableFollowupBooking =
    boka_config?.enable &&
    location?.toLowerCase() === "pt" &&
    opportunityFromApi.type === "Boka" &&
    zipcode;

  const isValid =
    selectedAction &&
    (selectedAction?.action.kind === "OTHER_ACTION_TAKEN"
      ? !!selectedAction.title && selectedAction.title.length > 2
      : true) &&
    (selectedAction?.action.kind === "SCHEDULE_CALL"
      ? !!selectedAction.date && !!selectedAction.time
      : true) &&
    (!enableFollowupBooking &&
    selectedAction?.action.kind === "SCHEDULE_AN_APPOINTMENT"
      ? !!selectedAction.date && !!selectedAction.time
      : true) &&
    (selectedAction.action.kind === "MANAGE_ORDER" &&
    selectedAction.reason === "IMPORT_ORDER"
      ? selectedAction.orderNo &&
        (selectedAction.orderNo.length === 10 ||
          selectedAction.orderNo.length === 9)
      : true);

  const saveAction = async () => {
    tracker.event({
      category: "Manage page",
      action: "Clicked on 'Save action' button",
      label: `Selected '${selectedAction!.action.kind}'`,
    });
    currentOpportunityDispatch({
      type: "UPDATE_ACTION",
      payload: selectedAction!,
    });
    setWaitingForSave(true);
  };

  const getSaljaUrl = (reason, externalId) => {
    const baseUrl = `${
      process.env.REACT_APP__WEB_SALJA__
    }/${locale.countryCode?.toLowerCase()}/${locale.languageCode?.toLowerCase()}`;
    if (reason === "CREATE_NEW_ORDER") {
      const partyId = customer?.customer?.partyId || "";
      const extId = opportunityFromApi.externalId || 0;
      const id = opportunityFromApi.id;
      return `${baseUrl}/cart/?partyuid=${partyId}&extId=${extId}&clientsystem=SLA&opportunityid=${id}`;
    } else if (reason === "MODIFY") {
      return `${baseUrl}/order/${selectedOrderNo}?clientSystem=SLA`;
    } else {
      return `${baseUrl}/order/${externalId}?clientSystem=SLA`;
    }
  };

  const manageableLinkedOrders =
    opportunityFromApi.orders?.linkedOrders?.filter(
      (order) => order.status !== "Cancelled" && order.status !== "Completed"
    ) || [];
  const manageableOrders = [
    opportunityFromApi.orders?.originalOrder,
    ...manageableLinkedOrders,
  ].filter(isDefined);

  const hasPreviousAppointment = hasCollidingAppointments(
    newAppointmentDate,
    reminders
  );

  return (
    <Box padding={30} color="bjornGrey">
      <Stack spacing={24} vertical>
        <Text header="h2">
          <T id="manage-opportunity.actions.title" />
        </Text>
        <Stack spacing={30} vertical>
          <Select
            id="RecommendedActionSelect"
            label={t("manage-opportunity.actions.label")}
            value={selectedAction?.action?.kind ?? ""}
            hintText={t("manage-opportunity.actions.choose-action")}
            disabled={disableEditing}
            onChange={(e) => {
              const value = e.target.value;

              const action = fogActions.find(
                (theAction) => theAction.kind === value
              );

              if (!action) {
                setSelectedAction(null);
                return;
              }

              setSelectedAction({
                action,
                channel: action.channel ? action.channel[0].kind : undefined,
                reason: action.reason ? action.reason[0].kind : undefined,
                orderNo:
                  action.kind === "MANAGE_ORDER" ? selectedOrderNo : undefined,
                plannerType:
                  action.kind === "MANAGE_PLANNER"
                    ? "OTHER_PLANNER"
                    : undefined,
                plannerId: undefined,
                sendCommunication: false,
              });
            }}
          >
            {fogActions
              .filter((action) => action.kind !== "MANAGE_PLANNER")
              .map((pcm, index) => (
                <Option
                  value={pcm.kind}
                  name={t(`fog.${pcm.kind}` as const)}
                  key={index}
                />
              ))}
          </Select>
          {selectedAction?.action && (
            <>
              {selectedAction.action.kind === "SPOKE_WITH_CUSTOMER" && (
                <Select
                  label={t(
                    "manage-opportunity.actions.spoke-with-customer.label"
                  )}
                  hintText={t("manage-opportunity.actions.choose.option")}
                  id="spokeChannel"
                  onChange={(e) => {
                    setSelectedAction({
                      ...selectedAction,
                      channel: e.target.value as Channel["kind"],
                    });
                  }}
                  value={selectedAction.channel}
                >
                  {selectedAction.action.channel?.map((channel) => (
                    <Option
                      value={channel.kind}
                      key={channel.kind}
                      name={t(`fog.${channel.kind}` as const)}
                    />
                  ))}
                </Select>
              )}
              {selectedAction?.action.kind === "SCHEDULE_CALL" && (
                <Grid columns={2} spacing={24}>
                  <DatePicker
                    id="date"
                    label={t("manage-opportunity.actions.date.label")}
                    isClearable
                    minDate={new Date()}
                    value={selectedAction.date}
                    onChange={(date) => {
                      setSelectedAction({
                        ...selectedAction,
                        date,
                      });
                    }}
                  />
                  <TimePicker
                    value={selectedAction.time}
                    date={selectedAction?.date}
                    onChange={(time) => {
                      setSelectedAction({
                        ...selectedAction,
                        time,
                      });
                    }}
                  />
                </Grid>
              )}
              {selectedAction?.action.kind === "OTHER_ACTION_TAKEN" && (
                <>
                  <InputField
                    type="text"
                    name="title"
                    label={t("manage-opportunity.actions.other-actions.label")}
                    maxLength={80}
                    onChange={(e) => {
                      setSelectedAction({
                        ...selectedAction,
                        title: e.target.value,
                      });
                    }}
                  />
                  <Checkbox
                    disabled={false}
                    checked={isOtherActionReminder!}
                    keepTogether={true}
                    label={t(
                      "manage-opportunity.actions.other-actions.checkbox.label"
                    )}
                    onChange={(value) => {
                      setOtherActionReminder(value);
                      setSelectedAction({
                        ...selectedAction,
                        date: value ? selectedAction.date : undefined,
                        time: value ? selectedAction.date : undefined,
                      });
                    }}
                  />
                  {isOtherActionReminder && (
                    <Grid columns={2} spacing={24}>
                      <DatePicker
                        id="date"
                        label={t("manage-opportunity.actions.date.label")}
                        isClearable
                        minDate={new Date()}
                        value={selectedAction.date}
                        onChange={(date) => {
                          setSelectedAction({
                            ...selectedAction,
                            date,
                          });
                        }}
                      />
                      <TimePicker
                        value={selectedAction.time}
                        date={selectedAction?.date}
                        onChange={(time) => {
                          setSelectedAction({
                            ...selectedAction,
                            time,
                          });
                        }}
                      />
                    </Grid>
                  )}
                </>
              )}
              {selectedAction?.action.kind === "SCHEDULE_AN_APPOINTMENT" &&
                (enableFollowupBooking ? (
                  <Grid columns={2}>
                    <Button
                      small
                      primary
                      href={`http://order.ikea.com/${
                        location?.toLowerCase() ?? "pt"
                      }/${
                        locale.languageCode
                      }/appointment/kitcheninternal?zip=${zipcode}`}
                      text={t(
                        "manage-opportunity.actions.book-appointment.button"
                      )}
                    />
                    <Text small bold>
                      <T id="manage-opportunity.actions.book-follow-up-appointment.info" />
                    </Text>
                  </Grid>
                ) : (
                  <>
                    <Grid columns={2} spacing={18}>
                      <Button
                        small
                        primary
                        href={config?.url.boka}
                        text={t(
                          "manage-opportunity.actions.book-appointment.button"
                        )}
                      />
                      <Text small bold>
                        <T id="manage-opportunity.actions.book-appointment.info" />
                      </Text>
                    </Grid>
                    <Grid columns={2} spacing={24}>
                      <DatePicker
                        id="date"
                        label={t("manage-opportunity.actions.date.label")}
                        minDate={new Date()}
                        isClearable
                        value={selectedAction.date}
                        onChange={(date) => {
                          setSelectedAction({
                            ...selectedAction,
                            date,
                          });
                        }}
                      />
                      <TimePicker
                        value={selectedAction.time}
                        date={selectedAction?.date}
                        onChange={(time) => {
                          setSelectedAction({
                            ...selectedAction,
                            time,
                          });
                        }}
                      />
                    </Grid>
                  </>
                ))}
              {selectedAction?.action.kind === "MANAGE_ORDER" && (
                <Select
                  label={t(
                    "manage-opportunity.actions.manage-order.actions.label"
                  )}
                  hintText={t("manage-opportunity.actions.choose.option")}
                  id="orderReason"
                  onChange={(e) => {
                    setSelectedAction({
                      ...selectedAction,
                      reason: e.target.value as Reason["kind"],
                      orderNo:
                        e.target.value === "IMPORT_ORDER"
                          ? undefined
                          : selectedAction.orderNo,
                    });
                  }}
                >
                  {selectedAction.action.reason?.map((reason) => (
                    <Option
                      key={reason.kind}
                      value={reason.kind}
                      name={t(`fog.${reason.kind}` as const)}
                    />
                  ))}
                </Select>
              )}

              {selectedAction.action.kind === "MANAGE_ORDER" &&
              selectedAction.reason === "MODIFY" ? (
                <>
                  {manageableOrders.length > 0 ? (
                    <OrderSelect
                      label={t(
                        "manage-opportunity.actions.manage-order.select-order.label"
                      )}
                      onChange={(val) => {
                        setSelectedOrderNo(val);
                        setSelectedAction({ ...selectedAction, orderNo: val });
                      }}
                      selectedOrderNo={selectedOrderNo}
                      originalOrderNo={
                        opportunityFromApi?.orders?.originalOrder?.orderId
                      }
                      orders={manageableOrders.filter(isDefined)}
                    />
                  ) : (
                    <Text color="redIdle">
                      <T id="manage-opportunity.actions.manage-order.no-orders" />
                    </Text>
                  )}
                </>
              ) : (
                selectedAction.reason === "IMPORT_ORDER" && (
                  <InputField
                    type="number"
                    name="Ordernr"
                    maxLength={10}
                    label={t(
                      "manage-opportunity.actions.manage-order.import-order.label"
                    )}
                    onChange={(e) => {
                      setSelectedAction({
                        ...selectedAction,
                        orderNo: e.target.value,
                      });
                    }}
                  />
                )
              )}
              {selectedAction.action.kind === "MANAGE_ORDER" &&
                selectedAction.reason !== "IMPORT_ORDER" && (
                  <Stack spacing={18}>
                    {opportunityFromApi.externalId && (
                      <Button
                        text={t(
                          "manage-opportunity.actions.manage-order.open-salja.button"
                        )}
                        primary
                        small
                        href={getSaljaUrl(
                          selectedAction.reason,
                          opportunityFromApi.externalId
                        )}
                      />
                    )}
                  </Stack>
                )}
              {hasPreviousAppointment ? (
                <InlineMessage
                  ssrIcon={infoCircle}
                  variant="informative"
                  title={t("manage-opportunity.actions.same-slot.info-header")}
                  body={t("manage-opportunity.actions.same-slot.info-content")}
                />
              ) : null}
              {!(
                selectedAction.action.kind === "SCHEDULE_AN_APPOINTMENT" &&
                enableFollowupBooking
              ) && (
                <TextAreaFixed
                  id="comment"
                  maxLength={400}
                  label={t("manage-opportunity.actions.comment.label")}
                  placeholder={t(
                    "manage-opportunity.actions.comment.placeholder"
                  )}
                  rows={4}
                  optional
                  value={selectedAction.comment}
                  onChange={(e) => {
                    setSelectedAction({
                      ...selectedAction,
                      comment: e.target.value,
                    });
                  }}
                />
              )}
              {selectedAction.action.kind === "SCHEDULE_CALL" && (
                <Checkbox
                  id="confirmationMessage"
                  disabled={false}
                  keepTogether={true}
                  checked={!!selectedAction.sendCommunication}
                  label={t("manage-opportunity.actions.confirmation.message")}
                  onChange={(value) => {
                    setSelectedAction({
                      ...selectedAction,
                      sendCommunication: value,
                    });
                  }}
                />
              )}
              {(selectedAction?.action.kind !== "SCHEDULE_AN_APPOINTMENT" ||
                !enableFollowupBooking) && (
                <Box justifyContent="flex-end">
                  <Button
                    small
                    emphasised
                    text={t("manage-opportunity.actions.save-action")}
                    disabled={!isValid}
                    loading={isLoading}
                    onClick={saveAction}
                  />
                </Box>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

RecommendedActions.displayName = "RecommendedActions";
