import { Notification } from "common/api";
import {
  Box,
  Icon,
  LocalizedDate,
  PopoverItem,
  PopoverMenu,
  Stack,
  Text,
} from "components";
import { getReminderIconByActivityType } from "common";
import { Button } from "components";
import { T, t } from "i18n";

interface IReminderItemProps {
  reminder: Notification;
  odd: boolean;
  markAsDone: (reminder: Notification) => Promise<void>;
}

export const ReminderItem: React.FunctionComponent<IReminderItemProps> = ({
  reminder,
  odd,
  markAsDone,
}) => {
  return (
    <Box padding={20} color={odd ? "bjornGrey" : "neutralWhite"}>
      <Stack vertical>
        <Stack justifyContent="space-between" alignItems="center">
          <Stack spacing={10}>
            <Icon
              icon={getReminderIconByActivityType(
                reminder.type,
                reminder.subType
              )}
            />
            <Text bold>{reminder.title}</Text>
          </Stack>
          <PopoverMenu>
            <PopoverItem
              text={t("my-opportunities.view-my-tasks.mark-as-done.button")}
              onClick={() => {
                markAsDone(reminder);
              }}
            />
          </PopoverMenu>
        </Stack>
        <Stack vertical spacing={6}>
          <Stack spacing={6} alignItems="center">
            <Icon icon="ClockIcon" color="redIdle" size={14} />
            <Text>
              <LocalizedDate pattern="Pp">
                {reminder.appointmentTime}
              </LocalizedDate>
            </Text>
          </Stack>
          {reminder.opportunityTitle && (
            <Text small>
              <T id="reminder.item.opportunity.title" />:{" "}
              {reminder.opportunityTitle}
            </Text>
          )}
          {reminder.opportunityId && (
            <Text small>ID: {reminder.opportunityId}</Text>
          )}
          {reminder.customerName && (
            <Text small>
              <T id="reminder.item.opportunity.customer" />:{" "}
              {reminder.customerName}
            </Text>
          )}
        </Stack>
        {reminder.comment !== ": " && (
          <Text pre maxCharacters={80} wordBreakAll>
            {reminder.comment}
          </Text>
        )}
        {reminder.opportunityId && (
          <Button
            small
            primary
            link={`/opportunity/${reminder.opportunityId}#id=${reminder.id}`}
            text={t("reminder.item.button.text")}
          />
        )}
      </Stack>
    </Box>
  );
};

ReminderItem.displayName = "ReminderItem";
