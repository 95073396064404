import React, { useState } from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { Notifications } from "./Notifications.component";
import { Reminders } from "./Reminders.component";
import { useNotifications } from "common/api";
import notification from "@ingka/ssr-icon/paths/information-circle";
import { Button } from "components";
import { ModalSheet, Box } from "components";
import { css } from "@emotion/css";
import { t } from "i18n";

export const RemindersAndNotifications = () => {
  const { data, isValidating: isLoading } = useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const notifications = data
    ? data.filter((isNotification) => isNotification.isDeleted === false)
    : [];
  return (
    <Box>
      <Button
        small
        iconPosition="leading"
        ssrIcon={notification}
        attention={notifications.length > 0}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        text={notifications?.length.toString() || "0"}
      />
      <ModalSheet
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("reminders-and-notifications.modal.title")}
        closeText={t("reminders-and-notifications.modal.close-text")}
        secondaryButtonProps={{ loading: isLoading }}
      >
        <Tabs
          defaultActiveTab="reminders"
          tabs={[
            <Tab
              key="tab-1"
              text={t("reminders-and-notifications.string.reminders")}
              tabPanelId="reminders"
            />,
            <Tab
              key="tab-2"
              text={t("reminders-and-notifications.string.notifications")}
              tabPanelId="notifications"
            />,
          ]}
          tabPanels={[
            <TabPanel key="tab-1" tabPanelId="reminders">
              <Reminders />
            </TabPanel>,
            <TabPanel key="tab-2" tabPanelId="notifications">
              <Notifications />
            </TabPanel>,
          ]}
          className={css`
            .tabs__tab {
              width: 47%;
            }
          `}
        />
      </ModalSheet>
    </Box>
  );
};

RemindersAndNotifications.displayName = "RemindersAndNotifications";
