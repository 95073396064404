import Banner from "@ingka/banner";
import { useSettings } from "settings/SettingsProvider";
export const AlertBanner = () => {
  const { health_monitoring, system_failure } = useSettings();

  const customAlert = health_monitoring?.alert;

  const displayAlert =
    system_failure?.failure ||
    (customAlert?.enabled &&
      customAlert.message !== "" &&
      (!customAlert?.clearing_time ||
        new Date() < new Date(customAlert?.clearing_time)));

  const alertMessage = system_failure?.failure
    ? system_failure.message
    : customAlert?.message;

  const dismissable = system_failure?.failure ? false : true;

  const emergency = system_failure?.failure
    ? true
    : customAlert?.emergency ?? false;

  if (displayAlert) {
    return (
      <div style={{ width: "100%" }}>
        <Banner
          body={alertMessage!}
          dismissable={dismissable}
          emergency={emergency}
        />
      </div>
    );
  }

  return null;
};
