import { useContext, useEffect, useState } from "react";
import { ModalHeader, ModalFooter } from "components/ui/modal";
import { Stack } from "components/layout";
import { Text } from "components/typography";
import { useHistory } from "react-router-dom";
import { findDialingCode, useValidationRules } from "common";
import { Form, Formik } from "formik";
import {
  createCustomer,
  CreateCustomerPayload,
  CustomerGlobal,
} from "common/api/customer";
import { CustomerForm } from "./CustomerForm";
import * as yup from "yup";
import { Toast } from "components/ui";
import { OpportunityContext } from "../OpportunityContext";
import { useConfig } from "common/api";
import { t } from "i18n";
import { tracker } from "analytics";
import { useUserLocation } from "common/api/user/userLocation";

function setCustomerData(
  values: Partial<CreateCustomerPayload>,
  response: { partyId: string },
  privacyPolicy: boolean
): CustomerGlobal {
  return {
    ...values,
    countryCode: values.country || "",
    languageCode: values.preferredLanguage || "",
    partyId: response.partyId,
    consents: {
      privacyPolicy,
    },
  } as CustomerGlobal;
}

const IGNORE_FIELDS = ["phonePrefix"];
// Remove fields from Formdata that shouldn't be posted to server
function cleanFormData(values: object) {
  IGNORE_FIELDS.forEach((field) => delete values[field]);
  return values;
}

export const NewCustomer = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const { data: config } = useConfig();
  const userLocation = useUserLocation();
  const validationRules = useValidationRules();

  const { consents, setCustomer, setConsents } = useContext(OpportunityContext);
  const { privacyPolicy } = consents;
  const hasConsented = privacyPolicy;

  useEffect(() => {
    if (config?.customer.requireConsent === false) {
      setConsents({
        ...consents,
        privacyPolicy: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, setConsents]);

  const initialValues = {
    customerType: "Individual",
    organizationName: "",
    firstName: "",
    lastName: "",
    streetAddress1: "",
    streetAddress2: "",
    streetAddress3: "",
    postalCode: "",
    city: "",
    email: "",
    phoneNumber: "",
    preferredLanguage: "en",
    companyNumber: "",
    vatNumber: "",
    country: userLocation || "SE",
  };

  const validationSchema = yup
    .object({
      customerType: yup.string().oneOf(["Individual", "Business"]).required(),
      organizationName: yup.string().when("customerType", {
        is: "Business",
        then: yup.string().required(t("new-opportunity.form.required")),
        otherwise: yup.string().optional(),
      }),
      firstName: yup.string().required(t("new-opportunity.form.required")),
      lastName: yup.string().when("customerType", {
        is: "Individual",
        then: yup.string().required(t("new-opportunity.form.required")),
        otherwise: yup.string().optional(),
      }),
      streetAddress1: yup.string().required(t("new-opportunity.form.required")),
      streetAddress2: yup.string().optional(),
      streetAddress3: yup.string().optional(),
      postalCode: yup
        .string()
        .required(t("new-opportunity.form.required"))
        .matches(
          new RegExp(validationRules.customer.postalCode.regExp),
          t("new-opportunity.customer.form.postal-code.error", {
            example: validationRules.customer.postalCode.example,
          })
        ),
      city: yup.string().required(t("new-opportunity.form.required")),
      email: yup
        .string()
        .required(t("new-opportunity.form.required"))
        .matches(
          new RegExp(validationRules.customer.email.regExp),
          t("new-opportunity.customer.form.email.error", {
            example: validationRules.customer.email.example,
          })
        ),
      phoneNumber: yup
        .string()
        .required(t("new-opportunity.form.required"))
        .test("is-valid-phone-number", function (value, testContext) {
          const dialingCode = findDialingCode(testContext.parent.phonePrefix);

          const r = new RegExp(dialingCode?.regex ?? "^\\+\\d{7,14}$");
          if (r.test(value ?? "")) {
            return true;
          }

          return this.createError({
            message: dialingCode?.example
              ? t("new-opportunity.customer.form.mobile.errorWithExample", {
                  example: dialingCode.example,
                })
              : t("new-opportunity.customer.form.mobile.error"),
            path: testContext.path,
          });
        }),
      preferredLanguage: yup.string(),
    })
    .defined();

  const onSubmit = async (values, { setSubmitting }) => {
    setError(null);

    const payload = {
      ...cleanFormData(values),
      consents: {
        privacyPolicy,
      },
    } as CreateCustomerPayload;

    try {
      const response = await createCustomer(payload);
      const customer = setCustomerData(values, response, privacyPolicy);
      setCustomer(customer);
      history.push({ hash: "/new-opportunity" });
    } catch (theError) {
      setError(theError);
      // eslint-disable-next-line no-console
      console.error(theError);
    }

    setSubmitting(false);
  };

  const onCustomerTypeChange = (formik): void => {
    formik.resetForm({
      values: {
        ...formik.values,
        organizationName: "",
        companyNumber: "",
        vatNumber: "",
      },
    });
  };

  return (
    <>
      {error && <Toast text={t("new-opportunity.customer.error")} />}
      <ModalHeader title={t("new-opportunity.modal.header")} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <Stack vertical spacing={24}>
              <Text header="h3">{t("new-opportunity.customer.heading")}</Text>
              <Text>{t("new-opportunity.customer.description")}</Text>
              <CustomerForm
                isBusiness={formik.values.customerType === "Business"}
                onCustomerTypeChange={() => {
                  onCustomerTypeChange(formik);
                }}
              />
            </Stack>
            <ModalFooter
              primaryButtonProps={{
                text: t("new-opportunity.modal.continue"),
                loading: formik.isSubmitting,
                disabled:
                  formik.isSubmitting || !(formik.isValid && hasConsented),
                onClick: () => {
                  tracker.event({
                    category: "New Sales Opportunity Creation",
                    action: "Clicked on 'Continue' after customer creation",
                    label:
                      formik.values.customerType === "Individual"
                        ? "Private"
                        : "Business",
                  });
                  formik.handleSubmit();
                },
              }}
              secondaryButtonProps={{
                text: t("modal.cancel"),
                onClick: () => {
                  history.goBack();
                },
              }}
            ></ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  );
};
