import { Box } from "../layout/Box.component";
import SkapaLoading, { LoadingBall } from "@ingka/loading";
import { css } from "@emotion/css";
import { t } from "i18n";

interface Props {
  /** What text to display when loading spinner is shown. Defaults to "Loading" */
  text?: string;
  /** Wether to show the loading spinner or not */
  isLoading: boolean;
  /** Display the loading spinner in the center of the window */
  fullscreen?: boolean;
}

/**
 * A wrapper for the SKAPA Loading component that renders the loading spinner
 * inside a box in order to make it fit our designs.
 */
export const Loading = ({ text, isLoading, fullscreen }: Props) =>
  isLoading ? (
    <Box
      position={fullscreen ? "absolute" : "relative"}
      width={fullscreen ? "100%" : undefined}
      height={fullscreen ? "100%" : undefined}
      alignItems="center"
      justifyContent="center"
      padding={fullscreen ? 0 : 40}
    >
      <SkapaLoading
        text={text || t("loading")}
        className={css`
          span.h3 {
            max-width: 100%;
          }
        `}
      >
        <LoadingBall />
      </SkapaLoading>
    </Box>
  ) : null;
