import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useRef,
} from "react";

/**
 * Run an effect with a debounce.
 * Should be a drop-in replacement for useEffect with
 * an added debounce time parameter.
 * 
 * @see https://stackoverflow.com/a/61127960
 * @see https://github.com/samanmohamadi/use-debounced-effect
 
 * @param effect  The function to run
 * @param wait    The amount of ms to debounce it for
 * @param deps    THe dependency list
 */
export const useDebouncedEffect = (
  effect: EffectCallback,
  wait: number,
  deps?: DependencyList
) => {
  const initialRender = useRef(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps || []);

  useEffect(() => {
    // Skip effect on initial render.
    if (initialRender.current) {
      return void (initialRender.current = false);
    }

    const handler = setTimeout(() => {
      callback();
    }, wait);

    return () => clearTimeout(handler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, wait, ...(deps || [])]);
};
