export const languageCodeToLanguage = {
  aa: "Afar",
  ab: "Abkhazian",
  af: "Afrikaans",
  ak: "Akan",
  als: "Alemannic",
  am: "Amharic",
  an: "Aragonese",
  ang: "Angal",
  ar: "Arabic",
  arc: "Aramaic",
  as: "Assamese",
  ast: "Asturian",
  av: "Avar",
  awa: "Awadhi",
  ay: "Aymara",
  az: "Azerbaijani",
  ba: "Bashkir",
  bar: "Bavarian",
  "bat-smg": "Samogitian",
  bcl: "Bikol",
  be: "Belarusian",
  "be-x-old": "Belarusian (Taraškievica)",
  bg: "Bulgarian",
  bh: "Bihari",
  bi: "Bislama",
  bm: "Bambara",
  bn: "Bengali",
  bo: "Tibetan",
  bpy: "Bishnupriya Manipuri",
  br: "Breton",
  bs: "Bosnian",
  bug: "Buginese",
  bxr: "Buriat (Russia)",
  ca: "Catalan",
  cdo: "Min Dong Chinese",
  ce: "Chechen",
  ceb: "Cebuano",
  ch: "Chamorro",
  cho: "Choctaw",
  chr: "Cherokee",
  chy: "Cheyenne",
  co: "Corsican",
  cr: "Cree",
  cs: "Czech",
  csb: "Kashubian",
  cu: "Old Church Slavonic / Old Bulgarian",
  cv: "Chuvash",
  cy: "Welsh",
  da: "language.danish",
  de: "language.german",
  diq: "Dimli",
  dsb: "Lower Sorbian",
  dv: "Divehi",
  dz: "Dzongkha",
  ee: "Ewe",
  el: "Greek",
  en: "language.english",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  eu: "Basque",
  ext: "Extremaduran",
  fa: "Persian",
  ff: "Peul",
  fi: "language.finnish",
  "fiu-vro": "Võro",
  fj: "Fijian",
  fo: "Faroese",
  fr: "language.french",
  frp: "Arpitan / Franco-Provençal",
  fur: "Friulian",
  fy: "West Frisian",
  ga: "Irish",
  gan: "Gan Chinese",
  gbm: "Garhwali",
  gd: "Scottish Gaelic",
  gil: "Gilbertese",
  gl: "Galician",
  gn: "Guarani",
  got: "Gothic",
  gu: "Gujarati",
  gv: "Manx",
  ha: "Hausa",
  hak: "Hakka Chinese",
  haw: "Hawaiian",
  he: "Hebrew",
  hi: "Hindi",
  ho: "Hiri Motu",
  hr: "language.croatian",
  ht: "Haitian",
  hu: "Hungarian",
  hy: "Armenian",
  hz: "Herero",
  ia: "Interlingua",
  id: "Indonesian",
  ie: "Interlingue",
  ig: "Igbo",
  ii: "Sichuan Yi",
  ik: "Inupiak",
  ilo: "Ilokano",
  inh: "Ingush",
  io: "Ido",
  is: "Icelandic",
  it: "language.italian",
  iu: "Inuktitut",
  ja: "Japanese",
  jbo: "Lojban",
  jv: "Javanese",
  ka: "Georgian",
  kg: "Kongo",
  ki: "Kikuyu",
  kj: "Kuanyama",
  kk: "Kazakh",
  kl: "Greenlandic",
  km: "Cambodian",
  kn: "Kannada",
  khw: "Khowar",
  ko: "Korean",
  kr: "Kanuri",
  ks: "Kashmiri",
  ksh: "Ripuarian",
  ku: "Kurdish",
  kv: "Komi",
  kw: "Cornish",
  ky: "Kirghiz",
  la: "Latin",
  lad: "Ladino / Judeo-Spanish",
  lan: "Lango",
  lb: "Luxembourgish",
  lg: "Ganda",
  li: "Limburgian",
  lij: "Ligurian",
  lmo: "Lombard",
  ln: "Lingala",
  lo: "Laotian",
  lzz: "Laz",
  lt: "Lithuanian",
  lv: "Latvian",
  "map-bms": "Banyumasan",
  mg: "Malagasy",
  man: "Mandarin",
  mh: "Marshallese",
  mi: "Maori",
  min: "Minangkabau",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mo: "Moldovan",
  mr: "Marathi",
  mrh: "Mara",
  ms: "Malay",
  mt: "Maltese",
  mus: "Creek / Muskogee",
  mwl: "Mirandese",
  my: "Burmese",
  na: "Nauruan",
  nah: "Nahuatl",
  nap: "Neapolitan",
  nd: "North Ndebele",
  nds: "Low German / Low Saxon",
  "nds-nl": "Dutch Low Saxon",
  ne: "Nepali",
  new: "Newar",
  ng: "Ndonga",
  nl: "Dutch",
  nn: "Norwegian Nynorsk",
  no: "language.norwegian",
  nr: "South Ndebele",
  nso: "Northern Sotho",
  nrm: "Norman",
  nv: "Navajo",
  ny: "Chichewa",
  oc: "Occitan",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "Ossetian / Ossetic",
  pa: "Panjabi / Punjabi",
  pag: "Pangasinan",
  pam: "Kapampangan",
  pap: "Papiamentu",
  pdc: "Pennsylvania German",
  pi: "Pali",
  pih: "Norfolk",
  pl: "language.polish",
  pms: "Piedmontese",
  ps: "Pashto",
  pt: "language.portuguese",
  qu: "Quechua",
  rm: "Raeto Romance",
  rmy: "Romani",
  rn: "Kirundi",
  ro: "language.romanian",
  "roa-rup": "Aromanian",
  ru: "Russian",
  rw: "Rwandi",
  sa: "Sanskrit",
  sc: "Sardinian",
  scn: "Sicilian",
  sco: "Scots",
  sd: "Sindhi",
  se: "Northern Sami",
  sg: "Sango",
  sh: "Serbo-Croatian",
  si: "Sinhalese",
  simple: "Simple English",
  sk: "Slovak",
  sl: "language.slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somalia",
  sq: "Albanian",
  sr: "language.serbian",
  ss: "Swati",
  st: "Southern Sotho",
  su: "Sundanese",
  sv: "language.swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  tet: "Tetum",
  tg: "Tajik",
  th: "Thai",
  ti: "Tigrinya",
  tk: "Turkmen",
  tl: "Tagalog",
  tlh: "Klingon",
  tn: "Tswana",
  to: "Tonga",
  tpi: "Tok Pisin",
  tr: "Turkish",
  ts: "Tsonga",
  tt: "Tatar",
  tum: "Tumbuka",
  tw: "Twi",
  ty: "Tahitian",
  udm: "Udmurt",
  ug: "Uyghur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  ve: "Venda",
  vi: "Vietnamese",
  vec: "Venetian",
  vls: "West Flemish",
  vo: "Volapük",
  wa: "Walloon",
  war: "Waray / Samar-Leyte Visayan",
  wo: "Wolof",
  xal: "Kalmyk",
  xh: "Xhosa",
  xmf: "Megrelian",
  yi: "Yiddish",
  yo: "Yoruba",
  za: "Zhuang",
  zh: "Chinese",
};
