import { useState } from "react";
import { Button, Stack, Text } from "components";
import { Tasks } from "features/tasks";
import { Notes } from "features/notes";
import { Links } from "features/links";
import { tracker } from "analytics";
import { t, T } from "i18n";

export const OpportunitiesAddons = () => {
  const [tasksIsOpen, setTasksIsOpen] = useState<boolean>(false);
  const [notesIsOpen, setNotesIsOpen] = useState<boolean>(false);
  const [linksIsOpen, setLinksIsOpen] = useState<boolean>(false);

  return (
    <>
      <Text header="h2">
        <T id="my-opportunities.addons.title" />
      </Text>
      <Text>
        <T id="my-opportunities.addons.description" />
      </Text>
      <Stack>
        <Button
          text={t("opportunities.addons.tasks.button")}
          small
          primary
          onClick={() => {
            tracker.event({
              category: "My Addons",
              action: "Clicked on 'My tasks'",
            });
            setTasksIsOpen(true);
          }}
        />

        <Tasks isOpen={tasksIsOpen} onClose={() => setTasksIsOpen(false)} />

        <Button
          text={t("opportunities.addons.notes.button")}
          small
          primary
          onClick={() => {
            tracker.event({
              category: "My Addons",
              action: "Clicked on 'My notes'",
            });
            setNotesIsOpen(true);
          }}
        />

        <Notes isOpen={notesIsOpen} onClose={() => setNotesIsOpen(false)} />

        <Button
          text={t("opportunities.addons.links.button")}
          small
          primary
          onClick={() => {
            tracker.event({
              category: "My Addons",
              action: "Clicked on 'My links'",
            });
            setLinksIsOpen(true);
          }}
        />

        <Links isOpen={linksIsOpen} onClose={() => setLinksIsOpen(false)} />
      </Stack>
    </>
  );
};
