import { Link } from "react-router-dom";
import { Button, Color, Icon } from "components";
import { t, T } from "i18n";
import {
  AppointmentsSearchFilters,
  AppointmentsSearchOptions,
} from "common/api";
import {
  LocalizedDate,
  Stack,
  Text,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from "components";
import { tracker } from "analytics";
import { Appointment } from "common/api";
import { useState } from "react";
import { AppointmentQuickView } from "./AppointmentQuickView";
import Tooltip from "@ingka/tooltip";
import DocumentCheckmark from "@ingka/ssr-icon/paths/document-checkmark";
import { CoWorkerName } from "common";
import { AppointmentStatus } from "./AppointmentStatus";
import { AppointmentService } from "./AppointmentService";
import {
  AppointmentPaymentStatus,
  mapStatusToPaymentStatus,
} from "./AppointmentPaymentStatus";
import { parseTitle } from "features/opportunities/OpportunitiesTab";
import { useSettings } from "settings/SettingsProvider";

export type AppointmentsTableProps = {
  isMe: boolean;
  numOfItems: number;
  appointments: Appointment[];
  filters: AppointmentsSearchFilters;
  options: AppointmentsSearchOptions;
  onSortKeyChange: (sortKey: string) => void;
  onSortOrderChange: (sortOrder: "asc" | "desc") => void;
};

type QuestionnaireStatus = "no_questions" | "not_answered" | "answered";
type Indicator = {
  color: Color;
  text: string;
};

const QuestionnaireIndicator = ({ available, answered }) => {
  const questionnaireStatus: QuestionnaireStatus = available
    ? answered
      ? "answered"
      : "not_answered"
    : "no_questions";
  const indicator: Indicator = { color: "bjornGrey", text: "" };
  switch (questionnaireStatus) {
    case "no_questions":
      indicator.color = "neutralGrey300";
      indicator.text = t("appointment.no-questionnaire.label");
      break;
    case "not_answered":
      indicator.color = "redHover";
      indicator.text = "Not answered";
      break;
    case "answered":
      indicator.color = "greenHover";
      indicator.text = "Answered";
      break;
  }
  return (
    <Tooltip tooltipText={indicator.text}>
      <Icon size={10} icon="Circle" color={indicator.color} />
    </Tooltip>
  );
};

export const AppointmentsTable = ({
  isMe,
  options,
  numOfItems,
  onSortKeyChange,
  onSortOrderChange,
  appointments,
}: AppointmentsTableProps) => {
  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>(null);
  const { boka_config } = useSettings();

  return (
    <>
      <Stack vertical spacing={24}>
        <Text header="h3">
          <T
            id="opportunities.table.items.state"
            values={{
              items: appointments.length,
              total: numOfItems,
            }}
          />
        </Text>
        <Table
          sortKey={options.sortKey}
          sortOrder={options.sortOrder}
          onSortKeyChange={onSortKeyChange}
          onSortOrderChange={onSortOrderChange}
        >
          <thead>
            <TableRow>
              <TableHeader>
                <T id="opportunities.table.header.customer" />
              </TableHeader>
              <TableHeader sortKey="timeWindowStartTime" minWidth="110px">
                <T id="appointment.time.label" />
              </TableHeader>
              <TableHeader sortKey="service">
                <T id="appointment.service.label" />
              </TableHeader>
              <TableHeader sortKey="locationName">
                <T id="opportunities.filters.businessunit.title" />
              </TableHeader>

              <TableHeader sortKey="appointmentStatus" minWidth="90px">
                <T id="opportunities.table.header.status" />
              </TableHeader>
              <TableHeader>
                <T id="appointment.payment.label" />
              </TableHeader>
              {boka_config?.enable && (
                <TableHeader>
                  <Tooltip
                    tooltipText={t("appointment.questionnaire.label")}
                    ssrIcon={DocumentCheckmark}
                  />
                </TableHeader>
              )}
              {!isMe && (
                <TableHeader>
                  <T id="appointment.assignedto.label" />
                </TableHeader>
              )}
              <TableHeader />
            </TableRow>
          </thead>
          <tbody>
            {appointments?.map((appointment) => (
              <TableRow
                key={appointment.id}
                onCellClick={() => setSelectedAppointment(appointment)}
              >
                <TableCell>
                  <Text
                    small
                  >{`${appointment.customer?.firstName} ${appointment.customer?.lastName}`}</Text>
                  <Text xsmall nowrap color="neutralGrey700">
                    <T id="appointments.table.title.opportunity" />:{" "}
                    {parseTitle(appointment.opportunityTitle)}
                  </Text>
                </TableCell>
                <TableCell>
                  <Text small inline>
                    <LocalizedDate>
                      {appointment.timeWindowStartTime}
                    </LocalizedDate>
                  </Text>
                  <Text xsmall color="neutralGrey700">
                    <LocalizedDate pattern="p">
                      {appointment.timeWindowStartTime}
                    </LocalizedDate>
                    {" - "}
                    <LocalizedDate pattern="p">
                      {appointment.timeWindowEndTime}
                    </LocalizedDate>
                  </Text>
                </TableCell>
                <TableCell>
                  <Text small wordBreakWord>
                    <AppointmentService id={appointment.service} />
                  </Text>
                </TableCell>
                <TableCell>
                  <Text small>{appointment.locationName}</Text>
                </TableCell>

                <TableCell>
                  <Text small>
                    <AppointmentStatus id={appointment.appointmentStatus} />
                  </Text>
                </TableCell>
                <TableCell>
                  <Text small>
                    <AppointmentPaymentStatus
                      id={
                        appointment.paymentStatus ??
                        mapStatusToPaymentStatus(
                          appointment.appointmentStatus,
                          appointment.price
                        )
                      }
                    />
                  </Text>
                </TableCell>
                {boka_config?.enable && (
                  <TableCell>
                    <QuestionnaireIndicator
                      available={appointment.questionsInfo?.questionsAvailable}
                      answered={appointment.questionsInfo?.questionsAnswered}
                    />
                  </TableCell>
                )}
                {!isMe && (
                  <TableCell maxWidth="200px">
                    <Text small wordBreakWord>
                      {(
                        <CoWorkerName>
                          {appointment.assignedCoworker}
                        </CoWorkerName>
                      ) ?? appointment.assignedCoworker}
                    </Text>
                  </TableCell>
                )}
                <TableCell preventCellClick>
                  <Link
                    to={`/opportunity/${appointment.opportunityId}#section=appointment&appointmentId=${appointment.id}`}
                  >
                    <Button
                      text={t("opportunities.table.manage.button")}
                      small
                      primary
                      onClick={() => {
                        tracker.event({
                          category: "Open Manage Opportunity",
                          action: "Clicked on 'Manage' button in table",
                        });
                      }}
                    />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>

        <AppointmentQuickView
          appointment={selectedAppointment}
          onClose={() => setSelectedAppointment(null)}
        />
      </Stack>
    </>
  );
};
