import { Stack } from "components/layout";
import { Text } from "components/typography";
import CheckBox from "@ingka/checkbox";
import { t } from "i18n";
import { renderLinkedText } from "common/helpers/renderLinkedText";

interface Props {
  onSelectionChange: (isApproved: boolean) => void;
}

export const PrivacyPolicyConsent = ({ onSelectionChange }: Props) => {
  const onChange = (e) => {
    onSelectionChange(e.target.checked);
  };

  return (
    <Stack vertical spacing={10}>
      <Text header="h3">
        {t("new-opportunity.customer-consent.privacy-policy.title")}
      </Text>
      <Stack vertical maxHeight="300px">
        <Text pre>
          {renderLinkedText(
            t("new-opportunity.customer-consent.privacy-policy.description")
          )}
        </Text>
      </Stack>
      <CheckBox
        id="privacy-policy-consent"
        label={t("new-opportunity.customer-consent.privacy-policy.agree")}
        value="agree"
        onChange={onChange}
      />
    </Stack>
  );
};
