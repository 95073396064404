import { environment } from "./environment";

const storageKey = "sa-features";

const availableFeatures = {
  localization: true,
  showOfficialLocalesOnly: environment.isProd || environment.isCTE,
};

const getFeatureFlagOverrides = (): Partial<typeof availableFeatures> => {
  try {
    const features = window.sessionStorage.getItem(storageKey);
    if (!features) return {};
    return JSON.parse(features);
  } catch (e) {
    return {};
  }
};

export const features: typeof availableFeatures = {
  ...availableFeatures,
  ...getFeatureFlagOverrides(),
};
