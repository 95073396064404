import { isNil, omit, omitBy } from "lodash";
import {
  getUserProfileCountryCode,
  getUserProfileNetworkName,
} from "common/graphApi";
import { getLoginToken, getToken } from "authorization";
import { ApiError } from "./ApiError";
import { getCachedLocale } from "common/hooks";
import { graphEndpoint } from "authorization/msalConfig";

// We should send this header to global API
const checkShouldSendUserId = (apiUrl: string = "") =>
  apiUrl.includes("/global/");

class Fetcher {
  private api: string;
  private getAccessToken: Function;

  constructor(api: string, getAccessToken?: Function) {
    this.api = api;
    this.getAccessToken = getAccessToken || getToken;
  }

  private async query(
    endpoint: string,
    options: Omit<RequestInit, "headers"> & {
      headers?: Record<string, string | undefined>;
    }
  ) {
    const accessToken = await this.getAccessToken();
    const countryCode = await getUserProfileCountryCode();
    const networkName = getUserProfileNetworkName();
    const locale = getCachedLocale();

    if (!accessToken || !countryCode) {
      throw new Error("Missing api headers");
    }

    const headers = omitBy(
      {
        Accept: "application/json",
        "Accept-Language": locale?.toString(),
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
        // TODO: Remove when API:s have been updated
        country_code: countryCode,
        "X-Market": countryCode,
        x_user_id: checkShouldSendUserId(endpoint) ? networkName : null,
        ...options?.headers,
      },
      isNil
    );

    const res = await fetch(this.api + endpoint, {
      headers,
      ...omit(options, "headers"),
    }).catch((e) => {
      throw new ApiError(500, e);
    });

    if (res.status > 299) {
      const data = await res?.json();
      throw new ApiError(res.status, data.message);
    }

    return res;
  }

  public async get<T>(
    endpoint: string,
    headers?: Record<string, string>
  ): Promise<T> {
    const res = await this.query(endpoint, {
      method: "GET",
      headers,
    });

    return res.json();
  }

  async post<T>(
    endpoint: string,
    data: object,
    headers?: Record<string, string>
  ): Promise<T> {
    const res = await this.query(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
      headers,
    });

    return res.json();
  }

  async update(endpoint: string, data: object): Promise<Response> {
    return this.query(endpoint, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async patch(endpoint: string, data: object): Promise<Response> {
    return this.query(endpoint, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
  }

  async remove(endpoint: string): Promise<Response> {
    return this.query(endpoint, {
      method: "DELETE",
    });
  }

  async download(endpoint: string): Promise<Blob> {
    const res = await this.query(endpoint, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    });

    const data = await res.arrayBuffer();
    const file = new Blob([data], { type: "application/pdf" });
    return file;
  }

  async upload(endpoint: string, file: File): Promise<Response> {
    const data = new FormData();
    data.append("file", file);

    return this.query(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": undefined,
      },
      body: data,
    });
  }
}

export const core = new Fetcher(process.env.REACT_APP__API_URL__!);
export const graph = new Fetcher(graphEndpoint, getLoginToken);

export const opportunity = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/opportunities"
);

export const notification = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/coworkers/notifications"
);

export const action = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/fog/actions"
);

export const link = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/coworkers/links"
);

export const note = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/coworkers/notes"
);

export const preference = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/coworkers/preferences"
);

export const coworkers = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/coworkers"
);

export const global = new Fetcher(process.env.REACT_APP__API_URL__ + "/global");

export const stores = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/global/stores"
);

export const designSearch = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/global/design-planner"
);

export const VPCSearch = new Fetcher(
  process.env.REACT_APP__API_URL__ + "/global/other-planner"
);
