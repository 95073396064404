import { initSentry } from "./sentry";
import { useProfile } from "common/api";
import React from "react";
import {
  ErrorBoundary as SentryErrorBoundary,
  ReportDialogOptions,
} from "@sentry/react";
import { t } from "i18n";

initSentry();

type Props = {
  children: React.ReactNode;
};

export const ErrorBoundary = ({ children }: Props) => {
  const { data: user } = useProfile();

  const dialogOptions: ReportDialogOptions = {
    user: {
      email: user?.mail || "",
      name: user?.displayName || "",
    },
    title: t("error-form.title"),
    subtitle: t("error-form.subtitle"),
    subtitle2: t("error-form.subtitle2"),
    labelName: t("error-form.name.label"),
    labelEmail: t("error-form.email.label"),
    labelComments: t("error-form.comments.label"),
    labelClose: t("error-form.close.label"),
    labelSubmit: t("error-form.submit.label"),
    errorGeneric: t("error-form.error-generic"),
    errorFormEntry: t("error-form.error-form-entry"),
    successMessage: t("error-form.success-message"),
  };

  return (
    <SentryErrorBoundary showDialog dialogOptions={dialogOptions}>
      {children}
    </SentryErrorBoundary>
  );
};
