import { ReactNode, useContext } from "react";
import { TableContext } from "./TableContext";
import { Chevron, Colors, Text } from "components";
import { css } from "@emotion/css";

export type TableHeaderProps = {
  sortKey?: string;
  children?: ReactNode;
  minWidth?: string;
};

export const TableHeader = ({
  sortKey,
  children,
  minWidth,
}: TableHeaderProps) => {
  const ctx = useContext(TableContext);

  const handleClick = () => {
    if (!sortKey) return;

    if (sortKey === ctx.sortKey)
      return void ctx.onSortOrderChange?.(
        ctx.sortOrder === "asc" ? "desc" : "asc"
      );

    ctx.onSortKeyChange?.(sortKey);
  };

  return (
    <th
      className={css`
        padding: 5px;
        border-bottom: 1px solid ${Colors.neutralGrey500};
        cursor: ${sortKey ? "pointer" : "auto"};
        min-width: ${minWidth ?? "auto"};
      `}
      onClick={handleClick}
    >
      <Text bold small>
        {children}
        {sortKey && sortKey === ctx.sortKey && (
          <span
            className={css`
              margin-left: 5px;
              min-width: ${minWidth ?? "auto"};
            `}
          >
            <Chevron rotated={ctx.sortOrder === "desc"} />
          </span>
        )}
      </Text>
    </th>
  );
};
