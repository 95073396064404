import { Order } from "common/api";
import { ProductList } from "common";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { CustomerDetails } from "./CustomerDetails.component";
import { t } from "i18n";

interface Props {
  order: Order;
}

export const ViewOrder = ({ order }: Props) => {
  return (
    <Tabs
      defaultActiveTab="products-tab"
      tabs={[
        <Tab key="tab-1" text={"Products"} tabPanelId="products-tab" />,
        order.deliveryContact ? (
          <Tab
            key="tab-2"
            text={t("manage-opportunity.order-info.billing-address")}
            tabPanelId="billing-tab"
          />
        ) : undefined,
        order.deliveryContact ? (
          <Tab
            key="tab-3"
            text={t("manage-opportunity.order-info.delivery-address")}
            tabPanelId="delivery-tab"
          />
        ) : undefined,
      ].filter((tab) => tab !== undefined)}
      tabPanels={[
        <TabPanel key="tab-1" tabPanelId="products-tab">
          <ProductList
            product={order}
            amounts={order.amounts}
            currency={order.amounts.Total?.currencyCode}
          />
        </TabPanel>,
        order.billingContact ? (
          <TabPanel key="tab-2" tabPanelId="billing-tab">
            <CustomerDetails customer={order.billingContact} />
          </TabPanel>
        ) : undefined,
        order.deliveryContact ? (
          <TabPanel key="tab-3" tabPanelId="delivery-tab">
            {order.deliveryContact && (
              <CustomerDetails customer={order.deliveryContact} />
            )}
          </TabPanel>
        ) : undefined,
      ].filter((tab) => tab !== undefined)}
    />
  );
};

ViewOrder.displayName = "ViewOrder";
