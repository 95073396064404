import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Box, Stack, Text, LocalizedDate, Icon, Checkbox } from "components";
import Skeleton from "@ingka/skeleton";
import { css } from "@emotion/css";
import {
  getReminderIconByActivityType,
  CoWorkerName,
  parseHashRoute,
  getHistoryIcon,
} from "common";
import {
  Opportunity,
  Activity,
  useTimeLog,
  useHistory,
  isHistory,
  History,
} from "common/api";
import { CurrentOpportunity } from "../currentOpportunity.reducer";
import { t, T } from "i18n";
import { orderBy } from "lodash";
import { InlineLabeledText } from "components/ui/InlineLabeledText.component";
import { orderStatus } from "./OrderInfo.component";

type ParsedRemark = {
  title: string;
  remark: string;
};

const parseRemarkTitle = (title: string): ParsedRemark => {
  return {
    title: title.substring(0, title.indexOf(":")),
    remark: title.substring(title.indexOf(":") + 2),
  };
};

interface IOpportunityTimeLineActivityProps {
  activity: Activity;
  selected?: boolean;
  last?: boolean;
  acted: boolean;
  onDone?(): any;
  disableEditing: boolean;
}

interface IOpportunityHistoryProps {
  history: History;
  last?: boolean;
}

const OpportunityTimeLineActivity = ({
  activity,
  selected,
  onDone,
  last,
  acted,
  disableEditing,
}: IOpportunityTimeLineActivityProps) => {
  const referenceDiv = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    selected &&
      referenceDiv?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
  }, [selected]);
  return (
    <Box
      color={selected ? "neutralWhite" : undefined}
      border={selected ? 1 : undefined}
      borderColor={selected ? "neutralGrey200" : undefined}
      padding={[12, 12, 4, 0]}
    >
      <Stack spacing={12}>
        <div
          ref={referenceDiv}
          className={
            !last
              ? css`
                  :after {
                    display: block;
                    content: "";
                    border-left: 1px solid #111;
                    height: 100%;
                    margin-left: 21px;
                  }
                `
              : undefined
          }
        >
          <Box
            color={selected ? "neutralWhite" : "bjornGrey"}
            padding={[4, 12, 12, 12]}
          >
            <Icon
              icon={getReminderIconByActivityType(
                activity.type,
                activity.subType
              )}
            />
          </Box>
        </div>
        <Stack vertical spacing={6}>
          <Stack vertical spacing={2}>
            <Text small bold wordBreakWord>
              {activity.subType === "Remark"
                ? parseRemarkTitle(activity.title).title
                : activity.title}
            </Text>
            <Text small inline color="neutralGrey500">
              {!activity.createdBy ||
              activity.createdBy.toLowerCase() === "edison" ||
              activity.createdBy.toLowerCase() === "system" ? (
                "System"
              ) : (
                <CoWorkerName>{activity.createdBy}</CoWorkerName>
              )}
              {" | "}
              <LocalizedDate pattern="Pp">{activity.createdAt}</LocalizedDate>
            </Text>
          </Stack>
          {activity.reminder && activity.appointmentTime && (
            <Stack justifyContent="space-between">
              <Stack spacing={6} alignItems="center">
                <Icon icon="ClockIcon" color="redIdle" size={14} />
                <Text small>
                  <LocalizedDate
                    pattern={
                      activity.subType === "ExpectedPurchase" ? undefined : "Pp"
                    }
                  >
                    {activity.appointmentTime}
                  </LocalizedDate>
                </Text>
              </Stack>
              <div>
                <Checkbox
                  id={activity.id}
                  disabled={activity.acted || disableEditing}
                  checked={acted}
                  label={t("manage-opportunity.timeline.done.label")}
                  onChange={() => onDone?.()}
                />
              </div>
            </Stack>
          )}
          {activity.subType === "Remark" && (
            <Text small wordBreakWord pre>
              {parseRemarkTitle(activity.title).remark}
            </Text>
          )}
          {activity.comment && (
            <Text small wordBreakWord>
              {activity.comment}
            </Text>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

const OpportunityHistory = ({ history, last }: IOpportunityHistoryProps) => {
  const orderStatusUpdateEntry = history.details?.updates?.find(
    (el) => el.field === "orderInformation/orderStatus" || el.field === "status"
  );

  return (
    <Box padding={[12, 12, 4, 0]}>
      <Stack spacing={12}>
        <div
          className={
            !last
              ? css`
                  :after {
                    display: block;
                    content: "";
                    border-left: 1px solid #111;
                    height: 100%;
                    margin-left: 21px;
                  }
                `
              : undefined
          }
        >
          <Box color={"bjornGrey"} padding={[4, 12, 12, 12]}>
            <Icon icon={getHistoryIcon(history.entryType)} />
          </Box>
        </div>
        <Stack vertical spacing={6}>
          <Stack vertical spacing={2}>
            <Text small bold wordBreakWord>
              {history.title}
            </Text>
            <Text small inline color="neutralGrey500">
              {!history.createdBy ||
              history.createdBy.toLowerCase() === "system" ? (
                "System"
              ) : (
                <CoWorkerName>{history.createdBy}</CoWorkerName>
              )}

              {" | "}
              <LocalizedDate pattern="Pp">{history.createdAt}</LocalizedDate>
            </Text>
          </Stack>
          {history.body && (
            <Text small wordBreakWord>
              {history.body}
            </Text>
          )}
          {history.note && (
            <Text small wordBreakWord>
              {history.note}
            </Text>
          )}
          {history.details?.order_number && (
            <InlineLabeledText
              small
              label={`${t("manage-opportunity.order-info.order-number")}:`}
              text={history.details.order_number}
            />
          )}

          {orderStatusUpdateEntry &&
            orderStatusUpdateEntry.old_value &&
            orderStatusUpdateEntry.new_value && (
              <Text small wordBreakWord>
                {t("manage-opportunity.order-status-update", {
                  oldStatus: t(
                    orderStatus[orderStatusUpdateEntry.old_value.toLowerCase()]
                  ),
                  newStatus: t(
                    orderStatus[orderStatusUpdateEntry.new_value.toLowerCase()]
                  ),
                })}
              </Text>
            )}
        </Stack>
      </Stack>
    </Box>
  );
};

interface IOpportunityTimeLineProps {
  currentOpportunity: CurrentOpportunity;
  opportunityFromApi: Opportunity;
  toggleAsDone(id: number): void;
  disableEditing: boolean;
}
export const OpportunityTimeLine = ({
  opportunityFromApi,
  toggleAsDone,
  currentOpportunity,
  disableEditing,
}: IOpportunityTimeLineProps) => {
  const { data: activities } = useTimeLog(opportunityFromApi.id);
  const { data: histories } = useHistory(opportunityFromApi.id);
  const params = parseHashRoute(useLocation().hash);
  const selectedId = Number(params.id);
  const entries = orderBy(
    [...(activities ?? []), ...(histories ?? [])],
    "createdAt",
    "desc"
  );

  return (
    <Box
      padding={activities ? 30 : 0}
      color="bjornGrey"
      stretchHeight
      alignItems="start"
    >
      {!activities ? (
        <Skeleton width="100%" height="100%" />
      ) : (
        <Stack spacing={8} vertical padding={[0, 0, 30, 0]}>
          <Text header="h2">
            <T id="manage-opportunity.timeline.title" />
          </Text>
          {entries.map((entry, i) =>
            isHistory(entry) ? (
              <OpportunityHistory
                key={entry.id}
                history={entry}
                last={i + 1 === entries.length}
              />
            ) : (
              <OpportunityTimeLineActivity
                selected={selectedId === entry.notificationId}
                activity={entry}
                key={entry.id}
                onDone={() => toggleAsDone(entry.id)}
                last={i + 1 === entries.length}
                acted={
                  entry.acted ||
                  !!currentOpportunity.markAsDone?.includes(entry.id)
                }
                disableEditing={disableEditing}
              />
            )
          )}
        </Stack>
      )}
    </Box>
  );
};

OpportunityTimeLine.displayName = "OpportunityTimeLine";
