import SKAPAProductIdentifier from "@ingka/product-identifier";

interface Props {
  children: string;
  subtle?: boolean;
}

/**
 * Display a product identifier (article number)
 * Takes a string and separates it into groups of three characters separated
 * by dots (ie. 12345678 becomes 123.456.78)
 */
export const ProductIdentifier = ({ children, subtle }: Props) => (
  <SKAPAProductIdentifier
    subtle={subtle}
    value={children.replace(/(.{3})/g, "$1.")}
  />
);
