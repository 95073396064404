import ReactGA from "react-ga";

type Category =
  | "New Sales Opportunity Creation"
  | "Open Manage Opportunity"
  | "User Navigation"
  | "My Addons"
  | "Manage page"
  | "Guidelines"
  | "Dashboard"
  | "Calendar View"
  | "Classification change"
  | "Appointments"
  | "Filters"
  | "Sorting"
  | "User Profile";

class Tracker {
  private initialized: boolean;
  constructor() {
    if (!window.location.href.includes("localhost")) {
      if (!process.env.REACT_APP__GOOGLE_ANALYTICS_TOKEN) {
        // eslint-disable-next-line no-console
        console.log("no google analytics token found");
      } else {
        ReactGA.initialize(process.env.REACT_APP__GOOGLE_ANALYTICS_TOKEN);
        this.initialized = true;
      }
    }
  }

  public setUser({
    userId,
    country,
    department,
    language,
    buName,
    role,
  }: {
    userId: string;
    country?: string | null;
    department?: string | null;
    language?: string | null;
    buName?: string | null;
    role?: string | null;
  }) {
    if (this.initialized) {
      ReactGA.set({
        userId,
        dimension1: country,
        dimension2: department,
        dimension3: language,
        dimension4: buName,
        dimension5: role,
      });
    }
  }

  public event(data: {
    category: Category;
    action: string;
    label?: string;
    value?: number;
    reason?: string | null;
  }) {
    if (this.initialized) {
      ReactGA.event(data);
    }
  }

  public pageView(url) {
    if (this.initialized) {
      ReactGA.pageview(url);
    }
  }
}

export const tracker = new Tracker();
