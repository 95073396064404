import { ReactNode } from "react";

import Modal, {
  Sheets,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@ingka/modal";

import { IButtonProps } from "components";
import Button from "@ingka/button";
import { useHistory } from "react-router-dom";
import { t } from "i18n";

interface Props {
  visible?: boolean;
  onClose(): void;
  title: string;
  primaryButtonProps?: {
    text?: IButtonProps["text"];
    disabled?: IButtonProps["disabled"];
    link?: IButtonProps["link"];
    loading?: IButtonProps["loading"];
    onClick?: IButtonProps["onClick"];
    emphasised?: IButtonProps["emphasised"];
  };
  secondaryButtonProps?: {
    onClick?: IButtonProps["onClick"];
    loading?: IButtonProps["loading"];
  };
  closeText?: string;
  children?: ReactNode;
}

export const ModalSheet = ({
  visible,
  onClose,
  title,
  primaryButtonProps,
  children,
  secondaryButtonProps,
  closeText,
}: Props) => {
  const history = useHistory();
  return (
    <Modal handleCloseBtn={onClose} visible={visible}>
      <Sheets
        closeBtnClick={onClose}
        header={<ModalHeader closeBtnClick={onClose} title={title} />}
        footer={
          <ModalFooter compact closeBtnClick={onClose}>
            {primaryButtonProps && (
              <Button
                type={primaryButtonProps.emphasised ? "emphasised" : "primary"}
                text={primaryButtonProps.text}
                disabled={primaryButtonProps.disabled}
                loading={primaryButtonProps.loading}
                onClick={
                  primaryButtonProps.link
                    ? () => history.push(primaryButtonProps.link!)
                    : primaryButtonProps.onClick
                }
              />
            )}
            <Button
              type="secondary"
              text={closeText || t("modal.cancel")}
              onClick={secondaryButtonProps?.onClick}
              loading={secondaryButtonProps?.loading}
            />
          </ModalFooter>
        }
      >
        <ModalBody>{children}</ModalBody>
      </Sheets>
    </Modal>
  );
};

ModalSheet.displayName = "ModalSheet";
