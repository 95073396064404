/* eslint-disable no-console */
import { useState } from "react";

/**
 * Use a value from local storage.
 * Returns an array with the value and a setter,
 * similarly to useState.
 *
 * @see https://usehooks.com/useLocalStorage/
 */
export const useLocalStorage = <T>(
  key: string
): [T | undefined, (value: T) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as unknown as T) : undefined;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  });

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(
        key,
        typeof value !== "string"
          ? (JSON.stringify(value) as unknown as string)
          : value
      );
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};
