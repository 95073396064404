import { useEffect } from "react";
import { tracker } from "./";
import { useProfile } from "common/api";
import { useLocale } from "common";
import { useUserRole } from "authorization/userRoleHook";

export const GoogleAnalytics = () => {
  useInitializeUser();

  return null;
};

export const useInitializeUser = () => {
  const { data: profile } = useProfile();
  const locale = useLocale();
  const { isAdmin, isSalesPerson } = useUserRole();

  useEffect(() => {
    if (profile?.id) {
      tracker.setUser({
        userId: profile.id,
        department: profile.department,
        buName: profile.city,
        country: locale.countryCode,
        language: locale.locale,
        role: isAdmin ? "Admin" : isSalesPerson ? "SalesPerson" : null,
      });
    }
  }, [profile, locale, isAdmin, isSalesPerson]);
};
