import { ChangeEvent } from "react";
import Textarea from "@ingka/text-area";
import { t } from "i18n";

interface Props {
  disabled?: boolean;
  label?: string;
  onChange(e: ChangeEvent<HTMLTextAreaElement>): void;
  value?: string;
  placeholder: string;
  rows?: number;
  id: string;
  maxLength?: number;
  optional?: boolean;
}

export const TextAreaFixed = (props: Props) => {
  return (
    <Textarea
      rows={props.rows}
      maxLength={props.maxLength}
      value={props.value || ""}
      placeholder={props.placeholder}
      label={`${props.label} ${
        props.optional ? `(${t("form.optional")})` : ""
      }`}
      disabled={props.disabled}
      onChange={props.onChange.bind(this)}
      id={props.id}
    />
  );
};

TextAreaFixed.displayName = "TextAreaFixed";
