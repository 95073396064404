import { t, T } from "i18n";
import { TranslationKeys } from "i18n/translationKeys";

interface Props {
  id: string;
}

const getdynamicTranslationKey = (id: string) =>
  `services.name.${id}` as TranslationKeys;

export const AppointmentService = ({ id }: Props) => (
  <T id={getdynamicTranslationKey(id)} />
);

export const getAppointmentService = (id: string) =>
  t(getdynamicTranslationKey(id));
