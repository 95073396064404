import { createContext, ReactNode, useContext, useMemo } from "react";
import Loading, { LoadingBall } from "@ingka/loading";
import useSWR from "swr";
import { isEqual, merge, uniqWith } from "lodash";
import { useProfile } from "common/api";
import { environment } from "../common/feature-flags/environment";
import { loadOverrideMarket } from "application/MarketSelector";
import { isActiveMarket, Market } from "common/market/markets";
import { Box, Stack, Text } from "components";
import { useLogin } from "authorization";

interface Props {
  children: ReactNode;
}

type MarketObejct = {
  index: number;
  country_code: Market;
};

export type Settings = {
  overrideMarket?: string;
  feature_flags?: {
    planner_drawing_edit: boolean;
    disable_create_manual_opportunity: boolean;
    disable_notes: boolean;
    disable_my_addons: boolean;
    disable_opportunity_statistics: boolean;
    redirect_to_salja: boolean;
  };
  markets?: MarketObejct[];
  health_monitoring?: {
    alert?: {
      enabled: boolean;
      message: string;
      clearing_time: string;
      emergency: boolean;
    };
  };
  system_failure?: {
    failure: boolean;
    message: string;
  };
  boka_config?: {
    enable: boolean;
  };
  general?: {
    redirect_to_salja?: string[];
  };
};

export const isLiveMarket = (
  userLocation: string,
  markets: MarketObejct[] | undefined
): boolean =>
  markets !== undefined &&
  markets.filter((m) => m.country_code === userLocation).length > 0;

//This is to be able to bust incorrect caches
const VERSION = 3;
const createSettingsFetcher = (file: string) => async () => {
  const noCacheHeaders = new Headers();
  noCacheHeaders.append("pragma", "no-cache");
  noCacheHeaders.append("cache-control", "no-cache");
  try {
    const res = await fetch(
      `${process.env.REACT_APP__SETTINGS_URL__}/${file}?v=${VERSION}`,
      {
        method: "GET",
        headers: noCacheHeaders,
      }
    );
    return await res?.json();
  } catch {
    /* If file is not found etc. assume no setting exists */
    return {};
  }
};

const createAlertFetcher = (file: string) => async () => {
  const noCacheHeaders = new Headers();
  noCacheHeaders.append("pragma", "no-cache");
  noCacheHeaders.append("cache-control", "no-cache");

  try {
    const res = await fetch(
      `${process.env.REACT_APP__SYSTEM_FAILURE_URL__}/${file}`,
      {
        method: "GET",
        headers: noCacheHeaders,
      }
    );
    return await res?.json();
  } catch {
    /* If file is not found etc. assume no setting exists */
    return {};
  }
};

const SettingsContext = createContext<Settings>({} as Settings);

export const SettingsProvider = ({ children }: Props) => {
  const envString = environment.isProd ? "prod" : "stage";
  const storedOverride = loadOverrideMarket();
  const { data: profile, isValidating: isLoadingProfile } = useProfile();
  const { userRoles } = useLogin();

  const { data: settings } = useSWR<Settings>(
    "main_settings.json",
    createSettingsFetcher("main_settings.json")
  );

  const { data: settingsEnv } = useSWR<Settings>(
    `main_settings_${envString}.json`,
    createSettingsFetcher(`main_settings_${envString}.json`)
  );

  const { data: failure } = useSWR<{ system_failure: boolean }>(
    "failure.json",
    createAlertFetcher("failure.json")
  );

  const userLocation = storedOverride || (profile?.usageLocation as string);

  const marketSettingsKey = `${userLocation?.toUpperCase()}/${userLocation?.toLowerCase()}_${envString}.json`;
  const { data: marketSettings } = useSWR<Settings>(
    userLocation ? marketSettingsKey : null,
    createSettingsFetcher(marketSettingsKey)
  );
  const combinedSettings = useMemo(() => {
    return {
      ...merge({}, settings, settingsEnv, marketSettings),
      overrideMarket: storedOverride ?? undefined,
      markets: uniqWith(
        [...(settings?.markets ?? []), ...(settingsEnv?.markets ?? [])],
        isEqual
      ),
      system_failure: {
        failure: failure?.system_failure ?? false,
        message:
          "We are currently experiencing some issues in the application and we are working on it. Sorry for the inconvenience.",
      },
    };
  }, [settings, settingsEnv, marketSettings, storedOverride, failure]);

  const isLive = isActiveMarket(userLocation) || userRoles.includes("Admin");

  if (!combinedSettings || isLoadingProfile) {
    return (
      <Loading labelTransitions>
        <LoadingBall />
      </Loading>
    );
  }

  if (!isLive) {
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        position="absolute"
      >
        <Stack vertical alignItems="center">
          <Text header="h1">
            This application is not currently live in your market.
          </Text>
        </Stack>
      </Box>
    );
  }

  return (
    <SettingsContext.Provider value={combinedSettings}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
