import { ReactNode } from "react";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { css } from "@emotion/css";

interface Props {
  title: string;
  disabled?: boolean;
  children?: ReactNode;
}

export const StyledAccordion = ({ title, children }: Props) => {
  return (
    <Accordion
      className={css`
        .accordion-item-header {
          border-top: none;
        }
        .accordion__content {
          padding-top: 0;
        }
      `}
    >
      <AccordionItem id={title} title={title}>
        {children}
      </AccordionItem>
    </Accordion>
  );
};
