import { i18n } from "@lingui/core";
import { Trans, TransProps } from "@lingui/react";
import { TranslationKeys } from "./translationKeys";

/**
 * Return a translated string for the provided key
 *
 * @param id      The translation key
 * @param values  Any values included in the translated string
 */
export const t = (id: TranslationKeys, values?: any) =>
  id ? i18n._({ id, values }) : id;

/**
 * A wrapper for the <Trans> component which only allows existing keys to be used
 *
 * @param id   The translation key
 */
interface Props extends Partial<TransProps> {
  id: TranslationKeys;
}
// eslint-disable-next-line react/jsx-props-no-spreading
export const T = (props: Props) => <Trans {...props} />;
