import { useContext } from "react";
import { ModalHeader, ModalFooter } from "components/ui/modal";
import { Stack } from "components/layout";
import { useHistory } from "react-router-dom";
import { CustomerInfo } from "../common/CustomerInfo";
import { CustomerConsent } from "../common/customer-consent";
import { OpportunityContext } from "../OpportunityContext";
import { t } from "i18n";

export const ExistingCustomerConsent = () => {
  const history = useHistory();
  const { customer, consents, resetContext } = useContext(OpportunityContext);

  if (!customer) {
    history.push({ hash: "/select-customer" });
    return null;
  }

  return (
    <>
      <ModalHeader title={t("new-opportunity.modal.header")} />
      <Stack vertical spacing={24}>
        <CustomerInfo customer={customer} />
        <CustomerConsent />
      </Stack>
      <ModalFooter
        primaryButtonProps={{
          text: t("new-opportunity.modal.continue"),
          disabled: !consents,
          link: { hash: "/new-opportunity" },
        }}
        secondaryButtonProps={{
          text: t("modal.cancel"),
          onClick: () => {
            history.goBack();
            resetContext();
          },
        }}
      ></ModalFooter>
    </>
  );
};
