import { createContext, Dispatch } from "react";
import { CreateOpportunityResponse } from "common/api";
import { CustomerGlobal } from "common/api/customer";

export type Opportunity = { title: string } & CreateOpportunityResponse;

export type Consents = {
  privacyPolicy: boolean;
  followUpConsent: boolean;
};

interface Props {
  customer: CustomerGlobal | null;
  opportunity: Opportunity | null;
  consents: Consents;
  isExistingCustomer: boolean;
  setCustomer: Dispatch<CustomerGlobal>;
  setOpportunity: Dispatch<Opportunity>;
  setConsents: Dispatch<Consents>;
  setIsExistingCustomer: Dispatch<boolean>;
  resetContext: () => void;
}

export const OpportunityContext = createContext<Props>(null!);
