import { useEffect, useState } from "react";
import { User } from "@microsoft/microsoft-graph-types";
import { InputDatalist, Item } from "components/forms";
import { useCoworkerName } from "./useCoworkerName";
import { useCoworkerSearch } from "./useCoworkerSearch";

const t = {
  errorMessage: "Please select a valid owner",
};

interface Props {
  label: string;
  selectedMail?: string;
  placeholder?: string;
  onSelect?(coworker: User): void;
  onChange?(searchTerm: string): void;
  onError?(error: string): void;
  onReset?(): void;
  disabled?: boolean;
}

export const CoworkerSearch = ({
  label,
  selectedMail = "",
  placeholder = "",
  onSelect,
  onChange,
  onError,
  onReset,
  disabled,
}: Props) => {
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [coworkers, resetCoworkerSearch] = useCoworkerSearch(searchTerm);
  const [selectedCoworker, setSelectedCoworker] = useState<User | null>(null);
  const coworkerName = useCoworkerName(selectedMail);

  const onInputChange = (e) => {
    const value = e.target?.value;
    setInputValue(value);
    resetCoworkerSearch();
    if (onChange) onChange(value);
  };

  const onInputReset = () => {
    if (onReset) onReset();
    setError("");
    setInputValue("");
    setSearchTerm("");
    setSelectedCoworker(null);
  };

  const validate = (e) => {
    const value = e.target?.value;
    const isValid =
      value === selectedCoworker?.displayName ||
      value === selectedCoworker?.mail ||
      value === selectedMail;
    setError(isValid ? "" : t.errorMessage);
  };

  const onSuggestionSelected = (selectedItem: Item) => {
    const selected = selectedItem.user;
    setError("");
    setSelectedCoworker(selected);
    if (onSelect) onSelect(selected);
  };

  useEffect(() => {
    if (inputValue !== selectedCoworker?.displayName) {
      setSearchTerm(inputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    if (selectedMail && coworkerName) {
      setInputValue(coworkerName);
      setSelectedCoworker({
        mail: selectedMail,
        displayName: coworkerName,
      });
    } else if (selectedMail) {
      setInputValue(selectedMail);
    } else {
      setInputValue("");
    }
  }, [selectedMail, coworkerName]);

  useEffect(() => {
    if (error && onError) onError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <InputDatalist
      name="opportunityOwner"
      type="text"
      label={label}
      list={coworkers.map((c) => ({
        value: c.mail || "",
        text: `${c.displayName ?? ""} (${c.mail})`,
        user: c,
      }))}
      value={inputValue}
      errorMessage={error}
      placeholder={placeholder}
      onChange={onInputChange}
      onBlur={validate}
      onReset={onInputReset}
      onSelect={onSuggestionSelected}
      disabled={disabled}
    />
  );
};
