import { Stack } from "components/layout";
import { StyledAccordion } from "./StyledAccordion";
import {
  City,
  Email,
  FirstName,
  LastName,
  PhoneNumber,
  PostalCode,
  StreetAddress1,
  StreetAddress2,
  StreetAddress3,
  PreferredLanguage,
  CompanyName,
  CompanyNumber,
  VatNumber,
  CustomerType,
} from "./FormFields";
import { CustomerConsent } from "../common/customer-consent";
import { t } from "i18n";
import { useConfig } from "common/api";
import { useUserLocation } from "common/api/user/userLocation";

interface Props {
  isBusiness: boolean;
  onCustomerTypeChange(): void;
}

export const CustomerForm = ({ isBusiness, onCustomerTypeChange }: Props) => {
  const { data: config } = useConfig();
  const userLocation = useUserLocation();
  return (
    <Stack vertical spacing={24}>
      <CustomerType onCustomerTypeChange={onCustomerTypeChange} />
      {isBusiness && <CompanyName />}
      <FirstName />
      <LastName customerType={isBusiness} />
      <StreetAddress1 />
      {isBusiness && <StreetAddress2 />}
      {isBusiness && <StreetAddress3 />}
      <PostalCode />
      <City />
      <Email />
      <PhoneNumber />
      {userLocation !== "DK" && userLocation !== "IN" && <PreferredLanguage />}
      {!isBusiness && (
        <StyledAccordion
          title={t("new-opportunity.customer.form.extende-information")}
        >
          <StreetAddress2 />
          <StreetAddress3 />
        </StyledAccordion>
      )}

      {isBusiness && (
        <StyledAccordion
          title={t("new-opportunity.customer.form.extende-information")}
        >
          <CompanyNumber />
          <VatNumber />
        </StyledAccordion>
      )}
      {config?.customer.requireConsent === true ? <CustomerConsent /> : null}
    </Stack>
  );
};
