import { ReactNode } from "react";
import { css, cx } from "@emotion/css";
import { Color, Colors } from "../Variables";
import { isArray } from "lodash";

interface Props {
  backgroundColor?: string;
  color?: Color;
  margin?: number | number[];
  padding?: number | number[];
  border?: number | number[];
  borderColor?: Color;
  borderRadius?: number;
  justifyContent?: "flex-start" | "space-between" | "center" | "flex-end";
  alignItems?:
    | "stretch"
    | "start"
    | "center"
    | "flex-start"
    | "flex-end"
    | "normal";
  vertical?: boolean;
  height?: string;
  position?: "relative" | "absolute" | "sticky";
  top?: string;
  grow?: boolean;
  left?: string;
  right?: string;
  overflow?: string;
  minWidth?: string;
  width?: string;
  maxWidth?: string;
  stretchHeight?: boolean;
  moveUp?: number;
  scroll?: boolean;
  onClick?(): void;
  children?: ReactNode;
  wrap?: boolean;
}

export const Box = ({
  backgroundColor,
  color,
  children,
  vertical,
  minWidth,
  maxWidth,
  margin = 0,
  padding = 0,
  height,
  overflow,
  scroll,
  onClick,
  justifyContent = "space-between",
  alignItems,
  position,
  width,
  top,
  left,
  right,
  stretchHeight,
  grow,
  border,
  borderColor,
  borderRadius,
  moveUp,
  wrap,
}: Props) => (
  <div
    className={cx(
      stretchHeight &&
        css`
          overflow-y: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        `,
      !!border &&
        css`
          border-color: ${borderColor
            ? Colors[borderColor]
            : Colors.neutralGrey900};
          border-style: solid;
          border-width: ${isArray(border)
            ? border.map((theWidth) => `${theWidth}px`).join(" ")
            : `${border}px`};
          border-radius: ${borderRadius ?? 0}px;
        `,
      scroll &&
        css`
          overflow-y: auto;
          align-items: flex-start !important;
          border-bottom: 1px solid ${Colors.neutralGrey200};
        `,
      stretchHeight &&
        css`
          align-self: stretch;
          overflow-y: auto;
        `,
      css`
        display: flex;
        background-color: ${color ? Colors[color] : "transparent"};
        align-items: center;
        justify-content: ${justifyContent};
        box-sizing: border-box;
        margin: ${isArray(margin)
          ? margin.map((mar) => `${mar}px`).join(" ")
          : `${margin}px`};
        padding: ${isArray(padding)
          ? padding.map((pad) => `${pad}px`).join(" ")
          : `${padding}px`};
        flex-direction: ${vertical ? "column" : "row"};
        align-items: ${alignItems};
        height: ${height};
        position: ${position};
        top: ${top};
        left: ${left};
        right: ${right};
        overflow: ${overflow};
        min-width: ${minWidth || "initial"};
        max-width: ${maxWidth || "initial"};
        width: ${width || "initial"};
        flex-grow: ${grow ? 1 : "initial"};
        flex-wrap: ${wrap ? "wrap" : "inherit"};
        z-index: ${moveUp || "auto"};
        cursor: ${onClick ? "pointer" : "inherit"};
      `,
      backgroundColor &&
        css`
          background-color: ${backgroundColor};
        `
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

Box.displayName = "Box";
