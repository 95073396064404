import useSWR from "swr";
import * as fetcher from "../fetcher";

export interface Customer {
  consents: {
    privacyPolicy: boolean;
    termsAndCondition: boolean;
  };
  customer: {
    city: string;
    customerType: "Individual" | "Business";
    email: string;
    familyNumber: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    postalCode: string;
    preferredContactType: "EMAIL" | "PHONE";
    streetAddress1: string;
    streetAddress2?: string;
    streetAddress3?: string;
    partyId?: string;
    language?: string;
    country: string;
    organizationName: string;
  };
}

export function useOpportunityCustomer(opportunityId?: number) {
  return useSWR<Customer>(
    opportunityId ? `/opportunities/${opportunityId}/customer` : null,
    (url) => fetcher.opportunity.get(`/${opportunityId}/customer`),
    {
      revalidateOnFocus: false,
    }
  );
}
