import { useEffect } from "react";
import { InputField, Select } from "components/formik";
import { Stack, Text, Icon, Colors, IconNames } from "components";
import { css, cx } from "@emotion/css";
import { useField } from "formik";
import { map } from "lodash";
import { t } from "i18n";
import { useConfig } from "common/api";
import { languageCodeToLanguage, PhoneNumberInput } from "common";

export const CompanyName = () => (
  <InputField
    name="organizationName"
    type="text"
    label={t("new-opportunity.customer.form.company-name.label")}
    placeholder={t("new-opportunity.customer.form.company-name.placeholder")}
  />
);

export const FirstName = () => (
  <InputField
    name="firstName"
    type="text"
    label={t("new-opportunity.customer.form.first-name.label")}
    placeholder={t("new-opportunity.customer.form.first-name.placeholder")}
  />
);

export const LastName = ({ customerType }: { customerType: boolean }) => (
  <InputField
    name="lastName"
    type="text"
    label={t("new-opportunity.customer.form.last-name.label")}
    placeholder={t("new-opportunity.customer.form.last-name.placeholder")}
    optional={customerType === true}
  />
);

export const StreetAddress1 = () => (
  <InputField
    name="streetAddress1"
    type="text"
    label={t("new-opportunity.customer.form.address.label")}
    placeholder={t("new-opportunity.customer.form.address.placeholder")}
  />
);

export const StreetAddress2 = () => (
  <InputField
    name="streetAddress2"
    type="text"
    label={t("new-opportunity.customer.form.address2.label")}
    placeholder={t("new-opportunity.customer.form.address2.placeholder")}
    optional
  />
);

export const StreetAddress3 = () => (
  <InputField
    name="streetAddress3"
    type="text"
    label={t("new-opportunity.customer.form.address3.label")}
    placeholder={t("new-opportunity.customer.form.address3.placeholder")}
    optional
  />
);

export const PostalCode = () => (
  <InputField
    name="postalCode"
    type="text"
    label={t("new-opportunity.customer.form.postal-code.label")}
    placeholder={t("new-opportunity.customer.form.postal-code.placeholder")}
  />
);

export const City = () => (
  <InputField
    name="city"
    type="text"
    label={t("new-opportunity.customer.form.city.label")}
    placeholder={t("new-opportunity.customer.form.city.placeholder")}
  />
);

export const Email = () => (
  <InputField
    name="email"
    type="email"
    label={t("new-opportunity.customer.form.email.label")}
    placeholder={t("new-opportunity.customer.form.email.placeholder")}
  />
);

export const PhoneNumber = () => (
  <PhoneNumberInput name="phoneNumber" type="tel" />
);

export const PreferredLanguage = () => {
  const { data: config } = useConfig();

  /**
   * Changing the structure from
   *
   * {"en-SE": "English", "sv-SE": "Swedish"}
   *
   * to
   *
   * [
   *  { "name": "English", "value": "EN"},
   *  { "name": "Swedish", "value": "SV"}
   * ]
   */
  const languages =
    config?.locales &&
    map(config?.locales, (_name, locale) => ({
      name: t(languageCodeToLanguage[locale.split("-")[0].toLowerCase()]),
      value: locale.split("-")[0].toUpperCase(),
    }));

  if (!languages) return null;

  return (
    <Select
      name="preferredLanguage"
      label={t("new-opportunity.customer.form.language.label")}
      options={languages}
      hintText=""
    />
  );
};

export const CompanyNumber = () => (
  <InputField
    name="companyNumber"
    type="tel"
    optional
    label={t("new-opportunity.customer.form.company-number.label")}
    placeholder={t("new-opportunity.customer.form.company-number.placeholder")}
  />
);

export const VatNumber = () => (
  <InputField
    name="vatNumber"
    type="tel"
    optional
    label={t("new-opportunity.customer.form.vat-number.label")}
    placeholder={t("new-opportunity.customer.form.vat-number.placeholder")}
  />
);

type ToggleLabelProps = {
  selected?: boolean;
  icon: IconNames;
  text: string;
  children?: React.ReactNode;
};

const ToggleLabel = ({ selected, icon, text, children }: ToggleLabelProps) => (
  <label
    className={cx(
      css`
        background-color: ${Colors.neutralGrey200};
        padding: 0.5rem 1rem;
        width: 50%;
        :hover {
          background-color: ${Colors.neutralGrey300};
        }
        :first-of-type {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }
        :last-of-type {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
      `,
      selected &&
        css`
          background-color: ${Colors.neutralBlack};
          &:hover {
            background-color: ${Colors.neutralBlack};
          }
          path {
            fill: ${Colors.neutralWhite};
          }
        `
    )}
  >
    <Stack justifyContent="center">
      <Icon icon={icon} color={selected ? "neutralWhite" : "neutralBlack"} />
      <Text color={selected ? "neutralWhite" : "neutralBlack"}>{text}</Text>
    </Stack>
    {children}
  </label>
);

type CustomerTypeProps = {
  onCustomerTypeChange(): void;
};

export const CustomerType = ({ onCustomerTypeChange }: CustomerTypeProps) => {
  const [individualField] = useField({
    type: "radio",
    name: "customerType",
    value: "Individual",
  });
  const [businessfield] = useField({
    type: "radio",
    name: "customerType",
    value: "Business",
  });

  useEffect(() => {
    onCustomerTypeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualField.checked]);

  return (
    <Stack spacing={0}>
      <ToggleLabel
        selected={individualField.checked}
        icon="AccountIcon"
        text={t("new-opportunity.customer.form.customer-type.private")}
      >
        <input
          className={css`
            display: none;
          `}
          type="radio"
          name={individualField.name}
          checked={individualField.checked}
          onBlur={individualField.onBlur}
          onChange={individualField.onChange}
          value={individualField.value}
        />
      </ToggleLabel>
      <ToggleLabel
        selected={businessfield.checked}
        icon="Home"
        text={t("new-opportunity.customer.form.customer-type.business")}
      >
        <input
          className={css`
            display: none;
          `}
          type="radio"
          name={businessfield.name}
          checked={businessfield.checked}
          onBlur={businessfield.onBlur}
          onChange={businessfield.onChange}
          value={businessfield.value}
        />
      </ToggleLabel>
    </Stack>
  );
};
