import SkapaRadioButton from "@ingka/radio-button";
import { useField } from "formik";

interface Props {
  name: string;
  id: string;
  label: string;
}

export const RadioButton = ({ id, name, label }: Props) => {
  const [field] = useField({ name, value: id, type: "radio" });

  return (
    <SkapaRadioButton
      id={id}
      label={label}
      subtle
      name={field.name}
      checked={field.checked}
      onBlur={field.onBlur}
      onChange={field.onChange}
      value={field.value}
    />
  );
};
