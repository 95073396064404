import { css } from "@emotion/css";
import { ReactNode } from "react";

export type TableCellProps = {
  children?: ReactNode;
  preventCellClick?: boolean;
  onClick?(): void;
  maxWidth?: string;
};

export const TableCell = ({ children, onClick, maxWidth }: TableCellProps) => {
  return (
    <td
      className={css`
        padding: 12px 10px 12px 5px;
        vertical-align: top;
        cursor: pointer;
        max-width: ${maxWidth ?? "auto"};
      `}
      onClick={onClick}
    >
      {children}
    </td>
  );
};
