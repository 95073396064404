import { useState } from "react";
import { Line, ModalSheet, Stack, Text, Toast } from "components";
import { LinkItem } from "./LinkItem.component";
import { LinkForm } from "./LinkForm.component";
import { createLink, deleteLink, Link, useLinks } from "common/api";
import { t, T } from "i18n";

interface ILinksProps {
  isOpen: boolean;
  onClose(): void;
}

export const Links: React.FunctionComponent<ILinksProps> = ({
  isOpen,
  onClose,
}) => {
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [link, setLink] = useState<Partial<Link>>();
  const { data, isValidating: isLoading, error } = useLinks();
  const links = data || [];

  const primaryButtonProps = showCreate
    ? {
        text: t("my-opportunities.view-my-links.save-link.button"),
        emphasised: true,
        loading: isLoading,
        onClick: () => {
          if (link) {
            createLink(link).finally(() => {
              setShowCreate(false);
              setLink(undefined);
            });
          }
        },
      }
    : {
        text: t("my-opportunities.view-my-links.create-new-link.button"),
        emphasised: true,
        loading: isLoading,
        disabled: showCreate,
        onClick: () => setShowCreate(true),
      };
  const secondaryButtonProps = showCreate
    ? {
        onClick: () => {
          setShowCreate(false);
          setLink(undefined);
        },
      }
    : undefined;
  const closeText = showCreate
    ? t("my-opportunities.view-my-links.cancel.button")
    : t("my-opportunities.view-my-links.modal.close");

  return (
    <ModalSheet
      title={t("my-opportunities.view-my-links.modal.header")}
      visible={isOpen}
      onClose={onClose}
      closeText={closeText}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <Stack vertical spacing={20}>
        {showCreate ? (
          <>
            <Line />
            <Stack vertical spacing={24}>
              <Text header="h3">
                <T id="my-opportunities.view-my-links.create-new-link.title" />
              </Text>
              <LinkForm
                setLink={(editedLink) => setLink(editedLink)}
                link={link}
              />
            </Stack>
          </>
        ) : (
          <Stack vertical spacing={10}>
            {links.map((item, index) => (
              <LinkItem
                key={item.id}
                link={item}
                odd={index % 2 === 1}
                onDelete={deleteLink}
                onEdit={(linkToEdit) => {
                  setLink(linkToEdit);
                  setShowCreate(true);
                }}
              />
            ))}
          </Stack>
        )}
        {error && <Toast text={error.message} time={error.timestamp} />}
      </Stack>
    </ModalSheet>
  );
};

Links.displayName = "Links";
