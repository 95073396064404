import { T } from "i18n";

const typesTranslated = {
  "Sales Opportunity": "opportunity.type.new-sales-opportunity",
  "Blocked Order": "opportunity.type.blocked-order",
  Boka: "opportunity.type.boka",
} as const;

interface IOpportunityType {
  children: keyof typeof typesTranslated;
}

export const OpportunityType = ({ children }: IOpportunityType) => (
  <T id={typesTranslated[children]} />
);

OpportunityType.displayName = "OpportunityType";
