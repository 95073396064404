import { useState } from "react";
import { Grid, Line, Select, Stack, Toast } from "components";
import { ReminderItem } from "./ReminderItem.component";
import { Button } from "components";
import down from "@ingka/ssr-icon/paths/chevron-down-small";
import up from "@ingka/ssr-icon/paths/chevron-up-small";
import { isUndefined, orderBy } from "lodash";
import {
  Notification,
  saveTask,
  useNotifications,
  useProfile,
  useTasks,
} from "common/api";
import { t } from "i18n";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import isThisWeek from "date-fns/isThisWeek";
import isThisMonth from "date-fns/isThisMonth";
import * as fetcher from "common/api/fetcher";
import { mutate } from "swr";

type FilterType = "Tasks" | "Appointments";
type FilterDate = "Today" | "Tomorrow" | "Week" | "Month";
type ReminderOrder = "Past" | "PastFuture";

const getDates = (reminderDates: ReminderOrder) => {
  const now = new Date().toISOString();
  return (item: Notification) => {
    const appointmentDate = new Date(item.appointmentTime).toISOString();
    return reminderDates === "Past"
      ? appointmentDate < now
      : appointmentDate > now;
  };
};

const getSortedReminders = (
  filteredReminders: Notification[],
  sortPlainDescending: boolean
) => {
  return orderBy(
    filteredReminders,
    "appointmentTime",
    sortPlainDescending ? "desc" : "asc"
  );
};

export const Reminders: React.FunctionComponent = () => {
  const { data: profile } = useProfile();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filterByType, setFilterByType] = useState<FilterType | "None">("None");
  const [filterByDate, setFilterByDate] = useState<FilterDate | "None">("None");
  const [sortDescending, setSortDescending] = useState<boolean>(false);
  const [sortPlainDescending, setSortPlainDescending] =
    useState<boolean>(false);
  const [filterMode, setFilterMode] = useState<string>("Appointment");
  const { data, error } = useNotifications();
  const { data: tasksData } = useTasks(profile?.mail);
  const reminders = data
    ? data.filter(
        ({ isDeleted, isReminder }) => isReminder && isDeleted === false
      )
    : [];
  const tasks = tasksData ?? [];

  const markAsDone = async (reminder: Notification) => {
    if (reminder.subType === "Task") {
      const taskToBeDone = tasks.find((task) => task.id === reminder.id);
      await saveTask({ ...taskToBeDone, isDone: true }, profile?.mail);
    } else {
      await fetcher.opportunity.update(
        `/${reminder.opportunityId}/notification/${reminder.id}`,
        {}
      );
    }
    mutate(`/notifications`);
    mutate(`/opportunities/${reminder.opportunityId}/timelog`);
    mutate(`/opportunities/${reminder.opportunityId}/history`);
  };

  const filteredReminders = reminders
    .filter((reminder) => {
      switch (filterByType) {
        case "Appointments":
          return reminder.type === "Schedule";
        case "Tasks":
          return !reminder.opportunityId;
        default:
          return reminder;
      }
    })
    .filter((reminder) => {
      const appointmentDate = new Date(reminder.appointmentTime);
      switch (filterByDate) {
        case "Today":
          return isToday(appointmentDate);
        case "Tomorrow":
          return isTomorrow(appointmentDate);
        case "Week":
          return isThisWeek(appointmentDate);
        case "Month":
          return isThisMonth(appointmentDate);
        default:
          return reminder;
      }
    })
    .filter((reminder) => !isUndefined(reminder));

  const pastReminders = filteredReminders.filter(getDates("Past"));
  const presentFutureReminders = filteredReminders.filter(
    getDates("PastFuture")
  );

  const getPastSortedReminders = () => {
    return orderBy(
      pastReminders,
      "appointmentTime",
      !sortDescending ? "desc" : "asc"
    );
  };
  const getPastFutureSortedReminders = () => {
    return orderBy(presentFutureReminders, "appointmentTime", "asc");
  };

  const newSortedReminders =
    filterMode === "Date"
      ? getSortedReminders(filteredReminders, sortPlainDescending)
      : sortDescending
      ? getPastSortedReminders().concat(getPastFutureSortedReminders())
      : getPastFutureSortedReminders().concat(getPastSortedReminders());

  return (
    <Stack vertical spacing={20}>
      <Stack vertical alignItems="flex-end">
        <Button
          tertiary
          iconPosition="trailing"
          text={
            showFilters
              ? t("reminders.hide-filters")
              : t("reminders.show-filters")
          }
          ssrIcon={showFilters ? up : down}
          onClick={() => setShowFilters(!showFilters)}
          small
        />
        {showFilters && (
          <Grid columns={2} fullWidth>
            <Select
              value={filterByType}
              options={{
                None: t("reminders.filter.type.none"),
                Appointments: t("reminders.filter.type.appointments"),
                Tasks: t("reminders.filter.type.tasks"),
              }}
              onChange={(value: FilterType) => {
                setFilterByType(value);
                setSortPlainDescending(false);
                filterMode !== "Appointment" && setFilterMode("Appointment");
              }}
            />
            <Select
              value={filterByDate}
              options={{
                None: t("reminders.filter.date.none"),
                Today: t("reminders.filter.date.today"),
                Tomorrow: t("reminders.filter.date.tomorrow"),
                Week: t("reminders.filter.date.this-week"),
                Month: t("reminders.filter.date.this-month"),
              }}
              onChange={(value: FilterDate) => {
                setFilterByDate(value);
                setSortPlainDescending(false);
                filterMode !== "Time" && setFilterMode("Time");
              }}
            />
            <></>
            <Button
              iconPosition="trailing"
              text={t("reminders.sort.due-date")}
              ssrIcon={sortDescending ? down : up}
              onClick={() => {
                setSortDescending(!sortDescending);
                setSortPlainDescending(!sortPlainDescending);
                filterMode !== "Date" && setFilterMode("Date");
              }}
              small
            />
          </Grid>
        )}
        <Line />
      </Stack>
      <Stack vertical spacing={10}>
        {newSortedReminders.map((reminder, index) => (
          <ReminderItem
            key={reminder.id}
            reminder={reminder}
            odd={index % 2 === 1}
            markAsDone={markAsDone}
          />
        ))}
      </Stack>
      {error && <Toast text={error.message} />}
    </Stack>
  );
};

Reminders.displayName = "Reminders";
