import { Stack } from "components/layout";
import { Text } from "components/typography";
import RadioButton from "@ingka/radio-button";
import { useLocale } from "common";
import { t } from "i18n";
import { renderLinkedText } from "common/helpers/renderLinkedText";

interface Props {
  onSelectionChange: (isApproved: boolean) => void;
}

export const exceptionLocales = [
  "en-FI",
  "fi-FI",
  "sv-FI",
  "en-CH",
  "de-CH",
  "it-CH",
  "en-PT",
  "en-AU",
];
export const isDesc2Required = (element: string) =>
  !exceptionLocales.includes(element);

export const FollowUpConsent = ({ onSelectionChange }: Props) => {
  const onRadioChange = (e) => {
    onSelectionChange(e.target.value === "approve");
  };
  const { locale } = useLocale();
  return (
    <Stack vertical spacing={10}>
      <Text header="h3">
        {t("new-opportunity.customer-consent.follow-up.title")}
      </Text>
      <Text pre>
        {renderLinkedText(
          t("new-opportunity.customer-consent.follow-up.description1")
        )}
      </Text>
      {isDesc2Required(locale) && (
        <Text>
          {t("new-opportunity.customer-consent.follow-up.description2")}
        </Text>
      )}
      <Stack>
        <RadioButton
          name="follow-up-consent"
          id="follow-up-consent-agree"
          label={t("new-opportunity.customer-consent.follow-up.agree")}
          value="approve"
          onChange={onRadioChange}
        />
        <RadioButton
          name="follow-up-consent"
          id="follow-up-consent-disagree"
          label={t("new-opportunity.customer-consent.follow-up.disagree")}
          value="disapprove"
          onChange={onRadioChange}
        />
      </Stack>
    </Stack>
  );
};
