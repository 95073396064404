import { Currency, Line, LocalizedDateRange, Stack, Text } from "components";
import { ProductListItem } from "common";
import { t, T } from "i18n";
import { Amounts } from "common/api";

interface Props {
  product: any;
  amounts?: Amounts;
  currency?: string;
  totalValue?: number;
}

interface IServiceItemProps {
  name: string;
  id?: string;
  currencyCode: string;
  price: number;
  timeWindows: {
    fromLocalDateTime: string;
    toLocalDateTime: string;
  }[];
}

const ValueItem = ({
  name,
  value,
  currency,
}: {
  name: string;
  value?: number;
  currency?: string;
}) => {
  return (
    <Stack justifyContent="space-between">
      <Text bold>{name}</Text>
      <Text bold>
        {value && currency ? (
          <Currency currency={currency} showSign fractions={2}>
            {value}
          </Currency>
        ) : (
          <T id="order-modal.amount-not-available" />
        )}
      </Text>
    </Stack>
  );
};

const ServiceItem = ({
  name,
  id,
  currencyCode,
  timeWindows,
  price,
}: IServiceItemProps) => (
  <Stack justifyContent="space-between">
    <Stack vertical spacing={0}>
      <Text bold>{name}</Text>
      {id && <Text small>{id}</Text>}
      {timeWindows &&
        timeWindows.map((tw) => (
          <div>
            <LocalizedDateRange
              fromDate={tw.fromLocalDateTime}
              toDate={tw.toLocalDateTime}
            />
          </div>
        ))}
    </Stack>
    <Text>
      <Currency currency={currencyCode} showSign fractions={2}>
        {price}
      </Currency>
    </Text>
  </Stack>
);

export const ProductList = ({
  product,
  currency,
  amounts,
  totalValue,
}: Props) => {
  const planner = product?.planners;
  return (
    <Stack vertical spacing={24}>
      <Stack vertical>
        <Text header="h2">
          <T id="planner-modal.items.header" />
        </Text>
        {product.items?.map(
          (item) => item && <ProductListItem key={item.itemNo} item={item} />
        )}

        <ValueItem
          name={t("order-modal.goods-value")}
          value={amounts?.GoodsValue?.grossAmount}
          currency={currency}
        />
      </Stack>

      {product.providedServices?.length > 0 && (
        <Stack vertical>
          <Line dotted color="neutralGrey500" />
          <Text header="h2">
            <T id="order-modal.services.header" />
          </Text>
          {product.providedServices.map((service) => (
            <ServiceItem
              key={service.serviceProductId}
              currencyCode={service.price.currencyCode}
              id={service.serviceProductId}
              name={service.serviceName}
              price={service.price.priceInclTax}
              timeWindows={service.timeWindows}
            />
          ))}
        </Stack>
      )}

      {product.deliveryArrangements?.length > 0 && (
        <>
          <Line dotted color="neutralGrey500" />
          <Text header="h2">
            <T id="order-modal.delivery.header" />
          </Text>
          {product.deliveryArrangements.map((arrangement) =>
            arrangement.deliveryServices.map((delivery) => (
              <ServiceItem
                key={delivery.serviceId}
                currencyCode={delivery.price.currencyCode}
                name={delivery.serviceName}
                price={delivery.price.priceInclTax}
                timeWindows={delivery.timeWindows}
              />
            ))
          )}
        </>
      )}

      <ValueItem
        name={t("order-modal.service-value")}
        value={amounts?.Service?.grossAmount}
        currency={currency}
      />

      <Line dotted color="neutralGrey500" />
      <Stack vertical spacing={6}>
        <ValueItem
          name={t("order-modal.total")}
          value={amounts?.Total?.grossAmount}
          currency={currency}
        />

        <ValueItem
          name={t("order-modal.discount")}
          value={amounts?.Discount?.grossAmount}
          currency={currency}
        />

        <ValueItem
          name={t("order-modal.to-pay")}
          value={amounts?.ToPay?.grossAmount}
          currency={currency}
        />
      </Stack>

      {planner?.length > 0 && (
        <>
          <Line dotted color="neutralGrey500" />
          <Stack vertical>
            {planner?.length > 1 ? (
              <Text header="h2">
                {`${t(
                  "manage-opportunity.view-order.associated-planners-count.title",
                  {
                    count: planner.length,
                  }
                )}`}
              </Text>
            ) : (
              <Text header="h2">
                <T id="manage-opportunity.view-order.associated-planner.title" />
              </Text>
            )}
            <ul>
              {planner.map(({ plannerId, plannerIdSource }) => (
                <li key={Math.random()}>
                  <Text bold xsmall inline>
                    <T id="manage-opportunity.order-info.view-order.planner-id.label" />
                    :
                  </Text>
                  <span style={{ marginRight: "0.6ch" }} />
                  {plannerId} -{" "}
                  <Text bold xsmall inline>
                    <T id="manage-opportunity.order-info.view-order.planner-type.label" />
                    :
                  </Text>
                  <span style={{ marginRight: "0.6ch" }} />
                  {plannerIdSource}
                </li>
              ))}
            </ul>
          </Stack>
        </>
      )}
    </Stack>
  );
};

ProductList.displayName = "ProductList";
