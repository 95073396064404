import * as React from "react";
import { t, T } from "i18n";

import { Box, Line, Stack, Text } from "components";
import { usePageTracking } from "analytics";

import bannerImg from "assets/home_banner.jpg";

export const Home = () => {
  usePageTracking("Home");

  return (
    <Box padding={24}>
      <Stack vertical spacing={24}>
        <Text header="h1">
          <T id="home.welcome.heading" />
        </Text>
        <Text>
          <T id="home.welcome.description" />
        </Text>
        <Line color="neutralGrey100" />

        <img
          src={bannerImg}
          alt={t("home.banner.alt-text")}
          width="100%"
          height="100%"
        />
      </Stack>
    </Box>
  );
};

Home.displayName = "Home";
