import { ReactNode } from "react";

import { Text } from "../typography/Text.component";
import { Stack } from "../layout/Stack.component";

interface Prop {
  /** A label that describes the text */
  label: string;
  /**  The text that the label describes. If left empty will render children instead */
  text?: string | number | JSX.Element;
  children?: ReactNode;
}

/**
 * Display a labelled text where the label is displayed
 * above the value. Displays the label with a "-" as value
 * if the value is undefined.
 */
export const LabeledText = ({ label, text, children }: Prop) => {
  return (
    <Stack spacing={0} dontGrow vertical>
      <Text label bold color="neutralGrey700">
        {label}
      </Text>
      {children ? children : text ? <Text>{text}</Text> : <Text>-</Text>}
    </Stack>
  );
};

LabeledText.displayName = "LabeledText";
