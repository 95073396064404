import { useState } from "react";
import {
  OpportunityPlanner,
  PlannerDetail,
  Opportunity as opportunityInterface,
  usePlanner,
  associateDrawing,
  disassociateDrawing,
} from "common/api";
import Modal, {
  Sheets,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "@ingka/modal";
import {
  getPlannerData,
  getPlannerId,
  getPlannerStatus,
  isOpportunityPlanner,
  plannerIsAdded,
} from "./utils";
import {
  LabeledText,
  LocalizedDate,
  Stack,
  Text,
  Grid,
  Button,
  useToast,
  Currency,
} from "components";
import SkapaButton from "@ingka/button";
import { ProductList } from "common";
import { css } from "@emotion/css";
import { t } from "i18n";
import { useSettings } from "settings/SettingsProvider";

type Planner = PlannerDetail | OpportunityPlanner;
interface PlannerModalProps {
  opportunity: opportunityInterface;
  closePlannerModal: () => void;
  showPlanner: boolean;
  addedPlanners: PlannerDetail[];
  addedVPCPlanners: OpportunityPlanner[];
  selectedPlanner: Planner;
  disableEditing: boolean;
  currencySymbol?: string;
}

export const PlannerModal = ({
  opportunity,
  closePlannerModal,
  showPlanner,
  addedPlanners,
  addedVPCPlanners,
  selectedPlanner,
  disableEditing,
  currencySymbol,
}: PlannerModalProps) => {
  const { feature_flags } = useSettings();
  const data = getPlannerData(selectedPlanner);

  const { data: opportunityPlanner } = usePlanner(
    opportunity.id,
    isOpportunityPlanner(selectedPlanner)
      ? getPlannerId(selectedPlanner)
      : undefined
  );

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { updateToast } = useToast();

  const handleModalClose = () => {
    setOpenConfirmModal(false);
    closePlannerModal();
  };

  const handleButtonClick = async () => {
    const drawingId = getPlannerData(selectedPlanner)?.designId;
    if (drawingId === undefined) return;
    if (plannerIsAdded(selectedPlanner, addedPlanners, addedVPCPlanners)) {
      setOpenConfirmModal(true);
    } else {
      try {
        setIsLoading(true);
        await associateDrawing(opportunity.id, drawingId, selectedPlanner);
      } catch (err) {
        updateToast({ text: t("planner.associate.error") });
      } finally {
        setIsLoading(false);
        closePlannerModal();
      }
    }
  };

  const removePlanner = async () => {
    const drawingId = getPlannerData(selectedPlanner)?.designId;
    if (drawingId === undefined) return;
    try {
      setOpenConfirmModal(false);
      setIsLoading(true);
      await disassociateDrawing(opportunity.id, drawingId);
    } catch (err) {
      updateToast({ text: t("planner.disassociate.error") });
    } finally {
      setIsLoading(false);
      closePlannerModal();
    }
  };

  if (!data) {
    return null;
  }

  return (
    <Modal handleCloseBtn={handleModalClose} visible={showPlanner}>
      <Sheets
        header={
          <ModalHeader
            closeBtnClick={closePlannerModal}
            title={t("planner.planner-detail.title")}
          />
        }
        footer={
          <ModalFooter closeBtnClick={closePlannerModal}>
            <Stack>
              {data.designUrlView && (
                <SkapaButton
                  className={css`
                    ${!feature_flags?.planner_drawing_edit &&
                    "margin-bottom: 16px"}
                  `}
                  text={t("planner.view.button")}
                  onClick={() => window.open(data.designUrlView)}
                  newWindow
                />
              )}
              {data.designUrlEdit && feature_flags?.planner_drawing_edit && (
                <SkapaButton
                  text={t("planner.edit.button")}
                  onClick={() => window.open(data.designUrlEdit)}
                  newWindow
                  disabled={disableEditing}
                />
              )}
            </Stack>
            <SkapaButton
              disabled={openConfirmModal || disableEditing}
              type={"primary"}
              small
              text={
                plannerIsAdded(selectedPlanner, addedPlanners, addedVPCPlanners)
                  ? t("planner.remove-from-opportunity.button")
                  : t("planner.add-to-opportunity.button")
              }
              onClick={handleButtonClick}
              loading={isLoading}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          {openConfirmModal ? (
            <>
              <Stack padding={[50, 0]} justifyContent="center">
                <Text bold>{t("planner-delete.confirm")}</Text>
              </Stack>
              <Stack justifyContent={"center"}>
                <Button
                  text={t("delete.no.button")}
                  onClick={() => setOpenConfirmModal(false)}
                />
                <Button
                  primary
                  text={t("delete.yes.button")}
                  onClick={removePlanner}
                />
              </Stack>
            </>
          ) : (
            <Stack vertical spacing={20}>
              <img
                src={data.thumbnailUrl}
                alt={
                  data.thumbnailUrl
                    ? "Planner image"
                    : "Planner image not available"
                }
                className={css`
                  object-fit: cover;
                  width: 370px;
                  height: 230px;
                  ${!data.thumbnailUrl && "border: 1px solid grey;"}
                `}
              />

              <Stack vertical spacing={0} padding={[10, 0]}>
                {data.designName && <Text header="h2">{data.designName}</Text>}
                <Text color="neutralGrey500">{data.designId}</Text>
              </Stack>

              <Grid columns={2}>
                <LabeledText
                  label={t("opportunities.label.type")}
                  text={data.plannerType}
                />

                {getPlannerStatus(data) && (
                  <LabeledText
                    label={t("opportunities.label.status")}
                    text={getPlannerStatus(data)}
                  />
                )}
                {data.createdDate && (
                  <LabeledText
                    label={t("opportunities.label.created")}
                    text={
                      <LocalizedDate pattern="Pp">
                        {data.createdDate}
                      </LocalizedDate>
                    }
                  />
                )}
                {data.lastModifiedDate && (
                  <LabeledText
                    label={t(
                      "manage-opportunity.opportunity-info.last-modified.label"
                    )}
                    text={
                      <LocalizedDate pattern="Pp">
                        {data.lastModifiedDate}
                      </LocalizedDate>
                    }
                  />
                )}
                <LabeledText
                  label={t("planner.price.label")}
                  text={
                    data.totalprice ? (
                      <Currency
                        currency={
                          data.currencySymbol || opportunity.currency || "SEK"
                        }
                        showSign
                        fractions={2}
                      >
                        {parseInt(data.totalprice)}
                      </Currency>
                    ) : (
                      "-"
                    )
                  }
                />
              </Grid>
              {opportunityPlanner && (
                <Stack padding={[20, 0, 0, 0]}>
                  <ProductList
                    product={opportunityPlanner}
                    totalValue={opportunityPlanner.amount}
                    currency={opportunity.currency}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </ModalBody>
      </Sheets>
    </Modal>
  );
};
