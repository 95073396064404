import { useState, useContext } from "react";
import { Text } from "components/typography";
import { Stack } from "components/layout";
import { SearchField } from "components";
import { CustomerGlobal, useConfig, useCustomerSearch } from "common/api";
import { Loading } from "components/ui";
import { Colors } from "components/Variables";
import { CustomerInfo } from "../common/CustomerInfo";
import { Button } from "components";
import { useHistory } from "react-router-dom";
import { OpportunityContext } from "../OpportunityContext";
import { T, t } from "i18n";
import { tracker } from "analytics";

export const CustomerSearch = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const { data, error, isValidating } = useCustomerSearch(searchTerm);
  const { data: config } = useConfig();

  const { setIsExistingCustomer, setCustomer } = useContext(OpportunityContext);

  const onCustomerSelected = (customer: CustomerGlobal) => {
    tracker.event({
      category: "New Sales Opportunity Creation",
      action: "Clicked on 'Select' button for existing customer",
    });
    setCustomer(customer);
    setIsExistingCustomer(true);

    const isConsentNeeded =
      config?.customer?.requireConsent === true &&
      config?.customer?.requireFollowUpConsent === true;

    history.push({
      hash: isConsentNeeded ? "/existing-customer-consent" : "/new-opportunity",
    });
  };

  const showResults = searchTerm && data && data.length !== 0;
  const notFound = searchTerm && data?.length === 0 && !isValidating;
  const isLoading = searchTerm && !data && !error;

  return (
    <Stack vertical spacing={24}>
      <Text header="h3">
        <T id="new-opportunity.customer-search.title" />
      </Text>

      <SearchField
        id="customer-search"
        placeholder={t("new-opportunity.customer-search.placeholder")}
        value={searchTerm}
        onSearch={(term) => setSearchTerm(term)}
        onClear={() => {
          setSearchTerm("");
        }}
      />

      {showResults && (
        <Stack vertical>
          <Text header="h3">
            <T
              id="new-opportunity.customer-search.customers-list.title"
              values={{
                searchTerm,
              }}
            />
          </Text>
          {data?.map((customer, index) => (
            <Stack
              key={customer.partyId}
              backgroundColor={
                index % 2 === 0 ? Colors.neutralGrey100 : "white"
              }
              padding={10}
            >
              <CustomerInfo customer={customer} />

              <Button
                primary
                small
                text={t("new-opportunity.customer-select.button")}
                onClick={() => onCustomerSelected(customer)}
              />
            </Stack>
          ))}
        </Stack>
      )}

      {notFound && (
        <Stack vertical>
          <Text bold>
            <T id="new-opportunity.customer-search.not-found.header" />
          </Text>
          <Text>
            <T id="new-opportunity.customer-search.not-found.sub-header" />
          </Text>
          <Text>
            <T id="new-opportunity.customer-search.not-found.description" />
          </Text>
        </Stack>
      )}

      {isLoading && <Loading isLoading={isLoading} />}

      {error && (
        <Text>
          <T id="new-opportunity.customer-search.error" />
        </Text>
      )}
    </Stack>
  );
};
