import { useLocation, useRouteMatch } from "react-router-dom";
import { useProfile } from "common/api";
import { Box, Line, Loading, Stack, Text } from "components";
import { usePageTracking } from "analytics";

import { OpportunitiesAddons } from "./OpportunitiesAddons";
import { t, T } from "i18n";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { OpportunitiesTab } from "./OpportunitiesTab";
import { AppointmentsTab } from "features/appointments/AppointmentsTab";
import { parseHashRoute } from "common";
import { useSettings } from "settings/SettingsProvider";

export const OpportunitiesPage = () => {
  const { feature_flags } = useSettings();
  const { path } = useRouteMatch();
  const isMe = !!useRouteMatch(`${path}/me`);

  const params = parseHashRoute(useLocation().hash);
  const selectedTab = params.tab ?? "oppoTab";

  const { data: profile } = useProfile();

  const { boka_config } = useSettings();
  usePageTracking(isMe ? "My-Sales-Opportunities" : "All-Sales-Opportunities");

  if (!profile) return <Loading fullscreen isLoading />;

  return (
    <Box padding={24} grow>
      <Stack vertical spacing={24}>
        <Text header="h1">
          {isMe ? (
            <T id="my-opportunities.header.title" />
          ) : (
            <T id="all-opportunities.header.title" />
          )}
        </Text>
        <Text>
          {isMe ? (
            <T id="my-opportunities.header.content" />
          ) : (
            <T id="all-opportunities.header.content" />
          )}
        </Text>

        <Line color="neutralGrey100" />

        {isMe && feature_flags?.disable_my_addons !== true && (
          <>
            <OpportunitiesAddons />
            <Line color="neutralGrey100" />
          </>
        )}

        <Tabs
          onTabChanged={(tabId) =>
            window.history.replaceState(null, "tabId", `#tab=${tabId}`)
          }
          defaultActiveTab={selectedTab}
          tabs={[
            <Tab
              key="tab-1"
              text={
                isMe
                  ? t("my-opportunities.header.title")
                  : t("opportunities.section.header.title")
              }
              tabPanelId="oppoTab"
            />,
            boka_config?.enable && (
              <Tab
                key="tab-2"
                text={
                  isMe
                    ? t("my-appointments.header.title")
                    : t("appointment.appointments.label")
                }
                tabPanelId="apptTab"
              />
            ),
          ]}
          tabPanels={[
            <TabPanel key="tab-1" tabPanelId="oppoTab">
              <OpportunitiesTab key={`opportunities-tab-${isMe}`} />
            </TabPanel>,
            boka_config?.enable && (
              <TabPanel key="tab-2" tabPanelId="apptTab">
                <AppointmentsTab key={`appointments-tab-${isMe}`} />
              </TabPanel>
            ),
          ]}
        />
      </Stack>
    </Box>
  );
};
