import { css } from "@emotion/css";
import { Box, Stack, Text } from "components";
import { Colors } from "components/Variables";
import { t, T } from "i18n";
import { usePageTracking } from "analytics";
import { defaultConfig } from "./dashboard-config";

export const StatisticsDashboards = () => {
  usePageTracking("Dashboards");
  const config = defaultConfig;
  return (
    <Box padding={24} grow>
      <Stack vertical spacing={24}>
        <Text header="h1">
          <T id="statistics-dashboard.header" />
        </Text>
        {config.adminLink && (
          <Text>
            <a
              href={config.adminLink}
              target="_blank"
              rel="noreferrer"
              style={{ color: "#0072c6", fontStyle: "italic" }}
            >
              <T id="statistics-managers-dashboard.label" />
            </a>
          </Text>
        )}
        <Text>
          <a
            href={config.link}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#0072c6", fontStyle: "italic" }}
          >
            <T id="statistics-dashboard.open-message" />
          </a>
        </Text>

        <iframe
          title={t("dashboard.title")}
          className={css`
            width: "100%";
            min-height: 1800px;
            max-width: 860px;
            border: 0;
            background: ${Colors.neutralWhite};
          `}
          src={config.iframe}
        ></iframe>
      </Stack>
    </Box>
  );
};
