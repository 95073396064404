import { useEffect, useState } from "react";
import {
  DialingCode,
  dialingCodes,
  findDialingCode,
  importantCountries,
  useMarket,
} from "common";
import { t } from "i18n";
import { Stack } from "../../components/layout/Stack.component";
import { css, cx } from "@emotion/css";
import { useField } from "formik";
import SkapaSelect, { Option } from "@ingka/select";
import { InputField as PureInputField } from "components/forms";

interface IProps {
  name: string;
  type: string;
}

const cleanPhoneNumber = (
  phone: string,
  setPhone?: React.Dispatch<React.SetStateAction<string>>
) => {
  let p = phone;

  // remove starting 0
  if (p[0] === "0") {
    p = phone.slice(1);
  }

  // replace invalid characters
  p = p.replace(/[\s-]/g, "");

  if (setPhone && p !== phone) {
    setPhone(p);
  }

  return p;
};

const sortImportantCountries = (
  a: DialingCode,
  b: DialingCode,
  activeMarket: string,
  selectedImportantCountries: string[]
) => {
  if (a.code === activeMarket) {
    return -1;
  }
  if (b.code === activeMarket) {
    return 1;
  }

  if (selectedImportantCountries.includes(a.code)) {
    if (selectedImportantCountries.includes(b.code)) {
      return a.code.localeCompare(b.code);
    }

    return -1;
  }

  if (selectedImportantCountries.includes(b.code)) {
    if (selectedImportantCountries.includes(a.code)) {
      return a.code.localeCompare(b.code);
    }

    return 1;
  }

  return a.code.localeCompare(b.code);
};

export const PhoneNumberInput = (props: IProps) => {
  const activeMarket = useMarket();
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [, meta, helpers] = useField({
    name: props.name,
    type: props.type,
  });
  const [, , phonePrefixHelper] = useField({
    name: "phonePrefix",
    type: "text",
  });
  const { setValue: setFormPhoneNumber, setTouched } = helpers;
  const { setValue: setFormPhonePrefix } = phonePrefixHelper;

  useEffect(() => {
    const initialDialingCode = findDialingCode(activeMarket);

    if (initialDialingCode) {
      setSelectedCountryCode(initialDialingCode.code);
      setFormPhonePrefix(initialDialingCode?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarket]);
  const selectedImportantCountries: string[] =
    importantCountries[activeMarket] ?? [];

  return (
    <Stack>
      <SkapaSelect
        id="PhoneNumberSelect"
        label={t("new-opportunity.customer.form.country-code.label")}
        onChange={(e) => {
          const dialingCode = findDialingCode(e.target.value);
          setSelectedCountryCode(dialingCode?.code ?? "");
          setFormPhoneNumber(dialingCode?.prefix + phone);
          setFormPhonePrefix(dialingCode?.code);
        }}
        value={selectedCountryCode}
      >
        {dialingCodes
          .sort((a, b) =>
            sortImportantCountries(
              a,
              b,
              activeMarket,
              selectedImportantCountries
            )
          )
          .map(({ prefix: thePrefix, code }) => (
            <Option value={code} name={`${code}(${thePrefix})`} key={code} />
          ))}
      </SkapaSelect>
      <div
        className={cx(
          css`
            flex-grow: 1;
          `
        )}
      >
        <PureInputField
          errorMessage={meta.touched ? meta.error : undefined}
          name={props.name}
          type={props.type}
          label={t("new-opportunity.customer.form.mobile.label")}
          placeholder={t("new-opportunity.customer.form.mobile.placeholder")}
          value={phone}
          onBlur={() => {
            const dialingCode = findDialingCode(selectedCountryCode);
            let phoneNumber = cleanPhoneNumber(phone, setPhone);

            setFormPhoneNumber(dialingCode?.prefix + phoneNumber);
            setTouched(true);
          }}
          onChange={(e) => {
            const dialingCode = findDialingCode(selectedCountryCode);
            let phoneNumber = cleanPhoneNumber(e.target.value);

            setFormPhoneNumber(dialingCode?.prefix + phoneNumber);
            setPhone(e.target.value);
          }}
        />
      </div>
    </Stack>
  );
};
