import { QuestionObject } from "common/api";
import { Checkbox, RadioButtonFixed, Stack, Text } from "components";
import { t } from "i18n";
import { isEmpty } from "lodash";

type Props = {
  questionnaire: QuestionObject[] | undefined;
  languageCode: string;
};

type QuestionItemProps = {
  question: QuestionObject;
  languageCode: string;
};

const QuestionItem = ({ question, languageCode }: QuestionItemProps) => {
  if (isEmpty(question.question) || isEmpty(question.question.text))
    return <></>;

  if (question.question.type === "TEXT") {
    return (
      <Stack vertical spacing={5}>
        <Text small bold wordBreakWord>
          {question.question.text[languageCode]}
        </Text>
        <Text small wordBreakWord>
          {question.answer.answer}
        </Text>
      </Stack>
    );
  }

  if (question.question.type === "RADIO") {
    return (
      <Stack vertical spacing={5}>
        <Text small bold wordBreakWord>
          {question.question.text[languageCode]}
        </Text>
        {question.question.options.map((option, index) => (
          <RadioButtonFixed
            id={String(index)}
            label={option.text[languageCode]}
            value=""
            onChange={() => {}}
            checked={question.answer.answer.includes(String(option.id))}
            disabled={!question.answer.answer.includes(String(option.id))}
          />
        ))}
      </Stack>
    );
  }

  if (question.question.type === "CHECKBOX") {
    return (
      <Stack vertical spacing={5}>
        <Text small bold wordBreakWord>
          {" "}
          {question.question.text[languageCode]}
        </Text>
        {question.question.options.map((option, index) => (
          <Checkbox
            id={String(index)}
            keepTogether
            label={option.text[languageCode]}
            checked={question.answer.answer.includes(String(option.id))}
            disabled={!question.answer.answer.includes(String(option.id))}
          />
        ))}
      </Stack>
    );
  }

  return <></>;
};

const AppointmentQuestionnaire = ({ questionnaire, languageCode }: Props) => {
  return (
    <Stack vertical spacing={20}>
      <Text header="h3">{t("appointment.questionnaire.label")}</Text>
      {questionnaire && questionnaire.length > 0 ? (
        questionnaire.map((question) => (
          <QuestionItem question={question} languageCode={languageCode} />
        ))
      ) : (
        <Text>{t("appointment.no-questionnaire.message")}</Text>
      )}
    </Stack>
  );
};

export default AppointmentQuestionnaire;
