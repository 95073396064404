import { createContext, useEffect, useState } from "react";
import { CTEMarket, defaultMarket, isActiveMarket, Market } from "./markets";
import defaultRules from "../../assets/validation-rules/global.json";
import { useUserLocation } from "common/api/user/userLocation";

interface ValidationRule {
  /** The maximum number of characters allowed */
  maxLength: number;
  /** The minmum number of characters needed */
  minLength: number;
  /** A regExp string used to validate the input */
  regExp: string;
  /** An example of a correct input */
  example: string;
}

interface ValidationRules {
  customer: {
    postalCode: ValidationRule;
    mobile: ValidationRule;
    landline: ValidationRule;
    email: ValidationRule;
  };
}

interface IMarketContext {
  activeMarket: Market | CTEMarket;
  activeMarketValidationRules: ValidationRules;
}

export const MarketContext = createContext<IMarketContext>(null!);

interface Props {
  children: React.ReactChild;
}

/**
 * Validate and set the currently active market as well as market rules.
 * Will default to SE if user has no market or does not belong to an active market.
 * Validation rules will deafult to global.
 */
export const MarketProvider = ({ children }: Props) => {
  const userLocation = useUserLocation();

  const [activeMarketValidationRules, setActiveMarketValidationRules] =
    useState<ValidationRules>(defaultRules);

  const userMarket =
    userLocation || window.sessionStorage.getItem("userCountry");
  const activeMarket =
    userMarket && isActiveMarket(userMarket) ? userMarket : defaultMarket;

  if (userMarket) {
    window.sessionStorage.setItem("userCountry", userMarket);
  }

  useEffect(() => {
    const fetchRules = async () => {
      if (!userMarket || !isActiveMarket(userMarket)) {
        return;
      }
      let rules;
      try {
        rules = await import(
          `assets/validation-rules/${userMarket?.toLowerCase()}.json`
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`${error}\n Market ${userMarket} has no validation rules.`);

        rules = await import(`assets/validation-rules/global.json`);
      }

      setActiveMarketValidationRules(rules);
    };
    fetchRules();
  }, [userMarket]);

  return (
    <MarketContext.Provider
      value={{
        activeMarket,
        activeMarketValidationRules,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
};
