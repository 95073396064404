import { T } from "i18n";

const translationKeys = {
  Assigned: "manage-opportunity.status.assigned",
  InProgress: "manage-opportunity.status.in-progress",
  ClosedWon: "manage-opportunity.status.closed-won",
  ClosedLost: "manage-opportunity.status.closed-lost",
  Closed: "manage-opportunity.status.closed",
  Invalid: "manage-opportunity.status.invalid",
  Unassigned: "manage-opportunity.status.unassigned",
} as const;

interface Props {
  children: keyof typeof translationKeys;
}

export const OpportunityStatus = ({ children }: Props) => (
  <T id={translationKeys[children]} />
);

OpportunityStatus.displayName = "OpportunityStatus";
