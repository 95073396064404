import { ReactNode } from "react";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { css } from "@emotion/css";
import { Box } from "components";

interface Props {
  id?: string;
  title: string;
  children?: ReactNode;
  open?: boolean;
}

export const ManageAccordion = ({ id, title, children, open }: Props) => (
  <Box color="bjornGrey" padding={[0, 30]}>
    <Accordion
      className={css`
        .accordion-item-header {
          border-top: none;
        }
        .accordion-item-header__title {
          font-size: 1.375rem;
          line-height: 1.45455;
        }
      `}
    >
      <AccordionItem id={id ?? title} title={title} open={open}>
        {children}
      </AccordionItem>
    </Accordion>
  </Box>
);

ManageAccordion.displayName = "ManageAccordion";
