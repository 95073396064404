import { useLocale } from "common";
import Button from "@ingka/button";
import { Box, Stack, Text } from "components";
import { T, t } from "i18n";

export const Redirect = () => {
  const locale = useLocale();

  const URL = `${
    process.env.REACT_APP__WEB_COWORKER__
  }/${locale.countryCode.toLowerCase()}/${locale.languageCode.toLowerCase()}/saleslead`;
  return (
    <Box padding={24} grow>
      <Stack vertical spacing={24}>
        <Text header="h1">
          <T id="redirect.title" />
        </Text>
        <Text>
          <T id="redirect.description" />
        </Text>
        <div>
          <Button type="primary" href={URL} text={t("redirect.button_label")} />
        </div>
      </Stack>
    </Box>
  );
};
