import { Box, Line, NavigationMenu, Stack } from "components";
import {
  HomeIcon,
  OpportunityIcon,
  MyTasksIcon,
  GuidelinesIcon,
  FeedbackIcon,
  ContactsIcon,
  CalendarIcon,
  IkeaLogo,
} from "components";
import { css } from "@emotion/css";
import { NavLink } from "react-router-dom";
import { NewSalesOpportunity } from "features/new-sales-opportunity";
import { t } from "i18n";
import { useSettings } from "settings/SettingsProvider";

const pages = [
  {
    path: "/",
    icon: HomeIcon,
    name: "navigation.home",
  },
  {
    path: "/calendar-view",
    icon: CalendarIcon,
    name: "calendar.header.title",
  },
  {
    path: "/opportunities/me",
    icon: MyTasksIcon,
    name: "navigation.my-opportunities",
  },
  {
    path: "/opportunities",
    icon: OpportunityIcon,
    name: "navigation.all-opportunities",
  },
  {
    path: "/statistics-dashboard",
    icon: FeedbackIcon,
    name: "navigation.dashboards",
  },
  {
    path: "/guidelines",
    icon: GuidelinesIcon,
    name: "navigation.guidelines",
  },
  {
    path: "/contact-us",
    icon: ContactsIcon,
    name: "navigation.contact",
  },
];

const Logo = () => (
  <Box border={[0, 0, 1, 0]} borderColor="neutralGrey200" height="5rem">
    <NavLink
      exact
      to={"/"}
      title={t("ikea.logo.title")}
      className={css`
        margin: 0 1rem;
        display: flex;
        width: fit-content;
      `}
    >
      <IkeaLogo />
    </NavLink>
  </Box>
);

export const SideMenu = () => {
  const { feature_flags } = useSettings();

  return (
    <aside
      className={css`
        max-width: 240px;
        min-width: 240px;
        top: 0;
        position: sticky;
        align-self: flex-start;
        z-index: 5;
      `}
    >
      <Stack vertical spacing={0}>
        <Logo />
        <NavigationMenu pages={pages} />
        {!feature_flags?.redirect_to_salja && (
          <>
            <Line />
            <Box justifyContent="center" padding={10}>
              <NewSalesOpportunity />
            </Box>
          </>
        )}
      </Stack>
    </aside>
  );
};
