import { Button } from "components";
import { css } from "@emotion/css";
import { t } from "i18n";

const style = css`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 640px;
  text-align: center;

  .full-page-message {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .logOutImage {
    position: relative;
    height: 23rem;
    width: 23rem;
    margin: 0 auto 2rem auto;
    transform: rotate(90deg);
  }

  .logOutImage:before {
    content: "";
    background: #fafafa;
    border-radius: 50%;
    height: 23rem;
    width: 23rem;
    position: absolute;
    left: 0;
    top: 0;
  }

  .logOutIcon {
    width: 13rem;
    height: 13rem;
    position: absolute;
    top: 5rem;
    left: 6rem;
  }

  .lotout--btn {
    button.btn--small {
      background: #003e72;
      width: 199px;
      margin: 20px 0;
    }
  }
`;

export function Logout() {
  return (
    <div className={style}>
      <div className="full-page-message">
        <div className="logOutImage">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="rotate-90 logOutIcon"
          >
            <path
              d="M21,10V21H3V10H5v9H19V10ZM11,7.71V15h2V7.71L14.29,9l1.42-1.41L12,3.88,8.29,7.59,9.71,9Z"
              fill="#666"
            />
          </svg>
        </div>
        <h2 className="logOutTitle full-page-message__title">
          Logged Out Successfully
        </h2>
        <p className="logOutBody full-page-message__body">
          You have been logged out from the Sales Assistant tool.
        </p>
        <div className="lotout--btn">
          <a href="/">
            <Button emphasised small text={t("login.button")} />
          </a>
        </div>
      </div>
    </div>
  );
}
