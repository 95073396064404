import { Box, PopoverMenu, PopoverItem, Stack, Text } from "components";
import { Link } from "common/api";
import { t } from "i18n";

interface ILinkItemProps {
  link: Link;
  odd: boolean;
  onEdit(link: Link): void;
  onDelete(id: number): Promise<any>;
}

export const LinkItem: React.FunctionComponent<ILinkItemProps> = ({
  link,
  onEdit,
  onDelete,
  odd,
}) => (
  <Box padding={20} color={odd ? "bjornGrey" : "neutralWhite"}>
    <Stack justifyContent="space-between">
      <Text small wordBreakAll>
        <a
          href={link.link}
          target="_blank"
          rel="noreferrer"
          style={{ color: "#0072c6" }}
        >
          {link.description || link.link}
        </a>
      </Text>
      <PopoverMenu>
        <PopoverItem
          text={t("my-opportunities.view-my-links.edit.button")}
          onClick={() => onEdit(link)}
        />
        <PopoverItem
          text={t("my-opportunities.view-my-links.delete.button")}
          onClick={() => onDelete(link.id)}
        />
      </PopoverMenu>
    </Stack>
  </Box>
);

LinkItem.displayName = "LinkItem";
