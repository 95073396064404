import { useConfig } from "common/api";
import { MultiSelect } from "components";
import { useField } from "formik";
import { t } from "i18n";

interface Props {
  name: string;
}

export const DepartmentSelector = ({ name }: Props) => {
  const { data: config } = useConfig();
  const [, meta, helpers] = useField({ name });
  const { setValue, setTouched } = helpers;
  const { value } = meta;

  return !config ? null : (
    <MultiSelect
      id="department"
      label={t("manage-opportunity.opportunity-info.department.label")}
      possibleValues={config.department}
      values={value}
      valid={!meta.touched ? undefined : !meta.error}
      sortByName={true}
      placeholder={t(
        "manage-opportunity.opportunity-info.department.placeholder"
      )}
      errorMessage={meta.error}
      onChange={(values) => {
        setValue(values);
        setTouched(true);
      }}
    />
  );
};
