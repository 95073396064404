import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button } from "components";
import Toast from "@ingka/toast";
import closeLarge from "@ingka/ssr-icon/paths/cross";
import arrowDownToLineIcon from "@ingka/ssr-icon/paths/arrow-down-to-line";
import { Box, Bytes, ModalSheet, Stack, Text } from "components";
import { css } from "@emotion/css";
import { CurrentOpportunity } from "../currentOpportunity.reducer";
import { ManageAccordion } from "./ManageAccordion.component";
import { downloadFile, uploadFile, deleteFile, Attachment } from "common/api";
import { mutate } from "swr";
import { T, t } from "i18n";
import { tracker } from "analytics";

interface Props {
  currentOpportunity: CurrentOpportunity;
  disableEditing: boolean;
}

export const FileUpload = ({ currentOpportunity, disableEditing }: Props) => {
  const { opportunityId } = useParams<{ opportunityId: string }>();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileToBeDeleted, setFileToBeDeleted] = useState<
    Attachment | undefined
  >(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onDelete = async (fileName) => {
    await deleteFile(opportunityId, fileName);
    mutate(`/opportunities/${opportunityId}`);
    mutate(`/opportunities/${opportunityId}/timelog`);
  };

  const onDownload = (fileName: string, operation = "download") =>
    downloadFile(opportunityId, fileName, operation);

  const onUpload = async (files: FileList) => {
    setIsLoading(true);
    try {
      await Promise.all(Array.from(files).map(onSave));
      tracker.event({
        category: "Manage page",
        action: "Uploaded File",
      });
      mutate(`/opportunities/${opportunityId}`);
      mutate(`/opportunities/${opportunityId}/timelog`);
      mutate(`/opportunities/${opportunityId}/history`);
    } catch {
      setErrorMsg(t("manage-opportunity.files.upload.failed"));
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onSave = async (fileUploadObj) => {
    if (fileUploadObj !== null) {
      const resNew = await uploadFile(opportunityId, fileUploadObj);

      if (!resNew) {
        throw new Error();
      }
    }
  };

  return (
    <>
      <Toast
        text={errorMsg}
        isOpen={!!errorMsg}
        actionButtonText={t("manage-opportunity.toasts.files-upload.close")}
        actionClick={() => setErrorMsg("")}
        className={css`
          z-index: 30;
        `}
      />
      <ManageAccordion
        title={
          currentOpportunity.attachments &&
          currentOpportunity.attachments.length >= 1
            ? t("manage-opportunity.files.title", {
                count: currentOpportunity.attachments.length,
              })
            : t("manage-opportunity.file.title")
        }
      >
        <Stack vertical spacing={24}>
          <Text>
            <T id="manage-opportunity.files.upload.text" />
          </Text>
          <Stack>
            <Box minWidth="16%" alignItems="start">
              <Button
                text={t("manage-opportunity.files.upload.button")}
                primary
                loading={isLoading}
                onClick={() => fileInputRef.current?.click()}
                disabled={disableEditing}
              />
              <input
                type="file"
                id="file"
                ref={fileInputRef}
                name="file"
                className={css`
                  display: none !important;
                `}
                accept="image/png, image/jpeg, image/jpg, .pdf, .xls, .xlsx, .txt, .csv, .docx"
                onChange={(event) => {
                  onUpload(event.target.files!);
                }}
                multiple
              />
            </Box>
            <Stack spacing={24} wrap>
              {currentOpportunity.attachments &&
                currentOpportunity.attachments.map((attachment, index) => (
                  <Stack
                    key={index}
                    alignItems="center"
                    spacing={8}
                    padding={5}
                    dontGrow
                  >
                    <Button
                      small
                      iconOnly
                      ssrIcon={closeLarge}
                      onClick={() => {
                        setFileToBeDeleted(attachment);
                      }}
                      disabled={disableEditing}
                    />
                    <Button
                      small
                      iconOnly
                      ssrIcon={arrowDownToLineIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDownload(attachment.name);
                      }}
                      disabled={disableEditing}
                    />
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      className={css`
                        text-decoration: underline;
                        cursor: pointer;
                      `}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDownload(attachment.name, "open");
                      }}
                    >
                      {attachment.name}
                    </a>
                  </Stack>
                ))}
            </Stack>
          </Stack>
        </Stack>
        <ModalSheet
          title={t("manage-opportunity.files.delete.title")}
          visible={!!fileToBeDeleted}
          onClose={() => setFileToBeDeleted(undefined)}
          primaryButtonProps={{
            text: t("manage-opportunity.files.delete.button"),
            loading: isLoading,
            emphasised: true,
            onClick: async () => {
              setIsLoading(true);
              await onDelete(fileToBeDeleted?.name);
              setIsLoading(false);
              setFileToBeDeleted(undefined);
            },
          }}
        >
          <Text>
            <T id="manage-opportunity.files.delete.text" />
          </Text>
          <Text>
            <strong>{fileToBeDeleted?.name}</strong>{" "}
            <Bytes>{fileToBeDeleted?.size}</Bytes>
          </Text>
        </ModalSheet>
      </ManageAccordion>
    </>
  );
};

FileUpload.displayName = "FileUpload";
