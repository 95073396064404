import { Action } from "./actions";

export const fogActions: Action[] = [
  {
    kind: "SPOKE_WITH_CUSTOMER",
    name: "Spoke with the customer",
    channel: [
      {
        kind: "CUSTOMER_CALLED_IN",
        name: "Customer Called In",
      },
      {
        kind: "CALLED_THE_CUSTOMER",
        name: "Called the customer",
      },
      {
        kind: "WALK_IN_CUSTOMER",
        name: "Drop in",
      },
    ],
  },
  {
    kind: "SCHEDULE_CALL",
    name: "Schedule a phone call",
  },
  {
    kind: "OTHER_ACTION_TAKEN",
    name: "Other action",
  },
  {
    kind: "MANAGE_ORDER",
    name: "Manage Order",
    reason: [
      {
        kind: "IMPORT_ORDER",
        name: "Import Order",
      },
      {
        kind: "CREATE_NEW_ORDER",
        name: "Create new order",
      },
      {
        kind: "MODIFY",
        name: "Modify",
      },
      {
        kind: "CANCEL",
        name: "Cancel",
      },
    ],
  },
  {
    kind: "SCHEDULE_AN_APPOINTMENT",
    name: "Book an appointment",
  },
  {
    kind: "MANAGE_PLANNER",
    name: "Manage Planner",
    reason: [
      {
        kind: "IMPORT_PLANNER",
        name: "Import Planner",
      },
    ],
  },
];
