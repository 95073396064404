import { css } from "@emotion/css";
import { Colors } from "components";
import {
  Children,
  cloneElement,
  MouseEvent,
  ReactElement,
  ReactNode,
} from "react";

export type TableRowProps = {
  children?: ReactNode;
  onCellClick?: (event: MouseEvent<HTMLTableCellElement>) => void;
  disableClick?: boolean;
};

const trStyle = css`
  :nth-of-type(even) td {
    background-color: ${Colors.neutralGrey100};
  }
`;

export const TableRow = ({
  children,
  onCellClick,
  disableClick = false,
}: TableRowProps) => {
  return (
    <tr className={trStyle}>
      {Children.map(
        children,
        (child: ReactElement) =>
          child &&
          cloneElement(child, {
            ...child?.props,
            onClick: (event: MouseEvent<HTMLTableCellElement>) => {
              if (disableClick) return;
              if (!("preventCellClick" in child.props)) onCellClick?.(event);
              child.props.onClick?.(event);
            },
          })
      )}
    </tr>
  );
};
