import { Color, Colors } from "../Variables";
import { css, cx } from "@emotion/css";

interface Props {
  color?: Color;
  dotted?: boolean;
}
export const Line = ({ color = "neutralGrey200", dotted = false }: Props) => (
  <hr
    className={cx(
      css`
        border: 0;
        border-bottom: 1px solid ${Colors[color]};
        width: 100%;
        margin: 0;
      `,
      dotted &&
        css`
          border-bottom: 1px dotted ${Colors[color]};
        `
    )}
  />
);

Line.displayName = "Line";
