import { useLocale } from "common/hooks";
import { currencyFormatter } from "./CurrencyFormatter";

interface Props {
  /**
   * The currency which is to be displayed
   */
  currency: string;
  /**
   * The number value (amount) of the currency
   */
  children: number;
  /** Force a number of fractions, defaults to 0 */
  fractions?: number;
  /** Show the currency code/sign, defaults to false */
  showSign?: boolean;
}

/**
 * Display a currency in the users locale
 */
export const Currency = ({
  currency,
  children,
  fractions = 0,
  showSign = false,
}: Props) => {
  const locale = useLocale();
  return (
    <>
      {currencyFormatter(
        children,
        currency,
        locale.toString(),
        showSign,
        fractions
      )}
    </>
  );
};
