import SkapaModal, {
  Prompt as SkapaPrompt,
  ModalFooter,
  ModalBody,
} from "@ingka/modal";
import Button from "@ingka/button";
import { IButtonProps } from "./Button.component";

interface Props {
  title: string;
  onClose(): void;
  children: React.ReactChild;
  escapable?: boolean;
  visible?: boolean;
  closeText?: string;
  primaryButtonProps?: {
    text?: IButtonProps["text"];
    disabled?: IButtonProps["disabled"];
    loading?: IButtonProps["loading"];
    onClick?: IButtonProps["onClick"];
    emphasised?: IButtonProps["emphasised"];
  };
  secondaryButtonProps?: {
    loading?: IButtonProps["loading"];
  };
}

/**
 * Show a prompt-type modal
 */
export const Prompt = ({
  onClose,
  title,
  children,
  primaryButtonProps,
  secondaryButtonProps,
  escapable,
  visible,
  closeText,
}: Props) => {
  return (
    <SkapaModal
      handleCloseBtn={onClose}
      escapable={escapable}
      visible={visible}
    >
      <SkapaPrompt
        title={title}
        footer={
          <ModalFooter>
            {primaryButtonProps && (
              <Button
                type={primaryButtonProps.emphasised ? "emphasised" : "primary"}
                text={primaryButtonProps.text}
                disabled={primaryButtonProps.disabled}
                loading={primaryButtonProps.loading}
                onClick={primaryButtonProps.onClick}
              />
            )}
            {escapable && closeText && (
              <Button
                text={closeText}
                onClick={onClose}
                loading={secondaryButtonProps?.loading}
              />
            )}
          </ModalFooter>
        }
      >
        <ModalBody>{children}</ModalBody>
      </SkapaPrompt>
    </SkapaModal>
  );
};
