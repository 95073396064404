import InputField from "@ingka/input-field";
import calendarIcon from "@ingka/ssr-icon/paths/calendar";
import arrowIcon from "@ingka/ssr-icon/paths/arrow-right";
import { css } from "@emotion/css";
import { isArray } from "lodash";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Stack } from "components/layout";
import { Icon } from "components/ui";
import { useLocale } from "common/hooks";
import { forwardRef } from "react";

interface Props {
  id: string;
  placeholder?: string | [string, string];
  label?: string | [string, string];
  /** The value is a Date object. If an array of Dates is provided it will render as a date range with two input fields */
  value?: Date | [Date, Date];
  isClearable?: boolean;
  /** Set a minimum date */
  minDate?: Date;
  maxDate?: Date;
  onChange?(start?: Date, end?: Date): void;
  maxWidth?: string;
  disabled?: boolean;
}

export const DatePicker = (props: Props) => {
  const locale = useLocale();

  const wrapperClass = css`
    max-width: ${props.maxWidth};

    .react-datepicker__close-icon {
      top: 6px;
    }

    .input-field__wrapper {
      background-color: #fff;
    }
  `;

  const onChange = (dates: Date | Date[] | null) => {
    if (dates === null) {
      return props.onChange?.(undefined);
    }
    if (isArray(dates)) {
      props.onChange?.(...dates);
    } else {
      props.onChange?.(dates);
    }
  };

  return isArray(props.value) ? (
    <Stack alignItems="baseline">
      <Box width="50%" overflow="hidden">
        <ReactDatePicker
          disabled={props.disabled}
          customInput={
            <DatePickerInput
              label={
                props.label
                  ? isArray(props.label)
                    ? props.label[0]
                    : props.label
                  : "From"
              }
              placeholder={
                isArray(props.placeholder)
                  ? props.placeholder[0]
                  : props.placeholder
              }
              disabled={props.disabled}
            />
          }
          onChange={(date: Date) => {
            onChange([date, props.value![1]]);
          }}
          id={`from_${props.id}`}
          startDate={props.value?.[0] || null}
          endDate={props.value?.[1] || null}
          maxDate={props.value?.[1] || null}
          selected={props.value?.[0] || null}
          selectsStart
          disabledKeyboardNavigation
          locale={locale.dateFnsLocale}
          dateFormat="P"
          wrapperClassName={wrapperClass}
        />
      </Box>
      <Box width="50%" overflow="hidden">
        <ReactDatePicker
          id={`to_${props.id}`}
          disabled={props.disabled}
          customInput={
            <DatePickerInput
              label={
                props.label
                  ? isArray(props.label)
                    ? props.label[1]
                    : props.label
                  : "To"
              }
              placeholder={
                isArray(props.placeholder)
                  ? props.placeholder[1]
                  : props.placeholder
              }
              icon={arrowIcon}
              disabled={props.disabled}
            />
          }
          onChange={(date: Date) => {
            onChange([props.value?.[0], date]);
          }}
          minDate={props.value?.[0] || null}
          maxDate={props.maxDate || null}
          startDate={props.value?.[0] || null}
          endDate={props.value?.[1] || null}
          selected={props.value?.[1] || null}
          selectsEnd
          disabledKeyboardNavigation
          locale={locale.dateFnsLocale}
          dateFormat="P"
          wrapperClassName={wrapperClass}
        />
      </Box>
      {props.isClearable && (!!props.value[0] || !!props.value[1]) && (
        <Icon icon="CloseLargeIcon" onClick={() => onChange(null)} />
      )}
    </Stack>
  ) : (
    <ReactDatePicker
      id={props.id}
      disabled={props.disabled}
      customInput={
        <DatePickerInput
          label={
            props.label
              ? isArray(props.label)
                ? props.label[0]
                : props.label
              : undefined
          }
          placeholder={
            isArray(props.placeholder)
              ? props.placeholder[0]
              : props.placeholder
          }
          disabled={props.disabled}
        />
      }
      onChange={onChange}
      minDate={props.minDate}
      selected={props.value || null}
      disabledKeyboardNavigation
      isClearable={props.isClearable}
      locale={locale.dateFnsLocale}
      dateFormat="P"
      wrapperClassName={wrapperClass}
    />
  );
};

DatePicker.displayName = "DatePicker";

interface IDatePickerInputProps {
  label?: string;
  placeholder?: string;
  id?: string;
  value?: string;
  icon?: (...args: any[]) => any;
  onClick?(): void;
  disabled?: boolean;
}

const DatePickerInput = forwardRef<HTMLInputElement, IDatePickerInputProps>(
  ({ label, placeholder, id, value, icon, onClick, disabled }, ref) => (
    <InputField
      type="text"
      label={label || ""}
      placeholder={placeholder || ""}
      id={id || placeholder || ""}
      value={value}
      iconPosition="leading"
      onClick={onClick}
      ssrIcon={icon || calendarIcon}
      autoComplete="off"
      ref={ref}
      disabled={disabled}
    />
  )
);
