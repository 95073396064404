import { useOpportunityAppointments } from "common/api";
import { Box, LocalizedDate, Stack, Text } from "components";
import { getAppointmentService } from "features/appointments/AppointmentService";
import { Link } from "react-router-dom";
import { AppointmentActions } from "./AppointmentActions.component";
import { findNextAppointment, isActiveAppointment } from "./utils";

type Props = {
  opportunityId: number;
  disabled;
};

export const AppointmentBanner = ({ opportunityId, disabled }: Props) => {
  const { data: appointments } = useOpportunityAppointments(opportunityId);

  const appointment = findNextAppointment(appointments);

  if (!appointment || !isActiveAppointment(appointment) || disabled)
    return null;

  return (
    <Box color={"navIconYellow"} padding={[15, 24]} grow width="100%">
      <Link to={`/opportunity/${opportunityId}#section=appointment`}>
        <Stack>
          <Text bold>{getAppointmentService(appointment.service)}</Text>
          <Text>
            <LocalizedDate>{appointment.timeWindowStartTime}</LocalizedDate>
            {" | "}
            <LocalizedDate pattern="p">
              {appointment.timeWindowStartTime}
            </LocalizedDate>
            {" - "}
            <LocalizedDate pattern="p">
              {appointment.timeWindowEndTime}
            </LocalizedDate>
          </Text>
        </Stack>
      </Link>
      <AppointmentActions
        appointment={appointment}
        small
        borderColor="neutralBlack"
        disabled={disabled}
      />
    </Box>
  );
};
