import { Text, Box } from "components";
import { T } from "i18n";

export const About = () => {
  return (
    <Box vertical alignItems="start">
      <Text>Sales Assistant Version 1.0</Text>
      <Text>
        <T id="user.about.join-iassist" />{" "}
        <a
          className="link"
          href="https://www.yammer.com/ikea.com/#/threads/inGroup?type=in_group&amp;feedId=33489952768&amp;view=all"
          target="_blank"
          rel="noreferrer"
        >
          Yammer
        </a>
      </Text>
    </Box>
  );
};
