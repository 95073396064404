import { Box, Button, Color, Popover, PopoverItem, Stack } from "components";
import circleEllipsis from "@ingka/ssr-icon/paths/ellipsis";
import { Appointment } from "common/api";
import { isActiveStatus } from "./utils";
import { t } from "i18n";

type Props = {
  appointment: Appointment;
  small?: boolean;
  borderColor?: Color;
  disabled: boolean;
};

export const AppointmentActions = ({
  appointment,
  small,
  borderColor,
  disabled,
}: Props) => {
  const openLink = () => window.open(appointment.appointmentLink, "_blank");
  const disableAction = disabled
    ? true
    : !appointment.appointmentLink || !isActiveStatus(appointment);
  const disableCancel = appointment.paymentStatus === "PAID";

  return (
    <Box>
      <Stack>
        <Button
          small={small}
          primary
          text={
            appointment.appointmentStatus === "STARTED"
              ? t("appointment.end.button")
              : t("appointment.start.button")
          }
          disabled={disableAction}
          onClick={openLink}
        />

        <Popover
          placement="right"
          disabled={disableAction || disableCancel}
          trigger={
            <Button
              small={small}
              iconOnly
              ssrIcon={circleEllipsis}
              disabled={disableAction || disableCancel}
              color={borderColor}
            />
          }
        >
          {appointment.appointmentStatus !== "CANCELLED" && (
            <PopoverItem text={t("modal.cancel")} onClick={openLink} />
          )}
          {appointment.appointmentStatus !== "CANCELLED_NO_SHOW" && (
            <PopoverItem
              text={t("appointment.status.no-show")}
              onClick={openLink}
            />
          )}
        </Popover>
      </Stack>
    </Box>
  );
};
