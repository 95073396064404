export type DashboardConfig = {
  link: string;
  iframe: string;
  adminLink: string;
};

export const defaultConfig: DashboardConfig = {
  adminLink: "",
  link: "https://lookerstudio.google.com/u/0/reporting/58de4bed-e763-4c2d-a3c1-26c405805b1e/page/p_yhp3y3u27c",
  iframe:
    "https://lookerstudio.google.com/embed/reporting/58de4bed-e763-4c2d-a3c1-26c405805b1e/page/p_yhp3y3u27c",
};
