import { User } from "@microsoft/microsoft-graph-types";
import useSWR from "swr";
import * as fetcher from "../fetcher";

export const useUserDetails = (userId: string) => {
  return useSWR<User>(
    userId ? `/users/${userId}` : null,
    (url) => fetcher.graph.get(url),
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );
};
