import { useEffect, useState } from "react";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { Button } from "components";
import Pill from "@ingka/pill";
import closeIcon from "@ingka/ssr-icon/paths/cross";
import {
  OpportunitiesSearchClassificationFilter,
  OpportunitiesSearchFilters,
  OpportunitiesSearchStatusFilter,
  OpportunitiesSearchTypeFilter,
  OpportunitiesSearchDepartmentFilter,
  OpportunitiesSearchBusinessUnitFilter,
  OpportunitiesSearchCustomerTypeFilter,
  useConfig,
  usePricerange,
  useCustomerMeetingPoints,
} from "common/api";
import { ModalSheet, Stack } from "components";
import { Checkbox } from "components/formik/Checkbox";
import { FormikDatePicker } from "components/formik";
import { PriceSlider } from "components/formik/PriceSlider";
import { Form, Formik } from "formik";
import { isEqual, omit } from "lodash";
import { t } from "i18n";
import { getStatusName } from "features/manage-opportunity/components/Status";
import { sortRecordByValue, useLocale } from "common";
import { useSettings } from "settings/SettingsProvider";
import { tracker } from "analytics";

export type OpportunitiesFilterProps = {
  filters: OpportunitiesSearchFilters;
  onFiltersChange: (filters: OpportunitiesSearchFilters) => void;
};

export const OpportunitiesFilter = ({
  filters,
  onFiltersChange,
}: OpportunitiesFilterProps) => {
  const locale = useLocale();
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const { data: config } = useConfig();
  const { data: pricerange } = usePricerange();
  const { boka_config } = useSettings();

  const initialValues: OpportunitiesSearchFilters = {
    type: [],
    status: [],
    department: [],
    businessUnit: [],
    customerType: [],
    services: [],
    value: undefined,
    classification: [],
    orderStatus: [],
    createdAt: [null, null],
  };

  const { data: stores } = useCustomerMeetingPoints();

  useEffect(() => {
    if (selectedFilter) {
      setTimeout(() => {
        const section = document.getElementById(selectedFilter);
        section?.scrollIntoView();
      }, 0);
    }
  }, [selectedFilter]);

  const onSubmit = (values) => {
    setSelectedFilter(null);
    onFiltersChange(values);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...filters,
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm, setValues }) => (
        <>
          <Stack justifyContent="space-between">
            <Stack>
              <Pill
                small
                label={t("opportunities.filters.created.button")}
                selected={!isEqual(filters.createdAt, initialValues.createdAt)}
                onClick={() => setSelectedFilter("createdAt")}
              />
              <Pill
                small
                label={t("opportunities.filters.type.button")}
                selected={!isEqual(filters.type, initialValues.type)}
                onClick={() => setSelectedFilter("type")}
              />
              <Pill
                small
                label={t("opportunities.filters.status.button")}
                selected={!isEqual(filters.status, initialValues.status)}
                onClick={() => setSelectedFilter("status")}
              />
              <Pill
                small
                label={t("opportunities.filters.value.button")}
                selected={filters.value !== undefined}
                onClick={() => setSelectedFilter("value")}
              />
              <Pill
                small
                label={t("opportunities.filters.classification.button")}
                selected={
                  !isEqual(filters.classification, initialValues.classification)
                }
                onClick={() => setSelectedFilter("classification")}
              />
              <Pill
                small
                label={t("opportunities.filters.department.button")}
                selected={
                  !isEqual(filters.department, initialValues.department)
                }
                onClick={() => setSelectedFilter("department")}
              />
              <Pill
                small
                label={t("opportunities.filters.businessunit.button")}
                selected={
                  !isEqual(filters.businessUnit, initialValues.businessUnit)
                }
                onClick={() => setSelectedFilter("businessUnit")}
              />
              <Pill
                small
                label={t("opportunities.filters.customerType.button")}
                selected={
                  !isEqual(filters.customerType, initialValues.customerType)
                }
                onClick={() => setSelectedFilter("customerType")}
              />
              <Pill
                small
                label={t("opportunities.filters.services.button")}
                selected={!isEqual(filters.services, initialValues.services)}
                onClick={() => setSelectedFilter("services")}
              />
              <Pill
                small
                label={t("opportunities.filters.deviation.title")}
                selected={
                  !isEqual(filters.orderStatus, initialValues.orderStatus)
                }
                onClick={() => setSelectedFilter("orderStatus")}
              />
            </Stack>

            {!isEqual(omit(filters, "term"), initialValues) && (
              <Button
                primary
                small
                iconPosition="leading"
                ssrIcon={closeIcon}
                text={t("opportunities.filters.clear.button")}
                onClick={() => {
                  setValues((values) => ({ ...values, ...initialValues }));
                  submitForm();
                }}
              />
            )}

            <ModalSheet
              title={t("opportunities.filters.modal.header")}
              visible={Boolean(selectedFilter)}
              onClose={() => setSelectedFilter(null)}
              primaryButtonProps={{
                text: t("opportunities.filters.show-sales-opportunities"),
                onClick: () => {
                  tracker.event({
                    category: "Filters",
                    action:
                      "Clicked on opportunities 'Show sales opportunities' button",
                  });
                  submitForm();
                },
              }}
              closeText={t("opportunities.filters.clear.button")}
              secondaryButtonProps={{
                onClick: () => {
                  tracker.event({
                    category: "Filters",
                    action: "Clicked on opportunities 'Clear filters' button",
                  });
                  setValues(initialValues);
                  submitForm();
                },
              }}
            >
              <Form>
                <Accordion>
                  <AccordionItem
                    id="createdAt"
                    title={t("opportunities.filters.created.title")}
                    open={selectedFilter === "createdAt"}
                  >
                    <FormikDatePicker
                      clearable
                      maxDate={new Date()}
                      name="createdAt"
                      label={[
                        t("opportunities.filters.date.from"),
                        t("opportunities.filters.date.to"),
                      ]}
                      placeholder={[
                        t("opportunities.filters.date.from"),
                        t("opportunities.filters.date.to"),
                      ]}
                      maxWidth={"150px"}
                    />
                  </AccordionItem>

                  <AccordionItem
                    id="type"
                    title={t("opportunities.filters.type.title")}
                    open={selectedFilter === "type"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="type"
                        value={"Blocked Order" as OpportunitiesSearchTypeFilter}
                        label={t("opportunities.filter.type.blocked-order")}
                      />
                      <Checkbox
                        subtle
                        name="type"
                        value={
                          "Sales Opportunity" as OpportunitiesSearchTypeFilter
                        }
                        label={t(
                          "opportunities.filter.type.new-sales-opportunity"
                        )}
                      />
                      {boka_config?.enable && (
                        <Checkbox
                          subtle
                          name="type"
                          value={"Boka" as OpportunitiesSearchTypeFilter}
                          label={t("opportunity.type.boka")}
                        />
                      )}
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="status"
                    title={t("opportunities.filters.status.title")}
                    open={selectedFilter === "status"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="status"
                        value={"UNASSIGNED" as OpportunitiesSearchStatusFilter}
                        label={getStatusName("Unassigned")}
                      />
                      <Checkbox
                        subtle
                        name="status"
                        value={"ASSIGNED" as OpportunitiesSearchStatusFilter}
                        label={getStatusName("Assigned")}
                      />
                      <Checkbox
                        subtle
                        name="status"
                        value={"IN_PROGRESS" as OpportunitiesSearchStatusFilter}
                        label={getStatusName("InProgress")}
                      />
                      <Checkbox
                        subtle
                        name="status"
                        value={"CLOSED_WON" as OpportunitiesSearchStatusFilter}
                        label={getStatusName("ClosedWon")}
                      />
                      <Checkbox
                        subtle
                        name="status"
                        value={"CLOSED_LOST" as OpportunitiesSearchStatusFilter}
                        label={getStatusName("ClosedLost")}
                      />
                      <Checkbox
                        subtle
                        name="status"
                        value={"INVALID" as OpportunitiesSearchStatusFilter}
                        label={getStatusName("Invalid")}
                      />
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="value"
                    title={t("opportunities.filters.value.title")}
                    open={selectedFilter === "value"}
                  >
                    <PriceSlider
                      currency={config?.currency.code || "eur"}
                      name="value"
                      minAmount={0}
                      maxAmount={Math.round(pricerange?.maxAmount ?? 999999)}
                    />
                  </AccordionItem>
                  <AccordionItem
                    id="classification"
                    title={t("opportunities.filters.classification.title")}
                    open={selectedFilter === "classification"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="classification"
                        value={
                          "URGENT" as OpportunitiesSearchClassificationFilter
                        }
                        label={t("classification.class-type.urgent")}
                      />
                      <Checkbox
                        subtle
                        name="classification"
                        value={
                          "HIGH" as OpportunitiesSearchClassificationFilter
                        }
                        label={t("classification.class-type.high")}
                      />
                      <Checkbox
                        subtle
                        name="classification"
                        value={
                          "MEDIUM" as OpportunitiesSearchClassificationFilter
                        }
                        label={t("classification.class-type.medium")}
                      />
                      <Checkbox
                        subtle
                        name="classification"
                        value={"LOW" as OpportunitiesSearchClassificationFilter}
                        label={t("classification.class-type.low")}
                      />
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="department"
                    title={t("opportunities.filters.department.title")}
                    open={selectedFilter === "department"}
                  >
                    <Stack vertical>
                      {config?.department &&
                        sortRecordByValue(
                          config.department,
                          locale.languageCode
                        ).map(([key, value]) => (
                          <Checkbox
                            key={key}
                            subtle
                            name="department"
                            value={key as OpportunitiesSearchDepartmentFilter}
                            label={value}
                          />
                        ))}
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="businessUnit"
                    title={t("opportunities.filters.businessunit.title")}
                    open={selectedFilter === "businessUnit"}
                  >
                    <Stack vertical>
                      {stores &&
                        sortRecordByValue(stores, locale.languageCode).map(
                          ([key, value]) => (
                            <Checkbox
                              key={key}
                              subtle
                              name="businessUnit"
                              value={
                                key as OpportunitiesSearchBusinessUnitFilter
                              }
                              label={value}
                            />
                          )
                        )}
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="customerType"
                    title={t("opportunities.filters.customerType.title")}
                    open={selectedFilter === "customerType"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="customerType"
                        value={
                          "Individual" as OpportunitiesSearchCustomerTypeFilter
                        }
                        label={t("opportunities.filters.individual.label")}
                      />
                      <Checkbox
                        subtle
                        name="customerType"
                        value={
                          "Business" as OpportunitiesSearchCustomerTypeFilter
                        }
                        label={t("opportunities.filters.business.label")}
                      />
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="services"
                    title={t("opportunities.filters.services.title")}
                    open={selectedFilter === "services"}
                  >
                    {config?.services && (
                      <Stack vertical>
                        {sortRecordByValue(
                          config?.services,
                          locale.languageCode
                        ).map(([key, value]) => (
                          <Checkbox
                            key={key}
                            subtle
                            name="services"
                            value={key}
                            label={value}
                          />
                        ))}
                      </Stack>
                    )}
                  </AccordionItem>
                  <AccordionItem
                    id="orderStatus"
                    title={t("opportunities.filters.deviation.title")}
                    open={selectedFilter === "orderStatus"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="orderStatus"
                        value={"unblocked"}
                        label={t("order.status.unblocked")}
                      />
                    </Stack>
                  </AccordionItem>
                </Accordion>
              </Form>
            </ModalSheet>
          </Stack>
        </>
      )}
    </Formik>
  );
};
