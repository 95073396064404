import { useState } from "react";
import { Grid, Line, ModalSheet, Stack, Text, Toast } from "components";
import Checkbox from "@ingka/checkbox";
import { TaskItem } from "./TaskItem.component";
import { TaskForm } from "./TaskForm.component";
import { Button } from "components";
import down from "@ingka/ssr-icon/paths/chevron-down-small";
import up from "@ingka/ssr-icon/paths/chevron-up-small";
import {
  createTask,
  useTasks,
  Task,
  deleteTask,
  saveTask,
  useProfile,
} from "common/api";
import { t, T } from "i18n";
import { orderBy } from "lodash";
import isToday from "date-fns/isToday";

interface ITasksProps {
  isOpen?: boolean;
  onClose(): void;
}

const onlyToday = (only) => (task: Task) =>
  only
    ? task.taskStartDateTime && isToday(new Date(task.taskStartDateTime))
    : true;

export const Tasks: React.FunctionComponent<ITasksProps> = ({
  isOpen,
  onClose,
}) => {
  const { data: profile } = useProfile();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [task, setTask] = useState<Partial<Task>>();
  const [sortDescending, setSortDescending] = useState<boolean>(true);
  const [showToday, setShowToday] = useState<boolean>(false);
  const { data, isValidating: isLoading, error } = useTasks(profile?.mail);
  const tasks = data || [];

  const filteredTasks = tasks.filter(onlyToday(showToday));
  const sortedTasks = orderBy(
    filteredTasks,
    "taskDate",
    sortDescending ? "desc" : "asc"
  );

  const primaryButtonProps = showCreate
    ? {
        text: t("my-opportunities.view-my-tasks.save-task.button"),
        emphasised: true,
        loading: isLoading,
        onClick: () => {
          if (task) {
            if (!task?.emailId && profile?.mail) {
              task.emailId = profile?.mail;
            }
            createTask(task, profile?.mail).finally(() => {
              setShowCreate(false);
              setTask(undefined);
            });
          }
        },
      }
    : {
        text: t("my-opportunities.view-my-tasks.create-new-task.button"),
        emphasised: true,
        loading: isLoading,
        disabled: showCreate,
        onClick: () => setShowCreate(true),
      };
  const secondaryButtonProps = showCreate
    ? {
        onClick: () => {
          setShowCreate(false);
          setTask(undefined);
        },
      }
    : undefined;
  const closeText = showCreate
    ? t("my-opportunities.view-my-tasks.cancel.button")
    : t("my-opportunities.view-my-tasks.modal.close");

  return (
    <ModalSheet
      title={t("my-opportunities.view-my-tasks.modal.header")}
      visible={isOpen}
      onClose={onClose}
      closeText={closeText}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <Stack vertical spacing={20}>
        {showCreate ? (
          <>
            <Line />
            <Stack vertical spacing={24}>
              <Text header="h3">
                <T id="my-opportunities.view-my-tasks.create-new-task.title" />
              </Text>
              <TaskForm
                task={task}
                setTask={(editedTask) => setTask(editedTask)}
              />
            </Stack>
          </>
        ) : (
          <>
            <Grid columns={2}>
              <Checkbox
                label={`${t(
                  "my-opportunities.view-my-tasks.todays-tasks.label"
                )} (${tasks.filter(onlyToday(true)).length})`}
                id="showToday"
                onChange={(event) => {
                  setShowToday(event.target.checked);
                }}
              />
              <Button
                iconPosition="trailing"
                text={t("my-opportunities.view-my-tasks.due-date.button")}
                ssrIcon={sortDescending ? down : up}
                onClick={() => setSortDescending(!sortDescending)}
                small
              />
            </Grid>
            <Line />
            <Stack vertical spacing={10}>
              {sortedTasks.map((item, index) => (
                <TaskItem
                  key={item.id}
                  task={item}
                  odd={index % 2 === 1}
                  onDelete={(id) => deleteTask(id, profile?.mail)}
                  onSave={(newTask) => saveTask(newTask, profile?.mail)}
                  onEdit={(taskToEdit) => {
                    setTask(taskToEdit);
                    setShowCreate(true);
                  }}
                />
              ))}
            </Stack>
          </>
        )}
        {error && <Toast text={error} />}
      </Stack>
    </ModalSheet>
  );
};

Tasks.displayName = "Tasks";
