import { useUserDetails } from "common/api";
import { useEffect, useState } from "react";

export const useCoworkerName = (mail: string): string => {
  const [coworkerName, setCoworkerName] = useState("");
  const { data } = useUserDetails(mail);

  useEffect(() => {
    if (data) {
      setCoworkerName(data.displayName || "");
    } else {
      setCoworkerName("");
    }
  }, [data]);

  return coworkerName;
};
