import Select, { Option } from "@ingka/select";
import { OpportunityPlanner } from "common/api";
import { orderBy } from "lodash";
import { format } from "date-fns";
import { useLocale } from "common/hooks";

interface Props {
  label: string;
  onChange(plannerId: string): void;
  selectedPlannerId?: string;
  planners?: OpportunityPlanner[];
}

export const PlannerSelect = (props: Props) => {
  const locale = useLocale();
  const { label, onChange, selectedPlannerId, planners } = props;

  return (
    <Select
      id="PlannerSelect"
      label={label}
      onChange={(event) => onChange(event.target.value)}
      value={selectedPlannerId}
    >
      {orderBy(planners, "createdTime", "asc").map((thePlanner) => (
        <Option
          name={`${format(new Date(thePlanner.updatedTime), "Pp", {
            locale: locale.dateFnsLocale,
          })} - ${thePlanner.plannerId} ${thePlanner.plannerType}`}
          key={thePlanner.plannerId}
          value={thePlanner.plannerId}
        />
      ))}
    </Select>
  );
};

PlannerSelect.displayName = "PlannerSelect";
