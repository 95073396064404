import { MarketContext } from "../market";
import { useContext } from "react";

/**
 * Get the validation rules for the user's current market
 */
export const useValidationRules = () => {
  const marketContext = useContext(MarketContext);

  return marketContext.activeMarketValidationRules;
};
