import { useContext, useEffect } from "react";
import { ModalFooter as SkapaModalFooter } from "@ingka/modal";
import { ModalContext } from "./context";
import { IButtonProps } from "components";
import Button from "@ingka/button";
import { useHistory } from "react-router-dom";
import { LocationDescriptorObject } from "history";

interface Props {
  primaryButtonProps?: {
    text?: IButtonProps["text"];
    disabled?: IButtonProps["disabled"];
    link?: string | LocationDescriptorObject;
    href?: IButtonProps["href"];
    loading?: IButtonProps["loading"];
    onClick?: IButtonProps["onClick"];
  };
  secondaryButtonProps?: {
    text?: IButtonProps["text"];
    link?: string | LocationDescriptorObject;
    onClick?: IButtonProps["onClick"];
  };
}

export const ModalFooter = ({
  primaryButtonProps,
  secondaryButtonProps,
}: Props) => {
  const { setFooter } = useContext(ModalContext);
  const history = useHistory();
  useEffect(() => {
    setFooter(
      <SkapaModalFooter compact>
        {primaryButtonProps && (
          <Button
            type="primary"
            text={primaryButtonProps.text}
            disabled={primaryButtonProps.disabled}
            loading={primaryButtonProps.loading}
            href={primaryButtonProps.href}
            onClick={
              primaryButtonProps.link
                ? () => history.push(primaryButtonProps.link!)
                : primaryButtonProps.onClick
            }
          />
        )}
        <Button
          type="secondary"
          text={secondaryButtonProps?.text}
          onClick={
            secondaryButtonProps?.link
              ? () => history.push(secondaryButtonProps.link!)
              : secondaryButtonProps?.onClick
          }
        />
      </SkapaModalFooter>
    );

    return () => {
      setFooter(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryButtonProps, secondaryButtonProps]);

  return null;
};
