import { useState } from "react";
import { Line, ModalSheet, Stack, Text, Toast } from "components";
import { NoteItem } from "./NoteItem.component";
import { NoteForm } from "./NoteForm.component";
import { createNote, deleteNote, Note, useNotes } from "common/api";
import { T, t } from "i18n";

interface INotesProps {
  isOpen: boolean;
  onClose(): void;
}

export const Notes: React.FunctionComponent<INotesProps> = ({
  isOpen,
  onClose,
}) => {
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [note, setNote] = useState<Partial<Note>>();
  const { data, isValidating: isLoading, error } = useNotes();
  const notes = data || [];

  const primaryButtonProps = showCreate
    ? {
        text: t("my-opportunities.view-my-notes.save-note.button"),
        emphasised: true,
        loading: isLoading,
        onClick: () => {
          if (note) {
            createNote(note).finally(() => {
              setShowCreate(false);
              setNote(undefined);
            });
          }
        },
      }
    : {
        text: t("my-opportunities.view-my-notes.create-new-note.button"),
        emphasised: true,
        loading: isLoading,
        disabled: showCreate,
        onClick: () => setShowCreate(true),
      };
  const secondaryButtonProps = showCreate
    ? {
        onClick: () => {
          setShowCreate(false);
          setNote(undefined);
        },
      }
    : undefined;
  const closeText = showCreate
    ? t("my-opportunities.view-my-notes.cancel.button")
    : t("my-opportunities.view-my-notes.modal.close");

  return (
    <ModalSheet
      title={t("my-opportunities.view-my-notes.modal.header")}
      visible={isOpen}
      onClose={onClose}
      closeText={closeText}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <Stack vertical spacing={20}>
        {showCreate ? (
          <>
            <Line />
            <Stack vertical spacing={2}>
              <Text header="h3">
                <T id="my-opportunities.view-my-notes.create-new-note.title" />
              </Text>
              <NoteForm
                note={note}
                setNote={(editedNote) => setNote(editedNote)}
              />
            </Stack>
          </>
        ) : (
          <Stack vertical spacing={10}>
            {notes.map((item, index) => (
              <NoteItem
                key={item.id}
                note={item}
                odd={index % 2 === 1}
                onDelete={deleteNote}
                onEdit={(noteToEdit) => {
                  setNote(noteToEdit);
                  setShowCreate(true);
                }}
              />
            ))}
          </Stack>
        )}

        {error && <Toast text={error.message} time={error.timestamp} />}
      </Stack>
    </ModalSheet>
  );
};

Notes.displayName = "Notes";
