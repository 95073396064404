import { css } from "@emotion/css";
import { ReactNode } from "react";
import { TableContext } from "./TableContext";

export type TableProps = {
  children?: ReactNode;
  sortKey?: string;
  sortOrder?: "asc" | "desc";
  onSortKeyChange?: (sortKey: string) => void;
  onSortOrderChange?: (sortOrder: "asc" | "desc") => void;
};

const tableStyle = css`
  width: 100%;
  border-collapse: collapse;
`;

export const Table = ({
  children,
  sortKey,
  sortOrder,
  onSortKeyChange,
  onSortOrderChange,
}: TableProps) => {
  return (
    <TableContext.Provider
      value={{
        sortKey,
        sortOrder,
        onSortKeyChange,
        onSortOrderChange,
      }}
    >
      <table className={tableStyle}>{children}</table>
    </TableContext.Provider>
  );
};
