import useSWR, { mutate } from "swr";
import { ApiError } from "../ApiError";
import * as fetcher from "../fetcher";

export interface Note {
  title: string;
  description: string;
  id: number;
}

export function useNotes() {
  return useSWR<Note[], ApiError>(`/notes`, (_url) => fetcher.note.get(""), {
    revalidateOnFocus: false,
  });
}

export function useNote(id: string) {
  return useSWR<Note>(`/notes/${id}`, (_url) => fetcher.note.get(`/${id}`), {
    revalidateOnFocus: false,
  });
}

export const deleteNote = async (id: number) => {
  mutate("/notes", (notes) => notes.filter((note) => note.id !== id), false);
  const res = await fetcher.note.remove(`/${id}`);
  mutate("/notes");
  return res;
};

export const saveNote = async (note: Partial<Note>) => {
  const res = await fetcher.note.update(``, {
    description: note.description,
    title: note.title,
    id: note.id,
  } as Partial<Note>);
  mutate(`/notes/${note.id}`);
  mutate(`/notes`);
  return res;
};

export const createNote = async (note: Partial<Note>) => {
  const res = await fetcher.note.update(``, note);
  mutate(`/notes`);
  return res;
};
