import { useContext, useEffect, useState } from "react";
import { Line, Stack } from "components/layout";
import { Text } from "components/typography";
import { ModalFooter, ModalHeader } from "components/ui/modal";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { CustomerInfo } from "../common/CustomerInfo";
import {
  createOpportunity,
  customerReadiness,
  customerInterestIndex,
  preferredContactMethod,
  CreateOpportunityPayload,
  CustomerGlobal,
  useProfile,
  useConfig,
  usePreferences,
} from "common/api";
import { Values } from "common/helpers";
import { OpportunityForm } from "./OpportunityForm";
import { User } from "@microsoft/microsoft-graph-types";
import { OpportunityContext } from "../OpportunityContext";
import { Toast } from "components";
import { t } from "i18n";
import { tracker } from "analytics";
import { useLocale } from "common";
import { useLogin } from "authorization";

type OpportunityFormValues = {
  title: string;
  preferredContactMethod: Values<typeof preferredContactMethod>;
  customerReadiness: Values<typeof customerReadiness>;
  customerInterestIndex: Values<typeof customerInterestIndex>;
  departments: string[];
  sendConfirmation: boolean;
};

export const NewOpportunity = () => {
  const history = useHistory();
  const { data: profile } = useProfile();
  const { data: config } = useConfig();

  const { data: preference } = usePreferences(profile?.mailNickname);
  const locale = useLocale();
  const {
    customer,
    consents,
    isExistingCustomer,
    setOpportunity,
    setConsents,
  } = useContext(OpportunityContext);

  const { retailUnit } = useLogin();
  const { followUpConsent } = consents;
  const [selectedCoworker, setSelectedCoworker] = useState<User | null>(null);
  const [toastMessage, setToastMessage] = useState<{
    message: string;
    time: Date;
  } | null>(null);

  useEffect(() => {
    if (config?.customer.requireConsent === false) {
      setConsents({
        ...consents,
        followUpConsent: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, setConsents]);

  const getCustomerInfo = (theCustomer: CustomerGlobal) => ({
    firstName: theCustomer.firstName,
    lastName: theCustomer.lastName,
    email: theCustomer.email,
    phone: theCustomer.phoneNumber,
    partyId: theCustomer.partyId,
    customerType: theCustomer.customerType,
    familyCardNumber: theCustomer.familyNumber || "",
    bcmId: theCustomer.bcmId,
    organizationName: theCustomer.organizationName,
  });

  const getConfirmationMessage = (
    values: OpportunityFormValues,
    theCustomer: CustomerGlobal
  ) => {
    if (!values.sendConfirmation) return undefined;

    return {
      type: values.preferredContactMethod,
      contact:
        values.preferredContactMethod === preferredContactMethod.email
          ? theCustomer.email
          : theCustomer.phoneNumber,
    };
  };

  const createOpportunityPayload = (
    values: OpportunityFormValues
  ): CreateOpportunityPayload => {
    if (!customer) {
      throw new Error("Cannot create a new opportunity without a customer");
    }

    const customerLocale = `${
      customer?.countryCode?.toLowerCase() ?? locale.countryCode.toLowerCase()
    }_${
      customer?.languageCode?.toUpperCase() ??
      locale.languageCode.toLocaleUpperCase()
    }`;

    return {
      opportunityInfo: {
        title: values.title,
        sourceSystem: profile?.department || undefined,
        createdBy: profile?.mail || undefined,
        customerLocale,
        assignedTo: selectedCoworker?.mail || undefined,
        businessUnit: {
          buCode: profile?.department || undefined,
          buType: "STO",
          buName: profile?.officeLocation || undefined,
        },
        customerInterestIndex: values.customerInterestIndex,
        customerReadiness: values.customerReadiness,
        hfb: values.departments,
        customerMeetingPointBuCode:
          preference?.coworkerMeetingPoint || undefined,
      },
      customerInfo: {
        ...getCustomerInfo(customer),
        preferredContactType: values.preferredContactMethod,
        customerConsents: [
          {
            consentType: "FOLLOWUP_CONSENT",
            consentProvided: config?.customer?.requireFollowUpConsent
              ? followUpConsent
              : true,
          },
        ],
      },
      confirmationMessage: getConfirmationMessage(values, customer),
      retailUnit,
    };
  };

  const onSubmit = async (values: OpportunityFormValues, actions) => {
    const opportunity = createOpportunityPayload(values);

    try {
      const response = await createOpportunity(opportunity);

      const createdOpportunity = {
        title: opportunity.opportunityInfo.title,
        ...response,
      };

      setOpportunity(createdOpportunity);
      history.push({ hash: "/summary" });
    } catch (error) {
      setToastMessage({
        message:
          "There was a problem while creating the sales opportunity, Please try again later",
        time: error.timestamp,
      });
    }
    actions.setSubmitting(false);
  };

  const validationSchema = yup
    .object({
      title: yup.string().required(t("new-opportunity.form.required")),
      preferredContactMethod: yup
        .string()
        .oneOf(
          Object.values(preferredContactMethod),
          t("new-opportunity.form.preferred-contact-method.error")
        )
        .required(t("new-opportunity.form.required")),
      customerReadiness: yup
        .string()
        .oneOf(Object.values(customerReadiness))
        .required(),
      customerInterestIndex: yup
        .mixed()
        .oneOf(Object.values(customerInterestIndex))
        .required(),
      sendConfirmation: yup.boolean(),
      departments: yup.array().min(1, t("new-opportunity.form.required")),
    })
    .defined();

  const initialValues: OpportunityFormValues = {
    title: "",
    preferredContactMethod: preferredContactMethod.email,
    customerReadiness: customerReadiness.exploring,
    customerInterestIndex: customerInterestIndex.low,
    departments: [],
    sendConfirmation: false,
  };

  if (!customer) {
    history.push({ hash: "/select-customer" });
    return null;
  }

  return (
    <>
      {toastMessage && (
        <Toast text={toastMessage.message} time={toastMessage.time} />
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <ModalHeader title={t("new-opportunity.modal.header")} />
              <Stack vertical spacing={24}>
                <CustomerInfo customer={customer} />
                <Line dotted />
                <Text bold header="h3">
                  {t("new-opportunity.form.header")}
                </Text>
                <OpportunityForm
                  onSelectedCoworkerChange={(coworker: User) =>
                    setSelectedCoworker(coworker)
                  }
                />
              </Stack>
              <ModalFooter
                primaryButtonProps={{
                  text: t("new-opportunity.modal.submit"),
                  loading: formik.isSubmitting,
                  disabled:
                    formik.isSubmitting || !(formik.isValid && formik.dirty),
                  onClick: () => {
                    tracker.event({
                      category: "New Sales Opportunity Creation",
                      action:
                        "Clicked on 'Submit' button for sales opportunity creation",
                      label: isExistingCustomer
                        ? "Existing customer"
                        : "New customer",
                    });
                    formik.handleSubmit();
                  },
                }}
                secondaryButtonProps={{
                  text: t("new-opportunity.modal.back"),
                  onClick: () => {
                    history.goBack();
                  },
                }}
              ></ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
