import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  AccountInfo,
} from "@azure/msal-browser";
import { getJsonFromGraphApi } from "common/graphApi/graphCommunicator";
import { hasOwnProperty, isDefined } from "common/helpers";
import { loginRequest, msalConfig, tokenRequest } from "./msalConfig";

export const MsalClientApplication = new PublicClientApplication(msalConfig);

export const logIn = () => {
  return MsalClientApplication.loginRedirect(loginRequest).catch((e) => {
    //This handles an issue with log errors
    const cache: any = MsalClientApplication.getTokenCache();
    cache?.storage?.clear();
    window.location.reload();
  });
};

export const logOut = () => MsalClientApplication.logout();

/**
 * This needs to be called when we return after
 * a login redirect flow has been initiated
 */
export const handleLogInRedirect = () =>
  MsalClientApplication.handleRedirectPromise();

export const setActiveAccount = (account: AccountInfo | null) =>
  MsalClientApplication.setActiveAccount(account);

/**
 * Get the active INGKA account from all active accounts using our tenant id.
 * Will return unefined if no account is active
 */
export const getActiveAccount = (): AccountInfo | undefined => {
  const allAccounts = MsalClientApplication.getAllAccounts();
  const activeAccount = allAccounts.find(
    (account) => account.tenantId === process.env.REACT_APP__MSAL_TENANT_ID
  );
  return activeAccount;
};

/**
 * Acquire an MSAL access token.
 * Tries to use the silent method first (which will fetch from cache),
 * If that fails it will use the redirect method.
 * If that also fails we trigger a log in.
 */
export const getAuthToken = async () => {
  const request = {
    ...tokenRequest,
    account: MsalClientApplication.getActiveAccount() || undefined,
  };

  return acquireToken(request);
};

//Provides token required to make Graph api calls. The response object contains accessToken and idToken
export const getMsalToken = async () => {
  const request = {
    ...loginRequest,
    account: getActiveAccount() || undefined,
  };

  return acquireToken(request);
};

const acquireToken = (request) => {
  return MsalClientApplication.acquireTokenSilent(request).catch((error) => {
    // Silent token acquisition failed, acquiring token using redirect instead
    if (error instanceof InteractionRequiredAuthError) {
      // Fallback to interaction when silent call fails
      return MsalClientApplication.acquireTokenRedirect(request).catch((e) => {
        //This handles an issue with log errors
        const cache: any = MsalClientApplication.getTokenCache();
        cache?.storage?.clear();
        window.location.reload();
      });
    } else {
      // We end up here if the active account has an invalid refresh token, we force a new login
      logIn();
    }
  });
};

const getGroupsFromClaims = (idTokenClaims: object) =>
  hasOwnProperty(idTokenClaims, "groups")
    ? (idTokenClaims.groups as string[])
    : undefined;

const getGroupsOfUser = async (userId: string): Promise<any[]> =>
  (await getJsonFromGraphApi(`users/${userId}/memberOf?$top=400`)).value ?? [];

export const getGroupsOfCurrentUser = async (userId) =>
  getGroupsOfUser(userId || "");

export const getActiveAccountGroupIds = async (
  forceFetch: boolean
): Promise<string[]> => {
  const activeAccount = getActiveAccount();

  if (!activeAccount) {
    throw Error("No active account available for tenant");
  }

  if (forceFetch) {
    return (await getGroupsOfCurrentUser(activeAccount.localAccountId))
      .map((group) => group.id)
      .filter(isDefined);
  }

  const groupsFromClaims =
    activeAccount.idTokenClaims &&
    getGroupsFromClaims(activeAccount.idTokenClaims);

  return (
    groupsFromClaims ||
    (await getGroupsOfCurrentUser(activeAccount.localAccountId))
      .map((group) => group.id)
      .filter(isDefined)
  );
};
