import { Grid, Stack, Text } from "components";
import { TimeTable } from "./TimeTable";
import { Stores } from "common/api/store";
import { capitalize } from "lodash";

interface Props {
  storeUnits: Stores["OpeningHours"];
}

export const OpeningHours = ({ storeUnits }: Props) => {
  return (
    <Grid fullWidth columns={2} spacing={10}>
      {Object.keys(storeUnits).map((unit, i) => {
        const unitName = capitalize(unit);

        return storeUnits[unit].OpenClose !== null ? (
          <Stack
            key={`unitName-${i}`}
            vertical
            spacing={2}
            padding={[20, 0, 0, 0]}
            dontGrow
          >
            <Text bold small color="neutralGrey900">
              {unitName}
            </Text>
            <TimeTable openClose={storeUnits[unit]} />
          </Stack>
        ) : null;
      })}
    </Grid>
  );
};
