import {
  InputField as PureInputField,
  IInputFieldProps,
} from "components/forms";
import { useField } from "formik";

export const InputField = (props: IInputFieldProps) => {
  const [field, meta] = useField({ name: props.name, type: props.type });

  return (
    <PureInputField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      errorMessage={meta.touched ? meta.error : undefined}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      value={field.value}
    />
  );
};
