import useSWR, { mutate } from "swr";
import { ApiError } from "../ApiError";
import * as fetcher from "../fetcher";

export interface Link {
  description: string;
  link: string;
  id: number;
}

export function useLinks() {
  return useSWR<Link[], ApiError>(`/links`, (_url) => fetcher.link.get(""), {
    revalidateOnFocus: false,
  });
}

export function useLink(id: string) {
  return useSWR<Link>(`/links/${id}`, (_url) => fetcher.link.get(`/${id}`), {
    revalidateOnFocus: false,
  });
}

export const deleteLink = async (id: number) => {
  mutate("/links", (links) => links.filter((link) => link.id !== id), false);
  const res = await fetcher.link.remove(`/${id}`);
  mutate("/links");
  return res;
};

export const saveLink = async (link: Partial<Link>) => {
  const res = await fetcher.link.update("", {
    description: link.description,
    id: link.id,
    link: link.link,
  } as Partial<Link>);
  mutate(`/links/${link.id}`);
  mutate(`/links`);
  return res;
};

export const createLink = async (link: Partial<Link>) => {
  const res = await fetcher.link.update("", link);
  mutate(`/links`);
  return res;
};
