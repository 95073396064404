import {
  ReactNode,
  useCallback,
  Children,
  isValidElement,
  cloneElement,
  useEffect,
  useState,
} from "react";
import { Box } from "../../layout/Box.component";
import { css } from "@emotion/css";
import { Colors } from "../../Variables";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core";

interface Props {
  trigger: JSX.Element;
  fixed?: boolean;
  grow?: boolean;
  placement?: Placement;
  children?: ReactNode;
  disabled?: boolean;
}

export const Popover = ({
  children,
  trigger,
  fixed,
  grow = false,
  placement = "auto",
  disabled = false,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [iconRef, setIconRef] = useState<HTMLDivElement | null>(null);
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(iconRef, menuRef, {
    placement,
    strategy: fixed ? "fixed" : "absolute",
    modifiers: [
      { name: "offset", options: { offset: [10, 5] } },
      { name: "arrow", options: { element: arrowRef } },
    ],
  });

  /** Only close popover when clicking outside of it  */
  const closePopover = useCallback(
    (e: MouseEvent) => {
      if (!menuRef?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [menuRef]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mouseup", closePopover);
    } else {
      document.removeEventListener("mouseup", closePopover);
    }
    return () => document.removeEventListener("mouseup", closePopover);
  }, [isOpen, menuRef, closePopover]);

  const position =
    attributes &&
    attributes.popper &&
    attributes.popper["data-popper-placement"];

  return (
    <Box position="relative" grow={grow} alignItems="normal">
      <div
        ref={setIconRef}
        onClick={() => {
          if (!disabled) setIsOpen(!isOpen);
        }}
        className={css`
          cursor: pointer;
          display: flex;
          flex-grow: 1;
        `}
      >
        {trigger}
      </div>
      {isOpen && (
        <div
          style={styles.popper}
          className={css`
            background-color: ${Colors.neutralWhite};
            border: 1px solid ${Colors.neutralGrey500};
            flex-direction: column;
            border-radius: 3px;
            padding: 12px 6px;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            transform-style: preserve-3d;
            z-index: 1;
          `}
          ref={setMenuRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes.popper}
        >
          <div
            style={styles.arrow}
            ref={setArrowRef}
            id="arrow"
            className={css`
              position: absolute;
              width: 20px;
              height: 20px;
              z-index: -1;
              top: ${position === "bottom" ? "-4px" : "unset"};
              bottom: ${position === "top" ? "-4px" : "unset"};
              left: ${position === "right" ? "-4px" : "unset"};
              right: ${position === "left" ? "-4px" : "unset"};
              transform-style: preserve-3d;

              ::before {
                position: absolute;
                width: 20px;
                height: 20px;
                z-index: -1;
                content: "";
                transform: rotate(45deg) translateZ(-1em);
                background: ${Colors.neutralGrey500};
              }
            `}
          />
          {Children.map(children, (child) =>
            isValidElement(child)
              ? cloneElement(child, {
                  closePopover: () => setIsOpen(false),
                } as any)
              : child
          )}
        </div>
      )}
    </Box>
  );
};

Popover.displayName = "Popover";
