import { css } from "@emotion/css";
import { t } from "i18n";
import { Box, Currency, LocalizedDate, Text } from "components";
import { InlineLabeledText } from "components/ui/InlineLabeledText.component";
import { Planner } from "./NewPlannerDrawing.component";
import { getPlannerData, getPlannerStatus } from "./utils";

interface AddedPlannerProps {
  openPlannerModal: () => void;
  setSelectedPlanner: React.Dispatch<React.SetStateAction<Planner | undefined>>;
  planner: Planner | undefined;
  currency?: string;
}

export const AddedPlanner = ({
  openPlannerModal,
  setSelectedPlanner,
  planner,
  currency,
}: AddedPlannerProps) => {
  const data = getPlannerData(planner);

  if (!data) {
    return null;
  }

  return (
    <Box
      vertical
      alignItems="start"
      justifyContent="flex-start"
      onClick={() => {
        openPlannerModal();
        setSelectedPlanner(planner);
      }}
    >
      <img
        src={data.thumbnailUrl}
        alt={
          data.thumbnailUrl ? "Planner image" : "Planner image not available"
        }
        className={css`
          object-fit: cover;
          width: 250px;
          height: 160px;
          ${!data.thumbnailUrl && "border: 1px solid grey;"}
          &:hover {
            opacity: 0.3;
            transition: all 0.2s ease-in-out;
          }
        `}
      />
      <Box vertical padding={[10, 0]} alignItems="start">
        <Text header="h2">{data.designName}</Text>
        <Text small color="neutralGrey500">
          {data.designId}
        </Text>
        {data.plannerType && (
          <InlineLabeledText
            small
            notBold
            label={`${t("opportunities.label.type")}:`}
          >
            <Text small color="neutralGrey500">
              {data.plannerType}
            </Text>
          </InlineLabeledText>
        )}
        {data.createdDate && (
          <InlineLabeledText
            small
            notBold
            label={`${t("opportunities.label.created")}:`}
          >
            <Text small color="neutralGrey500">
              <LocalizedDate pattern="Pp">{data.createdDate}</LocalizedDate>
            </Text>
          </InlineLabeledText>
        )}

        {data.lastModifiedDate && (
          <InlineLabeledText
            small
            notBold
            label={`${t(
              "manage-opportunity.opportunity-info.last-modified.label"
            )}:`}
          >
            <Text small color="neutralGrey500">
              <LocalizedDate pattern="Pp">
                {data.lastModifiedDate}
              </LocalizedDate>
            </Text>
          </InlineLabeledText>
        )}

        {getPlannerStatus(data) && (
          <InlineLabeledText
            small
            notBold
            label={`${t("opportunities.label.status")}:`}
          >
            <Text small color="neutralGrey500">
              {getPlannerStatus(data)}
            </Text>
          </InlineLabeledText>
        )}

        {data.totalprice !== undefined && (
          <InlineLabeledText
            small
            notBold
            label={`${t("planner.price.label")}:`}
          >
            <Text small color="neutralGrey500">
              {data.totalprice ? (
                <Currency
                  currency={data.currencySymbol || currency || "SEK"}
                  showSign
                  fractions={2}
                >
                  {parseInt(data.totalprice)}
                </Currency>
              ) : (
                "-"
              )}
            </Text>
          </InlineLabeledText>
        )}
      </Box>
    </Box>
  );
};
