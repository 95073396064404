import { useLogin } from "./LoginProvider";

export type UserRole = "Admin" | "SalesPerson";

export function useUserRole() {
  const { userRoles } = useLogin();

  return {
    isAdmin: userRoles.includes("Admin"),
    isSalesPerson: userRoles.includes("SalesPerson"),
  };
}
