import { ReactNode, Children } from "react";
import { css, cx } from "@emotion/css";

interface Props {
  columns?: number;
  spacing?: number;
  fullWidth?: boolean;
  children?: ReactNode;
}

const containeStyle = css`
  display: flex;
  flex-flow: wrap;

  > div {
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
  }
`;

const lastColumnInRowStyle = css`
  padding-right: 0;
`;
const lastRowItemStyle = css`
  margin-bottom: 0;
`;
const firstColumnInRowStyle = css`
  padding-left: 0;
`;
const firstRowItemStyle = css`
  margin-top: 0;
`;

export const Grid = ({
  children,
  columns = 4,
  spacing = 30,
  fullWidth,
}: Props) => {
  const items = Children.toArray(children).filter(
    (child) =>
      child != null && child !== "" && child !== undefined && child !== " "
  );
  const nrOfItems = items.length;
  const itemStyle = css`
    width: ${(1 / columns) * 100}%;
    margin-bottom: ${spacing / 2}px;
    padding-right: ${spacing / 2}px;
    margin-top: ${spacing / 2}px;
    padding-left: ${spacing / 2}px;
  `;
  return (
    <div
      className={cx(
        containeStyle,
        fullWidth &&
          css`
            width: 100%;
          `
      )}
    >
      {items.map((child, index) => (
        <div
          key={index}
          className={cx(
            itemStyle,
            (index + 1) % columns === 0 && lastColumnInRowStyle,
            (index + 1) % columns === 1 && firstColumnInRowStyle,
            nrOfItems / columns - (index + 1) / columns < 1 && lastRowItemStyle,
            index < columns && firstRowItemStyle,
            columns === 1 &&
              css`
                padding-left: 0;
              `
          )}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

Grid.displayName = "Grid";
