import alertIcon from "@ingka/ssr-icon/paths/warning-triangle";
import InlineMessage from "@ingka/inline-message";
import { useMemo, useState } from "react";
import { Stack } from "components";
import { StoreDetails } from "./components/StoreDetails";
import { SearchFieldDatalist } from "components/ui/SearchFieldDatalist";
import { useProfile } from "common/api";
import { useAllowedStores } from "common/api/coworker/allowedStores";
import { useStoreDetails, useStoreList } from "common/api/store";
import { savePreference, usePreferences } from "common/api/preference";
import { ApiError } from "common/api/ApiError";
import { t } from "i18n";
import { isAllowedToSetStore } from "./isAllowedToSetStore";

export const Stores = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchedStore, setSearchedStore] = useState("");
  const { data: searchedStoreDetails } = useStoreDetails(searchedStore);
  const { data: profile } = useProfile();
  const { data: preferences } = usePreferences(profile?.mailNickname);
  const { data: allowedStoresList } = useAllowedStores(profile?.mailNickname);
  const { data: stores } = useStoreList();

  const storeList = useMemo(
    () =>
      stores &&
      Object.entries(stores).map(([buCode, buName]) => ({
        text: `${buName} (${buCode})`,
        value: buCode,
      })),
    [stores]
  );

  const onSetStore = () => {
    if (!searchedStore) return;
    setIsSaving(true);

    const data = {
      buCode: searchedStore,
    };

    savePreference(data, profile?.mailNickname)
      .catch((e: ApiError) => {})
      .then(() => {
        onClear();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onClear = () => {
    setSearchTerm("");
    setSearchedStore("");
  };

  return (
    <Stack vertical spacing={20}>
      {storeList && (
        <SearchFieldDatalist
          id="search-store"
          placeholder={t("user.stores.search.placeholder")}
          onSelect={(item) => {
            setSearchedStore(item.value);
          }}
          onClear={onClear}
          onChange={setSearchTerm}
          value={searchTerm}
          list={storeList}
        />
      )}
      {searchedStore &&
        searchedStoreDetails &&
        !isAllowedToSetStore(
          searchedStore,
          allowedStoresList?.allowedStores ?? []
        ) && (
          <InlineMessage
            ssrIcon={alertIcon}
            variant="cautionary"
            title={t("user.stores.cannot-set-store")}
            body={t("user.stores.no-access-to-set-store")}
          />
        )}
      {searchedStore && (
        <StoreDetails
          buCode={searchedStore}
          title={t("user.stores.searched-store")}
          dismissible
          onDismiss={onClear}
          isSaving={isSaving}
          searchedStore={searchedStore}
          onSetStore={onSetStore}
          showSetStoreButton={true}
        />
      )}
      {preferences?.buCode && (
        <StoreDetails
          buCode={preferences.buCode}
          title={t("user.stores.currently-set-store")}
        />
      )}
    </Stack>
  );
};
