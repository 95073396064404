import { useState } from "react";
import account from "@ingka/ssr-icon/paths/person";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { About } from "./About";
import { Stores } from "./Stores";
import { Profile } from "./Profile";
import { ModalSheet, Box, Button } from "components";
import { tracker } from "analytics";
import { t } from "i18n";

export const User = () => {
  const [showUser, setShowUser] = useState<boolean>(false);
  return (
    <Box>
      <Button
        ssrIcon={account}
        iconOnly
        small
        onClick={() => {
          tracker.event({
            category: "User Profile",
            action: "Clicked on 'User Profile' button icon",
          });
          setShowUser(!showUser);
        }}
      />
      <ModalSheet
        title={t("user.modal-sheet-title")}
        onClose={() => setShowUser(false)}
        closeText={t("user.profile.close.button")}
        visible={showUser}
      >
        <Tabs
          defaultActiveTab="profileTab"
          tabs={[
            <Tab
              key="tab-1"
              text={t("user.tab-title.profile")}
              tabPanelId="profileTab"
            />,
            <Tab
              key="tab-2"
              text={t("user.tab-title.store")}
              tabPanelId="storeTab"
            />,
            <Tab
              key="tab-3"
              text={t("user.tab-title.about")}
              tabPanelId="aboutTab"
            />,
          ]}
          tabPanels={[
            <TabPanel key="tab-1" tabPanelId="profileTab">
              <Profile />
            </TabPanel>,
            <TabPanel key="tab-2" tabPanelId="storeTab">
              <Stores />
            </TabPanel>,
            <TabPanel key="tab-3" tabPanelId="aboutTab">
              <About />
            </TabPanel>,
          ]}
        />
      </ModalSheet>
    </Box>
  );
};
