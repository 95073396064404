import { User } from "@microsoft/microsoft-graph-types";
import useSWR from "swr";
import * as fetcher from "../fetcher";

export const useProfile = () => {
  return useSWR<User>("/me", (url) => fetcher.graph.get(url), {
    errorRetryCount: 3,
    revalidateOnFocus: false,
    dedupingInterval: 10 * 60 * 1000, // 10 minutes caching
  });
};
