import { SideMenu } from "./side-menu";
import { Header } from "./header";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router";
import { Stack, ToastProvider } from "components";
import { LocaleProvider } from "../i18n";
import { MarketProvider } from "common/market";
import { GoogleAnalytics } from "analytics";
import { SettingsProvider } from "settings/SettingsProvider";

export const App = () => {
  return (
    <ToastProvider>
      <LocaleProvider>
        <SettingsProvider>
          <MarketProvider>
            <BrowserRouter>
              <Stack spacing={0}>
                <GoogleAnalytics />
                <SideMenu />
                <Stack vertical spacing={0} explicitPosition width="80%">
                  <Header />
                  <Router />
                </Stack>
              </Stack>
            </BrowserRouter>
          </MarketProvider>
        </SettingsProvider>
      </LocaleProvider>
    </ToastProvider>
  );
};
