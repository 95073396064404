import Carousel from "@ingka/carousel";
import { OpportunityPlanner, PlannerDetail } from "common/api";
import { PlannerSearchCard } from "./PlannerSearchCard.component";
interface CarouselProps {
  onClick: (planner: any) => void;
  planners: PlannerDetail[];
  addedPlanners: PlannerDetail[];
  addedVPCPlanners: OpportunityPlanner[];
  currencySymbol?: string;
}

export const PlannerCarousel = ({
  onClick,
  planners,
  addedPlanners,
  addedVPCPlanners,
  currencySymbol,
}: CarouselProps) => {
  return (
    <div style={{ padding: "0 20px", width: "100%" }}>
      <Carousel>
        {planners
          ?.sort(
            (a, b) =>
              new Date(b.lastModifiedDate).getTime() -
              new Date(a.lastModifiedDate).getTime()
          )
          .map((planner) => (
            <PlannerSearchCard
              key={planner.designId}
              onClick={onClick}
              planner={planner}
              addedPlanners={addedPlanners}
              addedVPCPlanners={addedVPCPlanners}
              currencySymbol={currencySymbol}
            />
          ))}
      </Carousel>
    </div>
  );
};
