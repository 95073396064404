import { Button } from "components";
import down from "@ingka/ssr-icon/paths/chevron-down-small";
import { Popover, PopoverItem } from "./popover";
import { Placement } from "@popperjs/core";

interface Props {
  value: string;
  options: Record<string, string>;
  onChange(value: string): void;
  placement?: Placement;
}

/**
 * Display a button that looks like a drop down
 * that opens a popover with items to select from.
 */
export const Select = ({ onChange, value, options, placement }: Props) => {
  return (
    <Popover
      grow
      placement={placement}
      trigger={
        <Button
          text={options[value]}
          ssrIcon={down}
          small
          iconPosition="trailing"
        />
      }
    >
      {Object.keys(options).map((key) => (
        <PopoverItem
          key={key}
          text={options[key]}
          onClick={() => onChange(key)}
        />
      ))}
    </Popover>
  );
};
