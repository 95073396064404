import { SearchField } from "./SearchField";

export type Item = { value: string; text: string };

type SearchFieldDatalistProps = {
  id: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onClear?: () => void;
  onSelect?: (item: Item) => void;
  onChange?: (text: string) => void;
  list: Item[];
};

export const SearchFieldDatalist = ({
  id,
  disabled,
  placeholder,
  onClear,
  onSelect,
  onChange,
  value,
  list,
}: SearchFieldDatalistProps) => {
  const onInputChange = (newValue: string): void => {
    onChange?.(newValue);
    const selectedItem = list.find(({ text }) => text === newValue);
    if (selectedItem && onSelect) onSelect(selectedItem);
  };

  return (
    <>
      <SearchField
        id={id}
        value={value}
        onChange={onInputChange}
        onClear={onClear}
        placeholder={placeholder}
        disabled={disabled}
        list={`${id}-dataset`}
      />
      <datalist id={`${id}-dataset`}>
        {list && list.map(({ text }) => <option key={text} value={text} />)}
      </datalist>
    </>
  );
};

SearchFieldDatalist.displayName = "SearchFieldDatalist";
