import { useState } from "react";
import toDate from "date-fns/toDate";
import {
  CurrentOpportunity,
  CurrentOpportunityDispatch,
} from "../currentOpportunity.reducer";
import {
  Box,
  Stack,
  LocalizedDate,
  LabeledText,
  TextAreaFixed,
  Grid,
  Text,
  MultiSelect,
  CountryName,
  Currency,
  OpportunityType,
  InputField,
  DatePicker,
} from "components";
import { ManageAccordion } from "./ManageAccordion.component";
import {
  hfb,
  Opportunity,
  service,
  useConfig,
  useCustomerMeetingPoints,
  useProfile,
} from "common/api";
import {
  CoworkerSearch,
  CoWorkerName,
  CustomerMeetingPointSelector,
} from "common";
import { User } from "@microsoft/microsoft-graph-types";
import { t } from "i18n";
import { ClassificationChange } from "./ClassificationChange.component";
import alertIcon from "@ingka/ssr-icon/paths/warning-triangle";
import InlineMessage from "@ingka/inline-message";
import { getProjectValue } from "common/components/utils";

interface Props {
  opportunityFromApi: Opportunity;
  currentOpportunity: CurrentOpportunity;
  currentOpportunityDispatch: CurrentOpportunityDispatch;
  disableEditing: boolean;
}

const formatSourceText = (system: string, buName?: string, buCode?: string) => {
  if (buCode === "CSC") {
    return buName;
  }
  if (system === "SLA") {
    return (buName ? `${buName}` : "") + (buCode ? ` - ${buCode}` : "");
  }
  return (
    system + (buName ? ` - ${buName}` : "") + (buCode ? ` - ${buCode}` : "")
  );
};

const getUserCmp = (user: User, cmps: Record<string, string>) => {
  const userBuCode = user?.onPremisesExtensionAttributes?.extensionAttribute14 // is rcmp
    ? user?.onPremisesExtensionAttributes?.extensionAttribute13
        ?.replace(".", "")
        .concat(
          user?.onPremisesExtensionAttributes?.extensionAttribute14?.replace(
            "EXO",
            ""
          ) ?? ""
        )
    : user?.onPremisesExtensionAttributes?.extensionAttribute13?.replace(
        "STO.",
        ""
      );
  return userBuCode
    ? Object.keys(cmps).find((el) => el === userBuCode)
    : undefined;
};

export const OpportunityInfo = ({
  opportunityFromApi,
  currentOpportunity,
  currentOpportunityDispatch,
  disableEditing,
}: Props) => {
  const { data: config } = useConfig();
  const { data: profile } = useProfile();
  const { data: stores } = useCustomerMeetingPoints();

  const userId = profile?.mail || "";
  const [expectedPurchaseDate, setExpectedPurchaseDate] = useState<
    string | undefined
  >(
    opportunityFromApi.expectedPurchase
      ? toDate(new Date(opportunityFromApi.expectedPurchase)).toISOString()
      : ""
  );
  const showCMPChangedWarning =
    currentOpportunity.customerMeetingPointBuName !==
    opportunityFromApi.customerMeetingPointBuName;

  const updateTitle = (title: string) =>
    currentOpportunityDispatch({
      type: "UPDATE_TITLE",
      payload: title,
    });

  const updateInterest = (comment: string) =>
    currentOpportunityDispatch({
      type: "UPDATE_INTEREST",
      payload: {
        comment,
        userId,
      },
    });

  const changeCustomerMeetingPoint = (meetingPoint: {
    buCode: string;
    buName: string;
  }) => {
    currentOpportunityDispatch({
      type: "CHANGE_CUSTOMER_MEETING_POINT",
      payload: {
        customerMeetingPointBuCode: meetingPoint.buCode,
        customerMeetingPointBuName: meetingPoint.buName || "",
        sourceBuName:
          opportunityFromApi?.customerMeetingPointBuName ||
          opportunityFromApi?.businessUnit.buName ||
          profile?.department ||
          "",
      },
    });
  };

  return (
    <ManageAccordion title={t("manage-opportunity.opportunity-info.title")}>
      <Box>
        <Stack vertical spacing={30}>
          <Grid>
            <LabeledText
              label={t("manage-opportunity.opportunity-info.id.label")}
              text={opportunityFromApi.id}
            />
            <LabeledText
              label={t("manage-opportunity.opportunity-info.type.label")}
              text={
                <OpportunityType>{opportunityFromApi.type}</OpportunityType>
              }
            />

            <LabeledText
              label={t("manage-opportunity.opportunity-info.source.label")}
              text={formatSourceText(
                opportunityFromApi.source,
                opportunityFromApi.businessUnit.buName,
                opportunityFromApi.businessUnit.buCode
              )}
            />
            <LabeledText
              label={t(
                "manage-opportunity.opportunity-info.created-date.label"
              )}
              text={
                <LocalizedDate>{opportunityFromApi.createdAt}</LocalizedDate>
              }
            />
            {opportunityFromApi.updatedAt && (
              <LabeledText
                label={t(
                  "manage-opportunity.opportunity-info.last-modified.label"
                )}
                text={
                  <LocalizedDate>{opportunityFromApi.updatedAt}</LocalizedDate>
                }
              />
            )}
            {opportunityFromApi.blockedAt && (
              <LabeledText
                label={t(
                  "manage-opportunity.opportunity-info.blocked-date.label"
                )}
                text={
                  <LocalizedDate>{opportunityFromApi.blockedAt}</LocalizedDate>
                }
              />
            )}
            {opportunityFromApi && (
              <LabeledText
                label={t("manage-opportunity.opportunity-info.value.label")}
              >
                <Text>
                  <Currency
                    currency={opportunityFromApi.currency}
                    showSign
                    fractions={2}
                  >
                    {getProjectValue(opportunityFromApi)}
                  </Currency>
                </Text>
              </LabeledText>
            )}
            {opportunityFromApi.createdBy &&
              opportunityFromApi.createdBy !== "System" && (
                <LabeledText
                  label={t(
                    "manage-opportunity.opportunity-info.created-by.label"
                  )}
                >
                  <Text>
                    <CoWorkerName>{opportunityFromApi.createdBy}</CoWorkerName>
                  </Text>
                </LabeledText>
              )}

            <LabeledText
              label={t("manage-opportunity.opportunity-info.country.label")}
              text={<CountryName>{opportunityFromApi.country}</CountryName>}
            />

            <ClassificationChange
              key={`rereder-on-change-${currentOpportunity.classification}`}
              opportunity={currentOpportunity}
              reasons={config?.classificationChangeReason}
              currentOpportunity={currentOpportunity}
              currentOpportunityDispatch={currentOpportunityDispatch}
              disabled={disableEditing}
            />
          </Grid>
          <Grid columns={2} spacing={30}>
            <InputField
              type="text"
              name="title"
              maxLength={80}
              label={t("manage-opportunity.opportunity-info.title.label")}
              defaultValue={opportunityFromApi.title}
              onChange={(e) => {
                updateTitle(e.target.value);
              }}
              disabled={disableEditing}
            />
            <Stack vertical>
              {opportunityFromApi.status && (
                <CoworkerSearch
                  label={t(
                    "manage-opportunity.opportunity-info.opportunity-owner.label"
                  )}
                  selectedMail={currentOpportunity.owner}
                  onSelect={(coworker: User) => {
                    if (coworker.mail !== currentOpportunity.owner) {
                      currentOpportunityDispatch({
                        type: "ASSIGN",
                        payload: {
                          user: coworker,
                          buCode: getUserCmp(coworker, stores ?? {}),
                        },
                      });
                    }
                  }}
                  disabled={showCMPChangedWarning ? true : disableEditing}
                />
              )}
            </Stack>
            {config?.department && (
              <MultiSelect
                id="hfb"
                label={t(
                  "manage-opportunity.opportunity-info.department.label"
                )}
                possibleValues={config.department}
                values={currentOpportunity.hfb || []}
                placeholder={t(
                  "manage-opportunity.opportunity-info.department.placeholder"
                )}
                onChange={(values) => {
                  currentOpportunityDispatch({
                    type: "CHANGE_HFB",
                    payload: values as hfb[],
                  });
                }}
                sortByName={true}
                disabled={disableEditing}
              />
            )}
            <TextAreaFixed
              id="interest"
              label={t(
                "manage-opportunity.opportunity-info.interest-areas.label"
              )}
              rows={4}
              maxLength={400}
              placeholder={t(
                "manage-opportunity.opportunity-info.interest-areas.placeholder"
              )}
              value={currentOpportunity.interestArea}
              onChange={(e) => {
                updateInterest(e.target.value);
              }}
              disabled={disableEditing}
            />
            <Stack vertical>
              {showCMPChangedWarning && (
                <InlineMessage
                  ssrIcon={alertIcon}
                  variant="cautionary"
                  title={t("manage-opportunity.cmp-change.message.title")}
                  body={t("manage-opportunity.cmp-change.message")}
                />
              )}
              <CustomerMeetingPointSelector
                selectedBuCode={currentOpportunity.customerMeetingPointBuCode}
                onChange={changeCustomerMeetingPoint}
                disabled={disableEditing}
              />
            </Stack>
            {config?.services && (
              <MultiSelect
                id="servicesInterested"
                label={t("manage-opportunity.opportunity-info.services.label")}
                possibleValues={config.services}
                values={currentOpportunity.servicesInterested || []}
                placeholder={t(
                  "manage-opportunity.opportunity-info.services.placeholder"
                )}
                onChange={(values) => {
                  currentOpportunityDispatch({
                    type: "CHANGE_SERVICES",
                    payload: values as service[],
                  });
                }}
                sortByName={true}
                disabled={disableEditing}
              />
            )}
            <InputField
              type="number"
              name="customerBudget"
              label={t("manage-opportunity.opportunity-info.budget.label")}
              placeholder={t(
                "manage-opportunity.opportunity-info.budget.placeholder"
              )}
              optional={currentOpportunity.country === "PT" ? false : true}
              value={currentOpportunity.customerBudget ?? ""}
              onChange={(e) =>
                currentOpportunityDispatch({
                  type: "CHANGE_BUDGET",
                  payload: e.currentTarget.value,
                })
              }
              prefix={currentOpportunity.currencySymbol}
              disabled={disableEditing}
            />
            <DatePicker
              id="dateSelect"
              placeholder={t(
                "manage-opportunity.opportunity-info.expected-purchase.placeholder"
              )}
              label={t(
                "manage-opportunity.opportunity-info.expected-purchase.label"
              )}
              isClearable
              minDate={new Date()}
              value={
                expectedPurchaseDate
                  ? new Date(expectedPurchaseDate)
                  : undefined
              }
              onChange={(date) => {
                const isoDateTime =
                  date &&
                  new Date(
                    date.getTime() - date.getTimezoneOffset() * 60000
                  ).toISOString();

                isoDateTime
                  ? setExpectedPurchaseDate(isoDateTime)
                  : setExpectedPurchaseDate("");
                currentOpportunityDispatch({
                  type: "CHANGE_EXPECTED_PURCHASE_DATE",
                  payload: isoDateTime ? (isoDateTime as string) : "",
                });
              }}
              disabled={disableEditing}
            />
          </Grid>
        </Stack>
      </Box>
    </ManageAccordion>
  );
};
