import { useEffect, useRef } from "react";
import { Box, Stack, InputField } from "components";
import { Link } from "common/api";
import { t } from "i18n";

interface ILinkFormProps {
  link?: Partial<Link>;
  setLink(link: Partial<Link>): void;
}

export const LinkForm: React.FunctionComponent<ILinkFormProps> = ({
  setLink,
  link = {
    description: "",
    link: "",
  },
}) => {
  const referenceDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    referenceDiv.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }, []);

  return (
    <Box width="100%">
      <Stack vertical spacing={32}>
        <div ref={referenceDiv} />
        <InputField
          type="text"
          name={`description-${link.id || ""}`}
          value={link.description}
          label={t("my-opportunities.view-my-links.link-description.label")}
          placeholder={t(
            "my-opportunities.view-my-links.link-description.placeholder"
          )}
          maxLength={500}
          optional
          onChange={(event) =>
            setLink({ ...link, description: event.target.value })
          }
        />
        <InputField
          type="text"
          name={`link-${link.id || ""}`}
          value={link.link}
          label={t("my-opportunities.view-my-links.link-url.label")}
          placeholder={t("my-opportunities.view-my-links.link-url.placeholder")}
          maxLength={500}
          onChange={(event) => setLink({ ...link, link: event.target.value })}
        />
      </Stack>
    </Box>
  );
};

LinkForm.displayName = "LinkForm";
