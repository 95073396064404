import { Stack, LabeledText, Grid, CountryName } from "components";
import { Contact } from "common/api";

import { t } from "i18n";

interface Props {
  customer: Contact;
}

export const CustomerDetails = ({ customer }: Props) => {
  const isBusinesss =
    customer.customerContactDetails?.customerType === "Business";
  return (
    <Stack vertical spacing={24}>
      <Stack vertical spacing={30}>
        <Grid columns={2} fullWidth>
          {isBusinesss && (
            <LabeledText
              label={t("manage-opportunity.customer-info.organizationName")}
              text={customer.customerContactDetails?.businessName}
            />
          )}
          {!isBusinesss && (
            <LabeledText
              label={t("manage-opportunity.customer-info.first-name")}
              text={customer?.customerContactDetails?.firstName}
            />
          )}
          {!isBusinesss && (
            <LabeledText
              label={t("manage-opportunity.customer-info.last-name")}
              text={customer?.customerContactDetails?.lastName}
            />
          )}
          <LabeledText
            label={t("manage-opportunity.customer-info.type")}
            text={
              isBusinesss
                ? t("manage-opportunity.customer-info.type.business")
                : t("manage-opportunity.customer-info.type.private")
            }
          />
          <LabeledText
            label={t("manage-opportunity.customer-info.address")}
            text={customer.address?.addressLine1}
          />
          {customer.address?.addressLine2 && (
            <LabeledText
              label={`${t("manage-opportunity.customer-info.address")} 2`}
              text={customer.address?.addressLine2}
            />
          )}
          {customer.address?.addressLine2 && (
            <LabeledText
              label={`${t("manage-opportunity.customer-info.address")} 3`}
              text={customer.address?.addressLine2}
            />
          )}
          <LabeledText
            label={t("manage-opportunity.customer-info.postal-code")}
            text={customer.address?.zipCode}
          />
          <LabeledText
            label={t("manage-opportunity.customer-info.city")}
            text={customer.address?.cityName}
          />
          <LabeledText
            label={t("manage-opportunity.customer-info.country")}
            text={<CountryName>{customer.address?.countryCode}</CountryName>}
          />
          <LabeledText
            label={t("manage-opportunity.customer-info.contact-method")}
            text={
              customer?.customerContactDetails?.preferredContactType === "EMAIL"
                ? t("manage-opportunity.customer-info.email")
                : t("manage-opportunity.customer-info.phone")
            }
          />
          <LabeledText
            label={t("manage-opportunity.customer-info.email")}
            text={customer?.customerContactDetails?.contactTypeValues?.EMAIL}
          />
          <LabeledText
            label={t("manage-opportunity.customer-info.phone")}
            text={customer?.customerContactDetails?.contactTypeValues?.MOBILE}
          />
          {customer?.customerContactDetails?.familyNumber && (
            <LabeledText
              label={t("manage-opportunity.customer-info.family-number")}
              text={customer.customerContactDetails.familyNumber}
            />
          )}
        </Grid>
      </Stack>
    </Stack>
  );
};
