import { endOfDay } from "date-fns";
import qs from "querystring";
import useSWR from "swr";

import * as fetcher from "../fetcher";

import { ApiError } from "../ApiError";
import { Opportunity } from "./opportunity";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

export type OpportunitiesSearchTypeFilter =
  | "Blocked Order"
  | "Sales Opportunity"
  | "Boka";

export type OpportunitiesSearchStatusFilter =
  | "UNASSIGNED"
  | "ASSIGNED"
  | "IN_PROGRESS"
  | "CLOSED_WON"
  | "CLOSED_LOST"
  | "INVALID";

export type OpportunitiesSearchDepartmentFilter =
  | "hfb01"
  | "hfb02"
  | "hfb03"
  | "hfb04"
  | "hfb05"
  | "hfb06"
  | "hfb07"
  | "hfb08"
  | "hfb09"
  | "hfb12"
  | "hfb17";

export type OpportunitiesSearchClassificationFilter =
  | "URGENT"
  | "HIGH"
  | "MEDIUM"
  | "LOW";
export type OpportunitiesSearchCustomerTypeFilter = "Individual" | "Business";

export type OpportunitiesSearchBusinessUnitFilter = string;

export type CoworkersFilters = {
  myOpportunity: string;
  allOpportunities: string;
};

export type OpportunitiesSearchFilters = {
  term?: string;
  type?: OpportunitiesSearchTypeFilter[];
  status?: OpportunitiesSearchStatusFilter[];
  department?: OpportunitiesSearchDepartmentFilter[];
  businessUnit?: OpportunitiesSearchBusinessUnitFilter[];
  classification?: OpportunitiesSearchClassificationFilter[];
  customerType?: OpportunitiesSearchCustomerTypeFilter[];
  services?: number[];
  orderStatus?: string[];
  createdAt?: [Date | null, Date | null];
  value?: [number, number];
  assigned?: boolean;
};

export type OpportunitiesSearchOptions = {
  page: number;
  pageSize: number;
  sortKey: string;
  sortOrder: "asc" | "desc";
};

export type OpportunitiesSearchArgs = {
  filters: OpportunitiesSearchFilters;
  options?: OpportunitiesSearchOptions;
};

export type OpportunitiesSearch = {
  content: Opportunity[];
  pageable: {
    sort: { sorted: boolean; unsorted: boolean; empty: boolean };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: { sorted: boolean; unsorted: boolean; empty: boolean };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export const useOpportunitiesSearch = (
  { filters, options }: OpportunitiesSearchArgs,
  wait?: boolean
) => {
  const cache = useRef<OpportunitiesSearch>();
  const query = stringifySearchQuery(filters, options);
  const { data, ...result } = useSWR<OpportunitiesSearch, ApiError>(
    query && !wait ? `/opportunities/search?${query}` : null,
    (url) => fetcher.core.get(url)
  );

  if (data?.content) {
    cache.current = data;
  }

  return { ...result, data: cache.current };
};

export type OpportunitiesSearchSettings = { isMe?: boolean };
export const useOpportunitiesSearchSettings = ({
  isMe,
}: OpportunitiesSearchSettings) => {
  const { data, error } = useSWR<CoworkersFilters>(
    ["/coworkers/filters", useLocation()],
    (url) => fetcher.core.get(url)
  );

  return {
    data: data
      ? parseSearchQuery(isMe ? data.myOpportunity : data.allOpportunities)
      : undefined,
    error,
  };
};

export const formatLocalTimeToUTC = (localTime?: Date | null) => {
  if (!localTime || !(localTime instanceof Date)) return;
  return localTime.toISOString().substring(0, 19);
};

export const parseUTCToLocalTime = (UTCTime: string) => new Date(UTCTime + "Z");

export function stringifySearchQuery(
  filters?: OpportunitiesSearchFilters,
  options?: OpportunitiesSearchOptions
): string {
  if (!filters || !options) return "";

  const query: Record<string, any> = {
    pageIndex: `${options.page}`,
    pageSize: `${options.pageSize > 50 ? 10 : options.pageSize}`,
    sortKey: `${options?.sortKey}`,
    sortOrder: `${options?.sortOrder}`,
  };

  if (filters.term) {
    query.searchTerm = filters.term;
  }

  if (filters.type?.length) {
    query.opportunityTypeFilter = filters.type.join(",");
  }

  if (filters.status?.length) {
    query.statusFilter = filters.status.join(",");
  }

  if (filters.classification?.length) {
    query.classificationFilter = filters.classification.join(",");
  }

  if (filters.department?.length) {
    query.hfbFilter = filters.department.map(encodeURI).join(",");
  }

  if (filters.businessUnit?.length) {
    query.buFilter = filters.businessUnit.join(",");
  }

  if (filters.customerType?.length) {
    query.customerTypeFilter = filters.customerType.join(",");
  }

  if (filters.services?.length) {
    query.serviceFilter = filters.services.join(",");
  }

  if (filters.orderStatus?.length) {
    query.orderStatus = filters.orderStatus.join(",");
  }

  if (filters.createdAt?.[0] || filters.createdAt?.[1]) {
    const fromDate = filters.createdAt?.[0] ?? new Date("2000/01/01");
    const toDate = endOfDay(filters.createdAt?.[1] ?? new Date());
    query.dateCreatedFilter =
      formatLocalTimeToUTC(fromDate) + "-" + formatLocalTimeToUTC(toDate);
  }

  if (filters.value) {
    query.valueFilter = filters.value?.join("-");
  }

  if (filters.assigned) {
    query.assigned = true;
  }

  return qs.stringify(query);
}

const dateRangeRegex =
  /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})-(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})?/;

export const parseISODateRange = (str: string) => {
  try {
    const match = str.match(dateRangeRegex);

    if (match) {
      const fromDate = match[1];
      const toDate = match[2] || null;

      return [new Date(fromDate), toDate !== null ? new Date(toDate) : null];
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.log(ex);
  }

  return [null, null];
};

export function parseSearchQuery(queryString: string) {
  const query = qs.parse(queryString) as Record<string, any>;
  const [page, pageSize] = query.range?.split("-").map(Number) ?? [0, 10];
  const [sortKey, sortOrder] = query.sort?.split(",") ?? [
    "opportunityId",
    "desc",
  ];

  return {
    filters: {
      term: query.searchTerm ?? "",
      type: query.opportunityTypeFilter
        ? query.opportunityTypeFilter.split(",")
        : [],
      orderStatus: query.orderStatus ? query.orderStatus.split(",") : [],
      status: query.statusFilter ? query.statusFilter.split(",") : [],
      department: query.hfbFilter ? query.hfbFilter.split(",") : [],
      businessUnit: query.buFilter ? query.buFilter.split(",") : [],
      services: query.serviceFilter ? query.serviceFilter.split(",") : [],
      customerType: query.customerTypeFilter
        ? query.customerTypeFilter.split(",")
        : [],
      value: query.valueFilter
        ? query.valueFilter.split("-").map(Number)
        : undefined,
      classification: query.classificationFilter
        ? query.classificationFilter.split(",")
        : [],
      createdAt: query.dateCreatedFilter
        ? parseISODateRange(query.dateCreatedFilter)
        : [null, null],
    } as OpportunitiesSearchFilters,
    options: {
      page,
      pageSize,
      sortKey,
      sortOrder,
    } as OpportunitiesSearchOptions,
  };
}
