import { StatisticsDashboards } from "features/statistics-dashboards";
import { ContactUs } from "features/contact-us";
import { GuideLines } from "features/guidelines/index";
import { ManageOpportunity } from "features/manage-opportunity";
import { Home } from "features/home";
import { Route, Switch } from "react-router-dom";
import { css } from "@emotion/css";
import { Colors } from "components";
import { OpportunitiesPage } from "features/opportunities";
import { CalendarView } from "../../common/components/calendar-view/CalendarView";
import { AlertBanner } from "application/AlertBanner";
import { Redirect } from "features/redirect/Redirect";
import { useLocale } from "common";
import { useSettings } from "settings/SettingsProvider";

export const Router = () => {
  const locale = useLocale();
  const { feature_flags } = useSettings();

  let redirectUrl;
  if (feature_flags?.redirect_to_salja) {
    redirectUrl = `${
      process.env.REACT_APP__WEB_COWORKER__
    }/${locale.countryCode.toLowerCase()}/${locale.languageCode.toLowerCase()}/saleslead`;
  }

  return (
    <main
      className={css`
        border-left: 1px solid ${Colors.neutralGrey200};
      `}
    >
      <AlertBanner />
      {redirectUrl ? (
        <Redirect />
      ) : (
        <Switch>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/guidelines">
            <GuideLines />
          </Route>
          <Route path="/statistics-dashboard">
            <StatisticsDashboards />
          </Route>
          <Route path="/opportunity/:opportunityId">
            <ManageOpportunity />
          </Route>

          <Route path="/opportunities">
            <OpportunitiesPage />
          </Route>
          <Route path="/calendar-view">
            <CalendarView />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      )}
    </main>
  );
};
