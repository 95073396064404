import { allIcons, IconNames } from "./icons";
import { css, cx } from "@emotion/css";
import { Color, Colors } from "../Variables";
import { isArray } from "lodash";

interface Props {
  icon: IconNames;
  size?: number;
  color?: Color;
  onClick?(): void;
  padding?: number | number[];
}
export const Icon = ({
  icon,
  size = 24,
  color = "neutralGrey900",
  onClick,
  padding = 0,
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        css`
          display: flex;
          width: ${size}px;
          height: ${size}px;
          flex-shrink: 0;
          cursor: ${onClick ? "pointer" : "inherit"};
          svg {
            width: 100%;
            height: 100%;
            color: ${Colors[color]};
          }
          padding: ${isArray(padding)
            ? padding.map((pad) => `${pad}px`).join(" ")
            : `${padding}px`};
        `
      )}
    >
      {allIcons[icon]({ rotated: false })}
    </div>
  );
};

Icon.displayName = "Icon";
