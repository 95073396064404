import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "components";
import { t, T } from "i18n";
import {
  OpportunitiesSearchFilters,
  OpportunitiesSearchOptions,
  Opportunity,
} from "common/api";
import {
  Currency,
  LocalizedDate,
  Stack,
  Text,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  OpportunityType,
  OpportunityStatus,
  Checkbox,
} from "components";
import { tracker } from "analytics";
import { Classification, CoWorkerName } from "common";
import { OpportunitiesQuickView } from "./OpportunitiesQuickView";
import { OpportunitiesAssign } from "./OpportunitiesAssign";
import { find, xorBy } from "lodash";
import { parseTitle } from "./OpportunitiesTab";
import { getProjectValue } from "common/components/utils";

export type OpportunitiesTableProps = {
  isMe: boolean;
  numOfItems: number;
  opportunities: Opportunity[];
  filters: OpportunitiesSearchFilters;
  options: OpportunitiesSearchOptions;
  onSortKeyChange: (sortKey: string) => void;
  onSortOrderChange: (sortOrder: "asc" | "desc") => void;
};

export type OpportunitiesTableValues = {
  assign: string[];
};

export const OpportunitiesTable = ({
  isMe,
  numOfItems,
  opportunities,
  filters,
  options,
  onSortKeyChange,
  onSortOrderChange,
}: OpportunitiesTableProps) => {
  const [selectedOpportunities, setSelectedOpportunities] = useState<
    Opportunity[]
  >([]);
  const [selectedOpportunity, setSelectedOpportunity] =
    useState<Opportunity | null>(null);
  const [showAssign, setShowAssign] = useState<boolean>(false);

  return (
    <>
      <Stack vertical spacing={24}>
        <Text header="h3">
          <T
            id="opportunities.table.items.state"
            values={{
              items: opportunities.length,
              total: numOfItems,
            }}
          />
        </Text>

        <Table
          sortKey={options.sortKey}
          sortOrder={options.sortOrder}
          onSortKeyChange={onSortKeyChange}
          onSortOrderChange={onSortOrderChange}
        >
          <thead>
            <TableRow>
              <TableHeader>
                <T id="opportunities.table.header.customer" />
              </TableHeader>
              <TableHeader sortKey="createdDate">
                <T id="opportunities.table.header.created" />
              </TableHeader>
              <TableHeader sortKey="opportunityType">
                <T id="opportunities.table.header.type" />
              </TableHeader>
              <TableHeader sortKey="status">
                <T id="opportunities.table.header.status" />
              </TableHeader>
              <TableHeader sortKey="value">
                <T id="opportunities.table.header.value" />
              </TableHeader>
              <TableHeader sortKey="classification">
                <T id="opportunities.table.header.classification" />
              </TableHeader>
              <TableHeader />
              {!isMe && (
                <TableHeader>
                  <T id="opportunities.table.header.assign" />
                </TableHeader>
              )}
            </TableRow>
          </thead>
          <tbody>
            {opportunities?.map((opportunity) => (
              <TableRow
                key={opportunity.opportunityId}
                onCellClick={() => setSelectedOpportunity(opportunity)}
                disableClick={opportunity.lockStatus}
              >
                <TableCell>
                  <Stack vertical spacing={7}>
                    <Text small>{opportunity.customerName}</Text>
                    <Text xsmall color="neutralGrey700">
                      {parseTitle(opportunity.title)}
                    </Text>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Stack vertical spacing={7}>
                    <Text small>
                      <LocalizedDate>{opportunity.createdAt}</LocalizedDate>
                    </Text>

                    {opportunity.updatedAt && (
                      <Text xsmall color="neutralGrey700" inline>
                        <T id="opportunities.table.header.last-updated" />:{" "}
                        <LocalizedDate pattern="P">
                          {opportunity.updatedAt}
                        </LocalizedDate>
                        {" | "}
                        <LocalizedDate pattern="p">
                          {opportunity.updatedAt}
                        </LocalizedDate>
                      </Text>
                    )}
                  </Stack>
                </TableCell>
                <TableCell>
                  {opportunity.opportunityType && (
                    <Text small>
                      <OpportunityType>
                        {opportunity.opportunityType}
                      </OpportunityType>
                    </Text>
                  )}
                </TableCell>
                <TableCell>
                  <Stack vertical spacing={7}>
                    <Text small>
                      <OpportunityStatus>
                        {opportunity.status}
                      </OpportunityStatus>
                    </Text>
                    {opportunity.assignedTo && (
                      <Text xsmall color="neutralGrey700">
                        <CoWorkerName>{opportunity.assignedTo}</CoWorkerName>
                      </Text>
                    )}
                  </Stack>
                </TableCell>
                <TableCell>
                  <Text small>
                    <Currency
                      currency={opportunity.currency}
                      showSign
                      fractions={2}
                    >
                      {getProjectValue(opportunity)}
                    </Currency>
                  </Text>
                </TableCell>
                <TableCell>
                  <Classification small>
                    {opportunity.classification}
                  </Classification>
                </TableCell>
                <TableCell preventCellClick>
                  <Link to={`/opportunity/${opportunity.opportunityId}`}>
                    {opportunity.lockStatus ? (
                      <Button
                        text={t("opportunities.table.view.button")}
                        small
                      />
                    ) : (
                      <Button
                        text={t("opportunities.table.manage.button")}
                        small
                        primary
                        onClick={() => {
                          tracker.event({
                            category: "Open Manage Opportunity",
                            action: isMe
                              ? "Clicked on 'Manage' button in My Opportunities Table"
                              : "Clicked on 'Manage' button in All Opportunities Table",
                          });
                        }}
                      />
                    )}
                  </Link>
                </TableCell>
                {!isMe && (
                  <TableCell preventCellClick>
                    <Checkbox
                      disabled={opportunity.lockStatus}
                      checked={!!find(selectedOpportunities, opportunity)}
                      onChange={() => {
                        setSelectedOpportunities(
                          xorBy(
                            selectedOpportunities,
                            [opportunity],
                            (o) => o.opportunityId
                          )
                        );
                      }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </tbody>
        </Table>

        {!isMe && (
          <Stack alignItems="center" justifyContent="flex-end">
            {selectedOpportunities.length > 0 && (
              <Button
                small
                text={`${t("opportunities.table.assign-coworker.clear")} (${
                  selectedOpportunities.length
                })`}
                onClick={() => setSelectedOpportunities([])}
              />
            )}
            <Button
              small
              emphasised
              text={t("opportunities.table.assign-coworker.button")}
              disabled={selectedOpportunities.length === 0}
              onClick={() => setShowAssign(true)}
            />
          </Stack>
        )}
      </Stack>

      {selectedOpportunity && (
        <OpportunitiesQuickView
          opportunity={selectedOpportunity}
          onClose={() => setSelectedOpportunity(null)}
        />
      )}

      <OpportunitiesAssign
        opportunities={selectedOpportunities}
        setOpportunities={(theOpportunities) =>
          setSelectedOpportunities(theOpportunities)
        }
        onClose={() => setShowAssign(false)}
        visible={showAssign}
        filters={filters}
        options={options}
      />
    </>
  );
};
