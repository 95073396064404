import { useEffect, useState } from "react";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { Box, Button, Select } from "components";
import Pill from "@ingka/pill";
import closeIcon from "@ingka/ssr-icon/paths/cross";
import {
  AppointmentConfig,
  AppointmentConfigObject,
  AppointmentLocationType,
  AppointmentsSearchFilters,
  AppointmentStatus,
  useAppointmentConfig,
} from "common/api";
import { ModalSheet, Stack } from "components";
import { Checkbox } from "components/formik/Checkbox";
import { Form, Formik } from "formik";
import { isEqual, omit, keys, memoize } from "lodash";
import { t } from "i18n";
import { useLocale } from "common";
import { FormikDatePicker } from "components/formik";
import { getAppointmentStatus } from "./AppointmentStatus";
import { getAppointmentService } from "./AppointmentService";
import {
  startOfToday,
  endOfToday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfDay,
} from "date-fns";
import { getAppointmentServiceArea } from "./AppointmentServiceArea";
import { tracker } from "analytics";

type FilterDate = "None" | "Today" | "Week" | "Month" | "Upcoming";

const prefillDate = (option: FilterDate) => {
  const now = new Date();
  const dateRange = {
    None: [null, null],
    Today: [startOfToday(), endOfToday()],
    Week: [startOfWeek(now), endOfWeek(now)],
    Month: [startOfMonth(now), endOfMonth(now)],
    Upcoming: [now, null],
  };
  return dateRange[option];
};

const getConfig = memoize(
  (config: AppointmentConfigObject | undefined): AppointmentConfig | null => {
    if (!config) return null;

    const locations: string[] = [];
    const serviceAreas = [] as any;
    const services = [] as any;

    Object.entries(config).forEach(([k, v]) => {
      locations.push(k);
      serviceAreas.push(keys(v));
      services.push(Object.values(v));
    });

    return {
      locations,
      serviceAreas: Array.from(new Set(serviceAreas.flat())),
      services: Array.from(new Set(services.flat(2))),
    };
  }
);

export type AppointmentsFilterProps = {
  isMe: boolean;
  filters: AppointmentsSearchFilters;
  onFiltersChange: (filters: AppointmentsSearchFilters) => void;
};

export const AppointmentsFilter = ({
  filters,
  onFiltersChange,
}: AppointmentsFilterProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const locale = useLocale();
  const { data: config } = useAppointmentConfig();
  const [dateFilter, setDateFilter] = useState<FilterDate>("Upcoming");

  const reducedConfig = getConfig(config?.enabled);

  const initialValues: AppointmentsSearchFilters = {
    businessUnit: [],
    locationType: [],
    serviceArea: [],
    service: [],
    appointmentStatus: [],
    appointmentDate: [null, null],
    assignment: [],
  };

  useEffect(() => {
    if (selectedFilter) {
      setTimeout(() => {
        const section = document.getElementById(selectedFilter);
        section?.scrollIntoView();
      }, 0);
    }
  }, [selectedFilter]);

  const onSubmit = (values) => {
    setSelectedFilter(null);
    onFiltersChange(values);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...filters,
      }}
      onSubmit={onSubmit}
    >
      {({ submitForm, setValues }) => (
        <>
          <Stack justifyContent="space-between">
            <Stack>
              <Pill
                small
                label={t("appointment.date.label")}
                selected={
                  !isEqual(
                    filters.appointmentDate,
                    initialValues.appointmentDate
                  )
                }
                onClick={() => setSelectedFilter("appointmentDate")}
              />

              <Pill
                small
                label={t("opportunities.filters.businessunit.button")}
                selected={
                  !isEqual(filters.businessUnit, initialValues.businessUnit)
                }
                onClick={() => setSelectedFilter("businessUnit")}
              />
              <Pill
                small
                label={t("appointment.locationtype.label")}
                selected={
                  !isEqual(filters.locationType, initialValues.locationType)
                }
                onClick={() => setSelectedFilter("locationType")}
              />
              <Pill
                small
                label={t("appointment.servicearea.label")}
                selected={
                  !isEqual(filters.serviceArea, initialValues.serviceArea)
                }
                onClick={() => setSelectedFilter("serviceArea")}
              />
              <Pill
                small
                label={t("appointment.service.label")}
                selected={!isEqual(filters.service, initialValues.service)}
                onClick={() => setSelectedFilter("service")}
              />
              <Pill
                small
                label={t("opportunities.filters.status.button")}
                selected={
                  !isEqual(
                    filters.appointmentStatus,
                    initialValues.appointmentStatus
                  )
                }
                onClick={() => setSelectedFilter("appointmentStatus")}
              />
              <Pill
                small
                label={t("appointment.assignment.label")}
                selected={
                  !isEqual(filters.assignment, initialValues.assignment)
                }
                onClick={() => setSelectedFilter("assignment")}
              />
            </Stack>

            {!isEqual(omit(filters, "term"), initialValues) && (
              <Button
                primary
                small
                iconPosition="leading"
                ssrIcon={closeIcon}
                text={t("opportunities.filters.clear.button")}
                onClick={() => {
                  setDateFilter("None");
                  setValues((values) => ({
                    ...values,
                    ...initialValues,
                  }));
                  submitForm();
                }}
              />
            )}

            <ModalSheet
              title={t("opportunities.filters.modal.header")}
              visible={Boolean(selectedFilter)}
              onClose={() => setSelectedFilter(null)}
              primaryButtonProps={{
                text: t("appointment.show-appointments.filter"),
                onClick: () => {
                  tracker.event({
                    category: "Appointments",
                    action:
                      "Clicked on appointments 'Show appointments' button",
                  });
                  submitForm();
                },
              }}
              closeText={t("opportunities.filters.clear.button")}
              secondaryButtonProps={{
                onClick: () => {
                  tracker.event({
                    category: "Appointments",
                    action: "Clicked on appointments 'Clear filters' button",
                  });
                  setValues(initialValues);
                  submitForm();
                },
              }}
            >
              <Form>
                <Accordion>
                  <AccordionItem
                    id="appointmentDate"
                    title={t("appointment.date.label")}
                    open={selectedFilter === "appointmentDate"}
                  >
                    <Stack vertical>
                      <FormikDatePicker
                        clearable
                        name="appointmentDate"
                        label={[
                          t("opportunities.filters.date.from"),
                          t("opportunities.filters.date.to"),
                        ]}
                        placeholder={[
                          t("opportunities.filters.date.from"),
                          t("opportunities.filters.date.to"),
                        ]}
                        maxWidth={"150px"}
                        onChange={(start, end) => {
                          setDateFilter("None");
                          setValues((values) => ({
                            ...values,
                            ...initialValues,
                            appointmentDate: [
                              start ? startOfDay(start) : null,
                              end,
                            ],
                          }));
                        }}
                      />

                      <Box width="170px">
                        <Select
                          placement="bottom"
                          value={dateFilter}
                          options={{
                            None: t("appointment.select-date.label"),
                            Upcoming: t("appointment.upcoming.label"),
                            Today: t("reminders.filter.date.today"),
                            Week: t("reminders.filter.date.this-week"),
                            Month: t("reminders.filter.date.this-month"),
                          }}
                          onChange={(value: FilterDate) => {
                            setDateFilter(value);
                            setValues((values) => ({
                              ...values,
                              ...initialValues,
                              appointmentDate: prefillDate(value),
                            }));
                          }}
                        />
                      </Box>
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="businessUnit"
                    title={t("opportunities.filters.businessunit.title")}
                    open={selectedFilter === "businessUnit"}
                  >
                    <Stack vertical>
                      {reducedConfig?.locations &&
                        reducedConfig.locations
                          .sort((a, b) =>
                            a.localeCompare(b, locale.languageCode)
                          )
                          .map((location, index) => (
                            <Checkbox
                              key={index}
                              subtle
                              name="businessUnit"
                              value={location}
                              label={location}
                            />
                          ))}
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="locationType"
                    title={t("appointment.locationtype.label")}
                    open={selectedFilter === "locationType"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="locationType"
                        value={"STORE" as AppointmentLocationType}
                        label={t("appointment.location-type.store")}
                      />
                      <Checkbox
                        subtle
                        name="locationType"
                        value={"REMOTE" as AppointmentLocationType}
                        label={t("appointment.location-type.remote")}
                      />
                      <Checkbox
                        subtle
                        name="locationType"
                        value={"STORE_REMOTE" as AppointmentLocationType}
                        label={t("appointment.location-type.store-remote")}
                      />
                      <Checkbox
                        subtle
                        name="locationType"
                        value={"HOME" as AppointmentLocationType}
                        label={t("appointment.location-type.home")}
                      />
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="serviceArea"
                    title={t("appointment.servicearea.label")}
                    open={selectedFilter === "serviceArea"}
                  >
                    <Stack vertical>
                      {reducedConfig?.serviceAreas &&
                        reducedConfig.serviceAreas
                          .map((serviceArea) => ({
                            serviceArea,
                            label: getAppointmentServiceArea(serviceArea),
                          }))
                          .sort((a, b) =>
                            a.label.localeCompare(b.label, locale.languageCode)
                          )
                          .map((serviceArea, index) => (
                            <Checkbox
                              key={`config-serviceArea-${index}`}
                              subtle
                              name="serviceArea"
                              value={serviceArea.serviceArea}
                              label={serviceArea.label}
                            />
                          ))}
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="service"
                    title={t("appointment.service.label")}
                    open={selectedFilter === "service"}
                  >
                    <Stack vertical>
                      {reducedConfig?.services &&
                        reducedConfig.services
                          .map((service) => ({
                            service,
                            label: getAppointmentService(service),
                          }))
                          .sort((a, b) =>
                            a.label.localeCompare(b.label, locale.languageCode)
                          )
                          .map((service, index) => (
                            <Checkbox
                              key={`config-serv-${index}`}
                              subtle
                              name="service"
                              value={service.service}
                              label={service.label}
                            />
                          ))}
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="appointmentStatus"
                    title={t("opportunities.filters.status.title")}
                    open={selectedFilter === "appointmentStatus"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="appointmentStatus"
                        value={"ORDER_RECEIVED" as AppointmentStatus}
                        label={getAppointmentStatus("ORDER_RECEIVED")}
                      />
                      <Checkbox
                        subtle
                        name="appointmentStatus"
                        value={
                          "ORDER_RECEIVED_WAITING_FOR_PAYMENT" as AppointmentStatus
                        }
                        label={getAppointmentStatus(
                          "ORDER_RECEIVED_WAITING_FOR_PAYMENT"
                        )}
                      />
                      <Checkbox
                        subtle
                        name="appointmentStatus"
                        value={"PLANNED" as AppointmentStatus}
                        label={getAppointmentStatus("PLANNED")}
                      />
                      <Checkbox
                        subtle
                        name="appointmentStatus"
                        value={"CANCELLED" as AppointmentStatus}
                        label={getAppointmentStatus("CANCELLED")}
                      />
                      <Checkbox
                        subtle
                        name="appointmentStatus"
                        value={"CANCELLED_NO_SHOW" as AppointmentStatus}
                        label={getAppointmentStatus("CANCELLED_NO_SHOW")}
                      />
                      <Checkbox
                        subtle
                        name="appointmentStatus"
                        value={"STARTED" as AppointmentStatus}
                        label={getAppointmentStatus("STARTED")}
                      />
                      <Checkbox
                        subtle
                        name="appointmentStatus"
                        value={"COMPLETED" as AppointmentStatus}
                        label={getAppointmentStatus("COMPLETED")}
                      />
                    </Stack>
                  </AccordionItem>

                  <AccordionItem
                    id="assignment"
                    title={t("appointment.assignment.label")}
                    open={selectedFilter === "assignment"}
                  >
                    <Stack vertical>
                      <Checkbox
                        subtle
                        name="assignment"
                        value={"true"}
                        label={t("manage-opportunity.status.assigned")}
                      />
                      <Checkbox
                        subtle
                        name="assignment"
                        value={"false"}
                        label={t("manage-opportunity.status.unassigned")}
                      />
                    </Stack>
                  </AccordionItem>
                </Accordion>
              </Form>
            </ModalSheet>
          </Stack>
        </>
      )}
    </Formik>
  );
};
