import { createContext } from "react";

interface ITableContext {
  sortKey?: string;
  sortOrder?: "asc" | "desc";
  onSortKeyChange?: (sortKey: string) => void;
  onSortOrderChange?: (sortOrder: "asc" | "desc") => void;
}

export const TableContext = createContext<ITableContext>(null!);
