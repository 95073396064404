// Add polyfill for spread operator in Edge 17
import "react-app-polyfill/stable";

import "@ingka/accordion/dist/style.css";
import "@ingka/banner/dist/style.css";
import "@ingka/base/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/chunky-header/dist/style.css";
import "@ingka/core/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/list/dist/style.css";
import "@ingka/loading/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/search/dist/style.css";
import "@ingka/slider/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/tabs/dist/style.css";
import "regenerator-runtime/runtime";
import "./index.css";

import { Logout } from "features/logout";

import ReactDOM from "react-dom";

import { App } from "./application";

import { LoginProvider } from "./authorization";
import { ErrorBoundary } from "./ErrorBoundary";

/**
 * Page to show when the user successfully logged out.
 */
if (window.location.href.includes("logout")) {
  ReactDOM.render(<Logout />, document.getElementById("root"));
} else if (
  /**
   * An empty page which loads in a hidden iframe in order for
   * MSAL to renew tokens.
   */
  window.location.href.includes("login/oauth2/code/azure") ||
  window.location.href.includes("SAML/consume")
) {
  ReactDOM.render(<></>, document.getElementById("root"));
} else {
  /**
   * Bootstrap the app
   */
  ReactDOM.render(
    <LoginProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </LoginProvider>,
    document.getElementById("root")
  );
}
