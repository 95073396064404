import { VPCPlanner } from "common/api/opportunity";
import { useState } from "react";
import {
  Box,
  Currency,
  Icon,
  Line,
  ProductIdentifier,
  Stack,
  Text,
} from "components";
import { css } from "@emotion/css";
import { T } from "i18n";

interface IProductListItemProps {
  item: VPCPlanner["items"][0];
  subItem?: boolean;
}

export const ProductListItem = ({ item, subItem }: IProductListItemProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Stack fullWidth>
        <Box width="10%" alignItems="start">
          <Text small nowrap bold>
            {item.quantity} x
          </Text>
        </Box>
        <Box width="70%" alignItems="start">
          {subItem && item.imageVariants && (
            <img
              src={item.imageVariants[0].href}
              alt={item.itemName}
              width="50px"
              height="50px"
              className={css`
                margin-right: 12px;
              `}
            />
          )}
          <Box grow onClick={() => setIsOpen(!isOpen)}>
            <Stack vertical spacing={0}>
              <Stack justifyContent="space-between">
                <Text small bold>
                  {item.itemName}
                </Text>
                {!subItem && (item.imageVariants || item.childItems) && (
                  <Icon icon={isOpen ? "ChevronUpIcon" : "ChevronDownIcon"} />
                )}
              </Stack>
              <Stack alignItems="center" spacing={4}>
                {item.itemType !== "GRP" && (
                  <ProductIdentifier>{item.itemNo}</ProductIdentifier>
                )}
                {item.itemType === "GRP" && (
                  <Stack spacing={6} alignItems="center">
                    <Icon icon="BoxIcon" size={16} color="neutralGrey500" />
                    <Text small>
                      {item.childItems.length}{" "}
                      <T id="manage-opportunity.planner-drawing.items" />
                    </Text>
                  </Stack>
                )}
              </Stack>
              {item.imageVariants && isOpen && (
                <img
                  src={item.imageVariants[0].href}
                  alt={item.itemName}
                  width="200px"
                  height="200px"
                  className={css`
                    max-width: 100%;
                  `}
                />
              )}
            </Stack>
          </Box>
        </Box>
        <Box width="20%" alignItems="start" justifyContent="flex-end">
          {item.salesPrice && (
            <Text>
              <Currency
                showSign
                currency={item.salesPrice.currency}
                fractions={2}
              >
                {item.salesPrice.value}
              </Currency>
            </Text>
          )}
          {!item.salesPrice &&
            item.childItems &&
            item.childItems[0]?.salesPrice && (
              <Text>
                <Currency
                  showSign
                  currency={item.childItems[0].salesPrice.currency}
                  fractions={2}
                >
                  {item.childItems.reduce(
                    (prev, theSubItem) =>
                      prev + (theSubItem.salesPrice?.value || 0),
                    0
                  )}
                </Currency>
              </Text>
            )}
        </Box>
      </Stack>

      {item.childItems && isOpen && (
        <Stack vertical spacing={24}>
          {item.childItems.map((theSubItem) => (
            <ProductListItem item={theSubItem} subItem />
          ))}
          <Line dotted color="neutralGrey500" />
        </Stack>
      )}
    </>
  );
};

ProductListItem.displayName = "ProductListItem";
