import { FC } from "react";
import { NavLink } from "react-router-dom";
import { css } from "@emotion/css";
import { Colors } from "components/Variables";
import { Trans } from "@lingui/react";

interface Props {
  page: {
    path: string;
    name: string;
    icon: FC;
  };
}

const NavigationItem = ({ page }: Props) => (
  <li
    className={css`
      height: 4rem;
    `}
  >
    <NavLink
      exact
      to={page.path}
      className={css`
        display: flex;
        align-items: center;
        margin: auto;
        height: 100%;
        color: black;
        text-decoration: none;
        :hover {
          text-decoration: none;
          background-color: ${Colors.bjornGrey};
        }
      `}
      activeClassName={css`
        & div {
          background-color: ${Colors.navIconYellow};
        }
      `}
    >
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 3rem;
          height: 3rem;
          border-radius: 50%;
          margin-right: 1rem;
        `}
      >
        <page.icon />
      </div>
      <Trans id={page.name} />
    </NavLink>
  </li>
);

export { NavigationItem };
