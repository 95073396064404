/**
 * A custom Error class that adds a timestamp.
 * Useful for showing Toast notifications where a timestamp
 * is needed in order to reopen the toast upon further errors
 */
export class ApiError extends Error {
  timestamp: Date;
  errorCode: number;
  constructor(errorCode: number, message?: string) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.message = message || "";
    this.timestamp = new Date();
    this.errorCode = errorCode;
  }
}
