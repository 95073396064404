import { openUserGuide } from "common/api";
import { Box, Icon, Stack, Text } from "components";
import { T } from "i18n";
import { tracker, usePageTracking } from "analytics";
import { useUserLocation } from "common/api/user/userLocation";

export const GuideLines = () => {
  usePageTracking("Guidelines");
  const userLocation = useUserLocation();
  const userMarket =
    userLocation || window.sessionStorage.getItem("userCountry");

  return (
    <Box padding={24} grow alignItems="start">
      <Stack vertical spacing={24}>
        <Text header="h1">
          <T id="guidelines.title" />
        </Text>
        <Stack vertical>
          <Box
            onClick={() => {
              tracker.event({
                category: "Guidelines",
                action: "Clicked on Guidelines link",
              });
              openUserGuide();
            }}
          >
            <Stack>
              <Icon icon="Document" />
              <Text clickable>
                <T id="guidelines.open-user-guide.text" />
              </Text>
            </Stack>
          </Box>
        </Stack>
        {userMarket === "SE" && (
          <Stack>
            <Icon icon="StackIcon" />
            <Text clickable>
              <a
                href="https://iweof.sharepoint.com/teams/o365g_sellingse_retse012/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=6jAyxs&ovuser=720b637a%2D655a%2D40cf%2D816a%2Df22f40755c2c%2Cpourja%2Eautieri%40ingka%2Eikea%2Ecom&OR=Teams%2DHL&CT=1667485991158&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjEwMjgwNzIwMCIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&cid=dbd5394a%2Ddea1%2D4b54%2D9481%2Db32ae7633e7d&RootFolder=%2Fteams%2Fo365g%5Fsellingse%5Fretse012%2FShared%20Documents%2FSales%20Assistant&FolderCTID=0x012000A3FA72DD6D5ABE41B6C4E6A8A078CD6F"
                target="_blank"
                rel="noreferrer"
              >
                <T id="guidelines.open-sharepoint-link.text" />
              </a>
            </Text>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
