import { ChoiceItem } from "@ingka/choice";
import { savePreference, useProfile } from "common/api";
import { features } from "common/feature-flags";
import { Stack, Prompt, Toast, Box } from "components";
import { useContext, useState } from "react";
import { allSupportedLocales, Locale, LocaleContext } from "../i18n";
import { t } from "i18n";
import Expander from "@ingka/expander";
import { isActiveMarket } from "common/market/markets";
import { useUserLocation } from "common/api/user/userLocation";

interface ILocaleSelectorProps {
  visible: boolean;
  escapable?: boolean;
  selectedLocale: Locale;
}

interface ILocaleItemProps {
  locale: Locale;
  selected: boolean;
  onClick: () => void;
}

const LocaleItem = ({ locale, selected, onClick }: ILocaleItemProps) => (
  <ChoiceItem
    id={locale.toString()}
    key={locale.toString()}
    value={locale.toString()}
    title={locale.languageName}
    caption={locale.countryName}
    selected={selected}
    onClick={onClick}
  />
);

export const LocaleSelector = ({
  selectedLocale,
  visible,
  escapable,
}: ILocaleSelectorProps) => {
  const [error, setError] = useState<Date | undefined>(undefined);
  const [success, setSuccess] = useState<Date | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const localeContext = useContext(LocaleContext);
  const { data: profile } = useProfile();
  const userLocation = useUserLocation();

  const saveLocale = (theLocale?: Locale) => {
    if (!theLocale) {
      return;
    }
    setLoading(true);
    return savePreference(
      { language: theLocale.toString() },
      profile?.mailNickname
    )
      .then(() => setSuccess(new Date()))
      .catch(() => setError(new Date()))
      .finally(() => setLoading(false));
  };

  const officiallySupportedLocales = allSupportedLocales.filter((locale) =>
    isActiveMarket(locale.countryCode)
  );

  const allLocales = features.showOfficialLocalesOnly
    ? officiallySupportedLocales
    : allSupportedLocales;
  const marketLocales = allLocales.filter(
    (locale) => locale.countryCode === userLocation
  );
  const nonMarketLocales = allLocales.filter(
    (locale) => locale.countryCode !== userLocation
  );

  return (
    <>
      <Prompt
        title={t("user.profile.language-preference.header")}
        visible={visible}
        escapable={escapable}
        closeText={
          escapable
            ? t("user.profile.language-preference.close.button")
            : undefined
        }
        onClose={() => localeContext.setShowLocalePrompt(false)}
        secondaryButtonProps={{
          loading,
        }}
        primaryButtonProps={
          escapable
            ? undefined
            : {
                text: `${t("user.profile.use.selected-language.label")} ${
                  selectedLocale.name
                }`,
                onClick: () =>
                  saveLocale(selectedLocale)?.then(() =>
                    localeContext.setShowLocalePrompt(false)
                  ),
                emphasised: true,
                loading,
              }
        }
      >
        <Box minWidth="300px">
          <Stack vertical>
            {marketLocales.map((locale, i) => (
              <LocaleItem
                locale={locale}
                selected={selectedLocale === locale}
                onClick={() => saveLocale(locale)}
                key={i}
              />
            ))}
            <Expander
              labelClosed={t("user.profile.language-preference.show-more")}
              labelOpen={t("user.profile.language-preference.show-less")}
              open={!marketLocales.includes(selectedLocale)}
            >
              <Stack vertical>
                {nonMarketLocales.map((locale, i) => (
                  <LocaleItem
                    locale={locale}
                    selected={selectedLocale === locale}
                    onClick={() => saveLocale(locale)}
                    key={i}
                  />
                ))}
              </Stack>
            </Expander>
          </Stack>
        </Box>
      </Prompt>
      {error && (
        <Toast
          text={t("user.profile.language-preference.saving-failed")}
          time={error}
        />
      )}
      {success && (
        <Toast
          text={t("user.profile.toast.language-preference")}
          time={success}
        />
      )}
    </>
  );
};
