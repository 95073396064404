import { Notification } from "common/api";
import {
  Box,
  LocalizedDate,
  PopoverMenu,
  PopoverItem,
  Stack,
  Text,
  Icon,
} from "components";
import { getReminderIconByActivityType } from "common";
import { t, T } from "i18n";

interface INotificationItemProps {
  notification: Notification;
  odd: boolean;
  onClear(notification: Notification): Promise<void>;
}

export const NotificationItem: React.FunctionComponent<INotificationItemProps> =
  ({ notification, onClear, odd }) => {
    return (
      <Box padding={20} color={odd ? "bjornGrey" : "neutralWhite"}>
        <Stack vertical>
          <Stack justifyContent="space-between">
            <Stack spacing={10}>
              <Icon
                icon={getReminderIconByActivityType(
                  notification.type,
                  notification.subType
                )}
              />
              <Text bold wordBreakAll>
                {notification.title}
              </Text>
            </Stack>
            <PopoverMenu>
              <PopoverItem
                text={t("notification.item.clear-notification")}
                onClick={() => onClear(notification)}
              />
            </PopoverMenu>
          </Stack>
          <Stack vertical spacing={6}>
            <Text>
              <LocalizedDate pattern="Pp">
                {notification.createdAt}
              </LocalizedDate>
            </Text>
            {notification.opportunityTitle && (
              <Text small>
                <T id="notification.item.opportunity.title" />:{" "}
                {notification.opportunityTitle}
              </Text>
            )}
            {notification.opportunityId && (
              <Text small>ID: {notification.opportunityId}</Text>
            )}
            {notification.customerName && (
              <Text small>
                <T id="notification.item.opportunity.customer" />:{" "}
                {notification.customerName}
              </Text>
            )}
          </Stack>
          {notification.comment !== ": " && (
            <Text pre maxCharacters={80}>
              {notification.comment}
            </Text>
          )}
        </Stack>
      </Box>
    );
  };

NotificationItem.displayName = "NotificationItem";
