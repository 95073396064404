import { useLocale } from "common/hooks";
import {
  format,
  isToday,
  set,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import Select, { Option } from "@ingka/select";
import eachMinuteOfInterval from "common/helpers/eachMinuteOfInterval";
import { t } from "i18n";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";

const getClosestStartTime = (interval: number): Date => {
  const currentDate = setSeconds(new Date(), 0);
  const currentHour = getHours(new Date());
  const currentMinutes = getMinutes(new Date());

  const timeInMinutes = currentHour * 60 + currentMinutes;
  const closestStartTime = Math.ceil(timeInMinutes / interval) * interval;
  const roundedHours = Math.floor(closestStartTime / 60);
  const roundedMinutes = closestStartTime % 60;

  return setHours(setMinutes(currentDate, roundedMinutes), roundedHours);
};

interface ITImePickerProps {
  /**
   * Starting hour in the time picker given as a number from 0 to 24
   * @default 8
   */
  startHour?: number;

  /**
   * Ending hour in the time picker given as a number from 0 to 24
   * @default 20
   */
  endHour?: number;

  /**
   * The gap between the times generated, representing minutes from 0 to 60
   * @default 30
   */
  intervals?: number;

  /**
   * @default "Time"
   */
  label?: string;

  /**
   * @default "Select time"
   */
  placeholder?: string;
  value?: Date;
  onChange(value?: Date): void;
  date?: Date;
}

export const TimePicker = ({
  startHour = 8,
  endHour = 20,
  intervals = 30,
  label,
  value,
  placeholder,
  onChange,
  date,
}: ITImePickerProps) => {
  const locale = useLocale();
  const currentDate = setMinutes(setSeconds(new Date(), 0), 0);

  const startTime =
    date && isToday(date)
      ? getClosestStartTime(intervals)
      : setHours(currentDate, startHour);

  const endTime = setHours(currentDate, endHour);

  const times = eachMinuteOfInterval(
    { start: startTime, end: endTime },
    { step: intervals }
  );

  // TODO:
  // we need to convert time to current date here, because the value that is sent from the parent could perhaps contain
  // different dates. and in this component we don't care about the full date.
  // all we care about is the time.
  // the best approach here is to combine both the date and the time selector into one component.
  // But there are plans to use the Skapa component that is currently in the making.
  // so we will need to re-write this later.
  const valueToCurrentDate = value
    ? set(currentDate, {
        hours: getHours(value),
        minutes: getMinutes(value),
      }).toString()
    : "";

  return (
    <Select
      id="TimePickerSelect"
      label={label || t("manage-opportunity.actions.time.label")}
      onChange={(e) => {
        const val = e.target.value;
        onChange(val ? new Date(val) : undefined);
      }}
      value={valueToCurrentDate}
    >
      <Option
        value=""
        name={placeholder || t("manage-opportunity.actions.time.placeholder")}
      />
      {times.map((time, i) => {
        const formattedTime = format(time, "p", {
          locale: locale.dateFnsLocale,
        });
        return <Option value={time.toString()} name={formattedTime} key={i} />;
      })}
    </Select>
  );
};
