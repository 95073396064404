import {
  CurrentOpportunity,
  CurrentOpportunityDispatch,
} from "../currentOpportunity.reducer";
import { Box, Stack, Text, TextAreaFixed } from "components";
import { Opportunity } from "common/api";
import { t, T } from "i18n";
import { useSettings } from "settings/SettingsProvider";

interface Props {
  opportunityFromApi?: Opportunity;
  currentOpportunity: CurrentOpportunity;
  currentOpportunityDispatch: CurrentOpportunityDispatch;
  disableEditing: boolean;
}

export const Remarks = ({
  opportunityFromApi,
  currentOpportunity,
  currentOpportunityDispatch,
  disableEditing,
}: Props) => {
  const { feature_flags } = useSettings();
  const updateRemark = (remark: string) =>
    currentOpportunityDispatch({
      type: "UPDATE_REMARK",
      payload: remark,
    });

  if (feature_flags?.disable_notes) {
    return null;
  }

  return (
    <Box padding={30} color="bjornGrey">
      <Stack spacing={24} vertical>
        <Text header="h2">
          <T id="manage-opportunity.remark.title" />
        </Text>
        <TextAreaFixed
          id="remark"
          placeholder={t("manage-opportunity.remark.placeholder")}
          rows={4}
          optional
          label={t("manage-opportunity.remark.label")}
          onChange={(e) => {
            updateRemark(e.target.value);
          }}
          disabled={disableEditing}
          value={currentOpportunity.remark}
        />
      </Stack>
    </Box>
  );
};

Remarks.displayName = "Remarks";
