import { useState, useEffect, ChangeEvent } from "react";
import { css, cx } from "@emotion/css";
import Search from "@ingka/search";
import { useDebouncedEffect } from "../hooks";

type SearchFieldProps = {
  id: string;
  value?: string;
  disabled?: boolean;
  minWidth?: string;
  placeholder?: string;
  onChange?: (text: string) => void;
  onSearch?: (text: string) => void;
  onClear?: () => void;
  list?: string;
  backgroundColor?: string;
};

export const SearchField = ({
  id,
  value,
  disabled,
  minWidth,
  placeholder,
  onChange,
  onSearch,
  onClear,
  list,
  backgroundColor,
}: SearchFieldProps) => {
  const [term, setTerm] = useState(value ? value.trim() : "");

  const handleSearch = () => {
    onSearch?.(term);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTerm(event.currentTarget.value);
    onChange?.(event.currentTarget.value);
  };

  const handleClear = () => {
    onSearch?.("");
    onClear?.();
  };

  useEffect(() => {
    setTerm(value ?? "");
  }, [value]);

  useDebouncedEffect(
    () => {
      if (term !== value) onSearch?.(term);
    },
    500,
    [term, value, onSearch]
  );

  return (
    <Search
      id={id}
      value={term}
      onSearch={handleSearch}
      onChange={handleChange}
      onClear={handleClear}
      autoComplete={"off"}
      className={cx(
        minWidth &&
          css`
            min-width: ${minWidth};
          `,
        backgroundColor &&
          css`
            background-color: ${backgroundColor};
          `
      )}
      placeholder={placeholder}
      disabled={disabled}
      list={list}
    />
  );
};

SearchField.displayName = "SearchField";
