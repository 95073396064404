import { environment } from "../feature-flags/environment";

/**
 * The markets where this app is currently live
 */
export const activeMarkets = ["RO", "RS", "HR", "SI", "IN"] as const;

export const activeCTEMarkets = ["RO", "RS", "HR", "SI", "IN"] as const;

/**
 * A market where this app is currently live
 */
export type Market = typeof activeMarkets[number];

export type CTEMarket = typeof activeCTEMarkets[number];

/**
 * The fallback market to use if the user does not belong to any active market
 */
export const defaultMarket = "RO";

/**
 * Check if a market is one of our active markets
 */
export const isActiveMarket = (
  market: string
): market is Market | CTEMarket => {
  return environment.isProd
    ? activeMarkets.includes(market as Market)
    : activeCTEMarkets.includes(market as CTEMarket);
};
