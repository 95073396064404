import { Appointment, useOpportunity } from "common/api";
import {
  Box,
  Stack,
  Text,
  LocalizedDate,
  ModalSheet,
  Loading,
} from "components";
import { parseTitle } from "features/opportunities/OpportunitiesTab";
import { useHistory } from "react-router";
import { css } from "@emotion/css";
import { CoWorkerName } from "common";
import { getAppointmentLocationType } from "./AppointmentLocationType";
import { getAppointmentStatus } from "./AppointmentStatus";
import { getAppointmentService } from "./AppointmentService";
import { t } from "i18n";
import { Colors } from "components";
import { InlineLabeledText } from "components/ui/InlineLabeledText.component";
import { tracker } from "analytics";

export type AppointmentQuickViewProps = {
  appointment: Appointment | null;
  onClose: () => void;
};
export const AppointmentQuickView = ({
  appointment,
  onClose,
}: AppointmentQuickViewProps) => {
  const history = useHistory();

  const { data: opportunityFromApi, isValidating } = useOpportunity(
    appointment?.opportunityId
  );

  const appointments = opportunityFromApi?.appointmentDetails?.sort((a, b) =>
    a.timeWindowStartTime.localeCompare(b.timeWindowStartTime)
  );

  return (
    <ModalSheet
      title={t("appointment.appointments.label")}
      primaryButtonProps={{
        text: t("appointment.manage-appointments.button"),
        emphasised: true,
        onClick: () => {
          tracker.event({
            category: "Open Manage Opportunity",
            action: "Clicked on 'Manage' button in Appointments Quick View",
          });
          history.push(
            `/opportunity/${appointment!.opportunityId}#section=appointment`
          );
        },
      }}
      visible={Boolean(appointment)}
      onClose={onClose}
    >
      {appointment && (
        <Stack vertical spacing={30}>
          <Text wordBreakAll header="h2">
            {parseTitle(
              `${appointment.customer.firstName} ${appointment.customer.lastName}`
            )}
          </Text>

          {isValidating && <Loading isLoading={isValidating} />}

          {appointments &&
            appointments.map((appt) => (
              <div
                key={appt.id}
                className={css`
                  border: ${appointment.id === appt.id ? "2px" : "1.5px"} solid;
                  border-color: ${appointment.id === appt.id
                    ? Colors.blueHover
                    : Colors.neutralGrey300};
                  border-radius: 3.5px;
                  padding: 15px;
                `}
              >
                <Stack vertical spacing={4}>
                  <Box>
                    <Stack>
                      <Text small bold inline>
                        <LocalizedDate pattern="P">
                          {appt.timeWindowStartTime}
                        </LocalizedDate>
                        {" | "}
                        <LocalizedDate pattern="p">
                          {appt.timeWindowStartTime}
                        </LocalizedDate>
                        {" - "}
                        <LocalizedDate pattern="p">
                          {appt.timeWindowEndTime}
                        </LocalizedDate>
                      </Text>
                    </Stack>
                    <Text small bold>
                      {getAppointmentStatus(appt.appointmentStatus)}
                    </Text>
                  </Box>
                  <Text small inline color="neutralGrey500">
                    {appt.locationName}
                    {" | "}
                    {getAppointmentLocationType(appt.locationType)}

                    {appt.assignedCoworker && (
                      <>
                        {" | "}
                        <CoWorkerName>{appt.assignedCoworker}</CoWorkerName>
                      </>
                    )}
                  </Text>

                  <Stack vertical spacing={0}>
                    <InlineLabeledText
                      small
                      label={`${t("appointment.service.label")}:`}
                    >
                      <Text small inline wordBreakWord>
                        {getAppointmentService(appt.service)}
                      </Text>
                    </InlineLabeledText>

                    <InlineLabeledText
                      small
                      label={`${t("appointment.appointment-number.label")}:`}
                      text={appt.orderNumber}
                    />
                  </Stack>

                  {appt.comments && appt.comments.length !== 0 && (
                    <Text small>
                      <Text small bold>
                        {`${t("appointment.comments.label")}: `}
                      </Text>
                      {appt.comments.map((comment, index) => (
                        <Stack key={`comment-${index}`}>
                          <Text small color="neutralGrey500">
                            <LocalizedDate>{comment.createdAt}</LocalizedDate>
                          </Text>
                          <Text small>{comment.comment}</Text>
                        </Stack>
                      ))}
                    </Text>
                  )}
                </Stack>
              </div>
            ))}
        </Stack>
      )}
    </ModalSheet>
  );
};
