import { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import { Button, Icon, Loading, OpportunityType } from "components";
import archive from "@ingka/ssr-icon/paths/archive-box";
import { Stack, Box, Text, Toast } from "components";
import { currentOpportunityReducer } from "./currentOpportunity.reducer";
import {
  RecommendedActions,
  OpportunityStatusInfo,
  Remarks,
  OpportunityInfo,
  CustomerInfo,
  OpportunityTimeLine,
  OrderInfo,
  FileUpload,
  DoNotContact,
  NewPlannerDrawing,
  AppointmentInfo,
  AppointmentBanner,
} from "./components";
import { isEqual, omit } from "lodash";
import {
  useOpportunity,
  Opportunity,
  saveOpportunity,
  exportPdf,
  SaveOpportunity,
  useOpportunityCustomer,
} from "common/api";
import { ApiError } from "common/api/ApiError";
import { StatusIndicator } from "./components/StatusIndicator.component";
import { t, T } from "i18n";
import { parseTitle } from "features/opportunities/OpportunitiesTab";
import { tracker, usePageTracking } from "analytics";
import { isDefined } from "common";

const Spacer = () => <div>{"|"}</div>;

const trackWithLabel = (label: string) => {
  tracker.event({
    category: "Manage page",
    action: "Clicked on 'Save all changes' button at top",
    label,
  });
};

const trackSaveAllChanges = (changedData?: SaveOpportunity) => {
  if (!changedData) {
    return;
  }

  if (changedData.title) trackWithLabel("Changed Opportunity Title");
  if (changedData.hfb) trackWithLabel("Added department");
  if (changedData.customerMeetingPointBuCode)
    trackWithLabel("Added customer meeting point");
  if (changedData.customerBudget) trackWithLabel("Added customer budget");
  if (changedData.owner) trackWithLabel("Changed Opportunity Owner");
  if (changedData.interestArea) trackWithLabel("Added interest areas");
  if (changedData.servicesInterested)
    trackWithLabel("Added services interested in");
  if (changedData.expectedPurchase)
    trackWithLabel("Added expected purchase date");
  if (changedData.allowContact) trackWithLabel("Changed customer consent");
};

export const findOrdersWithoutAppointment = (
  opportunity: Opportunity | undefined
) => {
  const orders = [
    opportunity?.orders?.originalOrder,
    ...(opportunity?.orders?.linkedOrders ?? []),
  ].filter(isDefined);
  const appointmentIds = opportunity?.appointmentDetails?.map(
    (appointment) => appointment.orderNumber
  );
  return orders.filter((order) => !appointmentIds?.includes(order.orderId));
};

export const ManageOpportunity = () => {
  const { opportunityId: opportunityIdParam } =
    useParams<{ opportunityId: string }>();
  const opportunityId = Number(opportunityIdParam);
  const { data: customer } = useOpportunityCustomer(opportunityId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [currentOpportunityHasChanges, setCurrentOpportunityHasChanges] =
    useState<boolean>(false);
  const [currentOpportunity, currentOpportunityDispatch] = useReducer(
    currentOpportunityReducer,
    {} as Opportunity
  );
  const [toastMessage, setToastMessage] = useState<{
    message: string;
    time: Date;
  } | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const {
    data: opportunityFromApi,
    isValidating,
    error,
  } = useOpportunity(opportunityId);

  const disableEditing = opportunityFromApi?.lockStatus ?? false;

  usePageTracking("Manage-Opportunity");

  useEffect(() => {
    setIsLoading(isValidating || isSaving);
  }, [isSaving, isValidating]);

  useEffect(() => {
    if (opportunityFromApi) {
      currentOpportunityDispatch({
        type: "INIT_OPPORTUNITY",
        payload: opportunityFromApi,
      });
    }
  }, [opportunityFromApi]);

  useEffect(() => {
    const isDifferent = !isEqual(
      omit(currentOpportunity, ["markAsDone"]),
      opportunityFromApi
    );
    const shouldMarkAsDone = !!(
      currentOpportunity.markAsDone && currentOpportunity.markAsDone.length > 0
    );
    setCurrentOpportunityHasChanges(isDifferent || shouldMarkAsDone);
  }, [opportunityFromApi, currentOpportunity]);

  const onSave = () => {
    setIsSaving(true);
    trackSaveAllChanges(currentOpportunity.dataToSave);

    return saveOpportunity(
      opportunityFromApi!.id,
      currentOpportunity.dataToSave!,
      currentOpportunity.markAsDone
    )
      .catch((e: ApiError) => {
        setToastMessage({
          message: e.message,
          time: e.timestamp,
        });
      })
      .then(() => {
        currentOpportunityDispatch({
          type: "CLEAR_TOGGLE_AS_DONE",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const resetCurrentOpportunity = () => {
    currentOpportunityDispatch({
      type: "INIT_OPPORTUNITY",
      payload: opportunityFromApi!,
    });
  };

  const toggleAsDone = (id: number) => {
    currentOpportunityDispatch({
      type: "TOGGLE_AS_DONE",
      payload: id,
    });
  };
  if (isValidating && !opportunityFromApi) {
    return <Loading fullscreen isLoading={true} />;
  }

  if (!opportunityFromApi || !currentOpportunity) return null;

  const isValid = currentOpportunity.title?.length > 0;

  return (
    <>
      {toastMessage && (
        <Toast text={toastMessage.message} time={toastMessage.time} />
      )}
      {error && <Toast text={error.message} time={error.timestamp} />}
      <Box vertical grow>
        <Box position="sticky" top="0" moveUp={3} width="100%" vertical>
          <Box
            width="100%"
            color="neutralWhite"
            border={[0, 0, 1, 0]}
            borderColor="neutralGrey200"
            padding={24}
            grow
          >
            <Stack vertical>
              <Stack justifyContent="space-between" alignItems="center">
                <Stack vertical>
                  <Box>
                    <Text header="h1" wordBreakAll>
                      {parseTitle(currentOpportunity.title)}
                    </Text>
                  </Box>
                  <Box>
                    <Stack>
                      {disableEditing && (
                        <>
                          <Box>
                            <Box padding={[0, 5, 0, 0]}>
                              <Icon
                                icon="WarningIcon"
                                size={20}
                                color={"classUrgent"}
                              />
                            </Box>
                            <Text>
                              <T id="opportunity.locked.status" />
                            </Text>
                          </Box>
                          <Spacer />
                        </>
                      )}
                      {!currentOpportunity.allowContact && (
                        <>
                          <DoNotContact />
                          <Spacer />
                        </>
                      )}

                      <Text inline uppercase>
                        {currentOpportunity.type && (
                          <OpportunityType>
                            {currentOpportunity.type}
                          </OpportunityType>
                        )}
                      </Text>
                      <Spacer />
                      {currentOpportunity?.businessUnit?.buCode === "CSC" ? (
                        <Text>{currentOpportunity.businessUnit.buName}</Text>
                      ) : (
                        <Text>
                          <T
                            id="manage-opportunity.header.opportunity-source"
                            values={{
                              buType: currentOpportunity.businessUnit?.buType,
                              buCode: currentOpportunity.businessUnit?.buCode,
                            }}
                          />
                        </Text>
                      )}
                    </Stack>
                  </Box>
                </Stack>

                <Button
                  small
                  iconOnly
                  ssrIcon={archive}
                  loading={isDownloading}
                  onClick={async () => {
                    try {
                      setIsDownloading(true);
                      await exportPdf(opportunityFromApi.id);
                      setToastMessage({
                        message: t(
                          "manage-opportunity.toasts.download-pdf.success"
                        ),
                        time: new Date(),
                      });
                    } catch {
                      setToastMessage({
                        message: t(
                          "manage-opportunity.toasts.download-pdf.failed"
                        ),
                        time: new Date(),
                      });
                    } finally {
                      setIsDownloading(false);
                    }
                  }}
                  text={t("manage-opportunity.header.download-pdf.button-text")}
                />
              </Stack>

              {currentOpportunityHasChanges && (
                <Stack justifyContent="flex-end">
                  <Button
                    text={t("manage-opportunity.header.undo-button")}
                    disabled={isLoading}
                    small
                    onClick={resetCurrentOpportunity}
                  />
                  <Button
                    text={t("manage-opportunity.header.save-button")}
                    emphasised
                    loading={isLoading}
                    disabled={!isValid || disableEditing}
                    small
                    onClick={onSave}
                  />
                </Stack>
              )}
            </Stack>
          </Box>

          <AppointmentBanner
            opportunityId={opportunityId}
            disabled={disableEditing}
          />
        </Box>

        <Stack vertical spacing={24} maxWidth="80%" padding={[24, 0, 100, 0]}>
          <StatusIndicator status={currentOpportunity.status} />
          <Stack alignItems="stretch" spacing={24}>
            <Box width="50%">
              <Stack spacing={24} vertical>
                <OpportunityStatusInfo
                  opportunityFromApi={currentOpportunity}
                  currentOpportunity={currentOpportunity}
                  currentOpportunityDispatch={currentOpportunityDispatch}
                  disableEditing={disableEditing}
                />
                <RecommendedActions
                  opportunityFromApi={opportunityFromApi}
                  currentOpportunity={currentOpportunity}
                  currentOpportunityDispatch={currentOpportunityDispatch}
                  onSave={onSave}
                  isLoading={isLoading}
                  disableEditing={disableEditing}
                />
                <Remarks
                  opportunityFromApi={opportunityFromApi}
                  currentOpportunity={currentOpportunity}
                  currentOpportunityDispatch={currentOpportunityDispatch}
                  disableEditing={disableEditing}
                />
              </Stack>
            </Box>
            <Box width="50%" color="bjornGrey" position="relative">
              <OpportunityTimeLine
                opportunityFromApi={opportunityFromApi}
                currentOpportunity={currentOpportunity}
                toggleAsDone={toggleAsDone}
                disableEditing={disableEditing}
              />
            </Box>
          </Stack>

          <OpportunityInfo
            opportunityFromApi={opportunityFromApi}
            currentOpportunity={currentOpportunity}
            currentOpportunityDispatch={currentOpportunityDispatch}
            disableEditing={disableEditing}
          />
          <CustomerInfo
            opportunityFromApi={opportunityFromApi}
            currentOpportunity={currentOpportunity}
            currentOpportunityDispatch={currentOpportunityDispatch}
            disableEditing={disableEditing}
          />
          <AppointmentInfo
            opportunityFromApi={opportunityFromApi}
            disableEditing={disableEditing}
          />

          {findOrdersWithoutAppointment(opportunityFromApi).length > 0 && (
            <OrderInfo
              opportunityFromApi={opportunityFromApi}
              disableEditing={disableEditing}
            />
          )}
          <NewPlannerDrawing
            opportunity={opportunityFromApi}
            customerEmail={customer?.customer?.email}
            disableEditing={disableEditing}
          />
          <FileUpload
            currentOpportunity={currentOpportunity}
            disableEditing={disableEditing}
          />
        </Stack>
      </Box>
    </>
  );
};

ManageOpportunity.displayName = "ManageOpportunity";
