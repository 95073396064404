import { useContext } from "react";
import { ModalHeader, ModalFooter } from "components/ui/modal";
import { Box, Stack } from "components/layout";
import { Text } from "components/typography";
import { useHistory, useLocation } from "react-router-dom";
import { CustomerInfo } from "../common/CustomerInfo";
import { Icon } from "components/ui";
import { LocalizedDate } from "components/data";
import { OpportunityContext } from "../OpportunityContext";
import { t } from "i18n";
import { tracker } from "analytics";

export const Summary = () => {
  const history = useHistory();
  const location = useLocation();

  const { customer, opportunity, isExistingCustomer } =
    useContext(OpportunityContext);

  if (!customer || !opportunity) {
    history.push({ hash: "/select-customer" });
    return null;
  }

  return (
    <>
      <ModalHeader title={t("new-opportunity.summary.title")} />
      <Stack vertical spacing={36}>
        <Stack vertical>
          {isExistingCustomer ? (
            <Text header={"h3"}>
              {t("new-opportunity.summary.existing-customer")}
            </Text>
          ) : (
            <Text header={"h3"}>
              {t("new-opportunity.summary.customer.created")}
            </Text>
          )}
          <Stack>
            <Box alignItems="start" padding={[10, 0, 0, 0]}>
              <Icon icon="UserAccountIcon" />
            </Box>
            <CustomerInfo customer={customer} />
          </Stack>
        </Stack>
        <Stack vertical>
          <Text header={"h3"}>
            {t("new-opportunity.summary.opportunity.created")}
          </Text>
          <Stack>
            <Box alignItems="start" padding={[10, 0, 0, 0]}>
              <Icon icon="Checkmark" />
            </Box>
            <Stack vertical spacing={0}>
              <Text>
                {t("new-opportunity.summary.sales-opportunity-id")}
                {opportunity.id}
              </Text>
              <Text wordBreakAll>
                {t("new-opportunity.summary.opportunity-title")}
                {opportunity.title}
              </Text>
              <Text>
                {t("new-opportunity.summary.created-by")}
                {opportunity.createdBy}
              </Text>
              <Text>
                {t("new-opportunity.summary.date")}
                <LocalizedDate pattern="Pp">
                  {opportunity.createdAt}
                </LocalizedDate>
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ModalFooter
        primaryButtonProps={{
          text: t("new-opportunity.summary.manage.button"),
          onClick: () => {
            tracker.event({
              category: "Open Manage Opportunity",
              action:
                "Clicked on 'Manage' button in New sales creation overlay",
            });
            history.push(`/opportunity/${opportunity.id}`);
          },
        }}
        secondaryButtonProps={{
          text: t("new-opportunity.modal.close"),
          onClick: () => {
            // to close the modal and reset the context state
            history.push(location.pathname);
          },
        }}
      ></ModalFooter>
    </>
  );
};
