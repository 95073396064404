import { t, T } from "i18n";

const translationKeys = {
  PUBLISHED: "appointment.status.published",
  ORDER_RECEIVED: "appointment.status.received",
  ORDER_RECEIVED_WAITING_FOR_PAYMENT: "appointment.status.waiting-for-payment",
  CANCELLED: "appointment.status.cancelled",
  CANCELLED_NO_SHOW: "appointment.status.no-show",
  STARTED: "appointment.status.started",
  COMPLETED: "appointment.status.completed",
  PLANNED: "appointment.status.planned",
} as const;

interface Props {
  id: keyof typeof translationKeys;
}

export const AppointmentStatus = ({ id }: Props) => {
  if (!translationKeys[id]) return <>{id}</>;
  return <T id={translationKeys[id]} />;
};

export const getAppointmentStatus = (id: keyof typeof translationKeys) =>
  t(translationKeys[id]) ?? id;
