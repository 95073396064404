import { Values } from "common/helpers";
import useSWR, { mutate } from "swr";
import { Appointment } from ".";

import { ApiError } from "../ApiError";
import * as fetcher from "../fetcher";
import { ActionReason } from "./actions";
import { Item, PlannerDetail } from "./planner";
import { OpportunitiesSearchArgs, stringifySearchQuery } from "./search";

export const HFBValues = {
  hfb01: "Living room seating",
  hfb02: "Store and organise furniture",
  hfb03: "Workspaces",
  hfb04: "Bedroom furniture",
  hfb05: "Beds & mattresses",
  hfb06: "Bathroom",
  hfb07: "Kitchen",
  hfb08: "Dining",
  hfb09: "Children's IKEA",
  hfb12: "Home textiles",
  hfb17: "Outdoor",
};

export type hfb = keyof typeof HFBValues;

export const ServicesValues = {
  "2": "Home planning",
  "11": "Remote planning",
  "3": "Home delivery",
  "1": "Measurement service",
  "5": "Picking service",
  "6": "Assembly service",
  "9": "Business services",
  "7": "Installation",
  "8": "Store appointment",
  "10": "Financial support",
  "12": "Interior design",
  "13": "Collect Delivery",
  "14": "Kitchen planning - Store",
  "15": "Kitchen planning – Remote",
  "16": "Kitchen planning - Home",
  "17": "Wardrobe planning – Store",
  "18": "Wardrobe planning - Remote",
};

export type service = keyof typeof ServicesValues;

export interface Attachment {
  name: string;
  uri: string;
  size: number;
  type?: string;
}

export interface OpportunityOrder {
  blockDate: string;
  orderId: string;
  createdTime: string;
  updatedTime?: string;
  status?: string;
  updated?: boolean;
}

export interface OpportunityPlanner {
  designImage?: string;
  currencyCode?: string;
  amount: number;
  plannerId: string;
  plannerType: string;
  updatedTime: string;
  items?: Item[];
  statusCodeTranslated?: string;
}

export interface Opportunity {
  inprogressAmount?: number;
  closedWonAmount?: number;
  closedLostAmount?: number;
  id: number;
  opportunityId: number;
  externalId: number;
  source: "SLA" | "iSell";
  assignedTo: string;
  status:
    | "Unassigned"
    | "Assigned"
    | "InProgress"
    | "ClosedWon"
    | "ClosedLost"
    | "Invalid";
  remark?: string;
  title: string;
  interestArea?: string;
  hfb?: hfb[];
  countryCode?: string;
  servicesInterested?: service[];
  buName?: string;
  buType?: string;
  buCode?: string;
  customerMeetingPointBuCode: string;
  customerMeetingPointBuName: string;
  city?: string;
  sourceReasonCode:
    | "PIANOTPAID"
    | "MULTIPLE_ORDERS_GIVE_LOW_LIMIT"
    | "LOWLIMIT"
    | "CARDBLOCK"
    | "CANC_PIA_DATE_PASSED"
    | "NOT_PAID_ON_CRE_DATE"
    | "CREDIT_CARD_REJECTED"
    | "PAYM_GATEWAY_UNAVAIL"
    | "CUSTPAYM"
    | "PLANNING_SEASONAL_CUSTOMER"
    | "NEWLEAD";
  orderInfo?: {
    blockedAt: string;
    orderType: "SALESORDER" | "DISTR" | "PIANOTPAID";
    amount: number;
    items: string[];
    createdAt: string;
    updatedAt: string;
    orderNo: string;
  };

  customerBudget?: number;
  customerName?: string;
  owner?: string;
  allowContact: boolean;
  country: string;
  type: "Blocked Order" | "Sales Opportunity" | "Boka";
  opportunityType?: "Blocked Order" | "Sales Opportunity" | "Boka";
  classification: "Low" | "Medium" | "High" | "Urgent" | "None";
  classificationChangeReason: string;
  sourceSystem: string;
  businessUnit: {
    buCode: string;
    buType: string;
    buName: string;
  };
  createdAt: string;
  updatedAt?: string;
  createdBy?: string;
  attachments?: Attachment[];
  orders?: {
    originalOrder?: OpportunityOrder;
    linkedOrders?: OpportunityOrder[];
  };
  planners: {
    plannersInfo: OpportunityPlanner[];
    designs: PlannerDetail[];
    designsInfo: DesignsInfo[];
  };
  amount?: number;
  displayAmount?: number;
  currencySymbol?: string;
  currency: string;
  blockedAt?: string;
  expectedPurchase?: string;
  appointmentDetails?: Appointment[];
  lockStatus?: boolean;
}

export interface SaveOpportunity extends Partial<Opportunity> {
  action?: {
    action?: ActionReason;
    reason?: ActionReason;
    comment?: string;
    appointment?: string;
    title?: string;
    orderNo?: string;
    plannerId?: string;
    sendCommunication?: boolean;
  };
  consentChangeReason?: string;
  statusChangeDetails?: {
    reason?: string;
    description?: string;
  };
  fileNameToDelete?: string;
  sourceBuName?: string;
  expectedPurchase?: string;
  classificationChangeDetails?: {
    reason?: string;
    description?: string;
  };
}

export const customerType = {
  individual: "Individual",
  business: "Business",
} as const;

export const customerReadiness = {
  exploring: "Exploring",
  needsAssistance: "Needs Assistance",
  readyToBuy: "Ready to Buy",
} as const;

export const preferredContactMethod = {
  phone: "PHONE",
  email: "EMAIL",
} as const;

export const customerInterestIndex = {
  low: "Low",
  medium: "Medium",
  high: "High",
} as const;

export type ConsentType = "FOLLOWUP_CONSENT" | "PROMOTIONAL_CONSENT";

export type CustomerConsent = {
  consentId?: number;
  consentType: ConsentType;
  consentProvided?: boolean;
  hfb?: hfb[];
  updatedAt?: string;
  opportunityId?: number; // if consent is connected to a project
};

export interface CreateOpportunityPayload {
  opportunityInfo: {
    title: string;
    sourceSystem?: string;
    createdBy?: string;
    customerLocale: string;
    assignedTo?: string;
    businessUnit: {
      buCode?: string;
      buType: string;
      buName?: string;
    };
    customerInterestIndex: Values<typeof customerInterestIndex>;
    customerReadiness: Values<typeof customerReadiness>;
    hfb: string[];
    customerMeetingPointBuCode?: string;
  };
  customerInfo: {
    firstName: string;
    lastName: string;
    preferredContactType: Values<typeof preferredContactMethod>;
    email: string;
    phone: string;
    partyId: string;
    familyCardNumber: string;
    customerType: string;
    customerConsents?: CustomerConsent[];
  };
  confirmationMessage?: {
    type: Values<typeof preferredContactMethod>;
    contact: string;
  };
  retailUnit: string;
}

export interface CreateOpportunityResponse {
  createdAt: string;
  createdBy: string;
  id: number;
}

export type DesignsInfo = {
  designId: string;
  languageCode: string;
};

export const useOpportunity = (id?: number) =>
  useSWR<Opportunity, ApiError>(
    id ? `/opportunities/${id}` : null,
    (_url) => fetcher.opportunity.get(`/${id}`),
    {
      revalidateOnFocus: false,
    }
  );

export type AssignOpportunityInput = {
  [email: string]: { id: number; status: "Assigned" }[];
};
export const useAssignOpportunity = ({
  filters,
  options,
}: OpportunitiesSearchArgs) => {
  const url = "/opportunities/multiupdate";
  const query = stringifySearchQuery(filters, options);
  return async (email: string, opportunityIds: number[]) => {
    const payload = opportunityIds.map((id) => ({
      id,
      coworkerId: email,
    }));
    const res = await fetcher.core.update(url, payload);
    mutate(`/opportunities/search?${query}`);
    return res;
  };
};

export const createOpportunity = async (
  opportunity: CreateOpportunityPayload
) => {
  const res = await fetcher.opportunity.post<CreateOpportunityResponse>(
    "/opportunity",
    opportunity
  );
  return res;
};

export const saveOpportunity = async (
  id: number,
  opportunity: SaveOpportunity,
  tasks?: number[]
) => {
  if (opportunity) {
    await fetcher.opportunity.update(`/${id}`, opportunity);
  }
  if (tasks) {
    await Promise.all(
      tasks.map((taskId) =>
        fetcher.opportunity.update(`/${id}/timelog/${taskId}`, {})
      )
    );
  }
  mutate(`/opportunities/${id}`);
  mutate(`/opportunities/${id}/timelog`);
  mutate(`/opportunities/${id}/history`);
  mutate(`/notifications`);
};

export const deleteOrderInfo = async (
  opportunityId?: number,
  orderId?: string
) => {
  const res = await fetcher.opportunity.remove(
    `/${opportunityId}/order/${orderId}`
  );
  mutate(`/opportunities/${opportunityId}`);
  mutate(`/opportunities/${opportunityId}/timelog`);
  mutate(`/opportunities/${opportunityId}/history`);
  return res;
};
