import { useState, ReactNode } from "react";
import { css, cx } from "@emotion/css";
import { Color, Colors } from "../Variables";
import { isString } from "lodash";
import { t } from "i18n";

interface Props {
  color?: Color;
  inline?: boolean;
  header?: "h1" | "h2" | "h3" | "h4";
  align?: "left" | "right" | "center";
  nowrap?: boolean;
  bold?: boolean;
  large?: boolean;
  small?: boolean;
  xsmall?: boolean;
  /** Used to mimic label style  */
  label?: boolean;
  /** Display as preformatted text */
  pre?: boolean;
  uppercase?: boolean;
  maxWidth?: string;
  minWidth?: string;
  wordBreakAll?: boolean;
  wordBreakWord?: boolean;
  /** Only show this many characters, then show expand toggle */
  maxCharacters?: number;
  clickable?: boolean;
  children?: ReactNode;
}

const expandToggleStyle = css`
  display: block;
  text-decoration: underline;
  cursor: pointer;
`;

export const Text = ({
  color = "neutralGrey900",
  header,
  inline = false,
  children,
  align,
  maxWidth,
  minWidth,
  label,
  large,
  small,
  xsmall,
  uppercase,
  bold,
  pre,
  nowrap,
  wordBreakAll,
  wordBreakWord,
  maxCharacters,
  clickable = false,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const textStyle = cx(
    label &&
      css`
        transform: scale(0.75);
        transform-origin: top left;
      `,
    header ||
      css`
        font-weight: normal;
        font-size: 16px;
      `,
    bold
      ? css`
          font-weight: bold;
        `
      : undefined,
    css`
      margin: 0;
      max-width: ${maxWidth};
      min-width: ${minWidth};
      text-align: ${align};
      text-transform: ${uppercase ? "uppercase" : "inherit"};
      white-space: ${nowrap ? "nowrap" : "inherit"};
      /* Overwrite browser default CSS rule */
      :not(:last-of-type) {
        margin-bottom: inherit;
      }
    `,
    small &&
      css`
        font-size: 14px;
      `,
    large &&
      css`
        font-size: 30px;
      `,
    xsmall &&
      css`
        font-size: 12px;
      `,
    pre &&
      css`
        white-space: pre-wrap;
      `,
    wordBreakAll &&
      css`
        word-break: break-all;
      `,
    wordBreakWord &&
      css`
        word-break: break-word;
      `,
    color
      ? css`
          color: ${Colors[color]};
        `
      : undefined,
    clickable &&
      css`
        text-decoration-line: underline;
      `
  );

  if (inline) {
    return <span className={textStyle}>{children}</span>;
  }

  return maxCharacters && isString(children) ? (
    <p className={textStyle}>
      {children.slice(0, isExpanded ? children.length : maxCharacters)}
      {children.length > maxCharacters && (
        <span
          className={expandToggleStyle}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded
            ? t("my-opportunities.view-my-notes.read-less.link")
            : t("my-opportunities.view-my-notes.read-more.link")}
        </span>
      )}
    </p>
  ) : (
    <p className={textStyle}>{children}</p>
  );
};

Text.displayName = "Text";
