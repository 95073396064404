import { useSettings } from "settings/SettingsProvider";
import { useProfile } from ".";

export const useUserLocation = () => {
  const profile = useProfile();
  const { overrideMarket } = useSettings();

  return overrideMarket !== undefined && overrideMarket !== ""
    ? overrideMarket
    : (profile.data?.usageLocation as string | undefined);
};
