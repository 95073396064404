import { useLocation } from "react-router-dom";

interface Props {
  /**
   * the string to match with hash part of the url (should not include the hash)
   * @example "test" to match with "wwww.example.com/page#test"
   */
  hash: string;
  /**
   * The react node to render if the hash prop matches the hash in the url
   */
  children: React.ReactNode;
}

/**
 * React component for matching a single hash with the hash part of the url
 * the component is very similar to the <Route> component that comes from react-router-dom
 * but simplified and changed to suite our use case.
 *
 */
export const HashRoute = ({ hash, children }: Props) => {
  const location = useLocation();
  return location.hash === `#${hash}` ? <>{children}</> : null;
};
