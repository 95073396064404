import useSWR from "swr";
import * as fetcher from "../fetcher";
import {
  CreateCustomerPayload,
  CreateCustomerResponse,
  CustomerSearchResponse,
  CustomerGlobal,
} from "./types";
import { ApiError } from "../ApiError";

export async function createCustomer(input: CreateCustomerPayload) {
  return fetcher.core.post<CreateCustomerResponse>("/global/customers", input);
}

const phoneNumberRegExp = new RegExp("^[+\\s\\d-]*$");
const isPhoneNumber = (searchTerm) => phoneNumberRegExp.test(searchTerm);
const formatPhoneNumberForSearch = (phoneNumber: string) =>
  phoneNumber.replace(/[\s-]/g, "");

export function useCustomerSearch(searchTerm: string) {
  const key = searchTerm ? `/global/customers/search/${searchTerm}` : null;
  const { data, ...rest } = useSWR(
    key,
    async () => {
      try {
        const res = await fetcher.core.post<CustomerSearchResponse>(
          "/global/customers/search",
          {
            sourceSystem: "saleslead",
            freeText: isPhoneNumber(searchTerm)
              ? [formatPhoneNumberForSearch(searchTerm)]
              : searchTerm.split(" "),
          }
        );

        return res;
      } catch (err: any) {
        if (err instanceof ApiError && err.errorCode === 404) {
          return { customers: [] };
        }

        throw err;
      }
    },
    { revalidateOnFocus: false }
  );

  const customers: CustomerGlobal[] | undefined = data?.customers
    ?.map(({ customer }) => customer)
    .filter(Boolean);

  return { data: customers, ...rest };
}
