import { useEffect, useState } from "react";
import { Checkbox, InputField, RadioButtonGroup } from "components/formik";
import { Box, Stack } from "components";
import {
  customerInterestIndex,
  customerReadiness,
  preferredContactMethod,
  useProfile,
} from "common/api";
import { CoworkerSearch } from "common";
import { Button } from "components";
import { User } from "@microsoft/microsoft-graph-types";
import { t } from "i18n";
import { DepartmentSelector } from "./DepartmentSelector";

interface Props {
  onSelectedCoworkerChange: (coworker: User | null) => void;
}

export const OpportunityForm = ({ onSelectedCoworkerChange }: Props) => {
  const [selectedCoworker, setSelectedCoworker] = useState<User | null>(null);
  const { data: profile } = useProfile();

  const assignToMe = () => {
    setSelectedCoworker({
      displayName: profile?.displayName,
      mail: profile?.mail,
    });
  };

  useEffect(() => {
    onSelectedCoworkerChange(selectedCoworker);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoworker]);

  return (
    <Stack vertical spacing={32}>
      <InputField
        name="title"
        type="text"
        label={t("new-opportunity.form.title.label")}
        placeholder={t("new-opportunity.form.title.placeholder")}
      />
      <DepartmentSelector name="departments" />
      <CoworkerSearch
        label={t("new-opportunity.form.opportunity-owner.label")}
        placeholder={t("new-opportunity.form.opportunity-owner.placeholder")}
        selectedMail={selectedCoworker?.mail || ""}
        onSelect={(coworker) => {
          setSelectedCoworker(coworker);
        }}
        onChange={() => {
          setSelectedCoworker(null);
        }}
        onReset={() => {
          setSelectedCoworker(null);
        }}
        onError={() => {
          setSelectedCoworker(null);
        }}
      />
      <Box>
        <Button
          primary
          small
          text={t("new-opportunity.form.opportunity-owner.assign-to-me")}
          onClick={assignToMe}
        />
      </Box>

      <RadioButtonGroup
        name="preferredContactMethod"
        label={t("new-opportunity.form.preferred-contact-method.label")}
        list={[
          {
            id: preferredContactMethod.phone,
            label: t("new-opportunity.form.preferred-contact-method.phone"),
          },
          {
            id: preferredContactMethod.email,
            label: t("new-opportunity.form.preferred-contact-method.email"),
          },
        ]}
      />

      <RadioButtonGroup
        label={t("new-opportunity.form.customer-readiness.label")}
        name="customerReadiness"
        list={[
          {
            id: customerReadiness.exploring,
            label: t("new-opportunity.form.customer-readiness.exploring"),
          },
          {
            id: customerReadiness.needsAssistance,
            label: t(
              "new-opportunity.form.customer-readiness.needs-assistance"
            ),
          },
          {
            id: customerReadiness.readyToBuy,
            label: t("new-opportunity.form.customer-readiness.ready-to-buy"),
          },
        ]}
      />

      <RadioButtonGroup
        name="customerInterestIndex"
        label={t("new-opportunity.form.interest-index.label")}
        list={[
          {
            id: customerInterestIndex.low,
            label: t("new-opportunity.form.interest-index.low"),
          },
          {
            id: customerInterestIndex.medium,
            label: t("new-opportunity.form.interest-index.medium"),
          },
          {
            id: customerInterestIndex.high,
            label: t("new-opportunity.form.interest-index.high"),
          },
        ]}
      />
      <Checkbox
        name="sendConfirmation"
        label={t("new-opportunity.form.confirmation-message.label")}
      />
    </Stack>
  );
};
