import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { tracker } from "./";

export const usePageTracking = (path: string) => {
  const location = useLocation();

  useEffect(() => {
    tracker.pageView(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
};
