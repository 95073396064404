import useSWR, { mutate } from "swr";
import * as fetcher from "../fetcher";

export interface Task {
  id: number;
  taskStartDateTime?: string;
  taskEndDateTime?: string;
  title: string;
  isReminder: boolean;
  isTimeSet: boolean;
  coworkerId?: string;
  emailId?: string;
  opportunityId?: number;
  description?: string;
  isDone?: boolean;
  parentTaskType: "task" | "boka" | "schedule";
  subTaskType:
    | "genericTask"
    | "phoneCall"
    | "otherActions"
    | "bokaCoworkerPlannedSlot";
}

export function useTasks(emailId?: string | null) {
  return useSWR<Task[]>(
    emailId ? `/tasks?emailId=${emailId}` : null,
    async (_url) => fetcher.opportunity.get(_url),
    {
      revalidateOnFocus: false,
    }
  );
}

export function useTask(id: string) {
  return useSWR<Task>(
    `/tasks/${id}`,
    (_url) => fetcher.opportunity.get(`/tasks/${id}`),
    {
      revalidateOnFocus: false,
    }
  );
}

export const deleteTask = async (id: number, emailId?: string | null) => {
  mutate("/tasks", (tasks) => tasks?.filter((task) => task.id !== id), false);
  const res = await fetcher.opportunity.remove(`/tasks/${id}`);
  mutate("/tasks");
  mutate(`/tasks?emailId=${emailId}`);
  return res;
};

export const saveTask = async (
  task: Partial<Task>,
  emailId?: string | null
) => {
  mutate(
    "/tasks",
    (tasks) => tasks?.map((t) => (t.id === task.id ? task : t)),
    false
  );
  const res = await fetcher.opportunity.update(`/tasks`, {
    description: task.description,
    id: task.id,
    isDone: task.isDone,
    isTimeSet: task.isTimeSet,
    title: task.title,
    taskDate: task.taskStartDateTime,
    parentTaskType: "task",
    subTaskType: task.subTaskType ?? "genericTask",
  } as Partial<Task>);
  mutate(`/tasks/${task.id}`);
  mutate(`/tasks`);
  mutate(`/tasks?emailId=${emailId}`);
  return res;
};

export const createTask = async (
  task: Partial<Task>,
  emailId?: string | null
) => {
  const res = await fetcher.opportunity.update(`/tasks`, {
    ...task,
    parentTaskType: "task",
    subTaskType: "genericTask",
  });
  mutate(`/tasks`);
  mutate(`/tasks?emailId=${emailId}`);
  return res;
};
