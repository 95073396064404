import { useEffect, useRef, useState } from "react";
import { ManageAccordion } from "../ManageAccordion.component";
import { Box, Loading, LocalizedDate, Stack, Text } from "components";
import {
  Opportunity,
  Appointment,
  useOpportunityAppointments,
} from "common/api";
import AppointmentDetails from "./AppointmentDetails.component";
import { parseHashRoute, useLocale } from "common";
import { useLocation } from "react-router";
import { t } from "i18n";
import AppointmentQuestionnaire from "./AppointmentQuestionnaire.component";
import { getAppointmentStatus } from "features/appointments/AppointmentStatus";

import Select, { Option } from "@ingka/select";
import { format } from "date-fns";
import { getAppointmentService } from "features/appointments/AppointmentService";
import { groupAppointments, isActiveStatus } from "./utils";
import { AppointmentActions } from "./AppointmentActions.component";
import InlineMessage from "@ingka/inline-message";

interface Props {
  opportunityFromApi: Opportunity;
  disableEditing: boolean;
}

export const AppointmentInfo = ({
  opportunityFromApi,
  disableEditing,
}: Props) => {
  const { data: appointments, isValidating } = useOpportunityAppointments(
    opportunityFromApi.id
  );

  const referenceDiv = useRef<HTMLDivElement | null>(null);
  const params = parseHashRoute(useLocation().hash);
  const selected = params.section === "appointment";
  const defaultSelectedAppointment = appointments?.find(
    (appointment) => String(appointment.id) === params.appointmentId
  );

  useEffect(() => {
    setTimeout(() => {
      selected &&
        referenceDiv?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "center",
        });
    }, 100);
  }, [selected]);

  return (
    <>
      <div ref={referenceDiv} />
      {appointments !== undefined && appointments.length > 0 && (
        <ManageAccordion
          title={
            appointments.length > 1
              ? `${t("appointment.appointments.label")} (${
                  appointments.length
                })`
              : `${t("appointment.appointment.label")}`
          }
          open={true}
        >
          <div ref={referenceDiv} />

          <AppointmentSection
            appointments={appointments}
            currencySymbol={opportunityFromApi.currency}
            defaultSelectedAppointment={defaultSelectedAppointment}
            isLoading={isValidating}
            disableEditing={disableEditing}
          />
        </ManageAccordion>
      )}
    </>
  );
};

interface AppointmentSectionProps {
  appointments: Appointment[];
  currencySymbol?: string;
  defaultSelectedAppointment?: Appointment;
  isLoading?: boolean;
  disableEditing: boolean;
}

const AppointmentSection = ({
  appointments,
  currencySymbol,
  defaultSelectedAppointment,
  isLoading,
  disableEditing,
}: AppointmentSectionProps) => {
  const locale = useLocale();
  const appointmentsGroup = groupAppointments(appointments ?? []);
  const [currentAppointmentId, setCurrentAppointmentId] = useState<number>(
    defaultSelectedAppointment?.id ??
      appointmentsGroup.upcoming[0]?.id ??
      appointmentsGroup.completed[0]?.id
  );
  const currentAppointment = appointments.find(
    (a) => a.id === currentAppointmentId
  );

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (!currentAppointment) {
    return null;
  }

  return (
    <Stack vertical spacing={20}>
      <Select
        hintText={t("appointment.select-an-appointment.label")}
        id="appointments"
        label=""
        onChange={(event) => {
          let newAppointment = appointments.find(
            (appointment) => String(appointment.id) === event.target.value
          );

          if (newAppointment) {
            setCurrentAppointmentId(newAppointment.id);
          }
        }}
        value={currentAppointment.id.toString()}
      >
        {[...appointmentsGroup.upcoming, ...appointmentsGroup.completed].map(
          (appointment) => (
            <Option
              name={`${format(new Date(appointment.timeWindowStartTime), "P", {
                locale: locale.dateFnsLocale,
              })} | ${format(new Date(appointment.timeWindowStartTime), "p", {
                locale: locale.dateFnsLocale,
              })} - ${format(new Date(appointment.timeWindowEndTime), "p", {
                locale: locale.dateFnsLocale,
              })} | ${getAppointmentService(
                appointment.service
              )} (${getAppointmentStatus(appointment.appointmentStatus)})`}
              key={appointment.id}
              value={String(appointment.id)}
            />
          )
        )}
      </Select>

      {isActiveStatus(currentAppointment) &&
        !currentAppointment.assignedCoworker && (
          <InlineMessage
            title={t("appointment.unassigned.warning")}
            body={t("appointment.unassigned.message")}
            variant="cautionary"
          />
        )}

      <Stack spacing={0}>
        <Box alignItems="start" maxWidth="800px">
          <Stack vertical spacing={20} padding={[0, 20, 0, 0]}>
            <Stack vertical spacing={20}>
              <AppointmentDetails
                currentAppointment={currentAppointment}
                currency={currencySymbol}
                disableEditing={disableEditing}
              />
              <AppointmentActions
                appointment={currentAppointment}
                disabled={disableEditing}
              />
            </Stack>
          </Stack>
        </Box>

        <Box
          alignItems="start"
          border={[0, 0, 0, 1]}
          borderColor="neutralGrey200"
          minWidth="350px"
        >
          <Stack vertical padding={[0, 0, 0, 20]} spacing={30}>
            <AppointmentQuestionnaire
              questionnaire={currentAppointment.additionalInformation}
              languageCode={currentAppointment.languageCode}
            />

            {currentAppointment.comments &&
              currentAppointment.comments.length > 0 && (
                <Stack vertical>
                  <Text header="h3">{t("appointment.comments.label")}</Text>
                  {currentAppointment.comments.map((comment, index) => (
                    <Box
                      justifyContent="flex-start"
                      key={`manage-appt-comment-${index}`}
                    >
                      <Stack>
                        <Text small color="neutralGrey500">
                          <LocalizedDate>{comment.createdAt}</LocalizedDate>
                        </Text>
                        <Text small>{comment.comment}</Text>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
