import { History } from "common/api";
import { IconNames } from "components";
import { ActivityType, ActivitySubType } from "./activity.type";

/**
 * Icons to show in the time line
 */
export const timelineActivityIcons: Record<number, IconNames> = {
  2: "CancelIcon",
  3: "AssignedIcon",
  4: "CheckmarkCircle",
  5: "ChatIcon",
  // AUTOMATIC_EMAIL_FOLLOWUP: 6,
  // AUTOMATIC_SMS_FOLLOWUP: 7,
  8: "CheckmarkCircle",
  // UNBLOCKED: 9,
  10: "CancelIcon",
  11: "CancelIcon",
  12: "BlockedIcon",
  // LINKED_ORDER_UPDATED: 13,
  13: "CancelIcon",
  14: "CheckmarkCircle",
  15: "BlockedIcon",
  16: "BlockedIcon",
  17: "BlockedIcon",
  // NEW_OPP_ORDER_CREATED: 18,
  19: "CheckmarkCircle",
  // No_MATCH_FOUND: 20,
  21: "CalendarIcon",
  24: "CalendarIcon",
};

/**
 * Icons for activities that show up in reminders
 */
const reminderActivityIcons: Record<ActivityType, IconNames> = {
  Assigned: "AssignedIcon",
  Changed: "DocumentPencil",
  Closed: "CancelIcon",
  Communication: "NoteIcon",
  Created: "StarIcon",
  File: "DocumentPencil",
  Interaction: "CustomerServiceIcon",
  LinkedOrder: "ShoppinglistIcon",
  Order: "ShoppinglistIcon",
  Other: "DocumentPencil",
  Reminder: "AlertIcon",
  Schedule: "CalendarIcon",
  Planner: "LaptopPencilIcon",
};

const reminderActivitySubTypeIcons: Partial<
  Record<ActivitySubType, IconNames>
> = {
  ClosedWon: "CheckmarkCircle",
  Emailed: "EmailIcon",
  Meeting: "ClockIcon",
  Uploaded: "DocumentUploadIcon",
  Downloaded: "Document",
  ExpectedPurchase: "CalendarIcon",
  Task: "BellIcon",
  Call: "PhoneIcon",
};

const historyIcons: Record<History["entryType"], IconNames> = {
  GENERIC: "BellIcon",
  REMARK_UPDATE: "DocumentPencil",
  COMMUNICATION_SENT: "EmailIcon",
  COMMUNICATION_FAILED: "NotificationIcon",
  STATUS_UPDATE_ASSIGNED: "AssignedIcon",
};

export const getReminderIconByActivityType = (
  activityType: ActivityType,
  activitySubType: ActivitySubType
): IconNames => {
  return (
    reminderActivitySubTypeIcons[activitySubType] ??
    reminderActivityIcons[activityType] ??
    "NoteIcon"
  );
};

export const getHistoryIcon = (historyType: History["entryType"]) => {
  return historyIcons[historyType] ?? "NoteIcon";
};
