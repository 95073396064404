import { createContext, Dispatch } from "react";
import { ModalHeader, ModalFooter } from "@ingka/modal";

type ContextProps = {
  header: ReturnType<typeof ModalHeader>;
  footer: ReturnType<typeof ModalFooter>;
  setHeader: Dispatch<ReturnType<typeof ModalHeader>>;
  setFooter: Dispatch<ReturnType<typeof ModalFooter>>;
};

export const ModalContext = createContext<ContextProps>(null!);
