import { Text } from "components";
import { Icon } from "../../components/ui/Icon.component";
import { Stack } from "../../components/layout/Stack.component";
import { t } from "i18n";
import { Opportunity } from "common/api";
import { TranslationKeys } from "i18n/translationKeys";
import { Color } from "components/Variables";

const classificationTranslationKeys: Record<
  Opportunity["classification"],
  TranslationKeys
> = {
  None: "classification.class-type.none",
  Low: "classification.class-type.low",
  Medium: "classification.class-type.medium",
  High: "classification.class-type.high",
  Urgent: "classification.class-type.urgent",
};

const classificationNumberToColor: Record<number, Color> = {
  0: "classNone",
  1: "classLow",
  2: "classMedium",
  3: "classHigh",
  4: "classUrgent",
} as const;

const numberToClassification: Record<number, Opportunity["classification"]> = {
  0: "None",
  1: "Low",
  2: "Medium",
  3: "High",
  4: "Urgent",
} as const;

const classificationToColor: Record<Opportunity["classification"], Color> = {
  None: "classNone",
  Low: "classLow",
  Medium: "classMedium",
  High: "classHigh",
  Urgent: "classUrgent",
};

interface IClassificationProps {
  children: Opportunity["classification"];
  small?: boolean;
}

/**
 * Convert the API string response to an icon and text
 * representing the classification of an opportunity
 */
export const Classification = ({
  children: theClass,
  small = false,
}: IClassificationProps) => {
  return (
    <Stack alignItems="baseline" spacing={small ? 6 : 9}>
      {(typeof theClass === "string" && theClass !== "Urgent") ||
      (typeof theClass === "number" && theClass !== 4) ? (
        <Icon
          size={small ? 10 : 13}
          icon="Circle"
          color={
            typeof theClass === "string"
              ? classificationToColor[theClass]
              : classificationNumberToColor[theClass]
          }
        />
      ) : null}
      {(typeof theClass === "string" && theClass === "Urgent") ||
      (typeof theClass === "number" && theClass === 4) ? (
        <Icon
          size={small ? 12 : 13}
          color="classUrgent"
          icon="UrgentAlertIcon"
        />
      ) : null}
      <Text small={small}>
        {typeof theClass === "string"
          ? t(classificationTranslationKeys[theClass])
          : t(classificationTranslationKeys[numberToClassification[theClass]])}
      </Text>
    </Stack>
  );
};

Classification.displayName = "Classification";
