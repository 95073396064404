import { Box, Icon, Text } from "components";
import { T } from "i18n";

export const DoNotContact = () => {
  return (
    <Box>
      <Box padding={[0, 5, 0, 0]}>
        <Icon icon="CrossCircle" size={20} color={"classUrgent"} />
      </Box>
      <Text>
        <T id="manage-opportunity.header.do-not-contact" />
      </Text>
    </Box>
  );
};
