import { useMemo, useState } from "react";
import { Line, Loading, Text } from "components";
import { Stack } from "components";
import {
  Opportunity as opportunityInterface,
  OpportunityPlanner,
  PlannerDetail,
  useGetDesignInfo,
} from "common/api";
import { ManageAccordion } from "../ManageAccordion.component";
import { AddedPlanner } from "./AddedPlanner.component";
import { t } from "i18n";
import { css, cx } from "@emotion/css";
import { PlannerModal } from "./PlannerModal.component";
import { PlannerSearch } from "./PlannerSearch.component";
import { useUserLocation } from "common/api/user/userLocation";

interface Props {
  opportunity: opportunityInterface;
  customerEmail?: string;
  disableEditing: boolean;
}

export type Planner = PlannerDetail | OpportunityPlanner;

export type PlannerData = {
  designId: string;
  designUrl?: string;
  designUrlEdit?: string;
  designUrlView?: string;
  thumbnailUrl?: string;
  designName?: string;
  createdDate?: string;
  plannerType?: string;
  lastModifiedDate?: string;
  statusDescription?: string;
  totalprice?: string;
  currencySymbol?: string;
};

const containeStyle = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
`;

export const NewPlannerDrawing = ({
  customerEmail,
  opportunity,
  disableEditing,
}: Props) => {
  const countryCode = useUserLocation();
  const addedVPCPlanners = opportunity.planners?.plannersInfo ?? [];
  const designIds = useMemo(
    () => opportunity.planners?.designsInfo ?? [],
    [opportunity.planners]
  );
  const [showPlanner, setShowPlanner] = useState<boolean>(false);
  const [selectedPlanner, setSelectedPlanner] = useState<
    PlannerDetail | OpportunityPlanner
  >();
  const { data: addedPlanners = [], isValidating } = useGetDesignInfo(
    designIds,
    countryCode || "SE"
  );

  const addedPlannersCount = addedVPCPlanners?.length + designIds.length;

  const handlePlannerClick = (planner) => {
    setSelectedPlanner(planner);
    setShowPlanner(true);
  };

  const closePlannerModal = () => setShowPlanner(false);
  const openPlannerModal = () => setShowPlanner(true);

  return (
    <ManageAccordion
      id={"planner drawing"}
      title={
        addedPlannersCount > 0
          ? t("manage-opportunity.planner-drawing.planners-title", {
              count: addedPlannersCount,
            })
          : t("manage-opportunity.planner-drawing.title")
      }
      open={true}
    >
      <Stack vertical spacing={30} maxWidth="100%">
        {addedPlannersCount > 0 && (
          <Stack vertical spacing={30}>
            <Text header="h3">{t("planner.associated-drawings.label")}</Text>

            <div>
              {isValidating ? (
                <Loading
                  isLoading={isValidating}
                  text={t("planner.searching.text")}
                />
              ) : (
                <div className={cx(containeStyle)}>
                  {[...addedVPCPlanners, ...addedPlanners].map(
                    (planner, index) => (
                      <AddedPlanner
                        key={index}
                        openPlannerModal={openPlannerModal}
                        setSelectedPlanner={setSelectedPlanner}
                        planner={planner}
                        currency={opportunity.currency}
                      />
                    )
                  )}
                </div>
              )}
            </div>
            <Line />
          </Stack>
        )}

        <PlannerSearch
          customerEmail={customerEmail}
          handlePlannerClick={handlePlannerClick}
          currencySymbol={opportunity.currencySymbol}
          addedPlanners={addedPlanners}
          addedVPCPlanners={addedVPCPlanners}
        />

        {selectedPlanner && (
          <PlannerModal
            opportunity={opportunity}
            closePlannerModal={closePlannerModal}
            showPlanner={showPlanner}
            addedPlanners={addedPlanners}
            addedVPCPlanners={addedVPCPlanners}
            selectedPlanner={selectedPlanner}
            disableEditing={disableEditing}
          />
        )}
      </Stack>
    </ManageAccordion>
  );
};

NewPlannerDrawing.displayName = "NewPlannerDrawing";
