import { useField } from "formik";
import SkapaCheckBox, { CheckboxLabel } from "@ingka/checkbox";

interface Props {
  name: string;
  label: CheckboxLabel;
  value?: string | number;
  subtle?: boolean;
}

export const Checkbox = ({ name, label, value, subtle }: Props) => {
  const [field, , helpers] = useField({ name, type: "checkbox" });

  if (Array.isArray(field.value)) {
    if (!value)
      throw new Error("Value is required when checkbox is in a group");

    const checked = Boolean(field.value && field.value.includes(value));

    return (
      <SkapaCheckBox
        subtle={subtle}
        id={name + value}
        label={label}
        checked={checked}
        onChange={() => {
          if (checked) {
            helpers.setValue(field.value.filter((v) => v !== value));
          } else {
            helpers.setValue([...field.value, value]);
          }
        }}
      />
    );
  }

  return (
    <SkapaCheckBox
      subtle
      id={name}
      label={label}
      name={field.name}
      checked={field.checked}
      onBlur={field.onBlur}
      onChange={field.onChange}
      value={field.value}
    />
  );
};
