import isSameDay from "date-fns/isSameDay";
import format from "date-fns/format";

import { LocalizedDate } from "./LocalizedDate.component";
import { useLocale } from "common";

interface Props {
  fromDate?: string;
  toDate?: string;
}

export const LocalizedDateRange = ({ fromDate, toDate }: Props) => {
  const locale = useLocale();

  let left = fromDate ? new Date(fromDate) : undefined;
  let right = toDate ? new Date(toDate) : undefined;

  if (left && right) {
    // Same day => Show date and time
    if (isSameDay(left, right)) {
      const formattedFromTime = format(left, "p", {
        locale: locale.dateFnsLocale,
      });
      const formattedToTime = format(right, "p", {
        locale: locale.dateFnsLocale,
      });

      return (
        <>
          <LocalizedDate>{fromDate!}</LocalizedDate>{" "}
          {`${formattedFromTime} - ${formattedToTime}`}
        </>
      );
    }

    // Different days => Show date range
    return (
      <>
        <LocalizedDate>{fromDate!}</LocalizedDate>
        {" - "}
        <LocalizedDate>{toDate!}</LocalizedDate>
      </>
    );
  }

  // Only one date is defined => Show date
  return <LocalizedDate>{fromDate || toDate || ""}</LocalizedDate>;
};

LocalizedDate.displayName = "LocalizedDate";
