import { User } from "@microsoft/microsoft-graph-types";
import { FieldInputProps } from "formik";
import { InputField as PureInputField } from "./InputField.component";

export type Item = { value: string; text: string; user: User };

type Props = {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  list: Item[];
  errorMessage?: string;
  onSelect?: (item: Item) => void;
  onReset?: () => void;
  disabled?: boolean;
} & FieldInputProps<string>;

export const InputDatalist = ({
  name,
  type,
  label,
  placeholder,
  errorMessage,
  list,
  onBlur,
  onChange,
  onSelect,
  onReset,
  value,
  disabled,
}: Props) => {
  const onInputChange = (e) => {
    onChange(e);
    const newValue = e.target?.value;
    const selectedItem = list.find(({ text }) => text === newValue);
    if (selectedItem && onSelect) onSelect(selectedItem);
  };

  return (
    <>
      <PureInputField
        value={value}
        type={type}
        label={label}
        placeholder={placeholder}
        listId={`${name}-dataset`}
        errorMessage={errorMessage}
        name={name}
        onBlur={onBlur}
        onChange={onInputChange}
        onReset={onReset}
        disabled={disabled}
      />
      <datalist id={`${name}-dataset`}>
        {list.map(({ text }) => (
          <option key={text} value={text} />
        ))}
      </datalist>
    </>
  );
};
