import { useEffect, useRef } from "react";
import { Grid, TimePicker } from "components";
import { Box, DatePicker, Stack, TextAreaFixed, InputField } from "components";
import { Task } from "common/api";
import { t } from "i18n";
import { getHours, getMinutes, setHours, setMinutes } from "date-fns";

interface ITaskFormProps {
  task?: Partial<Task>;
  setTask: (task: Partial<Task> | undefined) => void;
}

export const TaskForm: React.FunctionComponent<ITaskFormProps> = ({
  setTask,
  task = {
    description: "",
    isDone: false,
    isTimeSet: false,
    taskStartDateTime: undefined,
    title: "",
  },
}) => {
  const referenceDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    referenceDiv?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }, []);
  return (
    <Box width="100%">
      <Stack vertical spacing={32}>
        <div ref={referenceDiv} />
        <InputField
          type="text"
          label={t("my-opportunities.view-my-tasks.title.label")}
          placeholder={t("my-opportunities.view-my-tasks.title.placeholder")}
          name={`title-${task.id || ""}`}
          defaultValue={task.title}
          maxLength={200}
          onChange={(event) => setTask({ ...task, title: event.target.value })}
        />
        <TextAreaFixed
          id={`description-${task.id || ""}`}
          value={task.description}
          label={t("my-opportunities.view-my-tasks.task-description.label")}
          placeholder={t(
            "my-opportunities.view-my-tasks.task-description.placeholder"
          )}
          optional
          maxLength={500}
          onChange={(event) =>
            setTask({ ...task, description: event.target.value })
          }
        />
        <Grid columns={2} spacing={24}>
          <DatePicker
            id="dateSelect"
            placeholder={t("my-opportunities.view-my-tasks.date.placeholder")}
            label={t("my-opportunities.view-my-tasks.date.label")}
            isClearable
            minDate={new Date()}
            value={
              task.taskStartDateTime
                ? new Date(task.taskStartDateTime)
                : undefined
            }
            onChange={(date) =>
              setTask({
                ...task,
                taskStartDateTime: date ? date.toISOString() : undefined,
              })
            }
          />
          {task.taskStartDateTime && (
            <TimePicker
              onChange={(time) => {
                const date = new Date(task.taskStartDateTime!);
                const hours = time ? getHours(time) : 0;
                const minutes = time ? getMinutes(time) : 0;
                const taskStartDateTime =
                  date &&
                  setHours(setMinutes(date, minutes), hours).toISOString();

                setTask({ ...task, taskStartDateTime, isTimeSet: !!time });
              }}
              label={t("my-opportunities.view-my-tasks.time.label")}
              placeholder={t("my-opportunities.view-my-tasks.no-time.label")}
              value={
                task.isTimeSet ? new Date(task.taskStartDateTime) : undefined
              }
              date={new Date(task.taskStartDateTime)}
            />
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

TaskForm.displayName = "TaskForm";
