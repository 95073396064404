import { css } from "@emotion/css";
import { OpportunityPlanner, PlannerDetail } from "common/api";
import { Box, Icon, LocalizedDate, Stack, Text } from "components";
import { InlineLabeledText } from "components/ui/InlineLabeledText.component";
import { t } from "i18n";
import {
  formatPrice,
  getPlannerData,
  getPlannerStatus,
  Planner,
  plannerIsAdded,
} from "./utils";

interface CardProps {
  onClick: (planner: any) => void;
  planner: Planner | undefined;
  addedPlanners: PlannerDetail[];
  addedVPCPlanners: OpportunityPlanner[];
  currencySymbol?: string;
}

export const PlannerSearchCard = ({
  onClick,
  planner,
  addedPlanners,
  addedVPCPlanners,
  currencySymbol,
}: CardProps) => {
  const data = getPlannerData(planner);
  if (!data) {
    return null;
  }
  return (
    <Box
      vertical
      width="220px"
      alignItems="start"
      onClick={() => onClick(planner)}
      key={data.designId}
    >
      <Box position="relative">
        {plannerIsAdded(planner, addedPlanners, addedVPCPlanners) && (
          <Box position="absolute" right="15px" top="90px">
            <Icon icon={"CheckmarkCircle"} color="greenIdle" />
          </Box>
        )}
        <img
          src={data.thumbnailUrl}
          alt={
            data.thumbnailUrl ? "Planner image" : "Planner image not available"
          }
          className={css`
            object-fit: cover;
            width: 200px;
            height: 125px;
            ${!data.thumbnailUrl && "border: 1px solid grey;"}
            &:hover {
              opacity: 0.3;
              transition: all 0.2s ease-in-out;
            }
          `}
        />
      </Box>
      <Stack vertical spacing={0} padding={[10, 0]}>
        <Text bold wordBreakWord>
          {data.designName}
        </Text>
        <Text xsmall color="neutralGrey500">
          {data.designId}
        </Text>

        {data.createdDate && (
          <InlineLabeledText
            small
            notBold
            label={`${t("opportunities.label.created")}:`}
          >
            <Text small color="neutralGrey500">
              <LocalizedDate pattern="Pp">{data.createdDate}</LocalizedDate>
            </Text>
          </InlineLabeledText>
        )}

        {data.lastModifiedDate && (
          <InlineLabeledText
            small
            notBold
            label={`${t(
              "manage-opportunity.opportunity-info.last-modified.label"
            )}:`}
          >
            <Text small color="neutralGrey500">
              <LocalizedDate pattern="Pp">
                {data.lastModifiedDate}
              </LocalizedDate>
            </Text>
          </InlineLabeledText>
        )}

        {getPlannerStatus(data) && (
          <InlineLabeledText
            small
            notBold
            label={`${t("opportunities.label.status")}:`}
          >
            <Text small color="neutralGrey500">
              {getPlannerStatus(data)}
            </Text>
          </InlineLabeledText>
        )}

        {data.totalprice && (
          <InlineLabeledText
            small
            notBold
            label={`${t("planner.price.label")}:`}
          >
            <Text small color="neutralGrey500">
              {formatPrice(
                data.totalprice,
                data.currencySymbol ?? currencySymbol
              )}
            </Text>
          </InlineLabeledText>
        )}
      </Stack>
    </Box>
  );
};
