import { ReactNode, useContext, useState } from "react";
import Modal, { Theatre } from "@ingka/modal";
import { Stack } from "components/layout";
import { Text } from "components/typography";
import { FollowUpConsent } from "./FollowUpConsent";
import { Button } from "components";
import { css } from "@emotion/css";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { PrivacyPolicyConsent } from "./PrivacyPolicyConsent";
import { OpportunityContext } from "../../OpportunityContext";
import { t } from "i18n";
import ReactDOM from "react-dom";
import { useConfig } from "common/api";

interface Props {
  showModal: boolean;
  onClose: () => void;
}

export const ConsentModal = ({ showModal, onClose }: Props) => {
  const [followUpConsent, setFollowUpConsent] = useState<boolean | undefined>(
    undefined
  );
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean | undefined>(
    undefined
  );

  const { isExistingCustomer, setConsents } = useContext(OpportunityContext);
  const { data: config } = useConfig();
  const requireFollowUpConsent = !!config?.customer?.requireFollowUpConsent;
  const requirePrivacyPolicy = !!config?.customer?.requirePrivacyPolicy;

  const onButtonSubmit = () => {
    setConsents({
      followUpConsent: followUpConsent!,
      privacyPolicy: privacyPolicy === undefined ? true : privacyPolicy,
    });
    onClose();
  };

  const onFollowUpSelectionChange = (isApproved: boolean) => {
    setFollowUpConsent(isApproved);
  };

  const tabs: ReactNode[] = [];

  const tabPanels: ReactNode[] = [];

  if (requireFollowUpConsent) {
    tabs.unshift(
      <Tab
        key="followUp-tab"
        text={t("new-opportunity.customer-consent.follow-up.title.short")}
        tabPanelId="followUp"
        type="button"
      />
    );

    tabPanels.unshift(
      <TabPanel key="followUp-panel" tabPanelId="followUp">
        <FollowUpConsent onSelectionChange={onFollowUpSelectionChange} />
      </TabPanel>
    );
  }

  if (!isExistingCustomer && requirePrivacyPolicy) {
    tabs.push(
      <Tab
        key="privacyPolicy-tab"
        text={t("new-opportunity.customer-consent.privacy-policy.title")}
        tabPanelId="privacyPolicy"
        type="button"
      />
    );
    tabPanels.push(
      <TabPanel key="privacyPolicy-panel" tabPanelId="privacyPolicy">
        <PrivacyPolicyConsent onSelectionChange={setPrivacyPolicy} />
      </TabPanel>
    );
  }

  return ReactDOM.createPortal(
    <Modal
      visible={showModal}
      handleCloseBtn={onClose}
      className={css`
        &.modal-wrapper {
          visibility: ${showModal ? "visible" : "hidden"};
          /* Hide extra backdrop so that it does not cover previously opened modal sheets */
          .modal-wrapper__backdrop {
            opacity: 0;
          }
        }
      `}
    >
      <Theatre open={showModal} closeBtnClick={onClose}>
        <Stack vertical padding={[0, 60, 0, 80]} spacing={5}>
          <Text header="h1">{t("new-opportunity.customer-consent.title")}</Text>
          <Tabs
            defaultActiveTab={
              requireFollowUpConsent ? "followUp" : "privacyPolicy"
            }
            tabs={tabs}
            tabPanels={tabPanels}
          />
        </Stack>
        <Stack justifyContent="flex-end" padding={[0, 80, 10]}>
          <Button
            text={t("new-opportunity.modal.submit")}
            emphasised
            disabled={
              (!isExistingCustomer &&
                requirePrivacyPolicy &&
                privacyPolicy === undefined) ||
              (requireFollowUpConsent && followUpConsent === undefined)
            }
            onClick={onButtonSubmit}
          />
        </Stack>
      </Theatre>
    </Modal>,
    document.getElementById("modal-root")!
  );
};
