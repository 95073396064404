import {
  isOpportunityPlanner,
  isPlannerDrawing,
  isVPC,
} from "features/manage-opportunity/components/Planner/utils";
import useSWR, { mutate } from "swr";
import * as fetcher from "../fetcher";
import { DesignsInfo, OpportunityPlanner } from "./opportunity";

interface SalesPrice {
  currency: string;
  value: number;
}

interface ImageVariant {
  quality: string;
  height: number;
  width: number;
  href: string;
}

export interface Item {
  itemNo: string;
  itemType: "ART" | "GRP";
  quantity: number;
  childItems: Item[];
  salesPrice?: SalesPrice;
  itemName: string;
  imageVariants: ImageVariant[];
}

export interface VPCPlanner {
  designImage?: string;
  countryCode?: string;
  amount?: number;
  plannerId: string;
  plannerType: string;
  updatedTime: string;
  items: Item[];
}

export interface PlannerDetail {
  orderNumber: string;
  plannerType: string;
  designName: string;
  designId: string;
  createdDate: string;
  lastModifiedDate: string;
  thumbnailUrl: string;
  designUrl: string;
  designUrlEdit?: string;
  designUrlView?: string;
  statusCode: string;
  statusDescription: string;
  totalprice: string;
  currencySymbol: string;
  statusCodeTranslated: [];
}

export interface PlannerListItem {
  name: string;
  advdesigns: string;
  designList: PlannerDetail[];
  error?: null;
}

export type DesignSearchResponse = {
  plannerList: PlannerListItem[];
};

export function usePlanner(opportunityId?: number, plannerId?: string) {
  return useSWR<OpportunityPlanner>(
    opportunityId && plannerId
      ? `/${opportunityId}/planner/${plannerId}`
      : null,
    (url) => fetcher.opportunity.get(url),
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );
}

export function useDesignSearch(
  market: string,
  language: string,
  searchValue?: string
) {
  return useSWR<DesignSearchResponse>(
    searchValue ? `/search/${searchValue}` : null,
    async () => {
      const res = (await fetcher.designSearch.post("/search", {
        searchType: searchValue?.includes("@") ? "userName" : "designId",
        searchValue,
      })) as DesignSearchResponse;

      const planners = res?.plannerList ?? [];

      return {
        plannerList: planners.filter((planner) => planner.error === null),
      };
    },
    {
      revalidateOnFocus: true,
      refreshInterval: 60 * 1000,
    }
  );
}

export function useVPCSearch(searchValue?: string, countryLocale?: string) {
  return useSWR<OpportunityPlanner>(
    searchValue ? `/search/${searchValue}` : null,
    () =>
      fetcher.VPCSearch.post("/search", {
        searchType: "designId",
        searchValue,
        countryLocale,
      }),
    {
      revalidateOnFocus: true,
      refreshInterval: 60 * 1000,
    }
  );
}

export const associateDrawing = async (
  opportunityId: number,
  drawingId: string,
  drawingObject?: PlannerDetail | OpportunityPlanner
) => {
  const res = await fetcher.opportunity.update(
    `/${opportunityId}/planner${
      isVPC(drawingId) ? "" : "/associate"
    }/${drawingId}`,
    isVPC(drawingId) && drawingObject ? drawingObject : {}
  );

  if (res.status < 300) {
    mutate(`/opportunities/${opportunityId}`, (opportunity) => {
      const oldDesigns = opportunity.planners?.designs ?? [];
      const oldPlannersInfo = opportunity.planners?.plannersInfo ?? [];

      const newPlanners = {
        designs: isPlannerDrawing(drawingObject)
          ? [...oldDesigns, drawingObject]
          : oldDesigns,

        plannersInfo: isOpportunityPlanner(drawingObject)
          ? [...oldPlannersInfo, drawingObject]
          : oldPlannersInfo,
      };
      return { ...opportunity, planners: newPlanners };
    });

    mutate(`/opportunities/${opportunityId}/timelog`);
    mutate(`/opportunities/${opportunityId}/history`);
  }
  return res;
};

export const disassociateDrawing = async (
  opportunityId: number,
  drawingId: string
) => {
  const res = await fetcher.opportunity.remove(
    `/${opportunityId}/planner${
      isVPC(drawingId) ? "" : "/associate"
    }/${drawingId}`
  );

  if (res.status < 300) {
    mutate(`/opportunities/${opportunityId}`, (opportunity) => {
      let newPlanners = {
        designs: opportunity.planners?.designs ?? [],
        plannersInfo: opportunity.planners?.plannersInfo ?? [],
      };

      if (isVPC(drawingId)) {
        newPlanners.plannersInfo = newPlanners.plannersInfo.filter(
          (planner) => planner.plannerId !== drawingId
        );
      } else {
        newPlanners.designs = newPlanners.designs.filter(
          (planner) => planner.designId !== drawingId
        );
      }
      return { ...opportunity, planners: newPlanners };
    });
    mutate(`/opportunities/${opportunityId}/timelog`);
  }
  return res;
};

export const searchDesign = async (
  searchValue: string,
  market: string,
  language: string
) => {
  const res = (await fetcher.designSearch.post("/search", {
    searchType: searchValue?.includes("@") ? "userName" : "designId",
    searchValue,
    countryCode: market.toUpperCase(),
    languageCode: language,
  })) as DesignSearchResponse;

  const planners = res?.plannerList ?? [];

  return {
    plannerList: planners.filter((planner) => planner.error === null),
  } as DesignSearchResponse;
};

export const searchVPC = async (
  searchValue: string,
  market: string,
  language: string
) => {
  const res = await fetcher.VPCSearch.post<OpportunityPlanner>("/search", {
    searchType: "vpc",
    searchValue,
    countryCode: market.toUpperCase(),
    languageCode: language,
  });
  return res;
};

export const deletePlannerDrawing = async (
  opportunityId?: number,
  plannerId?: string
) => {
  const res = await fetcher.opportunity.remove(
    `/${opportunityId}/planner/${plannerId}`
  );
  mutate(`/opportunities/${opportunityId}`);
  mutate(`/opportunities/${opportunityId}/timelog`);
  mutate(`/opportunities/${opportunityId}/history`);
  return res;
};

export function useGetDesignInfo(designIds: DesignsInfo[], market?: string) {
  return useSWR<PlannerDetail[]>(
    designIds && market
      ? `/design-planner/search/${designIds
          .map((d) => d.designId)
          .join(",")}/${market}`
      : null,
    async () => {
      const fetchedDesignInfos: PlannerDetail[] = [];

      for (const design of designIds) {
        const res = await fetcher.global.post<DesignSearchResponse>(
          "/design-planner/search",
          {
            searchType: "designId",
            searchValue: design.designId,
            countryCode: market,
            languageCode: design.languageCode,
          }
        );
        if (res) {
          const planners = res.plannerList
            .filter((planner) => planner.error === null)
            .flatMap((planner) => planner.designList.map((item) => item));
          fetchedDesignInfos.push(planners[0]);
        }
      }

      return fetchedDesignInfos;
    },
    {
      revalidateOnFocus: true,
      refreshInterval: 60 * 1000,
    }
  );
}
