import { Box, PopoverMenu, PopoverItem, Stack, Text, Icon } from "components";
import { Note } from "common/api";
import { t } from "i18n";

interface INoteItemProps {
  note: Note;
  odd: boolean;
  onEdit(note: Note): void;
  onDelete(id: number): Promise<any>;
}

export const NoteItem: React.FunctionComponent<INoteItemProps> = ({
  note,
  onDelete,
  onEdit,
  odd,
}) => {
  return (
    <Box padding={20} color={odd ? "bjornGrey" : "neutralWhite"}>
      <Stack vertical>
        <Stack justifyContent="space-between">
          <Stack spacing={6}>
            <Icon icon={"NoteIcon"} />
            <Text bold wordBreakAll>
              {note.title}
            </Text>
          </Stack>
          <PopoverMenu>
            <PopoverItem
              text={t("my-opportunities.view-my-notes.edit.button")}
              onClick={() => onEdit(note)}
            />
            <PopoverItem
              text={t("my-opportunities.view-my-notes.delete.button")}
              onClick={() => onDelete(note.id)}
            />
          </PopoverMenu>
        </Stack>
        <Text small pre maxCharacters={80} wordBreakAll>
          {note.description}
        </Text>
      </Stack>
    </Box>
  );
};

NoteItem.displayName = "NoteItem";
