import useSWR from "swr";
import * as fetcher from "../fetcher";
import qs from "querystring";
import { ApiError } from "../ApiError";
import { useRef } from "react";
import { formatLocalTimeToUTC } from ".";
import { endOfDay } from "date-fns";

export interface Appointment {
  id: number;
  opportunityId: number;
  opportunityTitle: string;
  assignedCoworker: string;
  appointmentStatus: AppointmentStatus;
  bookingTime: number;
  countryCode: string;
  customer: Customer;
  currency?: string;
  currencySymbol?: string;
  languageCode: string;
  locationName: string;
  locationType: AppointmentLocationType;
  orderNumber: string;
  price: number;
  service: string;
  additionalInformation?: QuestionObject[];
  timeWindowStartTime: string;
  timeWindowEndTime: string;
  actualTimeWindowStartTime?: string;
  actualTimeWindowEndTime?: string;
  comments?: Comment[];
  type: number;
  paymentStatus?: AppointmentPaymentStatus;
  videoLinkCoworker?: string;
  videoLinkCustomer?: string;
  appointmentLink?: string;
  questionsInfo?: QuestionsInfo;
}

type Customer = {
  firstName: string;
  lastName: string;
};

export type Comment = {
  createdAt: number;
  comment: string;
};

export interface QuestionObject {
  question: Question;
  answer: Answer;
}

type Question = {
  id: number;
  countryCode: string;
  type: "CHECKBOX" | "RADIO" | "TEXT";
  text: Record<string, string>;
  options: QuestionOption[];
  deleted: boolean;
};

type QuestionOption = {
  id?: number;
  text: Record<string, string>;
};

type Answer = {
  questionId: string;
  answer: string[];
};

export type QuestionsInfo = {
  questionsAvailable: boolean;
  questionsAnswered: boolean;
};

export type AppointmentStatus =
  | "ORDER_RECEIVED"
  | "ORDER_RECEIVED_WAITING_FOR_PAYMENT"
  | "CANCELLED"
  | "CANCELLED_NO_SHOW"
  | "STARTED"
  | "COMPLETED"
  | "PLANNED";

export type AppointmentLocationType =
  | "STORE"
  | "REMOTE"
  | "STORE_REMOTE"
  | "HOME";

export type AppointmentPaymentStatus = "PAID" | "NOT_PAID" | "FREE";

export type AppointmentsSearchBusinessUnitFilter = string;
export type AppointmentsSearchServiceAreaFilter = string;
export type AppointmentsSearchServiceFilter = string;

export type AppointmentsSearchAssignmentFilter = "ASSIGNED" | "UNASSIGNED";

export type AppointmentsSearchFilters = {
  term?: string;
  emailId?: string;
  isMe?: boolean;
  businessUnit?: AppointmentsSearchBusinessUnitFilter[];
  locationType?: AppointmentLocationType[];
  serviceArea?: AppointmentsSearchServiceAreaFilter[];
  service?: AppointmentsSearchServiceFilter[];
  appointmentStatus?: AppointmentStatus[];
  appointmentDate?: [Date | null, Date | null];
  assignment?: string[];
  calendarMode?: boolean;
};

export type AppointmentConfigObject = Record<
  AppointmentsSearchBusinessUnitFilter,
  AppointmentConfigItemObject
>;

export type AppointmentConfigItemObject = Record<
  AppointmentsSearchServiceAreaFilter,
  AppointmentsSearchServiceFilter[]
>;

export type AppointmentConfig = {
  locations: AppointmentsSearchBusinessUnitFilter[];
  serviceAreas: AppointmentsSearchServiceAreaFilter[];
  services: AppointmentsSearchServiceFilter[];
};

export interface AppointmentConfigResponse {
  enabled: AppointmentConfigObject;
  locations: string[];
  services: string[];
}

export type AppointmentsSearchOptions = {
  page: number;
  pageSize: number;
  sortKey: string;
  sortOrder: "asc" | "desc";
};

export type AppointmentsSearchArgs = {
  filters: AppointmentsSearchFilters;
  options?: AppointmentsSearchOptions;
};

export type AppointmentsSearchResponse = {
  content: Appointment[];
  pageable: {
    sort: { sorted: boolean; unsorted: boolean; empty: boolean };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: { sorted: boolean; unsorted: boolean; empty: boolean };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
};

export const useOpportunityAppointments = (opportunityId: number) => {
  return useSWR<Appointment[]>(
    opportunityId ? `/${opportunityId}/appointments` : null,
    (url) => fetcher.opportunity.get(url),
    {
      revalidateOnFocus: true,
      refreshInterval: 60 * 1000,
    }
  );
};

export const useAppointmentsSearch = ({
  filters,
  options,
}: AppointmentsSearchArgs) => {
  const query = stringifySearchQuery(filters, options);
  const cache = useRef<AppointmentsSearchResponse>();
  const { data, ...result } = useSWR<AppointmentsSearchResponse, ApiError>(
    query ? `/opportunities/appointments?${query}` : null,
    (url) => fetcher.core.get(url)
  );

  if (data?.content) {
    cache.current = data;
  }

  return { ...result, data: cache.current };
};

export function useAppointmentConfig() {
  return useSWR<AppointmentConfigResponse, ApiError>(
    `/opportunities/appointment/config`,
    (url) => fetcher.core.get(url),
    {
      revalidateOnFocus: false,
      dedupingInterval: 5 * 60 * 1000, // 5 minute caching
    }
  );
}

function stringifySearchQuery(
  filters?: AppointmentsSearchFilters,
  options?: AppointmentsSearchOptions
): string {
  if (!filters || !options) return "";
  if (filters.calendarMode && !filters.isMe && !filters.emailId) return "";

  const query: Record<string, any> = {
    pageIndex: `${options.page}`,
    pageSize: `${options.pageSize}`,
    sortKey: `${options?.sortKey}`,
    sortOrder: `${options?.sortOrder}`,
  };

  if (filters.term) {
    query.searchTerm = filters.term;
  }

  if (filters.appointmentDate?.[0] || filters.appointmentDate?.[1]) {
    const beginingOfTimeDate = new Date("2000/01/01");
    const formattedFromDate =
      formatLocalTimeToUTC(filters.appointmentDate[0]) ??
      formatLocalTimeToUTC(beginingOfTimeDate);
    const toDate = filters.appointmentDate[1];

    query.appointmentDateFilter =
      formattedFromDate +
      (toDate ? "-" + formatLocalTimeToUTC(endOfDay(toDate)) : "");
  }

  if (filters.businessUnit?.length) {
    query.buFilter = filters.businessUnit.join("||");
  }

  if (filters.locationType?.length) {
    query.locationTypeFilter = filters.locationType.join(",");
  }

  if (filters.service?.length) {
    query.serviceFilter = filters.service.join(",");
  }

  if (filters.serviceArea?.length) {
    query.serviceAreaFilter = filters.serviceArea.join(",");
  }

  if (filters.appointmentStatus?.length) {
    query.statusFilter = filters.appointmentStatus.join(",");
  }
  if (filters.assignment?.length === 1) {
    query.assignment = filters.assignment[0];
  }
  if (filters.isMe) {
    query.isMe = filters.isMe;
  }
  if (filters.emailId) {
    query.searchTerm = filters.emailId;
  }
  return qs.stringify(query);
}
