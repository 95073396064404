import { useState, useEffect } from "react";
import { useUserDetails } from "common/api";

interface Props {
  children: string;
}
/**
 * Takes an e-mail address as child and renders the users
 * name.
 */
export const CoWorkerName = ({ children = "" }: Props) => {
  const [name, setName] = useState<string | null>(null);
  const { data } = useUserDetails(children);

  useEffect(() => {
    if (data) {
      setName(`${data.givenName} ${data.surname}`);
    } else {
      setName(children);
    }
  }, [data, children]);

  return <>{name}</>;
};

CoWorkerName.displayName = "CoWorkerName";
