import { features } from "common/feature-flags";
import {
  fallbackLocale,
  findLocaleIfSupported,
  Locale,
  LocaleContext,
  SupportedLocale,
} from "i18n";
import { useContext, useState, useEffect } from "react";
import { usePreferences, useProfile } from "../api";
import { useLocalStorage } from "./useLocalStorage";

/**
 * The localeStorage key used to cache the user locale
 */
const userLocaleStore = "userLocale";

/**
 * Get the locally cached locale.
 * Prefer to use the useLocale hook if possible.
 */
export const getCachedLocale = () =>
  window.localStorage.getItem(userLocaleStore) as string | undefined;

/**
 * Clear the locally cached locale.
 * Call when the user logs out
 */
export const clearCachedLocale = () =>
  window.localStorage.removeItem(userLocaleStore);

/**
 * The localStorage cache used for the user's locale
 */
const useLocaleStore = () => useLocalStorage<SupportedLocale>(userLocaleStore);

/**
 * Get the preferred locale from the user preferences API as well
 * as the currently cached locale and the browser locale.
 * This should only be used if there is no cached locale available.
 */
export const useAndFetchLocale = (): {
  preferredLocale?: Locale | null;
  cachedLocale?: Locale | null;
  browserLocaleIfSupported?: Locale | null;
  fallbackLocale: Locale;
  loading: boolean;
} => {
  const [loading, setLoading] = useState<boolean>(true);
  const [preferredLocale, setPreferredLocale] =
    useState<SupportedLocale | null>(null);
  const { data: profile } = useProfile();
  const { data: preferences } = usePreferences(profile?.mailNickname);
  const [cachedLocale, setCachedLocale] = useLocaleStore();
  const browserLocale = window.navigator.language;

  useEffect(() => {
    if (preferences?.language) {
      setPreferredLocale(preferences?.language);
    }
  }, [preferences?.language]);

  useEffect(() => {
    // If the API responds with a non-supported locale we do not cache this
    if (preferences?.language && findLocaleIfSupported(preferences.language)) {
      setCachedLocale(preferences.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences?.language]);

  useEffect(() => {
    if (preferences) {
      setLoading(false);
    }
  }, [preferences]);

  if (!features.localization) {
    return {
      preferredLocale: null,
      cachedLocale: null,
      browserLocaleIfSupported: null,
      fallbackLocale,
      loading,
    };
  }

  return {
    preferredLocale: preferredLocale && findLocaleIfSupported(preferredLocale),
    cachedLocale: cachedLocale && findLocaleIfSupported(cachedLocale),
    browserLocaleIfSupported: findLocaleIfSupported(browserLocale),
    fallbackLocale,
    loading,
  };
};

/**
 * Get the active user locale.
 * The locale should be available after app boot-up.
 * If not, use the useAndFetchLocale hook instead.
 */
export const useLocale = () => {
  const localeContext = useContext(LocaleContext);

  return localeContext.activeLocale;
};
