import { ReactNode, Children, isValidElement } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children?: ReactNode;
}

/**
 * Switch component for rendering the first <HashRoute> that matches.
 *
 * the component is heavily inspired by the <Switch> component that comes from react-router-dom
 * @see https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Switch.js
 */
export const HashSwitch = ({ children }: Props) => {
  const location = useLocation();

  let element: ReactNode = null;
  let match: boolean = false;

  // We use React.Children.forEach instead of React.Children.toArray().find()
  // here because toArray adds keys to all child elements and we do not want
  // to trigger an unmount/remount for two <Route>s that render the same
  // component at different URLs.
  Children.forEach(children, (child) => {
    if (!match && isValidElement(child)) {
      element = child;

      const hash = child.props.hash;

      match = hash ? location.hash === `#${hash}` : false;
    }
  });

  return match ? <>{element}</> : null;
};
