import { useHistory } from "react-router-dom";
import {
  // Appointment,
  Opportunity,
  useOpportunity,
  useOpportunityCustomer,
} from "common/api";
import {
  Box,
  Stack,
  Text,
  LocalizedDate,
  Loading,
  ModalSheet,
  OpportunityType,
  OpportunityStatus,
  CountryName,
} from "components";
import alertIcon from "@ingka/ssr-icon/paths/warning-triangle";
import InlineMessage from "@ingka/inline-message";
import { languageCodeToLanguage, CoWorkerName, Classification } from "common";
import { tracker } from "analytics";
import { T, t } from "i18n";
import { TranslationKeys } from "i18n/translationKeys";
import { parseTitle } from "./OpportunitiesTab";
import { getAppointmentService } from "features/appointments/AppointmentService";
import { getAppointmentLocationType } from "features/appointments/AppointmentLocationType";
import { getAppointmentStatus } from "features/appointments/AppointmentStatus";
import { InlineLabeledText } from "components/ui/InlineLabeledText.component";
import PaymentLogo from "@ingka/payment-logo";
import IKEAFamilyLogo from "assets/IKEA-family-credit-card.svg";
import { findOrdersWithoutAppointment } from "features/manage-opportunity/components/Appointment/utils";
import { orderStatus } from "features/manage-opportunity/components/OrderInfo.component";

const reason: Record<Opportunity["sourceReasonCode"], TranslationKeys> = {
  PIANOTPAID: "opportunities.quick-view.reason.pia-not-paid",
  MULTIPLE_ORDERS_GIVE_LOW_LIMIT:
    "opportunities.quick-view.reason.multiple-orders-low-limit",
  LOWLIMIT: "opportunities.quick-view.reason.low-limit",
  CARDBLOCK: "opportunities.quick-view.reason.card-block",
  CANC_PIA_DATE_PASSED:
    "opportunities.quick-view.reason.cancelled-pia-date-passed",
  NOT_PAID_ON_CRE_DATE: "opportunities.quick-view.reason.not-paid-on-cre-date",
  CREDIT_CARD_REJECTED: "opportunities.quick-view.reason.card-rejected",
  PAYM_GATEWAY_UNAVAIL:
    "opportunities.quick-view.reason.payment-gateway-unavailable",
  CUSTPAYM: "opportunities.quick-view.reason.cutomer-payment",
  PLANNING_SEASONAL_CUSTOMER:
    "opportunities.quick-view.reason.planning-seasonal-customer",
  // TODO: This should be removed in the backend
  NEWLEAD: "NEWLEAD" as TranslationKeys,
};

export type OpportunitiesQuickViewProps = {
  opportunity: Opportunity;
  onClose: () => void;
};

export const OpportunitiesQuickView = ({
  opportunity,
  onClose,
}: OpportunitiesQuickViewProps) => {
  const history = useHistory();
  const { data, isValidating: isLoading } = useOpportunityCustomer(
    opportunity?.opportunityId
  );

  const { data: opportunityFromApi } = useOpportunity(
    opportunity.opportunityId
  );
  const filteredOrders = findOrdersWithoutAppointment(opportunityFromApi) || [];
  const blockedOrders = filteredOrders.filter(
    (order) => order.status === "unblocked"
  );

  const customer = data?.customer;

  return (
    <ModalSheet
      title={t("quick-view.modal.header")}
      primaryButtonProps={{
        text: t("quick-view.manage-opportunity.button"),
        emphasised: true,
        onClick: () => {
          if (opportunity) {
            tracker.event({
              category: "Open Manage Opportunity",
              action:
                "Clicked on 'Manage opportunity' button in Opportunity Quick View",
            });
            history.push(`/opportunity/${opportunity.opportunityId}`);
          }
        },
      }}
      visible={Boolean(opportunity)}
      onClose={onClose}
    >
      {opportunity && (
        <Stack vertical spacing={40}>
          <Text wordBreakAll header="h2">
            {parseTitle(opportunity?.title)}
          </Text>
          {blockedOrders.length > 0 && (
            <Box padding={[15, 10]}>
              <Stack vertical spacing={4}>
                <InlineMessage
                  ssrIcon={alertIcon}
                  variant="cautionary"
                  title={
                    blockedOrders.length > 1
                      ? `${t("quick-view.orderNos.label")}`
                      : `${t("quick-view.orderNo.label")}`
                  }
                  body={`${blockedOrders
                    .map((order) => `${order?.orderId}`)
                    .join(" | ")} ${
                    blockedOrders.length > 1
                      ? t("quick-view.blocked-orders.message")
                      : t("quick-view.blocked-order.message")
                  }`}
                />
              </Stack>
            </Box>
          )}
          <Box color="bjornGrey" padding={[15, 10]}>
            <Stack vertical spacing={4}>
              {(opportunity.buName || opportunity.buCode) && (
                <InlineLabeledText
                  small
                  label={`${t("quick-view.ikea-store.label")}:`}
                  text={
                    (opportunity.buName ? `${opportunity.buName}` : "") +
                    (opportunity.buCode ? ` - ${opportunity.buCode}` : "")
                  }
                />
              )}
              {opportunity.updatedAt && (
                <InlineLabeledText
                  label={`${t("quick-view.last-update.label")}:`}
                  small
                >
                  <Text small>
                    <LocalizedDate pattern="P">
                      {opportunity.updatedAt}
                    </LocalizedDate>
                    {" | "}
                    <LocalizedDate pattern="p">
                      {opportunity.updatedAt}
                    </LocalizedDate>
                  </Text>
                </InlineLabeledText>
              )}

              {opportunity.assignedTo && (
                <InlineLabeledText
                  small
                  label={`${t("quick-view.assigned-to.label")}:`}
                >
                  <Text small>
                    <CoWorkerName>{opportunity.assignedTo}</CoWorkerName>
                  </Text>
                </InlineLabeledText>
              )}

              <InlineLabeledText
                small
                label={`${t("quick-view.id.label")}:`}
                text={opportunity.opportunityId}
              />

              {opportunity.opportunityType && (
                <InlineLabeledText
                  small
                  label={`${t("quick-view.type.label")}:`}
                >
                  <Text small>
                    <OpportunityType>
                      {opportunity.opportunityType}
                    </OpportunityType>
                  </Text>
                </InlineLabeledText>
              )}

              <InlineLabeledText
                small
                label={`${t("quick-view.status.label")}:`}
              >
                <Text small>
                  <OpportunityStatus>{opportunity.status}</OpportunityStatus>
                </Text>
              </InlineLabeledText>

              <InlineLabeledText
                small
                label={`${t("quick-view.classification.label")}:`}
              >
                <Classification small>
                  {opportunity.classification}
                </Classification>
              </InlineLabeledText>
            </Stack>
          </Box>

          <Loading
            isLoading={isLoading}
            text={t("quick-view.loading-customer-info")}
          />
          {customer && (
            <Box
              border={[1, 0, 0, 0]}
              borderColor="neutralGrey200"
              padding={[30, 0, 0, 0]}
            >
              <Stack vertical spacing={20}>
                <Text header="h3">
                  <T id="quick-view.customer.header" />
                </Text>

                <Box color="bjornGrey" padding={[15, 10, 0, 10]}>
                  <Stack vertical spacing={4}>
                    <Box vertical alignItems="start" padding={[0, 0, 15, 0]}>
                      <Text small>
                        {customer.firstName} {customer.lastName}
                      </Text>
                      <Text small>
                        {customer.customerType === "Individual"
                          ? t("manage-opportunity.customer-info.type.private")
                          : t("manage-opportunity.customer-info.type.business")}
                      </Text>
                      {customer.language && customer.country !== "DK" && (
                        <Text small>
                          {t(
                            languageCodeToLanguage[
                              customer.language.toLowerCase()
                            ]
                          ) || customer.language}
                        </Text>
                      )}
                    </Box>

                    <Box
                      vertical
                      alignItems="start"
                      padding={[15, 0]}
                      border={[1, 0, 0, 0]}
                      borderColor="neutralGrey200"
                    >
                      <Stack>
                        <Text small>{customer.phoneNumber}</Text>
                        {customer.preferredContactType === "PHONE" && (
                          <Text small bold color="greenIdle">
                            <T id="quick-view.preferred" />
                          </Text>
                        )}
                      </Stack>
                      <Stack>
                        <Text small>{customer.email}</Text>
                        {customer.preferredContactType === "EMAIL" && (
                          <Text small bold color="greenIdle">
                            <T id="quick-view.preferred" />
                          </Text>
                        )}
                      </Stack>
                    </Box>

                    <Box
                      vertical
                      alignItems="start"
                      padding={[15, 0]}
                      border={[1, 0, 0, 0]}
                      borderColor="neutralGrey200"
                    >
                      <Text small>{customer.streetAddress1}</Text>
                      {customer.streetAddress2 && (
                        <Text small>{customer.streetAddress2}</Text>
                      )}
                      {customer.streetAddress3 && (
                        <Text small>{customer.streetAddress3}</Text>
                      )}
                      <Text small>
                        {customer.postalCode} {customer.city}
                      </Text>
                      <Text small>
                        <CountryName>{customer.country}</CountryName>
                      </Text>
                    </Box>

                    {customer.familyNumber && (
                      <Box
                        vertical
                        alignItems="start"
                        border={[1, 0, 0, 0]}
                        borderColor="neutralGrey200"
                        padding={[15, 0]}
                      >
                        <Stack>
                          <PaymentLogo src={IKEAFamilyLogo} size="small" />
                          <Text small>{customer.familyNumber}</Text>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          )}

          {opportunityFromApi?.appointmentDetails &&
            opportunityFromApi.appointmentDetails.length > 0 && (
              <Box
                border={[1, 0, 0, 0]}
                borderColor="neutralGrey200"
                padding={[30, 0, 0, 0]}
              >
                <Stack vertical spacing={20}>
                  <Text header="h3">
                    <T id="appointment.appointments.label" />
                  </Text>
                  {opportunityFromApi.appointmentDetails
                    .sort((a, b) =>
                      a.timeWindowStartTime.localeCompare(b.timeWindowStartTime)
                    )
                    .map((appointment) => (
                      <Box
                        color="bjornGrey"
                        padding={[15, 10]}
                        key={appointment.id}
                      >
                        <Stack vertical spacing={4}>
                          <Box
                            vertical
                            padding={[10, 0, 0, 0]}
                            alignItems="start"
                          >
                            <Stack vertical spacing={4}>
                              <InlineLabeledText
                                small
                                label={`${t("appointment.time.label")}:`}
                              >
                                <Text small>
                                  <LocalizedDate pattern="P">
                                    {appointment.timeWindowStartTime}
                                  </LocalizedDate>
                                  {" | "}
                                  <LocalizedDate pattern="p">
                                    {appointment.timeWindowStartTime}
                                  </LocalizedDate>
                                  {" - "}
                                  <LocalizedDate pattern="p">
                                    {appointment.timeWindowEndTime}
                                  </LocalizedDate>
                                </Text>
                              </InlineLabeledText>

                              <InlineLabeledText
                                small
                                label={`${t("quick-view.status.label")}:`}
                                text={getAppointmentStatus(
                                  appointment.appointmentStatus
                                )}
                              />
                              <InlineLabeledText
                                small
                                label={`${t(
                                  "opportunities.filters.businessunit.title"
                                )}:`}
                                text={appointment.locationName}
                              />

                              <InlineLabeledText
                                small
                                label={`${t(
                                  "appointment.locationtype.label"
                                )}:`}
                                text={getAppointmentLocationType(
                                  appointment.locationType
                                )}
                              />
                              <InlineLabeledText
                                small
                                label={`${t("appointment.service.label")}:`}
                                text={getAppointmentService(
                                  appointment.service
                                )}
                              />

                              <InlineLabeledText
                                small
                                label={`${t(
                                  "appointment.appointment-number.label"
                                )}:`}
                                text={appointment.orderNumber}
                              />

                              {appointment.assignedCoworker && (
                                <InlineLabeledText
                                  small
                                  label={`${t(
                                    "quick-view.assigned-to.label"
                                  )}:`}
                                >
                                  <Text small>
                                    <CoWorkerName>
                                      {appointment.assignedCoworker}
                                    </CoWorkerName>
                                  </Text>
                                </InlineLabeledText>
                              )}
                            </Stack>
                          </Box>
                        </Stack>
                      </Box>
                    ))}
                </Stack>
              </Box>
            )}

          {filteredOrders.length > 0 && (
            <Box
              border={[1, 0, 0, 0]}
              borderColor="neutralGrey200"
              padding={[30, 0, 0, 0]}
            >
              <Stack vertical spacing={20}>
                <Text header="h3">
                  {filteredOrders.length > 1 ? (
                    <T id="quick-view.orders.header" />
                  ) : (
                    <T id="quick-view.order.header" />
                  )}
                </Text>
                {filteredOrders.map((order) => (
                  <Box color="bjornGrey" padding={[15, 10]}>
                    <Stack vertical spacing={4}>
                      <InlineLabeledText
                        small
                        label={`${t("quick-view.orderNo.label")}:`}
                        text={order.orderId}
                      />
                      <InlineLabeledText
                        small
                        label={`${t("quick-view.created.label")}:`}
                      >
                        <Text small>
                          <LocalizedDate>{order.createdTime}</LocalizedDate>
                        </Text>
                      </InlineLabeledText>
                      {order?.blockDate && (
                        <InlineLabeledText
                          small
                          label={`${t("quick-view.blocked.label")}`}
                        >
                          <Text small>
                            <LocalizedDate>{order?.blockDate}</LocalizedDate>
                          </Text>
                        </InlineLabeledText>
                      )}
                      {opportunity.sourceReasonCode && (
                        <InlineLabeledText
                          small
                          label={`${t("quick-view.blocked-reason.label")}:`}
                          text={t(reason[opportunity.sourceReasonCode])}
                        />
                      )}
                      {opportunity.orderInfo?.blockedAt && (
                        <InlineLabeledText
                          small
                          label={`${t("quick-view.blocked-date.label")}:`}
                        >
                          <Text small>
                            <LocalizedDate>
                              {opportunity.orderInfo.blockedAt}
                            </LocalizedDate>
                          </Text>
                        </InlineLabeledText>
                      )}
                      {order.updatedTime && (
                        <InlineLabeledText
                          small
                          label={`${t("quick-view.last-update.label")}:`}
                        >
                          <Text small>
                            <LocalizedDate>{order.updatedTime}</LocalizedDate>
                          </Text>
                        </InlineLabeledText>
                      )}
                      {order.status && (
                        <InlineLabeledText
                          small
                          label={`${t("quick-view.order-status.label")}:`}
                        >
                          <Text small>
                            {t(orderStatus[order.status.toLowerCase()])}
                          </Text>
                        </InlineLabeledText>
                      )}
                    </Stack>
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      )}
    </ModalSheet>
  );
};
