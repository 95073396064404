import { getAuthToken } from "authorization";
import { getMsalToken } from "./msalClient";

export const getToken = async () => {
  const token = await getAuthToken();
  if (token) {
    return token.accessToken;
  }
};

export const getTokenUserId = async () => {
  const token = await getAuthToken();
  if (token && token.account) {
    return token.account.localAccountId;
  }
};

export const getLoginToken = async () => {
  const token = await getMsalToken();
  if (token) {
    return token.accessToken;
  }
};
