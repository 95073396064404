import designTokens from "@ingka/variables";

export const Colors = {
  blueHover: designTokens["$colour-blue-hover"],
  blueIdle: designTokens["$colour-blue-idle"],
  bluePressed: designTokens["$colour-blue-pressed"],
  brandBlue: designTokens["$colour-brand-blue"],
  brandYellow: designTokens["$colour-brand-yellow"],
  feedbackConfirmation: designTokens["$colour-feedback-confirmation"],
  feedbackError: designTokens["$colour-feedback-error"],
  feedbackFocus: designTokens["$colour-feedback-focus"],
  feedbackInformation: designTokens["$colour-feedback-information"],
  feedbackWarning: designTokens["$colour-feedback-warning"],
  greenHover: designTokens["$colour-green-hover"],
  greenIdle: designTokens["$colour-green-idle"],
  greenPressed: designTokens["$colour-green-pressed"],
  greyDisabled: designTokens["$colour-grey-disabled"],
  ikeaCoworkerBlue: designTokens["$colour-ikea-coworker-blue"],
  ikeaCoworkerYellow: designTokens["$colour-ikea-coworker-yellow"],
  ikeaFamilyBlue: designTokens["$colour-ikea-family-blue"],
  launchAqua300: designTokens["$colour-launch-aqua-300"],
  launchAqua500: designTokens["$colour-launch-aqua-500"],
  launchAqua700: designTokens["$colour-launch-aqua-700"],
  launchGreen300: designTokens["$colour-launch-green-300"],
  launchGreen500: designTokens["$colour-launch-green-500"],
  launchGreen700: designTokens["$colour-launch-green-700"],
  launchLightblue300: designTokens["$colour-launch-lightblue-300"],
  launchLightblue500: designTokens["$colour-launch-lightblue-500"],
  launchLightblue700: designTokens["$colour-launch-lightblue-700"],
  launchPurple300: designTokens["$colour-launch-purple-300"],
  launchPurple500: designTokens["$colour-launch-purple-500"],
  launchPurple700: designTokens["$colour-launch-purple-700"],
  neutralBlack: designTokens["$colour-neutral-black"],
  neutralGrey100: designTokens["$colour-neutral-grey-100"],
  neutralGrey200: designTokens["$colour-neutral-grey-200"],
  neutralGrey300: designTokens["$colour-neutral-grey-300"],
  neutralGrey500: designTokens["$colour-neutral-grey-500"],
  neutralGrey700: designTokens["$colour-neutral-grey-700"],
  neutralGrey800: designTokens["$colour-neutral-grey-800"],
  neutralGrey900: designTokens["$colour-neutral-grey-900"],
  neutralWhite: designTokens["$colour-neutral-white"],
  orangeHover: designTokens["$colour-orange-hover"],
  orangeIdle: designTokens["$colour-orange-idle"],
  orangePressed: designTokens["$colour-orange-pressed"],
  productBtiRed: designTokens["$colour-product-bti-red"],
  productBtiYellow: designTokens["$colour-product-bti-yellow"],
  productNew: designTokens["$colour-product-new"],
  productNlp: designTokens["$colour-product-nlp"],
  productTro: designTokens["$colour-product-tro"],
  redHover: designTokens["$colour-red-hover"],
  redIdle: designTokens["$colour-red-idle"],
  redPressed: designTokens["$colour-red-pressed"],
  whiteHover: designTokens["$colour-white-hover"],
  whiteIdle: designTokens["$colour-white-idle"],
  whitePressed: designTokens["$colour-white-pressed"],
  yellowHover: designTokens["$colour-yellow-hover"],
  yellowIdle: designTokens["$colour-yellow-idle"],
  yellowPressed: designTokens["$colour-yellow-pressed"],
  bjornGrey: "#f8f8f8",
  calendarGrey: "#f5f5f5",
  navIconYellow: "#f9ce5c",
  classLow: "#afc6ff",
  classMedium: "#ffa524",
  classHigh: "#fe6b1a",
  classNone: designTokens["$colour-neutral-grey-100"],
  classUrgent: "#fa0808",
  colorBlack: "#000",
  logoBlue: "#0058a3",
  notification: "#f3cf6f",
  activityDone: "#0a8a00",
  taskText: "#111",
  appBoka: "#90ee90",
  appCall: "#80c5f6",
  appMarkAsDone: "#D9D9D9",
  appMeeting: "#f9cefd",
  appOtherAction: "#ffcd3f",
  appTask: "#ffa089",
  appTimeCrossed: "#ff1919",
  textWhite: "#8C8C8C",
};

export type Color = keyof typeof Colors;

export const FontFamily = designTokens["$font-family-latin"];
