import { ChangeEvent } from "react";

interface Props {
  disabled?: boolean;
  label: string;
  onChange(e: ChangeEvent<HTMLOptionElement>): void;
  value: string;
  id: string;
  checked: boolean;
}

export const RadioButtonFixed = (props: Props) => {
  return (
    <div className="radio">
      <input
        id={props.id}
        type="radio"
        disabled={props.disabled}
        onChange={props.onChange.bind(this)}
        value={props.value}
        checked={props.checked}
      />
      <span className="radio__symbol" />
      <span className="radio__label">
        <label htmlFor={props.id}>{props.label}</label>
      </span>
    </div>
  );
};

RadioButtonFixed.displayName = "RadioButtonFixed";
