import { useState } from "react";
import { Button } from "components";
import circleEllipsis from "@ingka/ssr-icon/paths/ellipsis";
import { ViewOrder } from "./ViewOrder.component";
import { MultipleActions } from "./MultipleActions.component";
import {
  Box,
  Stack,
  LabeledText,
  LocalizedDate,
  Grid,
  ModalSheet,
  Toast,
  Currency,
} from "components";
import { ManageAccordion } from "./ManageAccordion.component";
import { Opportunity as opportunityInterface, useOrder } from "common/api";
import { OrderSelect } from "./OrderSelect.component";
import { T, t } from "i18n";
import { findOrdersWithoutAppointment } from "./Appointment/utils";
import { capitalize } from "lodash";
import { useLocale } from "common";

export const orderStatus = {
  completed: "order.status.completed",
  cancelled: "order.status.cancelled",
  created: "order.status.created",
  inprogress: "order.status.inprogress",
  draft: "order.status.draft",
  unblocked: "order.status.unblocked",
} as const;

interface Props {
  opportunityFromApi: opportunityInterface;
  disableEditing: boolean;
}

export const OrderInfo = ({ opportunityFromApi, disableEditing }: Props) => {
  const locale = useLocale();
  const filteredOrders = findOrdersWithoutAppointment(opportunityFromApi) || [];
  const [showOrder, setShowOrder] = useState<boolean>(false);
  const [deleteOrder, setDeleteOrder] = useState<boolean>(false);
  const [selectedOrderNo, setSelectedOrderNo] = useState<string | undefined>(
    filteredOrders[0].orderId
  );
  const {
    data: order,
    isValidating: isLoading,
    error,
  } = useOrder(selectedOrderNo);
  const paymentTransactions =
    order?.paymentPlans?.[0]?.payments?.[0]?.paymentTransactions?.[0];
  const preferredContactType =
    order?.deliveryContact?.customerContactDetails.preferredContactType;
  const planner = order?.planners;

  if (!opportunityFromApi.orders) {
    return null;
  }
  return (
    <>
      {error && (
        <Toast text={t("manage-opportunity.order-info.missing-order")} />
      )}
      <ManageAccordion
        title={
          filteredOrders.length > 1
            ? t("manage-opportunity.order-info.orders-title", {
                count: filteredOrders.length,
              })
            : t("manage-opportunity.order-info.title")
        }
      >
        <Box>
          <Stack vertical spacing={30}>
            {filteredOrders.length > 1 && (
              <OrderSelect
                label={t("manage-opportunity.order-info.select-order")}
                onChange={(val) => setSelectedOrderNo(val)}
                selectedOrderNo={selectedOrderNo}
                originalOrderNo={
                  opportunityFromApi.orders.originalOrder?.orderId
                }
                orders={filteredOrders}
              />
            )}
            <Grid>
              <LabeledText
                label={t("manage-opportunity.order-info.order-number")}
                text={order?.orderNo}
              />
              <LabeledText
                label={t("manage-opportunity.order-info.status")}
                text={order && t(orderStatus[order.orderStatus.toLowerCase()])}
              />
              <LabeledText
                label={t("manage-opportunity.order-info.type")}
                text={order?.orderType}
              />
              <LabeledText
                label={t("manage-opportunity.order-info.value")}
                text={
                  order?.orderValue &&
                  order.currencyCode && (
                    <Currency
                      currency={order.currencyCode}
                      showSign
                      fractions={2}
                    >
                      {order?.orderValue}
                    </Currency>
                  )
                }
              />
              {order?.orderCreationDate && (
                <LabeledText
                  label={t("manage-opportunity.order-info.created")}
                  text={
                    <LocalizedDate>{order.orderCreationDate}</LocalizedDate>
                  }
                />
              )}
              {order?.paymentPlans?.[0]?.dueDateTime && (
                <LabeledText
                  label={t(
                    "manage-opportunity.order-info.expected-payment-date"
                  )}
                  text={
                    <LocalizedDate>
                      {order?.paymentPlans?.[0]?.dueDateTime}
                    </LocalizedDate>
                  }
                />
              )}
              {paymentTransactions?.status && (
                <LabeledText
                  label={t("order.payment-status.label")}
                  text={
                    paymentTransactions?.status === "CAPTURED" ? (
                      <T id="order.status.paid" />
                    ) : (
                      capitalize(paymentTransactions?.status)
                    )
                  }
                />
              )}
              {paymentTransactions?.transactionDateTime && (
                <LabeledText
                  label={t("order.payment-date.label")}
                  text={
                    <LocalizedDate>
                      {paymentTransactions?.transactionDateTime}
                    </LocalizedDate>
                  }
                />
              )}
              {order && order?.deliveryArrangements.length > 0 && (
                <LabeledText label={t("order.service-type.label")}>
                  {order?.deliveryArrangements
                    .map(
                      (orderServiceType) => `${orderServiceType.serviceType}`
                    )
                    .join(", ")}
                </LabeledText>
              )}
              {order?.creationMethod && (
                <LabeledText
                  label={t("manage-opportunity.order-info.order-method")}
                  text={order.creationMethod}
                />
              )}
              {order?.buCode && (
                <LabeledText
                  label={t("manage-opportunity.order-info.store-number")}
                  text={order.buCode}
                />
              )}
              {order?.buName && (
                <LabeledText
                  label={t("manage-opportunity.order-info.store-name")}
                  text={order.buName}
                />
              )}

              {preferredContactType && (
                <LabeledText
                  label={t("manage-opportunity.customer-info.contact-method")}
                  text={
                    preferredContactType === "EMAIL"
                      ? t("manage-opportunity.customer-info.email")
                      : t("manage-opportunity.customer-info.phone")
                  }
                />
              )}
              {planner && planner.length > 0 && (
                <LabeledText
                  label={t(
                    "manage-opportunity.order-info.planner-drawing-title"
                  )}
                  text={t("manage-opportunity.order-info.planner-drawing-yes")}
                />
              )}
            </Grid>
            <Stack>
              <Button
                text={t("manage-opportunity.order-info.view-order")}
                primary
                loading={isLoading}
                disabled={!order}
                onClick={() => {
                  setShowOrder(true);
                }}
              />
              <Button
                text={t("manage-opportunity.order-info.open-salja-go")}
                href={`${
                  process.env.REACT_APP__WEB_SALJA__
                }/${locale.countryCode?.toLowerCase()}/${locale.languageCode?.toLowerCase()}/order/${
                  order?.orderNo
                }?clientSystem=SLA`}
              />
              <Button
                iconOnly
                ssrIcon={circleEllipsis}
                text="Overflow Button"
                disabled={disableEditing}
                onClick={() => {
                  setDeleteOrder(true);
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </ManageAccordion>
      <ModalSheet
        visible={showOrder}
        onClose={() => setShowOrder(false)}
        title={t("order-modal.title", {
          orderNumber: order?.orderNo,
        })}
        closeText={t("order-modal.close")}
      >
        {order && <ViewOrder order={order} />}
      </ModalSheet>
      <ModalSheet
        visible={deleteOrder}
        onClose={() => setDeleteOrder(false)}
        title={t("order-modal.title", {
          orderNumber: order?.orderNo,
        })}
        closeText={t("order-modal.close")}
      >
        {
          <MultipleActions
            onAction={() => setDeleteOrder(false)}
            itemId={order?.orderNo ?? selectedOrderNo}
            type="order"
            opportunityId={opportunityFromApi.id}
          />
        }
      </ModalSheet>
    </>
  );
};

OrderInfo.displayName = "OrderInfo";
