import { useHistory } from "react-router-dom";
import { css, cx } from "@emotion/css";
import SkapaButton, { ButtonProps } from "@ingka/button";
import { Color, Colors } from "../Variables";

export interface IButtonProps {
  /**
   * Make the button smaller
   */
  small?: boolean;
  /**
   * Use primary buttons to place a little extra emphasis on an action.
   */
  primary?: boolean;
  /**
   * Use tertiary buttons to place the least amount of emphasis on an action.
   */
  tertiary?: boolean;
  /**
   * Use an emphasized button with a Skapa primary accent colour to further emphasis on the most important actions.
   */
  emphasised?: boolean;
  /**
   * Use this to call attention to the button, such as with unread notifications.
   */
  attention?: boolean;
  text?: string;
  onClick?: ButtonProps["onClick"];
  loading?: boolean;
  disabled?: boolean;
  ssrIcon?: ButtonProps["ssrIcon"];
  iconPosition?: ButtonProps["iconPosition"];
  /**
   * Only display the icon.
   */
  iconOnly?: boolean;
  /**
   * Render the button as a submit element.
   * Useful in proper forms.
   */
  submit?: boolean;
  /**
   * Link to anything outside the application.
   * Links will open in an external window.
   */
  href?: string;
  /**
   * Link to anything inside the application.
   * Uses our internal routing to push the page change instead
   * of re-rendering the entire app.
   *
   * @example <Button link="/customers" />
   */
  link?: string;
  /**
   * Make the button take up 100% of the width
   */
  fluid?: boolean;
  color?: Color;
}

/**
 * A wrapper component around the SKAPA Button component.
 * Adds the ability to link within the app using routes as well
 * as a new "attention" type button.
 */
export const Button = (props: IButtonProps) => {
  const history = useHistory();
  return (
    <SkapaButton
      type={
        props.attention
          ? "danger"
          : props.primary
          ? "primary"
          : props.tertiary
          ? "tertiary"
          : props.emphasised
          ? "emphasised"
          : "secondary"
      }
      text={props.text}
      onClick={props.link ? () => history.push(props.link!) : props.onClick}
      loading={props.loading}
      disabled={props.disabled}
      ssrIcon={props.ssrIcon}
      iconPosition={props.iconPosition}
      small={props.small}
      htmlType={props.submit ? "submit" : "button"}
      href={props.href}
      newWindow={!!props.href}
      fluid={props.fluid}
      iconOnly={props.iconOnly}
      className={cx(
        props.attention
          ? css`
              &.btn--danger .btn__inner {
                background-color: ${Colors.notification};
                color: ${Colors.neutralGrey900};
              }
            `
          : undefined,
        props.color &&
          css`
            & .btn__inner {
              border: 1px solid ${Colors[props.color]};
            }
          `
      )}
    />
  );
};
