import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP__SENTRY_KEY,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP__VERSION,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // INGKA's trial for perfomance monitoring has elapsed,
    // until a new deal has been made this needs to be set to 0
    tracesSampleRate: 0,
  });
};
