import { User } from "@microsoft/microsoft-graph-types";
import {
  Action,
  Attachment,
  Channel,
  hfb,
  Opportunity,
  Reason,
  SaveOpportunity,
  service,
  PlannerTypes,
} from "common/api";
import { pull } from "lodash";
import { getHours, getMinutes, setHours, setMinutes } from "date-fns";

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

/**
 * We extend the Opportunity object with the details we need for saving the changes as well as
 * a list of tasks that the user has marked as done in the timelog.
 */

export interface CurrentOpportunity extends Opportunity {
  actionToCommit?: {
    action: Action;
    channel?: Channel["kind"];
    reason?: Reason["kind"];
    title?: string;
    orderNo?: string;
    comment?: string;
    date?: Date;
    time?: Date;
    plannerId?: string;
    plannerType?: PlannerTypes["kind"];
    sendCommunication?: boolean;
  };
  dataToSave?: SaveOpportunity;
  markAsDone?: number[];
}

/**
 * These actions can be taken on the CurrentOpportunity object and represent
 * changes made by the user.
 */
type CurrentOpportunityActionPayloads = {
  CHANGE_STATUS: {
    status: Opportunity["status"];
    reason?: string;
    description?: string;
  };
  UPDATE_ACTION: CurrentOpportunity["actionToCommit"];
  INIT_OPPORTUNITY: Opportunity;
  UPDATE_REMARK: string;
  ASSIGN: { user: User | undefined; buCode: string | undefined };
  UPDATE_TITLE: string;
  UPDATE_INTEREST: {
    comment: string;
    userId: string;
  };
  CHANGE_HFB: hfb[];
  CHANGE_EXPECTED_PURCHASE_DATE: string;
  CHANGE_SERVICES: service[];
  CHANGE_CUSTOMER_MEETING_POINT: {
    customerMeetingPointBuName: string;
    customerMeetingPointBuCode: string;
    sourceBuName: string;
  };
  CHANGE_BUDGET: number;
  UPDATE_CONTACT_CONSENT: {
    value?: boolean;
    reason: string;
  };
  UPLOAD_FILE: Attachment;
  DELETE_FILE: string;
  TOGGLE_AS_DONE: number;
  CLEAR_TOGGLE_AS_DONE: undefined;
  CHANGE_CLASSIFICATION: {
    classification: Opportunity["classification"];
    reason?: string;
    description?: string;
  };
};

type OpportunityActions =
  ActionMap<CurrentOpportunityActionPayloads>[keyof ActionMap<CurrentOpportunityActionPayloads>];

/**
 * This is the React useReducer dispatch for executing actions
 * against the CurrentOpportunity object
 */
export type CurrentOpportunityDispatch = React.Dispatch<OpportunityActions>;

export function currentOpportunityReducer(
  opportunity: CurrentOpportunity,
  action: OpportunityActions
): CurrentOpportunity {
  switch (action.type) {
    case "INIT_OPPORTUNITY": {
      return { ...action.payload, markAsDone: [] };
    }
    case "CHANGE_STATUS": {
      return {
        ...opportunity,
        status: action.payload.status,
        dataToSave: {
          ...opportunity.dataToSave,
          statusChangeDetails: {
            reason: action.payload.reason,
            description: action.payload.description,
          },
          status: action.payload.status,
        },
      };
    }
    case "UPDATE_ACTION": {
      if (!action.payload) {
        return { ...opportunity };
      }

      // We should add validation for the date here.
      const date = action.payload.date;
      const time = action.payload.time;
      const hours = time ? getHours(time) : 0;
      const minutes = time ? getMinutes(time) : 0;
      const fullDate =
        date && time ? setHours(setMinutes(date, minutes), hours) : date;

      const appointment = fullDate && fullDate.toISOString();

      return {
        ...opportunity,
        actionToCommit: action.payload,
        dataToSave: {
          ...opportunity.dataToSave,
          action: {
            appointment,
            comment: action.payload.comment,
            title: action.payload.title,
            action: action.payload.action.kind,
            reason: action.payload.reason || action.payload.channel,
            orderNo: action.payload.orderNo,
            plannerId: action.payload.plannerId,
            sendCommunication: action.payload.sendCommunication,
          },
        },
      };
    }
    case "UPDATE_REMARK": {
      return {
        ...opportunity,
        remark: action.payload,
        dataToSave: {
          ...opportunity.dataToSave,
          remark: action.payload,
        },
      };
    }
    case "ASSIGN": {
      let payload =
        action.payload && action.payload.user?.mail
          ? {
              ...opportunity,
              owner: action.payload.user?.mail,

              dataToSave: {
                ...opportunity.dataToSave,
                owner: action.payload.user.mail,
              },
            }
          : opportunity;

      return payload;
    }
    case "UPDATE_TITLE": {
      return {
        ...opportunity,
        title: action.payload,
        dataToSave: {
          ...opportunity.dataToSave,
          title: action.payload,
        },
      };
    }
    case "UPDATE_INTEREST": {
      return {
        ...opportunity,
        interestArea: action.payload.comment,
        dataToSave: {
          ...opportunity.dataToSave,
          interestArea: action.payload.comment,
        },
      };
    }
    case "CHANGE_HFB": {
      return {
        ...opportunity,
        hfb: action.payload,
        dataToSave: {
          ...opportunity.dataToSave,
          hfb: action.payload,
        },
      };
    }
    case "CHANGE_EXPECTED_PURCHASE_DATE": {
      return {
        ...opportunity,
        expectedPurchase: action.payload,
        dataToSave: {
          ...opportunity.dataToSave,
          expectedPurchase: action.payload,
        },
      };
    }
    case "CHANGE_CUSTOMER_MEETING_POINT": {
      return {
        ...opportunity,
        customerMeetingPointBuName: action.payload.customerMeetingPointBuName,
        customerMeetingPointBuCode: action.payload.customerMeetingPointBuCode,
        dataToSave: {
          ...opportunity.dataToSave,
          customerMeetingPointBuName: action.payload.customerMeetingPointBuName,
          customerMeetingPointBuCode: action.payload.customerMeetingPointBuCode,
          sourceBuName: action.payload.sourceBuName,
        },
      };
    }
    case "CHANGE_SERVICES": {
      return {
        ...opportunity,
        servicesInterested: action.payload,
        dataToSave: {
          ...opportunity.dataToSave,
          servicesInterested: action.payload,
        },
      };
    }
    case "CHANGE_BUDGET": {
      return {
        ...opportunity,
        customerBudget: action.payload,
        dataToSave: {
          ...opportunity.dataToSave,
          customerBudget: action.payload,
        },
      };
    }
    case "UPDATE_CONTACT_CONSENT": {
      return action.payload && action.payload.value !== undefined
        ? {
            ...opportunity,
            allowContact: action.payload.value,
            dataToSave: {
              ...opportunity.dataToSave,
              consentChangeReason: action.payload.reason,
              allowContact: action.payload.value,
            },
          }
        : opportunity;
    }
    case "UPLOAD_FILE": {
      return {
        ...opportunity,
        attachments: [action.payload, ...(opportunity.attachments || [])],
        dataToSave: {
          ...opportunity.dataToSave,
          attachments: [action.payload, ...(opportunity.attachments || [])],
        },
      };
    }
    case "TOGGLE_AS_DONE": {
      const previous = [...(opportunity.markAsDone || [])];
      const markAsDone = previous.includes(action.payload)
        ? pull(previous, action.payload)
        : [action.payload, ...previous];
      return {
        ...opportunity,
        markAsDone,
      };
    }
    case "CLEAR_TOGGLE_AS_DONE": {
      return {
        ...opportunity,
        markAsDone: [],
      };
    }
    case "DELETE_FILE": {
      return opportunity.attachments
        ? {
            ...opportunity,
            attachments: opportunity.attachments.filter(
              (attachment) => attachment.name !== action.payload
            ),
            dataToSave: {
              ...opportunity.dataToSave,
              fileNameToDelete: action.payload,
              attachments: opportunity.attachments.filter(
                (attachment) => attachment.name !== action.payload
              ),
            },
          }
        : opportunity;
    }
    case "CHANGE_CLASSIFICATION": {
      return {
        ...opportunity,
        classification: action.payload.classification,
        dataToSave: {
          ...opportunity.dataToSave,
          classificationChangeDetails: {
            reason: action.payload.reason,
            description: action.payload.description,
          },
          classification: action.payload.classification,
        },
      };
    }
    default:
      throw new Error();
  }
}
