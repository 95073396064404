import { MarketContext } from "../market";
import { useContext } from "react";

/**
 * Get the user's active market
 */
export const useMarket = () => {
  const marketContext = useContext(MarketContext);

  return marketContext.activeMarket;
};
