import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box, Colors, Icon, Loading, Stack, Text } from "components";
import { CoworkerSearch, useLocale, useLocalStorage } from "common";
import { Tasks } from "features/tasks";
import { getAppointmentService } from "features/appointments/AppointmentService";
import { tracker, usePageTracking } from "analytics";
import Toggle from "@ingka/toggle";
import { t, T } from "i18n";
import {
  useNotifications,
  useAllCoworkersNotifications,
  useAppointmentsSearch,
  AppointmentsSearchOptions,
  AppointmentStatus,
  useTasks,
  useProfile,
} from "common/api";
import {
  getRemindersList,
  getAllCoworkersRemindersList,
} from "../../../features/manage-opportunity/components/Appointment/utils";

import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import getDay from "date-fns/getDay";

import enAU from "date-fns/locale/en-AU";
import enCA from "date-fns/locale/en-CA";
import enDE from "date-fns/locale/de";
import enIN from "date-fns/locale/en-IN";
import enIT from "date-fns/locale/it";
import enJP from "date-fns/locale/ja";
import enNO from "date-fns/locale/nn";
import enFR from "date-fns/locale/fr";
import svSE from "date-fns/locale/sv";
import deDE from "date-fns/locale/de";
import daDK from "date-fns/locale/da";
import deAT from "date-fns/locale/de";
import deCH from "date-fns/locale/de";
import fiFI from "date-fns/locale/fi";
import frFR from "date-fns/locale/fr";
import frCA from "date-fns/locale/fr-CA";
import frCH from "date-fns/locale/fr-CH";
import hrHR from "date-fns/locale/hr";
import itCH from "date-fns/locale/it";
import itIT from "date-fns/locale/it";
import jaJP from "date-fns/locale/ja";
import noNO from "date-fns/locale/nn";
import plPL from "date-fns/locale/pl";
import ptPT from "date-fns/locale/pt";
import roRO from "date-fns/locale/ro";
import slSI from "date-fns/locale/sl";
import srRS from "date-fns/locale/sr";
import { addMinutes } from "date-fns";

const locales = {
  "de-AT": deAT,
  "de-CH": deCH,
  "de-DE": deDE,
  "da-DK": daDK,
  "en-AU": enAU,
  "en-CA": enCA,
  "en-DE": enDE,
  "en-FR": enFR,
  "en-IN": enIN,
  "en-IT": enIT,
  "en-JP": enJP,
  "en-NO": enNO,
  "sv-SE": svSE,
  "fr-CA": frCA,
  "fr-CH": frCH,
  "fi-FI": fiFI,
  "fr-FR": frFR,
  "hr-HR": hrHR,
  "it-CH": itCH,
  "it-IT": itIT,
  "ja-JP": jaJP,
  "no-NO": noNO,
  "pl-PL": plPL,
  "pt-PT": ptPT,
  "ro-RO": roRO,
  "sl-SI": slSI,
  "sr-RS": srRS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  getDay,
  locales,
});

const taskColors = {
  Meeting: Colors.appBoka,
  Call: Colors.appCall,
  Task: Colors.appTask,
  genericTask: Colors.appTask,
  OtherAction: Colors.appOtherAction,
  isDeleted: Colors.appMarkAsDone,
  COMPLETED: Colors.appMarkAsDone,
  CANCELLED: Colors.appMarkAsDone,
  CANCELLED_NO_SHOW: Colors.appMarkAsDone,
  BOKA_APPOINTMENT: Colors.appBoka,
  APPOINTMENT_TIME_CROSSED: Colors.appTimeCrossed,
};

const defaultOptions: AppointmentsSearchOptions = {
  page: 0,
  pageSize: 5000,
  sortKey: "timeWindowStartTime",
  sortOrder: "desc",
};

interface ICoworkerAppointments {
  title: string;
  start: Date;
  end: Date;
  appOppId: number;
  appAppointmentId: number;
  appAppointmentStatus: AppointmentStatus;
  appAssignedCoworker: string;
}

interface ICalendarData {
  isMe: boolean;
  selectedCoworker: string;
  fromDate: Date;
  toDate: Date;
}

export const CalendarView = () => {
  usePageTracking("Calendar View");
  const [cachedCalendarData, setCachedCalendarData] =
    useLocalStorage<ICalendarData>("calendarData");
  const { data: profile } = useProfile();
  const locale = useLocale();
  const history = useHistory();
  const [tasksIsOpen, setTasksIsOpen] = useState<boolean>(false);
  const { data } = useNotifications();
  const reminders = getRemindersList(data ?? []);
  const [emailId, setEmailId] = useState<string>(
    cachedCalendarData?.selectedCoworker ?? ""
  );
  const { data: tasks } = useTasks(emailId !== "" ? emailId : profile?.mail);

  const { data: AllCoworkersReminders, isValidating } =
    useAllCoworkersNotifications(emailId);
  const remindersAll = getAllCoworkersRemindersList(
    AllCoworkersReminders ?? []
  );
  const [isMe, setIsMe] = useState<boolean>(cachedCalendarData?.isMe ?? true);

  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const [calendarModeSelector, setCalendarModeSelector] = useState(
    localStorage.getItem("Views") ?? "week"
  );
  const [selectedDate, setSelectedDate] = useState(date);

  const [selectedWeek, setSelectedWeek] = useState({
    firstDayOfSelectedWeek: startOfWeek(date),
    lastDayOfSelectedWeek: endOfWeek(date),
  });

  let firstDay =
    calendarModeSelector === "week"
      ? new Date(selectedWeek.firstDayOfSelectedWeek)
      : new Date(y, m, 1);
  let lastDay =
    calendarModeSelector === "week"
      ? new Date(selectedWeek.lastDayOfSelectedWeek)
      : new Date(y, m + 1, 0);

  const [calendarFilter, setCalendarFilter] = useState({
    fromDate: firstDay,
    toDate: lastDay,
  });

  const { data: searchResult } = useAppointmentsSearch({
    filters: {
      appointmentDate: [calendarFilter.fromDate, calendarFilter.toDate],
      isMe,
      emailId,
      calendarMode: true,
    },
    options: defaultOptions,
  });
  const endAppointmentTime = (appointmentTime) => {
    let addThirtyMinutes = appointmentTime + 30 * 60 * 1000;
    return addThirtyMinutes;
  };

  const onNavigateFunc = (navigateDate) => {
    let startDateOfView, endDateOfView;

    setSelectedDate(navigateDate);

    if (calendarModeSelector === "month") {
      startDateOfView = startOfMonth(navigateDate);
      endDateOfView = endOfMonth(navigateDate);
    }
    if (calendarModeSelector === "week") {
      startDateOfView = startOfWeek(navigateDate);
      endDateOfView = endOfWeek(navigateDate);
    }
    if (calendarModeSelector === "day") {
      startDateOfView = startOfDay(navigateDate);
      endDateOfView = endOfDay(navigateDate);
    }

    setCalendarFilter({
      fromDate: startDateOfView,
      toDate: endDateOfView,
    });

    setCachedCalendarData({
      ...cachedCalendarData,
      isMe,
      selectedCoworker: emailId,
      fromDate: startDateOfView,
      toDate: endDateOfView,
    });
  };

  useEffect(() => {
    const today = new Date();
    const startOfCurrentWeek = startOfWeek(today);
    const endOfCurrentWeek = endOfWeek(today);
    setSelectedWeek({
      firstDayOfSelectedWeek: startOfCurrentWeek,
      lastDayOfSelectedWeek: endOfCurrentWeek,
    });
  }, []);

  useEffect(() => {
    onNavigateFunc(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarModeSelector]);

  const getNewReminders = (reminderList) =>
    reminderList.map((rem) => ({
      title: `${rem.title ?? ""}
${rem.comment ? `: ${rem.comment}` : ""} ${
        rem.customerName ? `- ${rem.customerName}` : ""
      } `,
      start: new Date(rem.appointmentTime),
      end: new Date(endAppointmentTime(rem.appointmentTime)),
      oppId: rem.opportunityId,
      oppType: rem.subType,
      isDeleted: rem.isDeleted,
      id: rem.id,
      coworkerId: rem.coworkerId,
    }));

  const newTasks =
    tasks
      ?.filter((ta) => ta.taskStartDateTime)
      .filter(
        (ta) =>
          ta.subTaskType !== "phoneCall" &&
          ta.subTaskType !== "otherActions" &&
          ta.subTaskType !== "bokaCoworkerPlannedSlot" &&
          ta.parentTaskType !== "schedule"
      )
      .map((ta) => ({
        title: `${ta.title ?? ""}
        ${ta.description ? `: ${ta.description}` : ""}`,
        start: new Date(ta.taskStartDateTime!),
        end: addMinutes(new Date(ta.taskStartDateTime!), 30),
        isDeleted: ta.isDone,
        id: ta.id,
        coworkerId: ta.emailId,
        oppType: ta.subTaskType,
      })) ?? [];

  const newReminders = getNewReminders(reminders);
  const newAllReminders = getNewReminders(remindersAll);

  let BOKAappointments: Array<ICoworkerAppointments> = [];

  if (searchResult) {
    BOKAappointments = searchResult.content
      .filter((a) => a.orderNumber !== null)
      .map((appt) => ({
        title: `${appt?.customer.firstName ?? ""} ${
          appt?.customer.lastName ?? ""
        }
${appt.service ? `- ${getAppointmentService(appt.service)}` : ""}`,
        start: new Date(appt.timeWindowStartTime),
        end: new Date(appt.timeWindowEndTime),
        appOppId: appt.opportunityId,
        appAppointmentId: appt.id,
        appAppointmentStatus: appt.appointmentStatus,
        appAssignedCoworker: appt.assignedCoworker,
      }));
  }

  const formats = {
    eventTimeRangeFormat: (range) =>
      `${format(range.start, "HH:mm")} – ${format(range.end, "HH:mm")}`,
    timeGutterFormat: "HH:mm",
  };

  const Event = (event) => {
    return (
      <span
        style={{ display: "inline-flex" }}
        className={
          event.event.isDeleted === true ||
          event.event.appAppointmentStatus === "COMPLETED" ||
          event.event.appAppointmentStatus === "CANCELLED" ||
          event.event.appAppointmentStatus === "CANCELLED_NO_SHOW"
            ? "iconAppCompleted"
            : "iconAppColor"
        }
      >
        <Icon
          icon={
            event.event.oppType === "Meeting"
              ? "ClockIcon"
              : event.event.oppType === "genericTask"
              ? "BellIcon"
              : event.event.oppType === "Call"
              ? "PhoneIcon"
              : event.event.oppType === "OtherAction"
              ? "DocumentPencil"
              : event.event.appAppointmentStatus === "COMPLETED"
              ? "ClipboardCheckmark"
              : event.event.appAppointmentStatus === "CANCELLED"
              ? "CrossCircle"
              : event.event.appAppointmentStatus === "CANCELLED_NO_SHOW"
              ? "VisibilityHide"
              : "LaptopPencilIcon"
          }
          size={24}
          padding={[0, 0, 5, 0]}
        />
        {event.title}
      </span>
    );
  };

  const handleRangeChange = (rangeInfo) => {
    setSelectedWeek({
      firstDayOfSelectedWeek: rangeInfo[0],
      lastDayOfSelectedWeek: rangeInfo[rangeInfo.length - 1],
    });
  };

  const calendarDataAll = [
    ...newAllReminders,
    ...BOKAappointments,
    ...newTasks,
  ];
  const calendarDataMy = [...newReminders, ...BOKAappointments, ...newTasks];

  const assignedCalendarData = calendarDataAll.filter((item) => {
    if (
      (item.appAssignedCoworker !== "" &&
        item.appAssignedCoworker !== undefined) ||
      (item.coworkerId !== "" && item.coworkerId !== undefined)
    ) {
      return true;
    }
    return false;
  });

  return (
    <>
      <Box padding={24} grow>
        <Stack vertical spacing={24}>
          <Text header="h1">
            <T id="calendar.header.title" />
          </Text>
          <Text>
            <T id="calendar.header.description" />
          </Text>
          <Box justifyContent="flex-start" alignItems="flex-start" wrap>
            <Box
              width="20px"
              height="20px"
              backgroundColor={taskColors.BOKA_APPOINTMENT}
              margin={[0, 3, 0, 0]}
            />{" "}
            <Text nowrap>
              <T id="appointment.appointments.label" />
            </Text>
            <Box
              width="20px"
              height="20px"
              backgroundColor={taskColors.Call}
              margin={[0, 3, 0, 15]}
            />{" "}
            <Text nowrap>
              <T id="calendar.action.call" />
            </Text>
            <Box
              width="20px"
              height="20px"
              borderRadius={5}
              backgroundColor={taskColors.Task}
              margin={[0, 3, 0, 15]}
            />{" "}
            <Text nowrap>
              <T
                id={
                  isMe === true
                    ? "calendar.action.my-task"
                    : "reminders.filter.type.tasks"
                }
              />
            </Text>
            <Box
              width="20px"
              height="20px"
              borderRadius={5}
              backgroundColor={taskColors.OtherAction}
              margin={[0, 3, 0, 15]}
            />{" "}
            <Text nowrap>
              <T id="calendar.action.other-action" />
            </Text>
            <Box margin={[0, 10, 0, 0]}>
              <Box
                width="20px"
                height="20px"
                borderRadius={5}
                backgroundColor={taskColors.APPOINTMENT_TIME_CROSSED}
                margin={[0, 3, 5, 15]}
              />{" "}
              <Text nowrap>
                <T id="calendar.appointment.missed" />
              </Text>
            </Box>
            <Box>
              <Box
                width="20px"
                height="20px"
                borderRadius={5}
                backgroundColor={taskColors.COMPLETED}
                margin={[0, 5, 5, 0]}
              />{" "}
              <Text nowrap>
                <T id="calendar.action.completed" />
              </Text>
            </Box>
          </Box>
          <Box>
            <Toggle
              iconOnly={false}
              buttons={[
                {
                  text: `${t("calendar.toggle.My")}`,
                },
                {
                  text: `${t("calendar.toggle.All")}`,
                },
              ]}
              fluid={true}
              activeIndex={isMe ? 0 : 1}
              onClick={(eventObj, index) => {
                const newIsMe = index !== 1;
                setIsMe(newIsMe);
                setCachedCalendarData({
                  ...cachedCalendarData,
                  isMe: newIsMe,
                  selectedCoworker: "",
                  fromDate: calendarFilter.fromDate,
                  toDate: calendarFilter.toDate,
                });
              }}
            />
          </Box>
          {isMe === false && (
            <Box>
              <CoworkerSearch
                label={t("calendar.select.coworker-name")}
                selectedMail={emailId}
                onSelect={(coworker) => {
                  setIsMe(false);
                  setEmailId(coworker.mail ?? "");
                  setCachedCalendarData({
                    ...cachedCalendarData,
                    isMe,
                    selectedCoworker: coworker.mail ?? "",
                    fromDate: calendarFilter.fromDate,
                    toDate: calendarFilter.toDate,
                  });
                }}
                onReset={() => {
                  setEmailId("");
                  setCachedCalendarData({
                    ...cachedCalendarData,
                    isMe,
                    selectedCoworker: "",
                    fromDate: calendarFilter.fromDate,
                    toDate: calendarFilter.toDate,
                  });
                }}
              />
            </Box>
          )}

          {isValidating && <Loading isLoading={isValidating} />}

          <Box borderColor="brandBlue" grow>
            <Calendar
              onNavigate={onNavigateFunc}
              onRangeChange={handleRangeChange}
              components={{
                event: Event,
              }}
              popup
              culture={locale.locale}
              localizer={localizer}
              events={isMe === false ? assignedCalendarData : calendarDataMy}
              startAccessor={"start"}
              endAccessor={"end"}
              step={10}
              timeslots={6}
              formats={formats}
              scrollToTime={new Date()}
              messages={{
                previous: t("calendar.previous.button"),
                next: t("calendar.next.button"),
                today: t("calendar.today.button"),
                day: t("calendar.day.button"),
                week: t("calendar.week.button"),
                month: t("calendar.month.button"),
                agenda: t("calendar.agenda.button"),
                noEventsInRange: t("calendar.no-events.text"),
                showMore: (total) => `+${total} ${t("calendar.more.link")}`,
              }}
              min={new Date(0, 0, 0, 0, 0, 0)}
              max={new Date(0, 0, 0, 23, 59, 59)}
              dayLayoutAlgorithm="no-overlap"
              views={["day", "week", "month", "agenda"]}
              onView={(e) => {
                setCalendarModeSelector(e);
                localStorage.setItem("Views", e);
              }}
              defaultView={
                localStorage.getItem("Views") === ""
                  ? Views.WEEK
                  : localStorage.getItem("Views") === "day"
                  ? Views.DAY
                  : localStorage.getItem("Views") === "week"
                  ? Views.WEEK
                  : localStorage.getItem("Views") === "month"
                  ? Views.MONTH
                  : localStorage.getItem("Views") === "agenda"
                  ? Views.AGENDA
                  : Views.WEEK
              }
              onSelectEvent={(event) => {
                tracker.event({
                  category: "Calendar View",
                  action: "Clicked on 'My appointments' through calendar view",
                });
                if (event.oppId) {
                  history.push(`/opportunity/${event.oppId}#id=${event.id}`);
                }
                if (event.appOppId && event.appAppointmentId) {
                  history.push(
                    `/opportunity/${event.appOppId}#section=appointment&appointmentId=${event.appAppointmentId}`
                  );
                }
                setTasksIsOpen(true);
              }}
              eventPropGetter={(event) => {
                const newStyle = {
                  backgroundColor: Colors.appBoka,
                  color: Colors.colorBlack,
                  borderRadius: "0",
                  border: "none",
                  cursor: "pointer",
                  animation: "none",
                };
                newStyle.backgroundColor = taskColors[event.oppType]
                  ? taskColors[event.oppType]
                  : newStyle.backgroundColor;
                if (
                  event.isDeleted === true ||
                  event.appAppointmentStatus === "COMPLETED" ||
                  event.appAppointmentStatus === "CANCELLED" ||
                  event.appAppointmentStatus === "CANCELLED_NO_SHOW"
                ) {
                  newStyle.backgroundColor = Colors.appMarkAsDone;
                  newStyle.color = Colors.textWhite;
                }
                if (
                  new Date() > event.end &&
                  event.reasonCode !== "BOKA_OPEN_SLOT" &&
                  (event.appointmentStatus === "PLANNED" ||
                    event.appAppointmentStatus === "PUBLISHED" ||
                    event.appAppointmentStatus === "ORDER_RECEIVED" ||
                    event.appAppointmentStatus ===
                      "ORDER_RECEIVED_WAITING_FOR_PAYMENT") &&
                  !event.oppType
                ) {
                  newStyle.backgroundColor = Colors.appTimeCrossed;
                }
                return { style: newStyle };
              }}
              style={{
                height: 650,
                width: "1150px",
                color: Colors.logoBlue,
                backgroundColor: Colors.calendarGrey,
              }}
            />
            <Tasks isOpen={tasksIsOpen} onClose={() => setTasksIsOpen(false)} />
          </Box>
        </Stack>
      </Box>
    </>
  );
};
