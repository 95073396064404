import { useContext, useState, useEffect } from "react";
import {
  savePreference,
  useConfig,
  usePreferences,
  Preference,
} from "common/api/preference";
import { Button, Toast, Text, Stack, Checkbox } from "components";
import { logOut } from "authorization";
import { sortRecordByValue } from "common";
import { useProfile } from "common/api";
import { ApiError } from "common/api/ApiError";
import { clearCachedLocale } from "common/hooks";
import { LocaleContext, T, t } from "i18n";
import { features } from "common/feature-flags";
import { xor, range } from "lodash";
import Skeleton from "@ingka/skeleton";
import { useUserRole } from "authorization/userRoleHook";

export const Profile = () => {
  const { isAdmin } = useUserRole();
  const { data: config } = useConfig();
  const { data: profile } = useProfile();
  const { data: preference } = usePreferences(profile?.mailNickname);
  const [selectedDepartments, setSelectedDepartments] = useState<
    Preference["hfbList"]
  >([]);
  const localeContext = useContext(LocaleContext);
  const [toastMessage, setToastMessage] = useState<{
    message: string;
    time: Date;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (preference?.hfbList) {
      setSelectedDepartments(preference.hfbList);
    }
  }, [preference]);

  const toggleDepartmentCheckbox = (id: string) => {
    setSelectedDepartments(xor(selectedDepartments, [id]));
  };

  const onSavePreference = () => {
    setIsLoading(true);
    const data = {
      id: profile?.mail,
      hfbList: selectedDepartments,
    };
    savePreference(data, profile?.mailNickname)
      .catch((e: ApiError) => {
        setToastMessage({
          message: t("user.profile.toast.failed"),
          time: e.timestamp,
        });
      })
      .then(() => {
        setToastMessage({
          message: t("user.profile.toast.success"),
          time: new Date(),
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleLogOut = () => {
    window.sessionStorage.removeItem("userCountry");
    window.sessionStorage.removeItem("networkName");
    clearCachedLocale();
    logOut();
  };

  return (
    <Stack vertical spacing={20}>
      {toastMessage && (
        <Toast text={toastMessage.message} time={toastMessage.time} />
      )}
      <Text header="h2">{profile?.displayName}</Text>
      <Stack justifyContent="space-between" alignItems="center">
        <Text>
          {profile?.officeLocation} {profile?.department}
        </Text>
        <Button
          text={t("user.profile.logout")}
          primary
          onClick={() => handleLogOut()}
        />
      </Stack>
      {features.localization && (
        <Button
          onClick={() => localeContext.setShowLocalePrompt(true)}
          text={t("user.profile.change-language-button-text")}
        />
      )}
      {isAdmin && (
        <Button
          onClick={() => localeContext.setShowMarketPrompt(true)}
          text={t("user.profile.market-override.header")}
        />
      )}
      {!preference || !config ? (
        <>
          {range(5).map((n) => (
            <Skeleton key={n} />
          ))}
        </>
      ) : (
        <>
          <Text header="h3">
            <T id="user.profile.department-title" />
          </Text>
          <Stack vertical spacing={12}>
            {sortRecordByValue(
              config.department,
              localeContext.activeLocale.languageCode
            ).map(([id]) => (
              <Checkbox
                leadingLabel
                key={id}
                id={id}
                label={config.department[id]}
                checked={selectedDepartments.includes(id)}
                onChange={() => toggleDepartmentCheckbox(id)}
              />
            ))}
          </Stack>
          <Button
            text={t("user.profile.save-preference.button")}
            emphasised
            onClick={onSavePreference}
            loading={isLoading}
          />
        </>
      )}
    </Stack>
  );
};
