import { useLocation } from "react-router-dom";
import { User } from "features/user";
import { RemindersAndNotifications } from "features/reminders";
import { css } from "@emotion/css";
import { Colors, Stack } from "components";

export const Header = () => {
  const location = useLocation();
  return (
    <header
      className={css`
        height: 5rem;
        padding: 1rem;
        display: flex;
        justify-content: flex-end;
        top: 0;
        border-bottom: 1px solid ${Colors.neutralGrey200};
        background-color: ${Colors.neutralWhite};
        position: ${location.pathname.includes("opportunity")
          ? "relative"
          : "sticky"};
        z-index: 4;
      `}
    >
      <Stack dontGrow>
        <User />
        <RemindersAndNotifications />
      </Stack>
    </header>
  );
};
