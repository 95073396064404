// eslint-disable-next-line
const URL_REGEX =
  // eslint-disable-next-line
  /(http|https):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const cleanupUrl = (url) => url.replace(/((http|https)?):\/\//, "");

export const renderLinkedText = (txt) =>
  txt.split(" ").map((part) =>
    URL_REGEX.test(part) ? (
      <a
        href={part}
        style={{ textDecoration: "underline" }}
        target="_blank"
        rel="noreferrer"
      >
        {cleanupUrl(part)}{" "}
      </a>
    ) : (
      part + " "
    )
  );
