import { useEffect, useRef } from "react";
import { Box, Stack, TextAreaFixed, InputField } from "components";
import { Note } from "common/api";
import { t } from "i18n";

interface INoteFormProps {
  note?: Partial<Note>;
  setNote: (note: Partial<Note> | undefined) => void;
}

export const NoteForm: React.FunctionComponent<INoteFormProps> = ({
  setNote,
  note = {
    description: "",
    title: "",
  },
}) => {
  const referenceDiv = useRef<HTMLDivElement>(null);
  useEffect(() => {
    referenceDiv.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  }, []);
  return (
    <Box width="100%">
      <Stack vertical spacing={32}>
        <div ref={referenceDiv} />
        <InputField
          type="text"
          label={t("my-opportunities.view-my-notes.title.label")}
          placeholder={t("my-opportunities.view-my-notes.title.placeholder")}
          name={`title-${note.id || ""}`}
          defaultValue={note.title}
          optional
          maxLength={200}
          onChange={(event) => setNote({ ...note, title: event.target.value })}
        />
        <TextAreaFixed
          id={`description-${note.id || ""}`}
          value={note.description}
          label={t("my-opportunities.view-my-notes.note-description.label")}
          placeholder={t(
            "my-opportunities.view-my-notes.note-description.placeholder"
          )}
          maxLength={500}
          onChange={(event) =>
            setNote({ ...note, description: event.target.value })
          }
        />
      </Stack>
    </Box>
  );
};

NoteForm.displayName = "NoteForm";
