import { css } from "@emotion/css";
import { ReactNode } from "react";
import { Text } from "../../typography";
import { Colors } from "../../Variables";

interface Props {
  /* Provide a text label, otherwise renders children */
  text?: string;
  selected?: boolean;
  onClick(): void;
  closePopover?(): void;
  children?: ReactNode;
}

export const PopoverItem = ({
  onClick,
  children,
  text,
  selected,
  closePopover,
}: Props) => {
  return (
    <div
      onClick={() => {
        onClick();
        closePopover?.();
      }}
      className={css`
        min-width: 150px;
        min-height: 40px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        display: flex;
        box-sizing: border-box;
        background-color: ${selected
          ? Colors.neutralGrey200
          : Colors.neutralWhite};
        flex-direction: column;
        border-radius: 3px;
        :hover {
          background-color: ${Colors.neutralGrey100};
        }
      `}
    >
      {text ? (
        <Text bold xsmall>
          {text}
        </Text>
      ) : (
        children
      )}
    </div>
  );
};

PopoverItem.displayName = "PopoverItem";
