import { useContext, useState } from "react";
import { Line, Stack } from "components/layout";
import { Text } from "components/typography";
import { Button } from "components";
import { ConsentModal } from "./ConsentModal";
import { Icon } from "components/ui";
import { OpportunityContext } from "../../OpportunityContext";
import { t } from "i18n";
import { useConfig } from "common/api";
import { renderLinkedText } from "common/helpers/renderLinkedText";

export const CustomerConsent = () => {
  const [showModal, setShowModal] = useState(false);
  const { isExistingCustomer, consents } = useContext(OpportunityContext);
  const { followUpConsent, privacyPolicy } = consents;
  const { data: config } = useConfig();
  const requirePrivacyPolicy = !!config?.customer?.requirePrivacyPolicy;

  return (
    <Stack vertical>
      <Line dotted />

      <Text bold header="h3">
        {t("new-opportunity.customer-consent.form.title")}
      </Text>
      <Stack>
        <Button
          text={t("new-opportunity.customer-consent.open-form.button")}
          primary
          small
          fluid
          onClick={() => setShowModal(true)}
        />
        <ConsentModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
        />
      </Stack>
      {consents && (
        <>
          {config?.customer?.requireFollowUpConsent === true && (
            <Stack>
              <Icon icon={followUpConsent ? "Checkmark" : "CrossSmall"} />

              {followUpConsent ? (
                <Text pre>
                  {renderLinkedText(
                    t(
                      "new-opportunity.customer-consent.follow-up.agree.confirmation"
                    )
                  )}
                </Text>
              ) : (
                <Text pre>
                  {renderLinkedText(
                    t(
                      "new-opportunity.customer-consent.follow-up.disagree.confirmation"
                    )
                  )}
                </Text>
              )}
            </Stack>
          )}
          {!isExistingCustomer && requirePrivacyPolicy === true && (
            <Stack>
              <Icon icon={privacyPolicy ? "Checkmark" : "CrossSmall"} />
              {privacyPolicy ? (
                <Text pre>
                  {renderLinkedText(
                    t(
                      "new-opportunity.customer-consent.privacy-policy.confirmation"
                    )
                  )}
                </Text>
              ) : (
                <Text pre>
                  {renderLinkedText(
                    t(
                      "new-opportunity.customer-consent.privacy-policy.disagree.confirmation"
                    )
                  )}
                </Text>
              )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
};
