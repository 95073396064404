import React, { useState, ReactNode } from "react";
import SkapaModal, {
  Sheets,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "@ingka/modal";
import { ModalContext } from "./context";

interface Props {
  visible: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const Modal = ({ visible, onClose, children }: Props) => {
  const [header, setHeader] = useState<ReturnType<typeof ModalHeader>>(null);
  const [footer, setFooter] = useState<ReturnType<typeof ModalFooter>>(null);
  return (
    <ModalContext.Provider
      value={{
        header,
        footer,
        setHeader,
        setFooter,
      }}
    >
      <SkapaModal handleCloseBtn={onClose} visible={visible} escapable={false}>
        <Sheets closeBtnClick={onClose} header={header} footer={footer}>
          <ModalBody>{children}</ModalBody>
        </Sheets>
      </SkapaModal>
    </ModalContext.Provider>
  );
};
