/**
 * Display a number as a currency in the user's locale
 *
 * @param value       The value (amount) of the currency to format
 * @param currency    The currency to display the value in
 * @param locale      The locale sortcode (ie. "fr-CA" to use)
 * @param showSign    Show the currency code. Defaults to false which will only display the number
 * @param fractions   How many fractions to display. Defaults to 0
 */
export const currencyFormatter = (
  value: number,
  currency: string,
  locale: string,
  showSign: boolean = false,
  fractions: number = 0
) => {
  const currencyFormat = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency.toUpperCase(),
    minimumFractionDigits: fractions,
    maximumFractionDigits: fractions,
    currencyDisplay: !showSign ? "code" : undefined,
  });

  return showSign
    ? currencyFormat.format(value)
    : currencyFormat.format(value).replace(currency, "");
};
