export interface DialingCode {
  name: string;
  prefix: string;
  code: string;
  regex?: RegExp;
  example?: string;
}

export const importantCountries: Record<string, string[]> = {
  AU: ["NZ"],
  CA: ["US"],
  SE: ["DK", "FI", "NO"],
  US: ["CA", "MX"],
};

export const dialingCodes: DialingCode[] = [
  {
    name: "Afghanistan",
    prefix: "+93",
    code: "AF",
    regex: undefined,
  },
  {
    name: "Aland Islands",
    prefix: "+358",
    code: "AX",
    regex: undefined,
  },
  {
    name: "Albania",
    prefix: "+355",
    code: "AL",
    regex: /^\+355\d{7,20}$/,
  },
  {
    name: "Algeria",
    prefix: "+213",
    code: "DZ",
    regex: undefined,
  },
  {
    name: "AmericanSamoa",
    prefix: "+1684",
    code: "AS",
    regex: undefined,
  },
  {
    name: "Andorra",
    prefix: "+376",
    code: "AD",
    regex: undefined,
  },
  {
    name: "Angola",
    prefix: "+244",
    code: "AO",
    regex: undefined,
  },
  {
    name: "Anguilla",
    prefix: "+1264",
    code: "AI",
    regex: undefined,
  },
  {
    name: "Antarctica",
    prefix: "+672",
    code: "AQ",
    regex: undefined,
  },
  {
    name: "Antigua and Barbuda",
    prefix: "+1268",
    code: "AG",
    regex: undefined,
  },
  {
    name: "Argentina",
    prefix: "+54",
    code: "AR",
    regex: undefined,
  },
  {
    name: "Armenia",
    prefix: "+374",
    code: "AM",
    regex: undefined,
  },
  {
    name: "Aruba",
    prefix: "+297",
    code: "AW",
    regex: undefined,
  },
  {
    name: "Australia",
    prefix: "+61",
    code: "AU",
    regex: /^\+61(?!\d*(\d)\1{7}\d*)2|4|9\d{8}$/,
    example: "0412345678",
  },
  {
    name: "Austria",
    prefix: "+43",
    code: "AT",
    regex:
      /^\+436(?:44|50|51|52|53|55|57|59|60|61|63|64|647|6475|648|65|66|67|68|680|681|688|69|70|76|768|7681|7682|7683|7684|7685|7686|7687|7688|7689|77|78|80|81|812|818|86|88|89|90|991|991599|992|998|9988|9989)[\d]{6,10}$/,
  },
  {
    name: "Azerbaijan",
    prefix: "+994",
    code: "AZ",
    regex: undefined,
  },
  {
    name: "Bahamas",
    prefix: "+1242",
    code: "BS",
    regex: undefined,
  },
  {
    name: "Bahrain",
    prefix: "+973",
    code: "BH",
    regex: /^\+973(?:3[28934567]|66)\d{6}$/,
  },
  {
    name: "Bangladesh",
    prefix: "+880",
    code: "BD",
    regex: undefined,
  },
  {
    name: "Barbados",
    prefix: "+1246",
    code: "BB",
    regex: undefined,
  },
  {
    name: "Belarus",
    prefix: "+375",
    code: "BY",
    regex: undefined,
  },
  {
    name: "Belgium",
    prefix: "+32",
    code: "BE",
    regex: /^\+324\d{8}$/,
  },
  {
    name: "Belize",
    prefix: "+501",
    code: "BZ",
    regex: undefined,
  },
  {
    name: "Benin",
    prefix: "+229",
    code: "BJ",
    regex: undefined,
  },
  {
    name: "Bermuda",
    prefix: "+1441",
    code: "BM",
    regex: undefined,
  },
  {
    name: "Bhutan",
    prefix: "+975",
    code: "BT",
    regex: undefined,
  },
  {
    name: "Bolivia, Plurinational State of",
    prefix: "+591",
    code: "BO",
    regex: undefined,
  },
  {
    name: "Bosnia and Herzegovina",
    prefix: "+387",
    code: "BA",
    regex: /^\+387\d{7,20}$/,
  },
  {
    name: "Botswana",
    prefix: "+267",
    code: "BW",
    regex: undefined,
  },
  {
    name: "Brazil",
    prefix: "+55",
    code: "BR",
    regex: undefined,
  },
  {
    name: "British Indian Ocean Territory",
    prefix: "+246",
    code: "IO",
    regex: undefined,
  },
  {
    name: "Brunei Darussalam",
    prefix: "+673",
    code: "BN",
    regex: undefined,
  },
  {
    name: "Bulgaria",
    prefix: "+359",
    code: "BG",
    regex: /^\+359\d{7,20}$/,
  },
  {
    name: "Burkina Faso",
    prefix: "+226",
    code: "BF",
    regex: undefined,
  },
  {
    name: "Burundi",
    prefix: "+257",
    code: "BI",
    regex: undefined,
  },
  {
    name: "Cambodia",
    prefix: "+855",
    code: "KH",
    regex: undefined,
  },
  {
    name: "Cameroon",
    prefix: "+237",
    code: "CM",
    regex: undefined,
  },
  {
    name: "Canada",
    prefix: "+1",
    code: "CA",
    regex: /^\+1[2-9]\d{9}$/,
    example: "2123456789",
  },
  {
    name: "Cape Verde",
    prefix: "+238",
    code: "CV",
    regex: undefined,
  },
  {
    name: "Cayman Islands",
    prefix: "+ 345",
    code: "KY",
    regex: undefined,
  },
  {
    name: "Central African Republic",
    prefix: "+236",
    code: "CF",
    regex: undefined,
  },
  {
    name: "Chad",
    prefix: "+235",
    code: "TD",
    regex: undefined,
  },
  {
    name: "Chile",
    prefix: "+56",
    code: "CL",
    regex: undefined,
  },
  {
    name: "China",
    prefix: "+86",
    code: "CN",
    regex: undefined,
  },
  {
    name: "Christmas Island",
    prefix: "+61",
    code: "CX",
    regex: undefined,
  },
  {
    name: "Cocos (Keeling) Islands",
    prefix: "+61",
    code: "CC",
    regex: undefined,
  },
  {
    name: "Colombia",
    prefix: "+57",
    code: "CO",
    regex: undefined,
  },
  {
    name: "Comoros",
    prefix: "+269",
    code: "KM",
    regex: undefined,
  },
  {
    name: "Congo",
    prefix: "+242",
    code: "CG",
    regex: undefined,
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    prefix: "+243",
    code: "CD",
    regex: undefined,
  },
  {
    name: "Cook Islands",
    prefix: "+682",
    code: "CK",
    regex: undefined,
  },
  {
    name: "Costa Rica",
    prefix: "+506",
    code: "CR",
    regex: undefined,
  },
  {
    name: "Cote d'Ivoire",
    prefix: "+225",
    code: "CI",
    regex: undefined,
  },
  {
    name: "Croatia",
    prefix: "+385",
    code: "HR",
    regex: /^\+3859\d{7,8}$/,
  },
  {
    name: "Cuba",
    prefix: "+53",
    code: "CU",
    regex: undefined,
  },
  {
    name: "Cyprus",
    prefix: "+357",
    code: "CY",
    regex: undefined,
  },
  {
    name: "Czech Republic",
    prefix: "+420",
    code: "CZ",
    regex: /^\+420\d{9}$/,
  },
  {
    name: "Denmark",
    prefix: "+45",
    code: "DK",
    regex: /^\+45\d{8}$/,
    example: "12345678",
  },
  {
    name: "Djibouti",
    prefix: "+253",
    code: "DJ",
    regex: undefined,
  },
  {
    name: "Dominica",
    prefix: "+1767",
    code: "DM",
    regex: undefined,
  },
  {
    name: "Dominican Republic",
    prefix: "+1849",
    code: "DO",
    regex: undefined,
  },
  {
    name: "Ecuador",
    prefix: "+593",
    code: "EC",
    regex: undefined,
  },
  {
    name: "Egypt",
    prefix: "+20",
    code: "EG",
    regex: undefined,
  },
  {
    name: "El Salvador",
    prefix: "+503",
    code: "SV",
    regex: undefined,
  },
  {
    name: "Equatorial Guinea",
    prefix: "+240",
    code: "GQ",
    regex: undefined,
  },
  {
    name: "Eritrea",
    prefix: "+291",
    code: "ER",
    regex: undefined,
  },
  {
    name: "Estonia",
    prefix: "+372",
    code: "EE",
    regex: /^\+372\d{7,8}$/,
  },
  {
    name: "Ethiopia",
    prefix: "+251",
    code: "ET",
    regex: undefined,
  },
  {
    name: "Falkland Islands (Malvinas)",
    prefix: "+500",
    code: "FK",
    regex: undefined,
  },
  {
    name: "Faroe Islands",
    prefix: "+298",
    code: "FO",
    regex: undefined,
  },
  {
    name: "Fiji",
    prefix: "+679",
    code: "FJ",
    regex: undefined,
  },
  {
    name: "Finland",
    prefix: "+358",
    code: "FI",
    regex: /^\+358\d{4,12}$/,
    example: "123456789",
  },
  {
    name: "France",
    prefix: "+33",
    code: "FR",
    regex: /^\+33\d{9}$/,
  },
  {
    name: "French Guiana",
    prefix: "+594",
    code: "GF",
    regex: undefined,
  },
  {
    name: "French Polynesia",
    prefix: "+689",
    code: "PF",
    regex: undefined,
  },
  {
    name: "Gabon",
    prefix: "+241",
    code: "GA",
    regex: undefined,
  },
  {
    name: "Gambia",
    prefix: "+220",
    code: "GM",
    regex: undefined,
  },
  {
    name: "Georgia",
    prefix: "+995",
    code: "GE",
    regex: undefined,
  },
  {
    name: "Germany",
    prefix: "+49",
    code: "DE",
    regex: /^\+49\d{10,11}$/,
  },
  {
    name: "Ghana",
    prefix: "+233",
    code: "GH",
    regex: undefined,
  },
  {
    name: "Gibraltar",
    prefix: "+350",
    code: "GI",
    regex: undefined,
  },
  {
    name: "Greece",
    prefix: "+30",
    code: "GR",
    regex: undefined,
  },
  {
    name: "Greenland",
    prefix: "+299",
    code: "GL",
    regex: undefined,
  },
  {
    name: "Grenada",
    prefix: "+1473",
    code: "GD",
    regex: undefined,
  },
  {
    name: "Guadeloupe",
    prefix: "+590",
    code: "GP",
    regex: undefined,
  },
  {
    name: "Guam",
    prefix: "+1671",
    code: "GU",
    regex: undefined,
  },
  {
    name: "Guatemala",
    prefix: "+502",
    code: "GT",
    regex: undefined,
  },
  {
    name: "Guernsey",
    prefix: "+44",
    code: "GG",
    regex: undefined,
  },
  {
    name: "Guinea",
    prefix: "+224",
    code: "GN",
    regex: undefined,
  },
  {
    name: "Guinea-Bissau",
    prefix: "+245",
    code: "GW",
    regex: undefined,
  },
  {
    name: "Guyana",
    prefix: "+595",
    code: "GY",
    regex: undefined,
  },
  {
    name: "Haiti",
    prefix: "+509",
    code: "HT",
    regex: undefined,
  },
  {
    name: "Holy See (Vatican City State)",
    prefix: "+379",
    code: "VA",
    regex: undefined,
  },
  {
    name: "Honduras",
    prefix: "+504",
    code: "HN",
    regex: undefined,
  },
  {
    name: "Hong Kong",
    prefix: "+852",
    code: "HK",
    regex: undefined,
  },
  {
    name: "Hungary",
    prefix: "+36",
    code: "HU",
    regex: /^\+36\d{8,9}$/,
  },
  {
    name: "Iceland",
    prefix: "+354",
    code: "IS",
    regex: undefined,
  },
  {
    name: "India",
    prefix: "+91",
    code: "IN",
    regex: /^\+91[6789]\d{9}$/,
  },
  {
    name: "Indonesia",
    prefix: "+62",
    code: "ID",
    regex: undefined,
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    prefix: "+98",
    code: "IR",
    regex: undefined,
  },
  {
    name: "Iraq",
    prefix: "+964",
    code: "IQ",
    regex: undefined,
  },
  {
    name: "Ireland",
    prefix: "+353",
    code: "IE",
    regex: /^\+353\d{9,10}$/,
  },
  {
    name: "Isle of Man",
    prefix: "+44",
    code: "IM",
    regex: undefined,
  },
  {
    name: "Israel",
    prefix: "+972",
    code: "IL",
    regex: undefined,
  },
  {
    name: "Italy",
    prefix: "+39",
    code: "IT",
    regex: /^\+39\d{6,11}/,
  },
  {
    name: "Jamaica",
    prefix: "+1876",
    code: "JM",
    regex: undefined,
  },
  {
    name: "Japan",
    prefix: "+81",
    code: "JP",
    regex: /^(\+[0-9]{1,3}[0-9]{6,14}|[0-9]{1,3}[0-9]{6,12})$/,
  },
  {
    name: "Jersey",
    prefix: "+44",
    code: "JE",
    regex: undefined,
  },
  {
    name: "Jordan",
    prefix: "+962",
    code: "JO",
    regex: /^\+962\d{9}$/,
  },
  {
    name: "Kazakhstan",
    prefix: "+77",
    code: "KZ",
    regex: /^\+77\d{9}$/,
  },
  {
    name: "Kenya",
    prefix: "+254",
    code: "KE",
    regex: undefined,
  },
  {
    name: "Kiribati",
    prefix: "+686",
    code: "KI",
    regex: undefined,
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    prefix: "+850",
    code: "KP",
    regex: undefined,
  },
  {
    name: "Korea, Republic of South Korea",
    prefix: "+82",
    code: "KR",
    regex: /^\+821[016789]\d{7,8}$/,
  },
  {
    name: "Kuwait",
    prefix: "+965",
    code: "KW",
    regex: /^\+965\d{8}$/,
  },
  {
    name: "Kyrgyzstan",
    prefix: "+996",
    code: "KG",
    regex: undefined,
  },
  {
    name: "Laos",
    prefix: "+856",
    code: "LA",
    regex: undefined,
  },
  {
    name: "Latvia",
    prefix: "+371",
    code: "LV",
    regex: undefined,
  },
  {
    name: "Lebanon",
    prefix: "+961",
    code: "LB",
    regex: undefined,
  },
  {
    name: "Lesotho",
    prefix: "+266",
    code: "LS",
    regex: undefined,
  },
  {
    name: "Liberia",
    prefix: "+231",
    code: "LR",
    regex: undefined,
  },
  {
    name: "Libyan Arab Jamahiriya",
    prefix: "+218",
    code: "LY",
    regex: undefined,
  },
  {
    name: "Liechtenstein",
    prefix: "+423",
    code: "LI",
    regex: /^\+4237\d{7}$/,
  },
  {
    name: "Lithuania",
    prefix: "+370",
    code: "LT",
    regex: undefined,
  },
  {
    name: "Luxembourg",
    prefix: "+352",
    code: "LU",
    regex: undefined,
  },
  {
    name: "Macao",
    prefix: "+853",
    code: "MO",
    regex: undefined,
  },
  {
    name: "Macedonia",
    prefix: "+389",
    code: "MK",
    regex: /^\+389\d{7,20}$/,
  },
  {
    name: "Madagascar",
    prefix: "+261",
    code: "MG",
    regex: undefined,
  },
  {
    name: "Malawi",
    prefix: "+265",
    code: "MW",
    regex: undefined,
  },
  {
    name: "Malaysia",
    prefix: "+60",
    code: "MY",
    regex: undefined,
  },
  {
    name: "Maldives",
    prefix: "+960",
    code: "MV",
    regex: undefined,
  },
  {
    name: "Mali",
    prefix: "+223",
    code: "ML",
    regex: undefined,
  },
  {
    name: "Malta",
    prefix: "+356",
    code: "MT",
    regex: undefined,
  },
  {
    name: "Marshall Islands",
    prefix: "+692",
    code: "MH",
    regex: undefined,
  },
  {
    name: "Martinique",
    prefix: "+596",
    code: "MQ",
    regex: undefined,
  },
  {
    name: "Mauritania",
    prefix: "+222",
    code: "MR",
    regex: undefined,
  },
  {
    name: "Mauritius",
    prefix: "+230",
    code: "MU",
    regex: undefined,
  },
  {
    name: "Mayotte",
    prefix: "+262",
    code: "YT",
    regex: undefined,
  },
  {
    name: "Mexico",
    prefix: "+52",
    code: "MX",
    regex: /^\+52[2-9]\d{9}$/,
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    prefix: "+691",
    code: "FM",
    regex: undefined,
  },
  {
    name: "Moldova",
    prefix: "+373",
    code: "MD",
    regex: undefined,
  },
  {
    name: "Monaco",
    prefix: "+377",
    code: "MC",
    regex: undefined,
  },
  {
    name: "Mongolia",
    prefix: "+976",
    code: "MN",
    regex: undefined,
  },
  {
    name: "Montenegro",
    prefix: "+382",
    code: "ME",
    regex: /^\+382\d{7,20}$/,
  },
  {
    name: "Montserrat",
    prefix: "+1664",
    code: "MS",
    regex: undefined,
  },
  {
    name: "Morocco",
    prefix: "+212",
    code: "MA",
    regex: /^\+212\d{9}$/,
  },
  {
    name: "Mozambique",
    prefix: "+258",
    code: "MZ",
    regex: undefined,
  },
  {
    name: "Myanmar",
    prefix: "+95",
    code: "MM",
    regex: undefined,
  },
  {
    name: "Namibia",
    prefix: "+264",
    code: "NA",
    regex: undefined,
  },
  {
    name: "Nauru",
    prefix: "+674",
    code: "NR",
    regex: undefined,
  },
  {
    name: "Nepal",
    prefix: "+977",
    code: "NP",
    regex: undefined,
  },
  {
    name: "Netherlands",
    prefix: "+31",
    code: "NL",
    regex: /^\+31\d{9}$/,
  },
  {
    name: "Netherlands Antilles",
    prefix: "+599",
    code: "AN",
    regex: undefined,
  },
  {
    name: "New Caledonia",
    prefix: "+687",
    code: "NC",
    regex: undefined,
  },
  {
    name: "New Zealand",
    prefix: "+64",
    code: "NZ",
    regex: undefined,
  },
  {
    name: "Nicaragua",
    prefix: "+505",
    code: "NI",
    regex: undefined,
  },
  {
    name: "Niger",
    prefix: "+227",
    code: "NE",
    regex: undefined,
  },
  {
    name: "Nigeria",
    prefix: "+234",
    code: "NG",
    regex: undefined,
  },
  {
    name: "Niue",
    prefix: "+683",
    code: "NU",
    regex: undefined,
  },
  {
    name: "Norfolk Island",
    prefix: "+672",
    code: "NF",
    regex: undefined,
  },
  {
    name: "Northern Mariana Islands",
    prefix: "+1670",
    code: "MP",
    regex: undefined,
  },
  {
    name: "Norway",
    prefix: "+47",
    code: "NO",
    regex: /^\+47[2459]\d{7}$/,
    example: "1234567",
  },
  {
    name: "Oman",
    prefix: "+968",
    code: "OM",
    regex: undefined,
  },
  {
    name: "Pakistan",
    prefix: "+92",
    code: "PK",
    regex: undefined,
  },
  {
    name: "Palau",
    prefix: "+680",
    code: "PW",
    regex: undefined,
  },
  {
    name: "Palestinian Territory, Occupied",
    prefix: "+970",
    code: "PS",
    regex: undefined,
  },
  {
    name: "Panama",
    prefix: "+507",
    code: "PA",
    regex: undefined,
  },
  {
    name: "Papua New Guinea",
    prefix: "+675",
    code: "PG",
    regex: undefined,
  },
  {
    name: "Paraguay",
    prefix: "+595",
    code: "PY",
    regex: undefined,
  },
  {
    name: "Peru",
    prefix: "+51",
    code: "PE",
    regex: undefined,
  },
  {
    name: "Philippines",
    prefix: "+63",
    code: "PH",
    regex: /^\+63\d{10}$/,
  },
  {
    name: "Pitcairn",
    prefix: "+872",
    code: "PN",
    regex: undefined,
  },
  {
    name: "Poland",
    prefix: "+48",
    code: "PL",
    regex: /^\+48\d{9}$/,
  },
  {
    name: "Portugal",
    prefix: "+351",
    code: "PT",
    regex: /^\+3519\d{8}$/,
  },
  {
    name: "Puerto Rico",
    prefix: "+1939",
    code: "PR",
    regex: undefined,
  },
  {
    name: "Qatar",
    prefix: "+974",
    code: "QA",
    regex: undefined,
  },
  {
    name: "Romania",
    prefix: "+40",
    code: "RO",
    regex: /^\+407\d{7,8}$/,
  },
  {
    name: "Russia",
    prefix: "+7",
    code: "RU",
    regex: /^\+7[79]\d{9}$/,
  },
  {
    name: "Rwanda",
    prefix: "+250",
    code: "RW",
    regex: undefined,
  },
  {
    name: "Reunion",
    prefix: "+262",
    code: "RE",
    regex: undefined,
  },
  {
    name: "Saint Barthelemy",
    prefix: "+590",
    code: "BL",
    regex: undefined,
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    prefix: "+290",
    code: "SH",
    regex: undefined,
  },
  {
    name: "Saint Kitts and Nevis",
    prefix: "+1869",
    code: "KN",
    regex: undefined,
  },
  {
    name: "Saint Lucia",
    prefix: "+1758",
    code: "LC",
    regex: undefined,
  },
  {
    name: "Saint Martin",
    prefix: "+590",
    code: "MF",
    regex: undefined,
  },
  {
    name: "Saint Pierre and Miquelon",
    prefix: "+508",
    code: "PM",
    regex: undefined,
  },
  {
    name: "Saint Vincent and the Grenadines",
    prefix: "+1784",
    code: "VC",
    regex: undefined,
  },
  {
    name: "Samoa",
    prefix: "+685",
    code: "WS",
    regex: undefined,
  },
  {
    name: "San Marino",
    prefix: "+378",
    code: "SM",
    regex: undefined,
  },
  {
    name: "Sao Tome and Principe",
    prefix: "+239",
    code: "ST",
    regex: undefined,
  },
  {
    name: "Saudi Arabia",
    prefix: "+966",
    code: "SA",
    regex: /^\+9665\d{8}$/,
  },
  {
    name: "Senegal",
    prefix: "+221",
    code: "SN",
    regex: undefined,
  },
  {
    name: "Serbia",
    prefix: "+381",
    code: "RS",
    regex: /^\+3816\d{7,8}$/,
  },
  {
    name: "Seychelles",
    prefix: "+248",
    code: "SC",
    regex: undefined,
  },
  {
    name: "Sierra Leone",
    prefix: "+232",
    code: "SL",
    regex: undefined,
  },
  {
    name: "Singapore",
    prefix: "+65",
    code: "SG",
    regex: undefined,
  },
  {
    name: "Slovakia",
    prefix: "+421",
    code: "SK",
    regex: /^\+421\d{9}$/,
  },
  {
    name: "Slovenia",
    prefix: "+386",
    code: "SI",
    regex: /^\+386(?:[347][01]|6[4589]|51)\d{6}$/,
  },
  {
    name: "Solomon Islands",
    prefix: "+677",
    code: "SB",
    regex: undefined,
  },
  {
    name: "Somalia",
    prefix: "+252",
    code: "SO",
    regex: undefined,
  },
  {
    name: "South Africa",
    prefix: "+27",
    code: "ZA",
    regex: undefined,
  },
  {
    name: "South Sudan",
    prefix: "+211",
    code: "SS",
    regex: undefined,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    prefix: "+500",
    code: "GS",
    regex: undefined,
  },
  {
    name: "Spain",
    prefix: "+34",
    code: "ES",
    regex: /^\+34(?:6\d{8}|7[1234]\d{7})$/,
  },
  {
    name: "Sri Lanka",
    prefix: "+94",
    code: "LK",
    regex: undefined,
  },
  {
    name: "Sudan",
    prefix: "+249",
    code: "SD",
    regex: undefined,
  },
  {
    name: "Suriname",
    prefix: "+597",
    code: "SR",
    regex: undefined,
  },
  {
    name: "Svalbard and Jan Mayen",
    prefix: "+47",
    code: "SJ",
    regex: undefined,
  },
  {
    name: "Swaziland",
    prefix: "+268",
    code: "SZ",
    regex: undefined,
  },
  {
    name: "Sweden",
    prefix: "+46",
    code: "SE",
    regex: /^\+467\d{8}$/,
    example: "0701234567",
  },
  {
    name: "Switzerland",
    prefix: "+41",
    code: "CH",
    regex: /^\+41\d{9}$/,
  },
  {
    name: "Syrian Arab Republic",
    prefix: "+963",
    code: "SY",
    regex: undefined,
  },
  {
    name: "Taiwan",
    prefix: "+886",
    code: "TW",
    regex: undefined,
  },
  {
    name: "Tajikistan",
    prefix: "+992",
    code: "TJ",
    regex: undefined,
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    prefix: "+255",
    code: "TZ",
    regex: undefined,
  },
  {
    name: "Thailand",
    prefix: "+66",
    code: "TH",
    regex: undefined,
  },
  {
    name: "Timor-Leste",
    prefix: "+670",
    code: "TL",
    regex: undefined,
  },
  {
    name: "Togo",
    prefix: "+228",
    code: "TG",
    regex: undefined,
  },
  {
    name: "Tokelau",
    prefix: "+690",
    code: "TK",
    regex: undefined,
  },
  {
    name: "Tonga",
    prefix: "+676",
    code: "TO",
    regex: undefined,
  },
  {
    name: "Trinidad and Tobago",
    prefix: "+1868",
    code: "TT",
    regex: undefined,
  },
  {
    name: "Tunisia",
    prefix: "+216",
    code: "TN",
    regex: undefined,
  },
  {
    name: "Turkey",
    prefix: "+90",
    code: "TR",
    regex: undefined,
  },
  {
    name: "Turkmenistan",
    prefix: "+993",
    code: "TM",
    regex: undefined,
  },
  {
    name: "Turks and Caicos Islands",
    prefix: "+1649",
    code: "TC",
    regex: undefined,
  },
  {
    name: "Tuvalu",
    prefix: "+688",
    code: "TV",
    regex: undefined,
  },
  {
    name: "Uganda",
    prefix: "+256",
    code: "UG",
    regex: undefined,
  },
  {
    name: "Ukraine",
    prefix: "+380",
    code: "UA",
    regex: /^\+380[1-9]\d{8}$/,
  },
  {
    name: "United Arab Emirates",
    prefix: "+971",
    code: "AE",
    regex: undefined,
  },
  {
    name: "United Kingdom",
    prefix: "+44",
    code: "GB",
    regex: /^\+44\d{10,11}$/,
  },
  {
    name: "United States",
    prefix: "+1",
    code: "US",
    regex: /^\+1[2-9]\d{9}$/,
    example: "2123456789",
  },
  {
    name: "Uruguay",
    prefix: "+598",
    code: "UY",
    regex: undefined,
  },
  {
    name: "Uzbekistan",
    prefix: "+998",
    code: "UZ",
    regex: undefined,
  },
  {
    name: "Vanuatu",
    prefix: "+678",
    code: "VU",
    regex: undefined,
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    prefix: "+58",
    code: "VE",
    regex: undefined,
  },
  {
    name: "Vietnam",
    prefix: "+84",
    code: "VN",
    regex: undefined,
  },
  {
    name: "Virgin Islands, British",
    prefix: "+1284",
    code: "VG",
    regex: undefined,
  },
  {
    name: "Virgin Islands, U.S.",
    prefix: "+1340",
    code: "VI",
    regex: undefined,
  },
  {
    name: "Wallis and Futuna",
    prefix: "+681",
    code: "WF",
    regex: undefined,
  },
  {
    name: "Yemen",
    prefix: "+967",
    code: "YE",
    regex: undefined,
  },
  {
    name: "Zambia",
    prefix: "+260",
    code: "ZM",
    regex: undefined,
  },
  {
    name: "Zimbabwe",
    prefix: "+263",
    code: "ZW",
  },
];
