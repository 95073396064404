import useSWR, { mutate } from "swr";
import * as fetcher from "../fetcher";
import { ActivityType, ActivitySubType } from "../../activity";

export interface Notification {
  id: number;
  opportunityId: number;
  opportunityTitle: string;
  coworkerId: string;
  type: ActivityType;
  subType: ActivitySubType;
  title: string;
  comment: string;
  customerName: string;
  appointmentTime: string;
  isDeleted: boolean;
  isReminder: boolean;
  createdBy: string;
  createdAt: string;
  start: string;
  end: string;
}

export const useNotifications = () =>
  useSWR<Notification[], Error>(
    "/notifications",
    () => fetcher.notification.get(""),
    { refreshInterval: 60 * 1000 }
  );

export const deleteNotification = async (id: number) => {
  const res = await fetcher.notification.remove(`/${id}`);
  mutate("/notifications");
  return res;
};

export const deleteAllNotifications = async () => {
  const res = await fetcher.notification.remove("");
  mutate("/notifications");
  return res;
};

export interface AllCoworkersReminders {
  coworkerId: string[];
}

export function useAllCoworkersNotifications(emailId?: string | null) {
  return useSWR<AllCoworkersReminders>(
    emailId ? `?emailId=${emailId}` : null,
    (url) => fetcher.notification.get(url),
    {
      revalidateOnFocus: false,
      dedupingInterval: 1 * 60 * 10000, // 1 minute caching
    }
  );
}
