import useSWR from "swr";

import * as fetcher from "../fetcher";

export interface AllowedStoresList {
  allowedStores: string[];
}

export function useAllowedStores(shortId?: string | null) {
  return useSWR<AllowedStoresList>(
    shortId ? `/coworker/allowed/${shortId}` : null,
    (url) => fetcher.global.get(url),
    {
      revalidateOnFocus: false,
      dedupingInterval: 1 * 60 * 10000, // 1 minute caching
    }
  );
}
