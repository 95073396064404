import * as fetcher from "../fetcher";
import { saveAs } from "file-saver";

const fileTypeMap = {
  png: "image/png",
  jpeg: "image/jpeg",
  jpg: "image/jpg",
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  txt: "text/plain",
  csv: "text/csv",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

const getFileType = (fileName: string): string => {
  const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
  return fileTypeMap[fileExtension] ?? "application/octet-stream";
};

export const downloadFile = async (
  opportunityId: string,
  fileName: string,
  operation = "download"
) => {
  const response = await fetcher.opportunity.download(
    `/${opportunityId}/files/${fileName}`
  );
  const myFile = new File([response], fileName, {
    type: getFileType(fileName),
  });

  const fileURL = document.createElement("a");
  fileURL.target = "_blank";
  fileURL.href = window.URL.createObjectURL(myFile);
  fileURL.download = myFile.name;

  document.body.appendChild(fileURL);
  if (operation === "download") {
    fileURL.click();
  }
  if (operation === "open") {
    window.open(fileURL.href, "_blank", "download=" + myFile.name);
  }
  document.body.removeChild(fileURL);
  return response;
};

export const uploadFile = (opportunityId: string, file: File) => {
  return fetcher.opportunity.upload(`/${opportunityId}/files`, file);
};

export const deleteFile = (opportunityId: string, fileName: string) => {
  return fetcher.opportunity.remove(`/${opportunityId}/files/${fileName}`);
};

export const exportPdf = async (opportunityId: number) => {
  const response = await fetcher.opportunity.download(
    `/${opportunityId}/export`
  );
  const fileName = `SalesAssistant-${opportunityId}.pdf`;
  saveAs(response, fileName);
};
