import { ChangeEvent } from "react";
import { Grid, Stack, Text } from "components";
import { Icon } from "./Icon.component";
import Select, { Option } from "@ingka/select";
import FormField from "@ingka/form-field";
import { isBoolean } from "lodash";
import { sortRecordByValue, useLocale } from "common";

interface Props {
  label: string;
  id: string;
  values: string[];
  valid?: boolean;
  errorMessage?: string;
  /**
   * A record with a key (which is the selected value) and
   * a value which will be displayed in the list
   */
  possibleValues: Record<string, string>;
  placeholder: string;
  onChange(values: string[]): void;
  sortByName?: boolean;
  disabled?: boolean;
}

/**
 * Display a drop down with possible alternatives.
 * When the user selects an alternative it is added to
 * a list below the drop down.
 */
export const MultiSelect = ({
  values = [],
  id,
  onChange,
  label,
  possibleValues,
  valid,
  errorMessage,
  placeholder,
  sortByName,
  disabled,
}: Props) => {
  const locale = useLocale();
  const orderedPossibleValues = sortByName
    ? sortRecordByValue(possibleValues, locale.languageCode)
    : Object.entries(possibleValues);

  return (
    <Stack vertical spacing={20}>
      <FormField
        valid={valid}
        shouldValidate={isBoolean(valid)}
        validation={
          valid === false
            ? {
                msg: <p>{errorMessage}</p>,
                id: `${id}-error-message`,
              }
            : {}
        }
      >
        <Select
          id={`${id}-dropDown`}
          label={label}
          hintText={placeholder}
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            !values.includes(e.currentTarget.value) &&
            onChange([...values, e.currentTarget.value])
          }
          value=""
          disabled={disabled}
        >
          {orderedPossibleValues.map(([key, value]) => (
            <Option key={key} value={key} name={value} />
          ))}
        </Select>
      </FormField>
      <Grid columns={2} spacing={8}>
        {values.map((key) => (
          <Stack spacing={4} key={key} alignItems="center">
            <Icon
              icon="CrossSmall"
              onClick={() =>
                onChange(values.filter((theValue) => theValue !== key))
              }
            />
            <Text>{possibleValues[key]}</Text>
          </Stack>
        ))}
      </Grid>
    </Stack>
  );
};
