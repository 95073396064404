import { ReactNode } from "react";

import { Text } from "../typography/Text.component";
import { Stack } from "../layout/Stack.component";

interface Prop {
  /** A label that describes the text */
  label: string;
  /**  The text that the label describes. If left empty will render children instead */
  text?: string | number | JSX.Element;
  children?: ReactNode;
  small?: boolean;
  bold?: boolean;
  notBold?: boolean;
  spacing?: number;
}

/**
 * Display a labelled text where the label is displayed
 * next to the value. Displays the label with a "-" as value
 * if the value is undefined.
 */
export const InlineLabeledText = ({
  label,
  text,
  children,
  small = false,
  notBold = false,
  spacing = 5,
}: Prop) => {
  return (
    <Stack spacing={spacing}>
      <Text small={small} bold={!notBold} inline nowrap>
        {label}
      </Text>

      {children ? (
        children
      ) : (
        <Text small={small} wordBreakWord>
          {text ?? "-"}
        </Text>
      )}
    </Stack>
  );
};

InlineLabeledText.displayName = "InlineLabeledText";
