import { useState } from "react";
import { Box, Grid, Line, Select, Stack, Text, Toast } from "components";
import { NotificationItem } from "./NotificationItem.component";
import { Button } from "components";
import cross from "@ingka/ssr-icon/paths/cross-small";
import down from "@ingka/ssr-icon/paths/chevron-down-small";
import up from "@ingka/ssr-icon/paths/chevron-up-small";
import {
  useNotifications,
  Notification,
  deleteNotification,
  deleteAllNotifications,
} from "common/api";
import { t, T } from "i18n";
import { orderBy } from "lodash";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import isThisWeek from "date-fns/isThisWeek";
import isThisMonth from "date-fns/isThisMonth";

type FilterDate = "Today" | "Tomorrow" | "Week" | "Month";

export const Notifications: React.FunctionComponent = () => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filterByDate, setFilterByDate] = useState<FilterDate | "None">("None");
  const [sortDescending, setSortDescending] = useState<boolean>(true);
  const { data, error, isValidating: isLoading, mutate } = useNotifications();
  const notifications = data
    ? data.filter(({ isReminder }) => !isReminder)
    : [];

  const onClearNotification = async (notification: Notification) => {
    mutate(
      notifications.filter(({ id }) => id !== notification.id),
      false
    );
    await deleteNotification(notification.id);
    mutate();
  };

  const onClearAllNotifications = async () => {
    mutate([], false);
    await deleteAllNotifications();
    mutate();
  };

  const filteredNotifications = notifications.filter((notification) => {
    const appointmentDate = new Date(notification.createdAt);
    switch (filterByDate) {
      case "Today":
        return isToday(appointmentDate);
      case "Tomorrow":
        return isTomorrow(appointmentDate);
      case "Week":
        return isThisWeek(appointmentDate);
      case "Month":
        return isThisMonth(appointmentDate);
      default:
        return notification;
    }
  });

  const sortedNotifications = orderBy(
    filteredNotifications,
    "createdAt",
    sortDescending ? "desc" : "asc"
  );

  return (
    <Stack vertical spacing={20}>
      <Stack vertical alignItems="flex-end">
        <Button
          tertiary
          iconPosition="trailing"
          text={
            showFilters
              ? t("notifications.hide-filters")
              : t("notifications.show-filters")
          }
          ssrIcon={showFilters ? up : down}
          onClick={() => setShowFilters(!showFilters)}
          small
        />
        {showFilters && (
          <Grid columns={2} fullWidth>
            <Select
              options={{
                None: t("notifications.filter.date.none"),
                Today: t("notifications.filter.date.today"),
                Tomorrow: t("notifications.filter.date.tomorrow"),
                Week: t("notifications.filter.date.this-week"),
                Month: t("notifications.filter.date.this-month"),
              }}
              value={filterByDate}
              onChange={(value: FilterDate) => setFilterByDate(value)}
            />
            <Button
              iconPosition="trailing"
              text={t("notifications.sort.date")}
              ssrIcon={sortDescending ? down : up}
              onClick={() => setSortDescending(!sortDescending)}
              small
            />
          </Grid>
        )}
        <Line />
      </Stack>
      <Stack vertical spacing={10}>
        {sortedNotifications.map((notification, index) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            odd={index % 2 === 1}
            onClear={onClearNotification}
          />
        ))}
        {notifications.length === 0 && !isLoading && (
          <Text bold>
            <T id="notifications.no-notification.message" />
          </Text>
        )}
        {notifications.length > 0 &&
          sortedNotifications.length === 0 &&
          !isLoading && (
            <Text bold>
              <T id="notifications.change-filter.message" />
            </Text>
          )}
        {notifications.length > 1 && (
          <Box justifyContent="flex-end">
            <Button
              small
              primary
              text={t("notifications.clear", {
                notificationLength: notifications.length,
              })}
              onClick={onClearAllNotifications}
              ssrIcon={cross}
            />
          </Box>
        )}
      </Stack>
      {error && <Toast text={error.message} />}
    </Stack>
  );
};

Notifications.displayName = "Notifications";
