import SkapaSlider from "@ingka/slider";
import { useLocale } from "common/hooks";
import { currencyFormatter } from "components/data";
import { useField } from "formik";
import { t } from "i18n";

export type PriceSliderProps = {
  id?: string;
  name: string;
  minAmount: number;
  maxAmount: number;
  currency: string;
};

export const PriceSlider = ({
  id,
  name,
  minAmount,
  maxAmount,
  currency,
}: PriceSliderProps) => {
  const [field, , helpers] = useField(name);
  const locale = useLocale();

  return (
    <SkapaSlider
      id={id || name}
      label={t("opportunities.filters.price-range.text", {
        start: currencyFormatter(
          field.value?.[0] ?? minAmount,
          currency,
          locale.toString(),
          true
        ),
        end: currencyFormatter(
          field.value?.[1] ?? maxAmount,
          currency,
          locale.toString(),
          true
        ),
      })}
      ariaLabels={["From", "To"]}
      range={{
        min: minAmount,
        max: maxAmount,
      }}
      start={field.value ?? [minAmount, maxAmount]}
      onChange={(value) => {
        helpers.setValue(value);
      }}
      step={100}
      margin={100}
      format={{
        to: (value) =>
          currencyFormatter(value, currency, locale.toString(), true),
        from: (value) =>
          currencyFormatter(value, currency, locale.toString(), true),
      }}
      ariaFormat={{
        to: (value) => {
          return value;
        },
        from: (value) => {
          return value;
        },
      }}
    />
  );
};
