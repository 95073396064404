import { Configuration } from "@azure/msal-browser";

/**
 * The MSAL configuration for our app
 */
export const msalConfig: Configuration = {
  auth: {
    redirectUri: process.env.REACT_APP__MSAL_REDIRECT_URI,
    clientId: process.env.REACT_APP__MSAL_CLIENT_ID!,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP__MSAL_TENANT_ID}`,
    postLogoutRedirectUri: process.env.REACT_APP__MSAL_LOG_OUT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

/* Scopes for id token to be used at MS Identity Platform endpoints. */
export const loginRequest = {
  scopes: ["User.Read", "openid", "profile", "Group.Read.All"],
};

/* Scopes for access token to be used at MS Graph API endpoints. */
export const tokenRequest = {
  scopes: process.env.REACT_APP__MSAL_SCOPE
    ? [process.env.REACT_APP__MSAL_SCOPE]
    : [],
};

/* Endpoints for Microsoft Graph API services we are using. */
export const graphEndpoint = "https://graph.microsoft.com/beta";

//Group name : salesleadassistant-global-super-users
export const isAdminUser = (groups: string[]) =>
  groups.includes(process.env.REACT_APP__AZ_ADMIN_GROUP!);

export const isSalesPerson = (groups: string[]) =>
  groups.includes(process.env.REACT_APP__AZ_USER_GROUP!);
