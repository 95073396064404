import { Stack, Text } from "components";
import { useLocale } from "common";
import { addDays, format } from "date-fns";
import { OpenCloseRecord } from "common/api";

interface Props {
  openClose?: OpenCloseRecord;
}

export const Weekdays = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
] as const;

const getAbbreviatedWeekDayMap = (dateFnsLocale: Locale) => {
  const date = new Date();
  const map = {};

  for (let i = 0; i < 7; i++) {
    const weekDate = addDays(date, i);
    const fallbackWeekDay = format(weekDate, "EEEE");
    const fullWeekDay = format(weekDate, "EEEE", {
      locale: dateFnsLocale,
    });
    const shortWeekDay = format(weekDate, "E", {
      locale: dateFnsLocale,
    });

    map[fullWeekDay.toUpperCase()] = shortWeekDay;
    map[fallbackWeekDay.toUpperCase()] = shortWeekDay;
  }

  return map;
};

const abbreviateDay = (day: string) =>
  day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase();

export const TimeTable = ({ openClose }: Props) => {
  const locale = useLocale();
  const weekDaysMap = getAbbreviatedWeekDayMap(locale.dateFnsLocale);

  if (!openClose) {
    return null;
  }

  return (
    <>
      {Weekdays.map(
        (day) =>
          openClose[day] && (
            <Stack key={day}>
              <Text small inline minWidth="2.2rem" color="neutralGrey700">
                {weekDaysMap[day] ?? abbreviateDay(day)}
              </Text>

              <Text small inline color="neutralGrey700">
                {openClose[day].Open + " - " + openClose[day].Close}
              </Text>
            </Stack>
          )
      )}
    </>
  );
};

TimeTable.displayName = "TimeTable";
