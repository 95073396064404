import { useContext, useEffect } from "react";
import { IToast, ToastContext } from "./ToastProvider";

/**
 * A Toast that will show up for 5 seconds and then disappear.
 * Provide a time for it to re-appear even if the text is the same.
 */
export const Toast = (props: IToast) => {
  const toastContext = useContext(ToastContext);
  useEffect(() => {
    toastContext.updateToast!(props);
    // Update toast if text or time changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text, props.time]);
  return <></>;
};
