import { NavigationItem } from "./NavigationItem";
import { css } from "@emotion/css";
import { t, T } from "i18n";
import { environment } from "../../common/feature-flags/environment";

interface IPage {
  path: string;
  icon: any;
  name: string;
}

interface Props {
  pages: IPage[];
}

const NavigationMenu = ({ pages }: Props) => {
  return (
    <nav>
      <ul
        className={css`
          display: flex;
          flex-direction: column;
          list-style: none;
          padding: 1rem;
        `}
      >
        {!environment.isProd && (
          <li
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgb(10, 138, 0);
              margin-bottom: 5px;
              color: white;
              padding: 10px;
            `}
            title={t("navigation.environment-info.description")}
          >
            <T id="navigation.environment-info.label" />
          </li>
        )}
        {pages.map((page, i) => (
          <NavigationItem page={page} key={i} />
        ))}
      </ul>
    </nav>
  );
};

export { NavigationMenu };
