const baseUrl: string = window.location.hostname;
const isProd = baseUrl === "salesassistant.ingka.com";
const isCTE = baseUrl.includes("cte");
const isDev = baseUrl.includes("dev");
const isLocalhost = !isProd && !isCTE && !isDev;

export const environment = {
  isProd,
  isCTE,
  isDev,
  isLocalhost,
};
