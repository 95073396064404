import * as React from "react";
import { Opportunity } from "common/api";
import { css, cx } from "@emotion/css";
import { Colors, Stack, Text } from "components";
import { Status } from "./Status";

const indicatorStyle = css`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 60px;
  background-color: ${Colors.bjornGrey};

  &::after,
  &::before {
    content: "";
    text-align: center;
    position: absolute;
    top: 0;
    right: -16px;
    border-top: 29px solid transparent;
    border-bottom: 29px solid transparent;
    border-left: 17.5px solid ${Colors.bjornGrey};
    z-index: 1;
  }
  &::before {
    left: 0;
    border-left: 17px solid ${Colors.whiteIdle};
    z-index: 0;
  }
  &:first-child:before {
    border: none;
  }
`;

const activeStyle = css`
  background-color: ${Colors.navIconYellow};
  &::after {
    border-left-color: ${Colors.navIconYellow};
  }
`;

const isClosed = (status: Opportunity["status"]) =>
  status === "Invalid" || status === "ClosedLost" || status === "ClosedWon";

interface Props {
  name: React.ReactNode;
  active?: boolean;
}

const Indicator = ({ name, active }: Props) => (
  <div className={cx(indicatorStyle, active && activeStyle)}>
    <Text align="center" bold={active}>
      {name}
    </Text>
  </div>
);

export const StatusIndicator = ({
  status,
}: {
  status: Opportunity["status"];
}) => (
  <Stack>
    {status === "Unassigned" ? (
      <Indicator name={<Status id="Unassigned" />} active />
    ) : (
      <Indicator
        name={<Status id="Assigned" />}
        active={status === "Assigned"}
      />
    )}
    <Indicator
      name={<Status id="InProgress" />}
      active={status === "InProgress"}
    />
    <Indicator name={<Status id="Closed" />} active={isClosed(status)} />
  </Stack>
);
