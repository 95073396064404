export type Values<T> = T[keyof T];

/**
 * Check if the value is defined in a type-safe manner
 * @example opportunities.filter(isDefined)
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

/**
 * Check if an object has a property in a type-safe manner
 * @example hasOwnProperty(tokenClaims, "groups")
 */
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}
