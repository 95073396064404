import { AppointmentStatus } from "common/api";
import { t, T } from "i18n";

const translationKeys = {
  NOT_PAID: "appointment.unpaid.label",
  PAID: "appointment.paid.label",
  FREE: "appointment.free.label",
} as const;

interface Props {
  id: keyof typeof translationKeys;
}

export const AppointmentPaymentStatus = ({ id }: Props) => {
  if (!translationKeys[id]) return <>{id}</>;
  return <T id={translationKeys[id]} />;
};

export const getAppointmentPaymentStatus = (id: keyof typeof translationKeys) =>
  t(translationKeys[id]) ?? id;

export const mapStatusToPaymentStatus = (
  status: AppointmentStatus,
  price: number
): keyof typeof translationKeys =>
  price === 0
    ? "FREE"
    : status === "ORDER_RECEIVED_WAITING_FOR_PAYMENT"
    ? "NOT_PAID"
    : "PAID";
