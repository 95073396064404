import {
  LabeledText,
  Grid,
  LocalizedDate,
  Stack,
  Text,
  Button,
  Currency,
} from "components";
import { CoWorkerName } from "common";
import { Appointment, QuestionObject } from "common/api";
import { t } from "i18n";
import { getAppointmentLocationType } from "features/appointments/AppointmentLocationType";
import { getAppointmentService } from "features/appointments/AppointmentService";
import {
  getAppointmentPaymentStatus,
  mapStatusToPaymentStatus,
} from "features/appointments/AppointmentPaymentStatus";
import person from "@ingka/ssr-icon/paths/person";
import Tooltip from "@ingka/tooltip";
import { isActiveStatus } from "./utils";

type Props = {
  currentAppointment: Appointment;
  currentQuestionaire?: QuestionObject[];
  currency?: string;
  disableEditing: boolean;
};

type AssignButtonProps = {
  isAssigned: boolean;
  appointmentLink: string | undefined;
  isActive?: boolean;
  disabled?: boolean;
};

const AssignButton = ({
  isAssigned,
  appointmentLink,
  isActive,
  disabled,
}: AssignButtonProps) => {
  const tooltipText = isAssigned
    ? t("appointment.reassign.button")
    : t("assign-opportunities.assign.button");
  const disableAssign = disabled ?? (!appointmentLink || !isActive);

  return (
    <Tooltip tooltipText={tooltipText} position="trailing">
      <Button
        small
        emphasised={!isAssigned && isActive}
        iconOnly
        ssrIcon={person}
        disabled={disableAssign}
        onClick={isActive ? () => window.open(appointmentLink!) : undefined}
      />
    </Tooltip>
  );
};

const AppointmentDetails = ({
  currentAppointment,
  currency,
  disableEditing,
}: Props) => {
  return (
    <Stack vertical spacing={20}>
      <Text header="h3">{t("appointment.details.label")}</Text>

      <Grid columns={2} fullWidth>
        <LabeledText
          label={t("opportunities.table.header.customer")}
          text={`${currentAppointment.customer.firstName} ${currentAppointment.customer.lastName}`}
        />

        <LabeledText label={t("appointment.assignedcoworker.label")}>
          <Stack spacing={10} alignItems="center">
            {currentAppointment.assignedCoworker && (
              <Text wordBreakWord>
                <CoWorkerName>
                  {currentAppointment.assignedCoworker}
                </CoWorkerName>
              </Text>
            )}
            <AssignButton
              isAssigned={!!currentAppointment.assignedCoworker}
              appointmentLink={currentAppointment.appointmentLink}
              isActive={isActiveStatus(currentAppointment)}
              disabled={disableEditing}
            />
          </Stack>
        </LabeledText>

        <LabeledText
          label={`${t("appointment.appointment-number.label")}/${t(
            "manage-opportunity.order-info.order-number"
          )}`}
          text={currentAppointment.orderNumber}
        />

        <LabeledText
          label={t("appointment.bookingtime.label")}
          text={
            <LocalizedDate pattern="Pp">
              {currentAppointment.bookingTime}
            </LocalizedDate>
          }
        />
        <LabeledText
          label={t("appointment.service.label")}
          text={getAppointmentService(currentAppointment.service)}
        />
        <LabeledText
          label={t("opportunities.filters.businessunit.title")}
          text={currentAppointment.locationName}
        />
        <LabeledText
          label={t("appointment.locationtype.label")}
          text={getAppointmentLocationType(currentAppointment.locationType)}
        />
        <LabeledText
          label={t("appointment.appointmentprice.label")}
          text={
            <Currency currency={currency ?? "SEK"} showSign fractions={2}>
              {currentAppointment.price}
            </Currency>
          }
        />
        <LabeledText
          label={t("appointment.paymentstatus.label")}
          text={getAppointmentPaymentStatus(
            currentAppointment.paymentStatus ??
              mapStatusToPaymentStatus(
                currentAppointment.appointmentStatus,
                currentAppointment.price
              )
          )}
        />
        <LabeledText label={t("appointment.appointmentlink.label")}>
          {currentAppointment.videoLinkCoworker && (
            <a
              href={currentAppointment.videoLinkCoworker}
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noreferrer"
            >
              {t("co-worker.label")}
            </a>
          )}
          {currentAppointment.videoLinkCustomer && (
            <a
              href={currentAppointment.videoLinkCustomer}
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noreferrer"
            >
              {t("manage-opportunity.customer-info.title")}
            </a>
          )}
        </LabeledText>
      </Grid>
    </Stack>
  );
};

export default AppointmentDetails;
