import { useState } from "react";
import { Box, Button, LabeledText, ModalSheet, Stack } from "components";
import FormField from "@ingka/form-field";
import TextArea from "@ingka/text-area";
import Tooltip from "@ingka/tooltip";
import RadioButtonGroup from "@ingka/radio-button-group";
import arrow from "@ingka/ssr-icon/paths/arrow-up-arrow-down";
import { Classification } from "common";
import { Opportunity } from "common/api";
import {
  CurrentOpportunity,
  CurrentOpportunityDispatch,
} from "../currentOpportunity.reducer";
import { tracker } from "analytics";
import { t } from "i18n";

const classifications: Opportunity["classification"][] = [
  "Urgent",
  "High",
  "Medium",
  "Low",
];

type Props = {
  opportunity: Opportunity;
  currentOpportunity: CurrentOpportunity;
  currentOpportunityDispatch: CurrentOpportunityDispatch;
  reasons: Record<string, string> | undefined;
  disabled: boolean;
};

export const ClassificationChange = ({
  opportunity,
  reasons,
  currentOpportunity,
  currentOpportunityDispatch,
  disabled,
}: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [changedClassification, setChangedClassification] = useState<
    Opportunity["classification"] | undefined
  >(undefined);
  const [classificationChangeReason, setClassificationChangeReason] = useState<
    string | undefined
  >(undefined);
  const [classificationReasonDescription, setClassificationReasonDescription] =
    useState<string | undefined>(undefined);
  const onOpenModal = () => {
    setChangedClassification(
      currentOpportunity.dataToSave?.classification ??
        opportunity.classification
    );
    setClassificationChangeReason(
      currentOpportunity.dataToSave?.classificationChangeDetails?.reason ??
        opportunity.classificationChangeReason
    );

    setClassificationReasonDescription(
      currentOpportunity.dataToSave?.classificationChangeDetails?.description
    );

    setShowConfirmation(true);
  };
  const onCloseModal = () => setShowConfirmation(false);

  const confirmDisabled =
    !classificationChangeReason ||
    (classificationReasonDescription !== undefined &&
      classificationReasonDescription.length <= 3);

  return (
    <>
      <LabeledText
        label={t("manage-opportunity.opportunity-info.classification.label")}
      >
        <Stack spacing={10} alignItems="center" dontGrow>
          <Box alignItems="start" justifyContent="flex-start">
            <Classification>
              {changedClassification || opportunity.classification}
            </Classification>
          </Box>
          <Tooltip
            tooltipText={t("manage-opportunity.change.classification.tooltip")}
            position="trailing"
          >
            <Button
              iconOnly
              small
              ssrIcon={arrow}
              onClick={onOpenModal}
              disabled={disabled}
            />
          </Tooltip>
        </Stack>
      </LabeledText>

      <ModalSheet
        title={t("manage-opportunity.classification.modal.header")}
        onClose={onCloseModal}
        visible={showConfirmation}
        primaryButtonProps={{
          text: t("manage-opportunity.status.modal.confirm-button"),
          disabled: confirmDisabled,

          onClick: () => {
            tracker.event({
              category: "Classification change",
              action: "Clicked on 'Classification change' button",
              reason: `${classificationChangeReason === "OTHER"} ? 
              'Changed classification to ${changedClassification!} for reason: ${classificationReasonDescription}' : 
              'Changed classification to ${changedClassification!} with selected reason: ${classificationChangeReason}'`,
            });
            currentOpportunityDispatch({
              type: "CHANGE_CLASSIFICATION",
              payload: {
                classification: changedClassification!,
                reason: classificationChangeReason,
                description: classificationReasonDescription,
              },
            });
            onCloseModal();
          },
        }}
        secondaryButtonProps={{
          onClick: () => {
            setChangedClassification(undefined);
            setClassificationChangeReason(undefined);
            setClassificationReasonDescription(undefined);
            onCloseModal();
          },
        }}
      >
        <Stack vertical spacing={40}>
          <RadioButtonGroup
            name={t("manage-opportunity.change.classification")}
            list={classifications.map((classification) => ({
              id: classification,
              name: "group1",
              value: classification,
              label: <Classification>{classification}</Classification>,
              defaultChecked: classification === changedClassification,
              onChange: (e) => {
                setChangedClassification(e.target.value);
              },
            }))}
          />

          {reasons && (
            <RadioButtonGroup
              name={t("manage-opportunity.classification.modal.title")}
              list={Object.entries(reasons).map(([key, value]) => ({
                name: "group2",
                id: key,
                label: value,
                value: key,
                defaultChecked: value === classificationChangeReason,
                onChange: (e) => {
                  setClassificationChangeReason(e.target.value);
                },
                disabled: opportunity.classification === changedClassification,
              }))}
            />
          )}
          {classificationChangeReason && (
            <FormField
              shouldValidate
              valid={
                !!(
                  classificationReasonDescription &&
                  classificationReasonDescription.length > 3
                )
              }
              validation={{
                msg: t(
                  "manage-opportunity.customer-consent.change-reason.error"
                ),
                id: "error",
                type: "error",
              }}
            >
              <TextArea
                id="reason"
                label={t("manage-opportunity.reason-change.label")}
                value={classificationReasonDescription}
                onChange={(e) =>
                  setClassificationReasonDescription(e.target.value)
                }
                disabled={opportunity.classification === changedClassification}
              />
            </FormField>
          )}
        </Stack>
      </ModalSheet>
    </>
  );
};

ClassificationChange.displayName = "ClassificationChange";
