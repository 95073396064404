import { i18n } from "@lingui/core";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { fallbackLocale, Locale, SupportedLocale } from "./index";
import { Locale as DateFnsLocale } from "date-fns";
import { en } from "make-plural/plurals";
import { TranslationKeys } from "./translationKeys";

/**
 * These supported locales have custom date-dns locale objects
 * in i18n/locales
 */
const hasCustomLocale: SupportedLocale[] = ["sv-FI", "en-FI"];

/**
 * Map between a supported locale and a date-fns locale objects.
 */
const dateFnsLocales: Record<SupportedLocale, string> = {
  "en-AT": "en-AT",
  "en-AU": "en-AU",
  "en-CA": "en-CA",
  "en-CH": "de",
  "en-DE": "de",
  "en-FI": "en-FI",
  "en-FR": "fr",
  "en-HR": "hr",
  "en-IN": "en-IN",
  "en-IT": "en-IT",
  "en-JP": "en-JP",
  "en-NO": "no",
  "en-PL": "pl",
  "en-PT": "pt",
  "en-RS": "sr",
  "en-SE": "sv",
  "en-SI": "sl",
  "en-XZ": "sv",
  "de-DE": "de",
  "en-DK": "da",
  "da-DK": "da",
  "de-AT": "de",
  "de-CH": "de",
  "fi-FI": "fi",
  "fr-FR": "fr",
  "fr-CA": "fr-CA",
  "fr-CH": "fr",
  "hr-HR": "hr",
  "it-CH": "it",
  "it-IT": "it",
  "ja-JP": "ja",
  "no-NO": "no",
  "pl-PL": "pl",
  "pt-PT": "pt",
  "ro-RO": "ro",
  "sl-SI": "sl",
  "sr-RS": "sr",
  "sv-FI": "sv-FI",
  "sv-SE": "sv",
};

/**
 * Register the react-datepicker date-fns locales we support in a type-safe manner
 */
const registerDateFnsLocale = (
  locale: SupportedLocale,
  localeObject: DateFnsLocale
) => registerLocale(locale, localeObject);

/**
 * dynamically import date-fns locale object
 * read more https://date-fns.org/v2.19.0/docs/Locale
 */
const importDateFnsLocales = async (
  locale: SupportedLocale
): Promise<DateFnsLocale | null> => {
  try {
    const { default: localeObject } = hasCustomLocale.includes(locale)
      ? await import(`i18n/locales/${dateFnsLocales[locale.toString()]}`)
      : await import(`date-fns/locale/${dateFnsLocales[locale.toString()]}`);

    return localeObject;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`${error}\n Cannot find the locale object for ${locale}`);
    return null;
  }
};

/**
 * dynamically import translations object
 */
const importTranslations = async (
  locale: string
): Promise<Record<TranslationKeys, string> | null> => {
  try {
    const { messages } = await import(
      `@lingui/loader!assets/translations/${locale}.po`
    );
    return messages;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`${error}\n ${locale} has no translations.`);
    return null;
  }
};

/**
 * Load translation object for the selected locales.
 * Falls back to the fallback locale.
 */
const loadTranslations = async (
  locale: string,
  fallback: string = fallbackLocale.toString()
): Promise<Record<TranslationKeys, string>> => {
  const translations = await importTranslations(locale);
  if (translations) return translations;

  const fallbackTranslations = await importTranslations(fallback);
  if (fallbackTranslations) return fallbackTranslations;

  throw new Error("Can't start the app with no translations");
};

/**
 * Initiate the localization features
 */
export const initLocale = async (locale: Locale) => {
  const localeCode = locale.toString();
  const messages = await loadTranslations(localeCode);

  const localeObject = await importDateFnsLocales(localeCode);

  if (localeObject) {
    locale.registerDateFnsLocale(localeObject);
    registerDateFnsLocale(localeCode, localeObject);
    setDefaultLocale(localeCode);
  }

  // since we are not using pluralization yet in our app, I will just set the pluralization rules
  // for all locales to English rules, read more here https://lingui.js.org/guides/plurals.html
  i18n.loadLocaleData(localeCode, { plurals: en });
  i18n.load(localeCode, messages);
  i18n.activate(localeCode);
};
