import { Stack } from "components/layout";
import { Text } from "components/typography";
import { useField } from "formik";
import { RadioButton } from "./RadioButton";

interface Props {
  name: string;
  label: string;
  list: {
    id: string;
    label: string;
  }[];
}

export const RadioButtonGroup = ({ name, label, list }: Props) => {
  const [, meta] = useField({ name });

  return (
    <Stack vertical>
      <Text bold>{label}</Text>
      {list.map(({ id, label: theLabel }) => (
        <RadioButton key={id} name={name} id={id} label={theLabel} />
      ))}
      {meta.touched && meta.error && (
        <Text xsmall color="redIdle">
          {meta.error}
        </Text>
      )}
    </Stack>
  );
};
