import { t, T } from "i18n";

const translationKeys = {
  Assigned: "manage-opportunity.status.assigned",
  InProgress: "manage-opportunity.status.in-progress",
  ClosedWon: "manage-opportunity.status.closed-won",
  ClosedLost: "manage-opportunity.status.closed-lost",
  Closed: "manage-opportunity.status.closed",
  Invalid: "manage-opportunity.status.invalid",
  Unassigned: "manage-opportunity.status.unassigned",
} as const;

interface Props {
  id: keyof typeof translationKeys;
}

export const Status = ({ id }: Props) => <T id={translationKeys[id]} />;

export const getStatusName = (id: keyof typeof translationKeys) =>
  t(translationKeys[id]);
