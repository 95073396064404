import { isDefined } from "common";
import { Appointment, Opportunity, Notification } from "common/api";
import { addMinutes, subMinutes } from "date-fns";

const inactiveStatus = ["COMPLETED", "CANCELLED_NO_SHOW", "CANCELLED"];

export const groupAppointments = (appointments: Appointment[]) => {
  const upcomingAppointments = appointments
    .filter((appt) => isActiveStatus(appt))
    .sort((a, b) => a.timeWindowStartTime.localeCompare(b.timeWindowStartTime));

  const upcomingIds = upcomingAppointments.map((appt) => appt.id);
  const rest = appointments
    .filter((appt) => !upcomingIds.includes(appt.id))
    .sort((a, b) => a.timeWindowStartTime.localeCompare(b.timeWindowStartTime));

  return {
    upcoming: upcomingAppointments,
    completed: rest,
  };
};

export const findOrdersWithoutAppointment = (
  opportunity: Opportunity | undefined
) => {
  const orders = [
    opportunity?.orders?.originalOrder,
    ...(opportunity?.orders?.linkedOrders ?? []),
  ].filter(isDefined);
  const appointmentIds = opportunity?.appointmentDetails?.map(
    (appointment) => appointment.orderNumber
  );
  return orders.filter((order) => !appointmentIds?.includes(order.orderId));
};

export const findNextAppointment = (
  appointments?: Appointment[]
): Appointment | undefined => {
  if (!appointments) return undefined;
  const nextAppointment = groupAppointments(appointments)?.upcoming[0];
  if (!nextAppointment) return undefined;
  return nextAppointment;
};

export const isActiveAppointment = (
  appointment: Appointment | undefined
): boolean => {
  const now = new Date();
  if (!appointment) return false;

  const isActive =
    (now > subMinutes(new Date(appointment.timeWindowStartTime), 10) &&
      isActiveStatus(appointment)) ||
    appointment.appointmentStatus === "STARTED";
  return isActive;
};

export const isActiveStatus = (appointment: Appointment): boolean =>
  !inactiveStatus.includes(appointment.appointmentStatus);

export const hasActiveAppointment = (
  opportunity: Opportunity | undefined
): boolean => {
  if (
    !opportunity ||
    !opportunity.appointmentDetails ||
    opportunity.appointmentDetails?.length === 0
  ) {
    return false;
  }
  return groupAppointments(opportunity.appointmentDetails).upcoming.length > 0;
};

const reminderLengthMinutes = 30;
export const hasCollidingAppointments = (
  newAppointmentDate: Date | null,
  reminders: Notification[]
) => {
  if (!newAppointmentDate) {
    return false;
  }

  const collidingReminders = reminders.filter((r) => {
    const reminderDateStartTime = new Date(r.appointmentTime);
    const reminderDateEndTime = addMinutes(
      reminderDateStartTime,
      reminderLengthMinutes
    );

    return (
      newAppointmentDate >= reminderDateStartTime &&
      newAppointmentDate < reminderDateEndTime
    );
  });

  return collidingReminders.length > 0;
};

export const getRemindersList = (reminders) => {
  return reminders.filter(({ subType }) => subType !== "ExpectedPurchase");
};

export const getAllCoworkersRemindersList = (reminders) => {
  return reminders.filter(({ subType }) => subType !== "ExpectedPurchase");
};
