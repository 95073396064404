import { useState } from "react";
import {
  CurrentOpportunityDispatch,
  CurrentOpportunity,
} from "../currentOpportunity.reducer";
import {
  Box,
  Stack,
  Text,
  Line,
  LabeledText,
  RadioButtonFixed,
  Grid,
  Loading,
  CountryName,
  ModalSheet,
  TextAreaFixed,
} from "components";
import { languageCodeToLanguage, useLocale } from "common";
import { ManageAccordion } from "./ManageAccordion.component";
import { Opportunity, useConfig, useOpportunityCustomer } from "common/api";
import FormField from "@ingka/form-field";
import { T, t } from "i18n";
import { isDesc2Required } from "features/new-sales-opportunity/common/customer-consent/FollowUpConsent";
import { renderLinkedText } from "common/helpers/renderLinkedText";

interface Props {
  opportunityFromApi: Opportunity;
  currentOpportunity: CurrentOpportunity;
  currentOpportunityDispatch: CurrentOpportunityDispatch;
  disableEditing: boolean;
}

export const CustomerInfo = ({
  opportunityFromApi,
  currentOpportunity,
  currentOpportunityDispatch,
  disableEditing,
}: Props) => {
  const { data: customer, isValidating: isLoading } = useOpportunityCustomer(
    opportunityFromApi.id
  );
  const { data: config } = useConfig();
  const [modalOpen, setModalOpen] = useState(false);
  const { locale } = useLocale();

  const contactIsAllowed = currentOpportunity.allowContact;
  const contactWasAllowed = opportunityFromApi.allowContact;
  const isBusinessCustomer = customer?.customer?.customerType === "Business";
  const requirePrivacyPolicy = !!config?.customer?.requirePrivacyPolicy;

  return (
    <ManageAccordion title={t("manage-opportunity.customer-info.title")}>
      {customer ? (
        <Box>
          <Stack vertical spacing={30}>
            <Grid>
              {isBusinessCustomer && (
                <LabeledText
                  label={t("manage-opportunity.customer-info.organizationName")}
                  text={customer.customer?.organizationName}
                />
              )}
              <LabeledText
                label={t("manage-opportunity.customer-info.first-name")}
                text={customer.customer?.firstName}
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.last-name")}
                text={customer.customer?.lastName}
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.type")}
                text={
                  isBusinessCustomer
                    ? t("manage-opportunity.customer-info.type.business")
                    : t("manage-opportunity.customer-info.type.private")
                }
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.address")}
                text={customer.customer?.streetAddress1}
              />
              {customer.customer?.streetAddress2 && (
                <LabeledText
                  label={`${t("manage-opportunity.customer-info.address")} 2`}
                  text={customer.customer.streetAddress2}
                />
              )}
              {customer.customer?.streetAddress3 && (
                <LabeledText
                  label={`${t("manage-opportunity.customer-info.address")} 3`}
                  text={customer.customer.streetAddress3}
                />
              )}
              <LabeledText
                label={t("manage-opportunity.customer-info.postal-code")}
                text={customer.customer?.postalCode}
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.city")}
                text={customer.customer?.city}
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.country")}
                text={<CountryName>{customer.customer?.country}</CountryName>}
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.contact-method")}
                text={
                  customer.customer?.preferredContactType === "EMAIL"
                    ? t("manage-opportunity.customer-info.email")
                    : t("manage-opportunity.customer-info.phone")
                }
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.email")}
                text={customer.customer?.email}
              />
              <LabeledText
                label={t("manage-opportunity.customer-info.phone")}
                text={customer.customer?.phoneNumber}
              />
              {customer.customer?.language &&
                customer.customer?.country !== "DK" && (
                  <LabeledText
                    label={t(
                      "manage-opportunity.customer-info.preffered-language"
                    )}
                    text={
                      t(
                        languageCodeToLanguage[
                          customer.customer.language.toLowerCase()
                        ]
                      ) || customer.customer.language
                    }
                  />
                )}
              {customer.customer?.familyNumber && (
                <LabeledText
                  label={t("manage-opportunity.customer-info.family-number")}
                  text={customer.customer.familyNumber}
                />
              )}
            </Grid>
            <Stack vertical spacing={26}>
              <Line />
              <Text header="h2">
                {t("manage-opportunity.customer-consent.title")}
              </Text>
              {requirePrivacyPolicy === true && (
                <Grid>
                  <LabeledText
                    label={t(
                      "manage-opportunity.customer-consent.terms-and-conditions"
                    )}
                    text={
                      customer.consents?.termsAndCondition
                        ? t("manage-opportunity.customer-consent.yes")
                        : t("manage-opportunity.customer-consent.no")
                    }
                  />
                  <LabeledText
                    label={t(
                      "manage-opportunity.customer-consent.privacy-policy"
                    )}
                    text={
                      customer.consents?.privacyPolicy
                        ? t("manage-opportunity.customer-consent.yes")
                        : t("manage-opportunity.customer-consent.no")
                    }
                  />
                </Grid>
              )}
              <Stack justifyContent="space-between">
                <Stack vertical spacing={30}>
                  <Stack spacing={4} vertical>
                    <Text label bold inline>
                      <T id="manage-opportunity.customer-consent.follow-up" />
                      <span
                        style={{
                          paddingLeft: "0.6ch",
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setModalOpen(true);
                        }}
                      >
                        <T id="my-opportunities.view-my-notes.read-more.link" />
                      </span>
                    </Text>
                    <ModalSheet
                      visible={modalOpen}
                      onClose={() => setModalOpen(false)}
                      title={t(
                        "manage-opportunity.customer-consent.overlay.title"
                      )}
                      closeText={t(
                        "manage-opportunity.customer-consent.legal-need.modal.close"
                      )}
                    >
                      <Stack vertical>
                        <Text bold>
                          <T id="new-opportunity.customer-consent.follow-up.title" />
                        </Text>
                        <Text pre>
                          {renderLinkedText(
                            t(
                              "new-opportunity.customer-consent.follow-up.description1"
                            )
                          )}
                        </Text>
                        {isDesc2Required(locale) && (
                          <Text>
                            {t(
                              "new-opportunity.customer-consent.follow-up.description2"
                            )}
                          </Text>
                        )}
                      </Stack>
                    </ModalSheet>
                    <Stack spacing={30}>
                      <RadioButtonFixed
                        id="contactYes"
                        label={t("manage-opportunity.customer-consent.yes")}
                        value="true"
                        checked={contactIsAllowed}
                        onChange={() => {
                          currentOpportunityDispatch({
                            type: "UPDATE_CONTACT_CONSENT",
                            payload: {
                              value: true,
                              reason:
                                currentOpportunity.dataToSave
                                  ?.consentChangeReason || "",
                            },
                          });
                        }}
                        disabled={disableEditing}
                      />
                      <RadioButtonFixed
                        id="contactNo"
                        label={t("manage-opportunity.customer-consent.no")}
                        value="false"
                        checked={!contactIsAllowed}
                        onChange={() => {
                          currentOpportunityDispatch({
                            type: "UPDATE_CONTACT_CONSENT",
                            payload: {
                              value: false,
                              reason:
                                currentOpportunity.dataToSave
                                  ?.consentChangeReason || "",
                            },
                          });
                        }}
                        disabled={disableEditing}
                      />
                    </Stack>
                  </Stack>
                  {contactIsAllowed !== contactWasAllowed && (
                    <FormField>
                      <TextAreaFixed
                        id="reason"
                        label={t(
                          "manage-opportunity.customer-consent.change-reason.label"
                        )}
                        placeholder={t(
                          "manage-opportunity.customer-consent.change-reason.label"
                        )}
                        optional
                        rows={4}
                        value={
                          currentOpportunity.dataToSave?.consentChangeReason ||
                          ""
                        }
                        onChange={(e) => {
                          currentOpportunityDispatch({
                            type: "UPDATE_CONTACT_CONSENT",
                            payload: {
                              value: currentOpportunity.allowContact,
                              reason: e.target.value,
                            },
                          });
                        }}
                      />
                    </FormField>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      ) : isLoading ? (
        <Loading isLoading={isLoading} />
      ) : (
        <Text>
          <T id="manage-opportunity.customer-info.missing-details" />
        </Text>
      )}
    </ManageAccordion>
  );
};

CustomerInfo.displayName = "CustomerInfo";
