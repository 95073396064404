import Select, { Option } from "@ingka/select";
import { OpportunityOrder } from "common/api";
import { orderBy } from "lodash";
import { format } from "date-fns";
import { useLocale } from "common/hooks";
import { t } from "i18n";
import { orderStatus } from "./OrderInfo.component";

interface Props {
  label: string;
  onChange(orderId: string): void;
  selectedOrderNo?: string;
  orders?: OpportunityOrder[];
  originalOrderNo?: string;
}

export const OrderSelect = (props: Props) => {
  const locale = useLocale();
  const { label, onChange, selectedOrderNo, orders, originalOrderNo } = props;

  return (
    <Select
      id="OrderSelect"
      label={label}
      onChange={(event) => onChange(event.target.value)}
      value={selectedOrderNo}
    >
      {orderBy(orders, "createdTime", "asc").map((theOrder) => (
        <Option
          name={`${format(new Date(theOrder.createdTime), "Pp", {
            locale: locale.dateFnsLocale,
          })} - ${theOrder.orderId} ${
            originalOrderNo === theOrder.orderId
              ? t("manage-opportunity.original-order")
              : ""
          } ${
            theOrder?.status
              ? `(${t(orderStatus[theOrder?.status?.toLowerCase()])})`
              : ""
          }`}
          key={theOrder.orderId}
          value={theOrder.orderId}
        />
      ))}
    </Select>
  );
};

OrderSelect.displayName = "OrderSelect";
