import useSWR from "swr";
import * as fetcher from "../fetcher";
import { ApiError } from "../ApiError";
import { useUserLocation } from "../user/userLocation";

export type OpenCloseRecord = Record<
  string,
  {
    Open: string;
    Close: string;
  }
>;

export interface Stores {
  Name: string;
  No: string;
  PrimaryLocale: string;
  SupportedLocales: string[];
  TimeZone: string;
  StoreAddress: Record<
    string,
    {
      Address1: string;
      CityName: string;
    }
  >;
  OpeningHours: {
    BISTRO?: OpenCloseRecord;
    STORE?: OpenCloseRecord;
    RESTAURANT?: OpenCloseRecord;
    SMALAND?: OpenCloseRecord;
  };
}

export function useStoreDetails(storeNo: string) {
  const { data, error } = useSWR<Stores>(
    storeNo ? `/${storeNo}` : null,
    (url) => fetcher.stores.get(url),
    {
      revalidateOnFocus: false,
      dedupingInterval: 5 * 60 * 1000, // 5 minute caching
    }
  );

  return {
    data,
    isLoading: !error && !data,
    error,
  };
}

type CustomerMeetingPoint = Record<string, string>;

export function useStoreList() {
  return useSWR<CustomerMeetingPoint, ApiError>(
    `/stores`,
    (_url) => fetcher.stores.get(""),
    {
      revalidateOnFocus: false,
      dedupingInterval: 5 * 60 * 1000, // 5 minute caching
    }
  );
}

export function useCustomerMeetingPoints(retailUnit?: string) {
  const userLocation = useUserLocation();

  return useSWR<CustomerMeetingPoint, ApiError>(
    userLocation
      ? `/global/stores?country=${userLocation}${
          retailUnit ? `&coworkerUnit=${retailUnit}` : ""
        }`
      : null,
    (url) => fetcher.core.get(url),
    {
      revalidateOnFocus: false,
      dedupingInterval: 5 * 60 * 1000, // 5 minute caching
    }
  );
}
