import { MouseEvent } from "react";
import SkapaCheckbox from "@ingka/checkbox";
import { Stack } from "components/layout";
import { Text } from "components/typography";
import { css, cx } from "@emotion/css";

interface Props {
  /** Enables subtle checkbox styling	 */
  subtle?: boolean;
  /** Wether the checkbox is checked or not */
  checked: boolean;
  /** The text label for this checkbox */
  label?: string;
  /** Show the label before the checkbox. Defaults to trailing */
  leadingLabel?: boolean;
  disabled?: boolean;
  /** Provide explicit ID for the HTML element. Otherwise uses the label value */
  id?: string | number;
  /** By default the checkbox spreads the box and label to 100% of the parent width.
   * Enable this to keep the box and the label together instead. */
  keepTogether?: boolean;
  onChange?(value: boolean): void;
}

/**
 * A checkbox that is a wrapper for the SKAPA component
 */
export const Checkbox = ({
  subtle,
  checked,
  label,
  onChange,
  disabled,
  leadingLabel,
  keepTogether,
  id,
}: Props) => {
  const htmlId =
    (id as string) || encodeURI(label || String(Math.random() * 100));
  const components = [
    <Text
      key={`${htmlId}_label`}
      color={disabled ? "greyDisabled" : undefined}
      small
    >
      <label
        className={cx(
          css`
            cursor: pointer;
          `,
          disabled &&
            css`
              pointer-events: none;
            `
        )}
        htmlFor={htmlId}
      >
        {label}
      </label>
    </Text>,
    <SkapaCheckbox
      key={`${htmlId}_checkbox`}
      id={htmlId}
      subtle={subtle}
      checked={checked}
      disabled={disabled}
      onChange={(e: MouseEvent<HTMLInputElement>) =>
        onChange && onChange(e.currentTarget.checked)
      }
    />,
  ];
  return (
    <Stack justifyContent={keepTogether ? undefined : "space-between"}>
      {leadingLabel ? components : components.reverse()}
    </Stack>
  );
};

Checkbox.displayName = "Checkbox";
