import {
  Box,
  Icon,
  LocalizedDate,
  PopoverMenu,
  PopoverItem,
  Stack,
  Text,
} from "components";
import { Task } from "common/api";
import { t } from "i18n";

interface ITaskItemProps {
  task: Task;
  odd: boolean;
  onSave(task: Partial<Task>): Promise<any>;
  onDelete(id: number): Promise<any>;
  onEdit(task: Task): void;
}

export const TaskItem: React.FunctionComponent<ITaskItemProps> = ({
  task,
  onSave,
  onDelete,
  onEdit,
  odd,
}) => {
  return (
    <Box padding={20} color={odd ? "bjornGrey" : "neutralWhite"}>
      <Stack vertical>
        <Stack justifyContent="space-between">
          <Stack spacing={6}>
            <Icon
              icon={task.isDone ? "CheckmarkCircle" : "CalendarIcon"}
              color={task.isDone ? "activityDone" : "taskText"}
            />
            <Text
              bold
              wordBreakAll
              color={task.isDone ? "activityDone" : "taskText"}
            >
              {task.title || "Unnamed task"}
            </Text>
          </Stack>
          <PopoverMenu>
            <PopoverItem
              text={t("my-opportunities.view-my-tasks.edit.button")}
              onClick={() => onEdit(task)}
            />
            <PopoverItem
              text={t("my-opportunities.view-my-tasks.delete.button")}
              onClick={() => {
                onSave({ ...task, isDone: true });
                onDelete(task.id);
              }}
            />
            <PopoverItem
              text={t("my-opportunities.view-my-tasks.mark-as-done.button")}
              onClick={() => onSave({ ...task, isDone: true })}
            />
          </PopoverMenu>
        </Stack>
        {task.taskStartDateTime && (
          <Stack spacing={6} alignItems="center">
            <Icon icon="ClockIcon" color="redIdle" size={14} />
            <Text small>
              <LocalizedDate pattern={task.isTimeSet ? "Pp" : "P"}>
                {task.taskStartDateTime}
              </LocalizedDate>
            </Text>
          </Stack>
        )}
        <Text small pre maxCharacters={80} wordBreakAll>
          {task.description}
        </Text>
      </Stack>
    </Box>
  );
};

TaskItem.displayName = "TaskItem";
