import SkapaSelect, { Option } from "@ingka/select";
import { useField } from "formik";
import { t } from "i18n";

interface Props {
  name: string;
  label: string;
  options: { value: string; name: string }[];
  hintText: string;
}

export const Select = ({ name, label, options }: Props) => {
  const [field] = useField({ name, type: "select" });

  const disabled = options.length === 1 && options[0].value === "";

  return (
    <SkapaSelect
      id={name}
      label={label}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      value={field.value}
      disabled={disabled}
      hintText={t("new-opportunity.customer.form.choose.option")}
    >
      {options.map(({ value, name: theName }) => (
        <Option value={value} name={theName} key={value} />
      ))}
    </SkapaSelect>
  );
};
