import { useState } from "react";
import Select, { Option } from "@ingka/select";
import {
  CurrentOpportunityDispatch,
  CurrentOpportunity,
} from "../currentOpportunity.reducer";
import RadioButton from "@ingka/radio-button";
import Skeleton from "@ingka/skeleton";
import TextArea from "@ingka/text-area";
import FormField from "@ingka/form-field";
import { Box, ModalSheet, Stack, Text } from "components";
import { Opportunity, useConfig } from "common/api";
import { t, T } from "i18n";
import { getStatusName } from "./Status";
import { range } from "lodash";
import InlineMessage from "@ingka/inline-message";
import { hasActiveAppointment } from "./Appointment/utils";
import { tracker } from "analytics";

const statuses = [
  "Assigned",
  "InProgress",
  "ClosedWon",
  "ClosedLost",
  "Invalid",
] as const;

const closedStatus = ["ClosedWon", "ClosedLost", "Invalid"];

interface Props {
  opportunityFromApi?: Opportunity;
  currentOpportunity: CurrentOpportunity;
  currentOpportunityDispatch: CurrentOpportunityDispatch;
  disableEditing?: boolean;
}

export const OpportunityStatusInfo = ({
  opportunityFromApi,
  currentOpportunity,
  currentOpportunityDispatch,
  disableEditing,
}: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [temporaryStatusReason, setTemporaryStatusReason] = useState("");
  const [
    temporaryStatusReasonDescription,
    setTemporaryStatusReasonDescription,
  ] = useState("");
  const { data: config } = useConfig();

  const [temporaryStatus, setTemporaryStatus] = useState<
    Opportunity["status"] | null
  >(null);

  const showCloseWarning =
    temporaryStatus &&
    closedStatus.includes(temporaryStatus) &&
    hasActiveAppointment(opportunityFromApi);
  const onClose = () => {
    setShowConfirmation(false);
    setTemporaryStatus(null);
    setTemporaryStatusReason("");
    setTemporaryStatusReasonDescription("");
  };

  return (
    <>
      <Box padding={30} color="bjornGrey">
        <Stack spacing={24} vertical>
          <Text header="h2">
            <T id="manage-opportunity.status.header" />
          </Text>
          {currentOpportunity.status && (
            <Select
              id="OpportunitySelect"
              label={t("manage-opportunity.status.label")}
              hintText={t("manage-opportunity.status.choose.option")}
              value={currentOpportunity.status}
              onChange={(e) => {
                if (
                  e.target.value === "ClosedWon" ||
                  e.target.value === "ClosedLost" ||
                  e.target.value === "Invalid"
                ) {
                  setTemporaryStatus(e.target.value);
                  setShowConfirmation(true);
                } else {
                  currentOpportunityDispatch({
                    type: "CHANGE_STATUS",
                    payload: {
                      status: e.target.value,
                    },
                  });
                }
              }}
              disabled={disableEditing}
            >
              {opportunityFromApi?.status === "Unassigned" && (
                <Option
                  value={"Unassigned"}
                  name={t("manage-opportunity.status.unassigned")}
                />
              )}
              {statuses.map((status) => {
                return (
                  <Option
                    value={status}
                    name={getStatusName(status)}
                    key={status}
                  />
                );
              })}
            </Select>
          )}
        </Stack>
      </Box>
      <ModalSheet
        title={t("manage-opportunity.status.modal.header")}
        onClose={onClose}
        visible={showConfirmation}
        primaryButtonProps={{
          text: t("manage-opportunity.status.modal.confirm-button"),
          disabled:
            !temporaryStatusReason ||
            (temporaryStatusReason === "OTHER" &&
              temporaryStatusReasonDescription.length <= 3),

          onClick: () => {
            tracker.event({
              category: "Manage page",
              action: "Clicked on 'Status change' button",
              reason: `${temporaryStatusReason === "OTHER"} ? 
              'Changed Status to ${temporaryStatus!} for reason: ${temporaryStatusReason} with a description: ${temporaryStatusReasonDescription}' : 
              'Changed Status to ${temporaryStatus!} with selected reason: ${temporaryStatusReason} ${temporaryStatusReasonDescription}'`,
            });
            currentOpportunityDispatch({
              type: "CHANGE_STATUS",
              payload: {
                status: temporaryStatus!,
                reason: temporaryStatusReason,
                description: temporaryStatusReasonDescription || undefined,
              },
            });
            onClose();
          },
        }}
      >
        <Stack vertical spacing={24}>
          {showCloseWarning && (
            <InlineMessage
              title={t("active-appointment.warning")}
              body={t("active-appointment.message")}
              variant="negative"
            />
          )}
          <Text header="h3">
            <T id="manage-opportunity.status.modal.title" />
          </Text>
          {temporaryStatus &&
            !config &&
            range(5).map((n) => (
              <Skeleton key={n} width="100%" height="25px" />
            ))}
          {temporaryStatus &&
            config?.reasons &&
            Object.keys(config.reasons[temporaryStatus]).map((id) => (
              <RadioButton
                key={id}
                id={"r-" + id}
                value={id}
                label={config.reasons[temporaryStatus][id]}
                checked={temporaryStatusReason === id}
                onChange={(e) => {
                  setTemporaryStatusReason(e.target.value);
                }}
              />
            ))}
          <FormField
            fieldHelper={{
              msg:
                temporaryStatusReason === "OTHER"
                  ? t(
                      "manage-opportunity.reason-change.placeholder.too-few-characters"
                    )
                  : t("manage-opportunity.reason-change.placeholder.optional"),
            }}
          >
            <TextArea
              id="reason"
              label={t("manage-opportunity.reason-change.label")}
              onChange={(e) =>
                setTemporaryStatusReasonDescription(e.target.value)
              }
            />
          </FormField>
        </Stack>
      </ModalSheet>
    </>
  );
};

OpportunityStatusInfo.displayName = "OpportunityStatusInfo";
