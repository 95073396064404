import { t, T } from "i18n";
import { TranslationKeys } from "i18n/translationKeys";

interface Props {
  id: string;
}

const getdynamicTranslationKey = (id: string) =>
  `service-area.name.${id}` as TranslationKeys;

export const AppointmentServiceArea = ({ id }: Props) => (
  <T id={getdynamicTranslationKey(id)} />
);

export const getAppointmentServiceArea = (id: string) =>
  t(getdynamicTranslationKey(id));
