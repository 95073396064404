import SkapaInputField, { InputFieldProps } from "@ingka/input-field";
import cross from "@ingka/ssr-icon/paths/cross";
import FormField from "@ingka/form-field";
import { t } from "i18n";

export type IInputFieldProps = {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  listId?: string;
  errorMessage?: string;
  optional?: boolean;
  value?: string | number;
  defaultValue?: string | number;
  icon?: InputFieldProps["ssrIcon"];
  iconPosition?: InputFieldProps["iconPosition"];
  maxLength?: number;
  onChange?(e: React.ChangeEvent<any>): void;
  onBlur?(e: React.ChangeEvent<any>): void;
  onReset?: () => void;
  onClick?(): void;
  prefix?: string;
  disabled?: boolean;
};

export const InputField = ({
  name,
  type,
  label,
  placeholder,
  errorMessage,
  listId,
  value,
  icon,
  optional,
  iconPosition = "trailing",
  onBlur,
  onChange,
  onReset,
  onClick,
  maxLength,
  defaultValue,
  prefix,
  disabled = false,
}: IInputFieldProps) => {
  const validation = errorMessage
    ? {
        msg: <p>{errorMessage}</p>,
        id: `${name}-error-message`,
      }
    : {};

  return (
    <FormField
      valid={!errorMessage}
      shouldValidate={!!errorMessage}
      validation={validation}
      fieldHelper={{ msg: placeholder }}
    >
      <SkapaInputField
        id={name}
        type={type}
        label={`${label} ${optional ? `(${t("form.optional")})` : ""}`}
        autoComplete={"off"}
        list={listId}
        name={name}
        value={value}
        defaultValue={defaultValue}
        ssrIcon={icon ? icon : onReset && value ? cross : undefined}
        iconPosition={iconPosition}
        onBlur={onBlur}
        onChange={onChange}
        iconOnClick={onReset}
        onClick={onClick}
        req={!optional}
        maxLength={maxLength}
        prefixLabel={prefix}
        disabled={disabled}
      />
    </FormField>
  );
};
