import { byteFormatter } from "./ByteFormatter";

interface Props {
  /** The number to format */
  children?: number;
  /** How many decimals to display. Defaults to 0 */
  decimals?: number;
}

/**
 * Display a byte size in human readable format
 */
export const Bytes = ({ children, decimals = 0 }: Props) => {
  return children ? <>{byteFormatter(children, decimals)}</> : null;
};
