import { Locale as DateFnsLocale } from "date-fns";

/**
 * Represents a locale in our system.
 */
export class Locale {
  readonly locale: SupportedLocale;

  /**
   * a locale that is imported from the date fns library
   */
  dateFnsLocale: DateFnsLocale;

  /**
   * Takes a locale string and creates a Locale object
   *
   * @param locale  The locale short code (ie. "fr-CA")
   */
  constructor(locale: string) {
    this.locale = locale as SupportedLocale;
  }

  /**
   * The country name in the native language
   */
  get countryName() {
    return nativeTranslations[this.locale].country;
  }

  /**
   * The language name in the native language
   */
  get languageName() {
    return nativeTranslations[this.locale].language;
  }

  /**
   * A human readable string in the native language (ie. "Français (Canada)")
   */
  get name() {
    return `${this.languageName} (${this.countryName})`;
  }

  /**
   * Get the two-letter country code for this locale
   */
  get countryCode() {
    return this.locale.split("-")[1];
  }

  /**
   * The language code for this locale
   */
  get languageCode() {
    return this.locale.split("-")[0];
  }

  /**
   * set the locale object that is imported from the date fns library
   */
  registerDateFnsLocale(localeObject: DateFnsLocale) {
    this.dateFnsLocale = localeObject;
  }

  /**
   * The locale short-code (ie. "fr-CA")
   */
  toString() {
    return this.locale;
  }
}

/**
 * The currently supported locales.
 * This array should match the contents of our /locales directory.
 * Don't forget to add new locales in the .linguirc config as well!
 */
export const supportedLocales = [
  "en-AT",
  "en-AU",
  "en-DE",
  "en-DK",
  "en-CA",
  "en-CH",
  "en-FI",
  "en-FR",
  "en-HR",
  "en-IN",
  "en-IT",
  "en-JP",
  "en-NO",
  "en-PL",
  "en-PT",
  "en-RS",
  "en-SE",
  "en-SI",
  "en-XZ",
  "de-DE",
  "da-DK",
  "de-AT",
  "de-CH",
  "fi-FI",
  "fr-FR",
  "fr-CA",
  "fr-CH",
  "hr-HR",
  "it-CH",
  "it-IT",
  "ja-JP",
  "no-NO",
  "pl-PL",
  "pt-PT",
  "ro-RO",
  "sl-SI",
  "sr-RS",
  "sv-FI",
  "sv-SE",
] as const;

/**
 * A supported locale
 */
export type SupportedLocale = typeof supportedLocales[number];

/**
 * All supported locales in the application in Locale format.
 */
export const allSupportedLocales = supportedLocales.map(
  (locale) => new Locale(locale)
);

/**
 * The default fall back locale if no locale is set.
 * In this case it is "en-CA"
 */
export const fallbackLocale = new Locale("en-CA");

/**
 * If it exists get a supported locale based on the short code (ie. "en-US")
 * If not supported it returns undefined.
 */
export const findLocaleIfSupported = (localeString?: string) =>
  localeString
    ? allSupportedLocales.find(
        (supportedLocale) => supportedLocale.toString() === localeString
      )
    : undefined;

/**
 * Get a supported locale based on the short code (ie. "en-US")
 */
export const findSupportedLocale = (localeString: SupportedLocale) => {
  const locale = allSupportedLocales.find(
    (supportedLocale) => supportedLocale.toString() === localeString
  );
  if (!locale) {
    throw Error(`${localeString} is not supported`);
  }
  return locale;
};

/**
 * The supported locales in their native language.
 * Used for presenting a list of languages to choose from.
 */
const nativeTranslations: Record<
  SupportedLocale,
  { country: string; language: string }
> = {
  "en-AT": { language: "English", country: "Austria" },
  "en-AU": { language: "English", country: "Australia" },
  "en-CA": { language: "English", country: "Canada" },
  "en-CH": { language: "English", country: "Switzerland" },
  "en-DE": { language: "English", country: "Germany" },
  "en-DK": { language: "English", country: "Denmark" },
  "en-FI": { language: "English", country: "Finland" },
  "en-FR": { language: "English", country: "France" },
  "en-HR": { language: "English", country: "Croatia" },
  "en-IN": { language: "English", country: "India" },
  "en-IT": { language: "English", country: "Italy" },
  "en-JP": { language: "English", country: "Japan" },
  "en-NO": { language: "English", country: "Norway" },
  "en-PL": { language: "English", country: "Poland" },
  "en-PT": { language: "English", country: "Portugal" },
  "en-RS": { language: "English", country: "Serbia" },
  "en-SE": { language: "English", country: "Sweden" },
  "en-SI": { language: "English", country: "Slovenia" },
  "en-XZ": { language: "English", country: "Dev" },
  "de-AT": { language: "Deutsch", country: "Austria" },
  "de-CH": { language: "Deutsch", country: "Schweiz" },
  "de-DE": { language: "German", country: "Germany" },
  "da-DK": { language: "dansk", country: "Danmark" },
  "fi-FI": { language: "Suomi", country: "Suomi" },
  "fr-FR": { language: "French", country: "France" },
  "fr-CA": { language: "Français", country: "Canada" },
  "fr-CH": { language: "Français", country: "la Suisse" },
  "hr-HR": { language: "Hrvatski", country: "Hrvatska" },
  "it-CH": { language: "Italiano", country: "Svizzera" },
  "it-IT": { language: "Italiano", country: "Italia" },
  "ja-JP": { language: "Japanese", country: "Japan" },
  "no-NO": { language: "Norwegian", country: "Norway" },
  "pl-PL": { language: "Polskie", country: "Polska" },
  "pt-PT": { language: "Português", country: "Portugal" },
  "ro-RO": { language: "Română", country: "România" },
  "sl-SI": { language: "Slovenščina", country: "Slovenija" },
  "sr-RS": { language: "Српски", country: "Србија" },
  "sv-FI": { language: "Svenska", country: "Finland" },
  "sv-SE": { language: "Svenska", country: "Sverige" },
};

/**
 * Supported locales for contact form.
 */
export const contactFormLocales: Record<SupportedLocale, string> = {
  "en-AT": "en",
  "en-AU": "en",
  "en-DE": "en",
  "en-DK": "en",
  "en-CA": "en",
  "en-CH": "en",
  "en-FI": "en",
  "en-FR": "en",
  "en-HR": "en",
  "en-IN": "en",
  "en-IT": "en",
  "en-JP": "en",
  "en-NO": "en",
  "en-PL": "en",
  "en-PT": "en",
  "en-RS": "en",
  "en-SE": "en",
  "en-SI": "en",
  "en-XZ": "en",
  "de-DE": "de",
  "da-DK": "da",
  "de-AT": "de",
  "de-CH": "de",
  "fi-FI": "fi",
  "fr-FR": "fr",
  "fr-CA": "fr-ca",
  "fr-CH": "fr",
  "hr-HR": "en",
  "it-CH": "it",
  "it-IT": "it",
  "ja-JP": "jp",
  "no-NO": "no",
  "pl-PL": "pl",
  "pt-PT": "pt",
  "ro-RO": "en",
  "sl-SI": "en",
  "sr-RS": "en",
  "sv-FI": "sv",
  "sv-SE": "sv",
};
