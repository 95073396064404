import { createContext, useContext, useState } from "react";
import SkapaToast from "@ingka/toast";

interface IIToastContext {
  updateToast: (toast: IToast) => void;
}

export interface IToast {
  /**
   * The text to show in the toast
   */
  text: string;
  /**
   * Provide a time for the message to re-appear even it the text is the same
   */
  time?: Date;
  action?: {
    title: string;
    onClick(): void;
  };
}

export const ToastContext = createContext<IIToastContext>({
  updateToast: () => {},
});

/**
 * A provider that makes sure to only render a single toast
 * in the app. It makes sure that the toast is independent of the current view
 * as well as keeps track of the timeout if multiple Toast are triggered.
 */
export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState<IToast | undefined>(undefined);
  let timeoutId: NodeJS.Timeout | null = null;

  const updateToast = (theToast: IToast) => {
    // Clear old timer
    timeoutId && clearTimeout(timeoutId);
    // Update toast message
    setToast(theToast);
    // Set new timer
    timeoutId = setTimeout(() => setToast(undefined), 50000);
  };

  return (
    <ToastContext.Provider
      value={{
        updateToast,
      }}
    >
      {children}
      <SkapaToast
        text={toast?.text || ""}
        isOpen={!!toast}
        onCloseRequest={() => setToast(undefined)}
        actionButtonText={toast?.action?.title}
        actionClick={toast?.action?.onClick}
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
