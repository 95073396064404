import { Box, Loading, SearchField, Stack } from "components";
import { useRouteMatch } from "react-router-dom";
import { AppointmentsFilter } from "./AppointmentsFilter";
import { AppointmentsTable } from "./AppointmentsTable";
import { useEffect, useState } from "react";
import { t } from "i18n";
import {
  AppointmentsSearchFilters,
  AppointmentsSearchOptions,
  useAppointmentsSearch,
} from "common/api";
import { OpportunitiesPagination } from "features/opportunities/OpportunitiesPagination";

type Settings<T> = {
  all: T;
  me: T;
};
type FilterSettings = Settings<AppointmentsSearchFilters>;
type OptionSettings = Settings<AppointmentsSearchOptions>;

function updateSettings<T extends FilterSettings | OptionSettings>(
  isMe: boolean,
  oldValue: T,
  newValue: T extends FilterSettings
    ? Partial<AppointmentsSearchFilters>
    : Partial<AppointmentsSearchOptions>,
  setter: React.Dispatch<React.SetStateAction<T>>
) {
  const newSettings = JSON.parse(JSON.stringify(oldValue));

  if (isMe) {
    newSettings.me = { ...newSettings.me, ...newValue };
  } else {
    newSettings.all = { ...newSettings.all, ...newValue };
  }
  setter(newSettings);
}

const defaultOptions: AppointmentsSearchOptions = {
  page: 0,
  pageSize: 10,
  sortKey: "timeWindowStartTime",
  sortOrder: "asc",
};

const defaultFilters: AppointmentsSearchFilters = {
  businessUnit: [],
  locationType: [],
  serviceArea: [],
  service: [],
  appointmentStatus: [
    "ORDER_RECEIVED",
    "ORDER_RECEIVED_WAITING_FOR_PAYMENT",
    "PLANNED",
    "STARTED",
  ],
  appointmentDate: [new Date(), null],
  assignment: [],
};

const cleanFilterData = (newValue: Partial<AppointmentsSearchFilters>) => {
  const newData = {
    term: newValue.term,
    emailId: newValue.emailId,
    isMe: newValue.isMe,
    businessUnit: newValue.businessUnit,
    locationType: newValue.locationType,
    serviceArea: newValue.serviceArea,
    service: newValue.service,
    appointmentStatus: newValue.appointmentStatus,
    appointmentDate: newValue.appointmentDate,
    assignment: newValue.assignment,
  };
  return parseObject(newData);
};

const dateOrNull = (d: Date | null) => {
  if (!d) {
    return null;
  }

  return new Date(d);
};

function arrayOrValue<T>(a?: T[] | null) {
  if (!a) {
    return [];
  }

  return a;
}

const parseObject = (
  obj: AppointmentsSearchFilters
): AppointmentsSearchFilters => {
  const newObj = JSON.parse(JSON.stringify(obj));
  newObj.appointmentDate = [
    dateOrNull(obj?.appointmentDate?.[0] ?? null),
    dateOrNull(obj?.appointmentDate?.[1] ?? null),
  ];

  newObj.businessUnit = arrayOrValue(obj.businessUnit);
  newObj.locationType = arrayOrValue(obj.locationType);
  newObj.serviceArea = arrayOrValue(obj.serviceArea);
  newObj.service = arrayOrValue(obj.service);
  newObj.appointmentStatus = arrayOrValue(obj.appointmentStatus);
  newObj.assignment = arrayOrValue(obj.assignment);

  return newObj;
};

const parseStoredQuery = (
  storedValue: string
): Settings<AppointmentsSearchFilters> => {
  const settings = JSON.parse(
    storedValue
  ) as Settings<AppointmentsSearchFilters>;

  settings.all = parseObject(settings.all);
  settings.me = parseObject(settings.me);

  return settings;
};

export const AppointmentsTab = () => {
  const storedFilter = parseStoredQuery(
    window.sessionStorage.getItem("APPOINTMENT_FILTERS") ??
      JSON.stringify({ all: defaultFilters, me: defaultFilters })
  );
  const { path } = useRouteMatch();
  const isMe = !!useRouteMatch(`${path}/me`);
  const [filters, setFilters] = useState<FilterSettings>({
    all: storedFilter.all,
    me: storedFilter.me,
  });

  const [options, setOptions] = useState<OptionSettings>({
    all: defaultOptions,
    me: defaultOptions,
  });

  useEffect(() => {
    window.sessionStorage.setItem(
      "APPOINTMENT_FILTERS",
      JSON.stringify(filters)
    );
  }, [filters]);

  const currentFilters = isMe ? filters.me : filters.all;
  const currentOptions = isMe ? options.me : options.all;

  const updateFilters = (newValue: Partial<AppointmentsSearchFilters>) => {
    updateSettings<FilterSettings>(
      isMe,
      filters,
      cleanFilterData(newValue),
      setFilters
    );
  };
  const updateOptions = (newValue: Partial<AppointmentsSearchOptions>) => {
    updateSettings<OptionSettings>(isMe, options, newValue, setOptions);
  };

  const { data: searchResult } = useAppointmentsSearch({
    filters: { ...currentFilters, isMe },
    options: currentOptions,
  });

  if (!searchResult) return <Loading fullscreen isLoading />;

  return (
    /* key prop ensure the component will rerender when switching between all/me */
    <Stack vertical spacing={30} key={String(isMe)}>
      <Box width="30rem">
        <SearchField
          id="search"
          minWidth="100%"
          value={currentFilters.term}
          onSearch={(term) => {
            updateFilters({ ...currentFilters, term });
            updateOptions({ page: 0 });
          }}
          placeholder={t("opportunities.search.placeholder")}
        />
      </Box>

      <AppointmentsFilter
        isMe={isMe}
        filters={currentFilters}
        onFiltersChange={(theFilters) => {
          updateFilters({ ...theFilters, term: currentFilters.term });
          updateOptions({ page: 0 });
        }}
      />
      <AppointmentsTable
        isMe={isMe}
        filters={currentFilters}
        options={currentOptions}
        numOfItems={searchResult?.totalElements ?? 0}
        onSortKeyChange={(sortKey) => updateOptions({ sortKey, page: 0 })}
        onSortOrderChange={(sortOrder) => updateOptions({ sortOrder, page: 0 })}
        appointments={searchResult?.content}
      />
      <OpportunitiesPagination
        page={searchResult.pageable.pageNumber}
        pageSize={searchResult.pageable.pageSize}
        numOfPages={searchResult?.totalPages ? searchResult.totalPages - 1 : 0}
        onPageChange={(page) => updateOptions({ page })}
        onPageSizeChange={(pageSize) => updateOptions({ pageSize, page: 0 })}
        labelKey="appointment.pagination.text"
      />
    </Stack>
  );
};
