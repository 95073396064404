import { Box, Loading, SearchField, Text } from "components";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import InlineMessage from "@ingka/inline-message";
import infoCircle from "@ingka/ssr-icon/paths/information-circle";
import {
  DesignSearchResponse,
  OpportunityPlanner,
  PlannerDetail,
  PlannerListItem,
  searchDesign,
  searchVPC,
} from "common/api";
import { PlannerCarousel } from "./PlannerCarousel.component";
import { useCallback, useEffect, useRef, useState } from "react";
import { isDesignSearchResponse, isVPC } from "./utils";
import { t } from "i18n";
import { css } from "@emotion/css";
import { PlannerSearchCard } from "./PlannerSearchCard.component";
import { useLocale } from "common";
import { useUserLocation } from "common/api/user/userLocation";

type Props = {
  handlePlannerClick: (planner: PlannerDetail | OpportunityPlanner) => void;
  customerEmail?: string;
  currencySymbol?: string;
  addedPlanners: PlannerDetail[];
  addedVPCPlanners: OpportunityPlanner[];
};

export const PlannerSearch = ({
  handlePlannerClick,
  customerEmail,
  currencySymbol,
  addedPlanners,
  addedVPCPlanners,
}: Props) => {
  const locale = useLocale();
  const countryCode = useUserLocation();
  const [searchValue, setSearchValue] = useState<string>("");
  const isDefaultSearch = useRef<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isErrored, setIsErrored] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<
    DesignSearchResponse | OpportunityPlanner | undefined
  >();

  const searchPlanner = useCallback(
    async (value: string | undefined, isRefreshing?: boolean) => {
      if (value === "" || value === undefined) return;
      if (!isRefreshing) {
        setIsLoading(true);
      }
      try {
        const res = isVPC(value)
          ? await searchVPC(value, countryCode || "SE", locale.languageCode)
          : await searchDesign(value, countryCode || "SE", locale.languageCode);
        setSearchResult(res);
      } catch (err) {
        if (!isDefaultSearch.current) {
          setSearchResult(undefined);
          setIsErrored(true);
        }
      }
      setIsLoading(false);
    },
    [locale, countryCode]
  );

  // default search with customer email
  useEffect(() => {
    if (isDefaultSearch) {
      searchPlanner(customerEmail);
    }
  }, [customerEmail, searchPlanner]);

  // set error state
  useEffect(() => {
    if (!isDefaultSearch.current && searchResult) {
      // design search
      if (isDesignSearchResponse(searchResult)) {
        if (searchResult.plannerList.length === 0) {
          setSearchResult(undefined);
          setIsErrored(true);
        } else if (searchResult.plannerList[0].error) {
          setSearchResult(undefined);
          setIsErrored(true);
        } else {
          setIsErrored(false);
        }
        // vpc search
      } else {
        if (searchResult.plannerId) {
          setIsErrored(false);
        } else {
          setSearchResult(undefined);
          setIsErrored(true);
        }
      }
    }
  }, [isDefaultSearch, searchResult, isErrored, isLoading]);

  // refresh
  useEffect(() => {
    const refetchInterval = setInterval(
      () => searchPlanner(searchValue, true),
      1000 * 60
    );
    return () => {
      clearInterval(refetchInterval);
    };
  }, [searchValue, searchPlanner]);

  // clear search result
  useEffect(() => {
    if (searchValue === "" || searchValue === undefined) {
      setIsLoading(false);
      setIsErrored(false);
      setSearchResult(undefined);
    }
  }, [searchValue]);

  const generateTabTitles = (list: PlannerListItem[]) => {
    return list.map((item, index) => (
      <Tab key={index} text={item.name} tabPanelId={item.name} />
    ));
  };

  const generateTabPanels = (list: PlannerListItem[]) => {
    return list.map((item, index) => (
      <TabPanel key={index} tabPanelId={item.name}>
        <PlannerCarousel
          addedPlanners={addedPlanners}
          addedVPCPlanners={addedVPCPlanners}
          planners={item.designList}
          onClick={handlePlannerClick}
          currencySymbol={currencySymbol}
        />
      </TabPanel>
    ));
  };

  return (
    <>
      <Box width="35rem">
        <SearchField
          backgroundColor="white"
          id="planner-search"
          minWidth="100%"
          value={searchValue}
          onChange={() => {
            isDefaultSearch.current = false;
          }}
          onSearch={(value) => {
            setSearchValue(value);
            searchPlanner(value);
          }}
          onClear={() => setSearchValue("")}
          placeholder={t("planner.search.placeholder")}
        />
      </Box>

      {isLoading && (
        <Loading isLoading={isLoading} text={t("planner.searching.text")} />
      )}

      {isErrored && !isLoading && (
        <InlineMessage
          dismissable
          ssrIcon={infoCircle}
          variant="negative"
          title={t("planner.not-found.message")}
          body={t("planner.try-again.message")}
          className={css`
            width: 35rem;
          `}
        />
      )}
      {searchResult &&
        isDesignSearchResponse(searchResult) &&
        searchResult?.plannerList.length > 0 &&
        !isErrored &&
        !isLoading && (
          <>
            <div>
              <Text header="h3">{`${t(
                "manage-opportunity.planner-drawing.search_result_for"
              )} ${customerEmail}:`}</Text>
            </div>
            <Tabs
              defaultActiveTab={searchResult.plannerList[0].name}
              tabs={generateTabTitles(searchResult.plannerList)}
              tabPanels={generateTabPanels(searchResult.plannerList)}
              className={css`
                .tabs__tab {
                  width: 20%;
                }

                .tabs_panel {
                  padding: 2.5rem 1.5rem;
                }
              `}
            />
          </>
        )}

      {searchResult &&
        !isDesignSearchResponse(searchResult) &&
        !isLoading &&
        !isErrored && (
          <PlannerSearchCard
            addedPlanners={addedPlanners}
            addedVPCPlanners={addedVPCPlanners}
            planner={searchResult}
            onClick={handlePlannerClick}
            currencySymbol={currencySymbol}
          />
        )}
    </>
  );
};
