import { useField } from "formik";
import { DatePicker } from "../forms";

export type DatePickerProps = {
  name: string;
  label: string | [string, string];
  placeholder?: string | [string, string];
  clearable?: boolean;
  maxWidth?: string;
  maxDate?: Date;
  onChange?: (start: Date | undefined, end: Date | undefined) => void;
};

export const FormikDatePicker = ({
  name,
  label,
  placeholder,
  clearable,
  maxWidth,
  maxDate,
  onChange,
}: DatePickerProps) => {
  const [field, , helpers] = useField(name);
  return (
    <DatePicker
      label={label}
      placeholder={placeholder}
      id={name}
      isClearable={clearable}
      value={field?.value?.map((value: Date) => value || null)}
      onChange={(start, end) => {
        helpers.setValue([start, end]);
        if (onChange) {
          onChange(start, end);
        }
      }}
      maxWidth={maxWidth}
      maxDate={maxDate}
    />
  );
};
