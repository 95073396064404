import { useContext, useEffect } from "react";
import { ModalHeader as SkapaModalHeader } from "@ingka/modal";
import { ModalContext } from "./context";

interface Props {
  title: string;
}

export const ModalHeader = ({ title }: Props) => {
  const { setHeader } = useContext(ModalContext);

  useEffect(() => {
    setHeader(<SkapaModalHeader title={title} />);

    return () => {
      setHeader(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
