import { getJsonFromGraphApi } from "./graphCommunicator";
import { User, Group } from "@microsoft/microsoft-graph-types";
import { isDefined } from "common/helpers";

export type { User, Group };
/**
 * Get the logged in user's profile
 */
export const getUserProfile = (): Promise<User> => getJsonFromGraphApi("me");

export const getUserProfileCountryCode = async () =>
  window.sessionStorage.getItem("userCountry") ||
  (await getUserProfile()).usageLocation;

export const getUserProfileNetworkName = () =>
  window.sessionStorage.getItem("networkName");

/**
 * Get the members of a specific group transitivley
 * (ie. follow the nested groups)
 */
export const getMembersOfGroupTransitive = async (
  groupId: string,
  query: string = ""
): Promise<User[]> =>
  (await getJsonFromGraphApi(`groups/${groupId}/transitiveMembers${query}`))
    .value;

const userGroup = process.env.REACT_APP__AZ_USER_GROUP;
const adminGroup = process.env.REACT_APP__AZ_ADMIN_GROUP;

/**
 * Search for members of a specific market and only return the displayName and mail
 */
export const searchMarketCoworkers = async (
  searchTerm: string,
  userLocation: string = "SE"
): Promise<User[]> => {
  const userQuery = `?$count=true&$filter=usageLocation eq '${userLocation}'&$search="displayName:${searchTerm}"&$select=displayName,mail,surname,givenName,officeLocation,onPremisesExtensionAttributes`;
  const adminQuery = `?$count=true&$search="displayName:${searchTerm}"&$select=displayName,mail,surname,givenName,officeLocation,onPremisesExtensionAttributes`;
  const requests = [
    userGroup ? getMembersOfGroupTransitive(userGroup, userQuery) : undefined,
    adminGroup
      ? getMembersOfGroupTransitive(adminGroup, adminQuery)
      : undefined,
  ].filter(isDefined);

  const res = await Promise.all(requests);
  return dedupeUsers((res[0] ?? []).concat(res[1] ?? []));
};

function dedupeUsers(allUsers: User[]) {
  const dedupeSet = new Set<string>();

  const result: User[] = [];

  for (const user of allUsers) {
    if (!user.mail) {
      result.push(user);
      continue;
    }
    if (!dedupeSet.has(user.mail)) {
      result.push(user);
      dedupeSet.add(user.mail);
    }
  }

  return result;
}
