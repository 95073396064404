import { Box } from "components";
import { useLocale } from "common/hooks";
import { contactFormLocales } from "i18n";
import { t } from "i18n";
import { usePageTracking } from "analytics";

export const ContactUs = () => {
  const { locale } = useLocale();
  const formLocale = contactFormLocales[locale];

  usePageTracking("Contact");

  return (
    <Box padding={24} grow alignItems="start" height="90vh">
      <iframe
        title={t("feedback.title")}
        width="100%"
        height="100%"
        src={`https://forms.office.com/Pages/ResponsePage.aspx?id=emMLclplz0CBavIvQHVcLCcydit8J65Cg3JxY-yO9JhURVhYVVlZSlNRSFU2WTM1TEZXUUpZQ1hFSC4u&lang=${formLocale}`}
        frameBorder={0}
        marginWidth={0}
        marginHeight={0}
        allowFullScreen
      />
    </Box>
  );
};
