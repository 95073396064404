import useSWR from "swr";
import * as fetcher from "../fetcher";

export interface Amount {
  currencyCode: string;
  grossAmount: number;
  netAmount: number;
  vatAmount: number;
}

export interface Amounts {
  Discount?: Amount;
  DeliveryService?: Amount;
  Total?: Amount;
  GoodsValue?: Amount;
  Service?: Amount;
  DiscountEmployee?: Amount;
  Balance?: Amount;
  ToPay?: Amount;
}

interface SalesPrice {
  currency: string;
  value: number;
}

interface ImageVariant {
  quality: string;
  height: number;
  width: number;
  href: string;
}

interface Item {
  itemNo: string;
  itemType: "ART" | "GRP";
  quantity: number;
  childItems: Item[];
  salesPrice?: SalesPrice;
  itemName: string;
  imageVariants: ImageVariant[];
  notes: any[];
}

interface Price {
  currencyCode: string;
  priceExclTax: number;
  priceInclTax: number;
}

interface TaxRate {
  taxAmount: number;
  taxCode: string;
  taxPercentage: number;
  taxType: string;
}

interface TimeWindow {
  fromLocalDateTime: string;
  timeZone: string;
  toLocalDateTime: string;
}

interface DeliveryService {
  deliveryDestinationType: string;
  price: Price;
  referencedItems: string[];
  serviceId: string;
  serviceItemNo: string;
  serviceItemType: string;
  serviceName: string;
  status: string;
  statusText: string;
  taxRates: TaxRate[];
  timeWindows: TimeWindow[];
}

interface DeliveryArrangement {
  deliveryServices: DeliveryService[];
  serviceSubType: string;
  serviceType: string;
}

interface PaymentTransaction {
  amount: number;
  currencyCode: string;
  paymentGateWay: string;
  paymentSystem: string;
  status: string;
  tenderType: string;
  transactionDateTime: string;
  transactionType: string;
}

interface Payment {
  grossAmount: number;
  netAmount: number;
  paymentTransactions: PaymentTransaction[];
}

interface PaymentPlan {
  amount: number;
  currencyCode: string;
  dueDateTime: string;
  timing: string;
  type: string;
  payments: Payment[];
}

interface Planner {
  plannerId: string;
  plannerIdSource: string;
}

export interface Contact {
  address?: Address;
  customerContactDetails: {
    businessName?: string;
    firstName?: string;
    lastName?: string;
    familyNumber?: string;
    locale: string;
    preferredContactType?: string;
    customerType?: string;
    contactTypeValues: {
      EMAIL?: string;
      MOBILE?: string;
    };
  };
}

interface Address {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  cityName: string;
  countryCode: string;
  streetName: string;
  zipCode: string;
}
interface Service {
  price: Price;
  referencedItems: string[];
  serviceItemNo: string;
  serviceItemType: string;
  serviceName: string;
  servicePaymentTo: string;
  serviceProductId: string;
  serviceProviderId: string;
  serviceProviderName: string;
  status: string;
  statusText: string;
  taxRates: TaxRate[];
  timeWindows: TimeWindow[];
}

export interface Order {
  orderValue: number;
  orderType: string;
  orderNo: string;
  orderNoSource: string;
  currencyCode: string;
  orderCreationDate: string;
  orderStatus: string;
  amounts: Amounts;
  items: Item[];
  deliveryArrangements: DeliveryArrangement[];
  paymentPlans: PaymentPlan[];
  planners: Planner[];
  providedServices: Service[];
  buCode: string;
  buName: string;
  creationMethod: string;
  billingContact: Contact;
  deliveryContact?: Contact;
}

export function useOrder(id?: string) {
  return useSWR<Order>(
    id ? `/global/orders/${id}` : null,
    (url) => fetcher.core.get(url),
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );
}
