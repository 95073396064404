import { useState } from "react";
import { Button, Stack, Text } from "components";
import deleteIcon from "@ingka/ssr-icon/paths/trash-can";
import { deleteOrderInfo } from "../../../common/api/opportunity/opportunity";
import { t } from "i18n";
import { deletePlannerDrawing } from "common/api";

interface Props {
  onAction(): void;
  opportunityId: number;
  itemId: string | undefined;
  type: string;
}

export const MultipleActions = ({
  onAction,
  opportunityId,
  itemId,
  type,
}: Props) => {
  const [actionAlert, setActionAlert] = useState<boolean>(false);
  const [deleteComfirm, setDeleteConfirm] = useState<boolean>(false);

  return (
    <>
      <Stack vertical spacing={24}>
        {!actionAlert && (
          <Button
            small
            ssrIcon={deleteIcon}
            text={t("modal.delete")}
            onClick={() => {
              setDeleteConfirm(true);
              setActionAlert(true);
            }}
          />
        )}
      </Stack>
      <Stack vertical spacing={20}>
        {deleteComfirm && (
          <>
            {actionAlert && (
              <>
                <Stack padding={[50, 0]} justifyContent="center" spacing={30}>
                  <Text bold>
                    {type === "order"
                      ? t("order-delete.confirm")
                      : type === "planner"
                      ? t("planner-delete.confirm")
                      : ""}
                  </Text>
                </Stack>
                <Stack justifyContent={"center"}>
                  <Button
                    text={t("delete.no.button")}
                    onClick={() => {
                      setDeleteConfirm(false);
                      setActionAlert(false);
                    }}
                  />
                  <Button
                    primary
                    text={t("delete.yes.button")}
                    onClick={async () => {
                      if (itemId) {
                        let deleteResponse;
                        if (type === "order") {
                          deleteResponse = await deleteOrderInfo(
                            opportunityId,
                            itemId
                          );
                        }
                        if (type === "planner") {
                          deleteResponse = await deletePlannerDrawing(
                            opportunityId,
                            itemId
                          );
                        }
                        if (deleteResponse?.status === 200) {
                          setDeleteConfirm(true);
                          onAction();
                        }
                      }
                    }}
                  />
                </Stack>
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

MultipleActions.displayName = "MultipleActions";
