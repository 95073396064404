import { ChoiceItem } from "@ingka/choice";
import { Stack, Prompt, Box } from "components";
import { LocaleContext } from "../i18n";
import { t } from "i18n";
import { useContext } from "react";
import { activeCTEMarkets } from "common/market/markets";

interface IMarketSelectorProps {
  visible: boolean;
  selectedMarket: string;
}

interface ILocaleItemProps {
  market: string;
  selected: boolean;
  onClick: () => void;
}

const MarketItem = ({ market, selected, onClick }: ILocaleItemProps) => (
  <ChoiceItem
    id={market}
    title={market}
    selected={selected}
    onClick={onClick}
  />
);

export const saveOverrideMarket = (market: string) =>
  window.sessionStorage.setItem("overrideMarket", market);
export const clearOverrideMarket = () =>
  window.sessionStorage.removeItem("overrideMarket");
export const loadOverrideMarket = () =>
  window.sessionStorage.getItem("overrideMarket") ?? "";

export const MarketSelector = ({
  selectedMarket,
  visible,
}: IMarketSelectorProps) => {
  const localeContext = useContext(LocaleContext);

  const markets = Array.from(activeCTEMarkets).sort((a: string, b: string) =>
    a < b ? -1 : 1
  );

  return (
    <>
      <Prompt
        title={t("user.profile.market-override.header")}
        visible={visible}
        escapable={true}
        closeText={t("user.profile.language-preference.close.button")}
        onClose={() => localeContext.setShowMarketPrompt(false)}
        primaryButtonProps={{
          text: t("user.profile.market-override.reset.button"),
          onClick: () => {
            clearOverrideMarket();
            window.sessionStorage.removeItem("userCountry");
            window.location.reload();
          },
        }}
      >
        <Box minWidth="300px">
          <Stack vertical>
            {markets.map((market, i) => (
              <MarketItem
                key={i}
                market={market}
                selected={selectedMarket === market}
                onClick={() => {
                  saveOverrideMarket(market);
                  window.location.reload();
                }}
              />
            ))}
          </Stack>
        </Box>
      </Prompt>
    </>
  );
};
