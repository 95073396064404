import { useEffect, useState } from "react";
import { tracker } from "analytics";
import { useLocation, useRouteMatch } from "react-router-dom";
import qs from "querystring";
import {
  OpportunitiesSearchBusinessUnitFilter,
  OpportunitiesSearchClassificationFilter,
  OpportunitiesSearchCustomerTypeFilter,
  OpportunitiesSearchDepartmentFilter,
  OpportunitiesSearchFilters,
  OpportunitiesSearchOptions,
  OpportunitiesSearchStatusFilter,
  useOpportunitiesSearch,
  useOpportunitiesSearchSettings,
} from "common/api";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { Box, Loading, SearchField, Stack } from "components";
import { OpportunitiesFilter } from "./OpportunitiesFilter";
import { OpportunitiesTable } from "./OpportunitiesTable";
import { OpportunitiesPagination } from "./OpportunitiesPagination";
import { t } from "i18n";

type OpportunitiesPageSearch = {
  status?: OpportunitiesSearchStatusFilter;
  classification?: OpportunitiesSearchClassificationFilter;
  department?: OpportunitiesSearchDepartmentFilter;
  businessUnit?: OpportunitiesSearchBusinessUnitFilter;
  customerType?: OpportunitiesSearchCustomerTypeFilter;
  services?: number[];
  orderStatus?: string[];
};

function getInitialFilters(
  settings?: OpportunitiesSearchFilters,
  search?: {
    status?: OpportunitiesSearchStatusFilter;
    classification?: OpportunitiesSearchClassificationFilter;
    department?: OpportunitiesSearchDepartmentFilter;
    businessUnit?: OpportunitiesSearchBusinessUnitFilter;
    customerType?: OpportunitiesSearchCustomerTypeFilter;
    services?: number[];
  }
): OpportunitiesSearchFilters {
  return {
    ...settings,
    ...(search?.status && {
      status: [search.status] as OpportunitiesSearchStatusFilter[],
    }),
    ...(search?.classification && {
      classification: [
        search.classification,
      ] as OpportunitiesSearchClassificationFilter[],
    }),
    ...(search?.department && {
      department: [search.department] as OpportunitiesSearchDepartmentFilter[],
    }),
    ...(search?.businessUnit && {
      businessUnit: [
        search.businessUnit,
      ] as OpportunitiesSearchBusinessUnitFilter[],
    }),
    ...(search?.services && {
      services: search.services,
    }),
    ...(search?.customerType && {
      customerType: [
        search.customerType,
      ] as OpportunitiesSearchCustomerTypeFilter[],
    }),
  };
}

export function parseTitle(title: string) {
  const translations = {
    PIANOTPAID: "opportunities.quick-view.reason.pia-not-paid",
    MULTIPLE_ORDERS_GIVE_LOW_LIMIT:
      "opportunities.quick-view.reason.multiple-orders-low-limit",
    LOWLIMIT: "opportunities.quick-view.reason.low-limit",
    CARDBLOCK: "opportunities.quick-view.reason.card-block",
    CANC_PIA_DATE_PASSED:
      "opportunities.quick-view.reason.cancelled-pia-date-passed",
    NOT_PAID_ON_CRE_DATE:
      "opportunities.quick-view.reason.not-paid-on-cre-date",
    CREDIT_CARD_REJECTED: "opportunities.quick-view.reason.card-rejected",
    PAYM_GATEWAY_UNAVAIL:
      "opportunities.quick-view.reason.payment-gateway-unavailable",
    CUSTPAYM: "opportunities.quick-view.reason.cutomer-payment",
    PLANNING_SEASONAL_CUSTOMER:
      "opportunities.quick-view.reason.planning-seasonal-customer",
  };

  return title in translations ? t(translations[title]) : title;
}

export const defaultOptions: OpportunitiesSearchOptions = {
  page: 0,
  pageSize: 10,
  sortKey: "opportunityId",
  sortOrder: "desc",
};

export const defaultFilters: OpportunitiesSearchFilters = {
  type: [],
  status: [],
  department: [],
  businessUnit: [],
  customerType: [],
  services: [],
  value: undefined,
  classification: [],
  createdAt: [null, null],
};

export const OpportunitiesTab = () => {
  const { path } = useRouteMatch();
  const query = qs.parse(
    useLocation().search.substr(1)
  ) as OpportunitiesPageSearch;
  const isMe = !!useRouteMatch(`${path}/me`);
  const { data: settings, error } = useOpportunitiesSearchSettings({
    isMe,
  });
  const [filters, setFilters] = useState<
    OpportunitiesSearchFilters | undefined
  >(undefined);

  const [options, setOptions] = useState(settings?.options ?? defaultOptions);
  const { data: search } = useOpportunitiesSearch(
    {
      filters: { ...(filters ?? {}), assigned: isMe },
      options,
    },
    !filters
  );

  useDeepCompareEffectNoCheck(() => {
    if (settings || error) {
      setFilters(getInitialFilters(settings?.filters ?? defaultFilters, query));
    }
  }, [settings?.filters, error]);

  useDeepCompareEffectNoCheck(() => {
    setOptions(settings?.options ?? defaultOptions);
  }, [settings?.options]);

  const numOfPages =
    search?.totalElements && options?.pageSize
      ? Math.floor(search.totalElements / options.pageSize)
      : 0;

  useEffect(() => {
    if (options && options.page > numOfPages)
      setOptions({ ...options, page: numOfPages });
  }, [options, numOfPages, setOptions]);

  if (!search || !filters) return <Loading fullscreen isLoading />;

  return (
    <Stack vertical spacing={30}>
      <Box width="30rem">
        <SearchField
          id="search"
          minWidth="100%"
          value={filters.term}
          onSearch={(term) => {
            setFilters({ ...filters, term });
            setOptions({ ...options, page: 0 });
          }}
          placeholder={t("opportunities.search.placeholder")}
        />
      </Box>
      <OpportunitiesFilter
        filters={filters}
        onFiltersChange={(theFilters) => {
          setFilters({ ...theFilters, term: filters.term });
          setOptions({ ...options, page: 0 });
        }}
      />
      <OpportunitiesTable
        isMe={isMe}
        filters={filters}
        options={options}
        numOfItems={search.totalElements}
        onSortKeyChange={(sortKey) => {
          tracker.event({
            category: "Sorting",
            action: `Sort on ${sortKey}`,
          });
          setOptions({ ...options, sortKey, page: 0 });
        }}
        onSortOrderChange={(sortOrder) =>
          setOptions({ ...options, sortOrder, page: 0 })
        }
        opportunities={search?.content}
      />

      <OpportunitiesPagination
        page={options.page}
        pageSize={options.pageSize}
        numOfPages={search.totalPages - 1}
        onPageChange={(page) => setOptions({ ...options, page })}
        onPageSizeChange={(pageSize) =>
          setOptions({ ...options, pageSize, page: 0 })
        }
        labelKey="opportunities.pagination.text"
      />
    </Stack>
  );
};
