import * as fetcher from "../fetcher";

export const openUserGuide = async () => {
  const response = await fetcher.opportunity.download(
    `/files/guide/guidelines/IKEA_Sales_Assistant_UserGuide.pdf`
  );
  const fileURL = URL.createObjectURL(response);

  window.open(fileURL);
};
