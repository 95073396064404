import { User } from "@microsoft/microsoft-graph-types";
import { useUserLocation } from "common/api/user/userLocation";
import { searchMarketCoworkers } from "common/graphApi";
import { useDebouncedEffect } from "components/hooks";
import { useState } from "react";

export const useCoworkerSearch = (searchTerm: string): [User[], () => void] => {
  const [coworkers, setCoworkers] = useState<User[]>([]);
  const userLocation = useUserLocation();

  const resetResults = () => {
    setCoworkers([]);
  };

  useDebouncedEffect(
    () => {
      if (searchTerm) {
        const search = async (term: string) => {
          try {
            const results = await searchMarketCoworkers(term, userLocation);
            setCoworkers(results);
          } catch (error) {
            setCoworkers([]);
          }
        };
        search(searchTerm);
      } else {
        resetResults();
      }
    },
    500,
    [searchTerm]
  );

  return [coworkers, resetResults];
};
