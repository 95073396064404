import { useAndFetchLocale } from "common/hooks";
import { useState, useEffect } from "react";
import { initLocale } from "./initiate";
import { I18nProvider } from "@lingui/react";
import { createContext } from "react";
import { i18n } from "@lingui/core";
import { LocaleSelector } from "application/LocaleSelector";
import { Locale } from "i18n";
import { features } from "common/feature-flags";
import { loadOverrideMarket, MarketSelector } from "application/MarketSelector";

interface ILocaleContext {
  showLocalePrompt: boolean;
  setShowLocalePrompt: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMarketPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  activeLocale: Locale;
}

export const LocaleContext = createContext<ILocaleContext>(null!);

interface Props {
  children: React.ReactChild;
}

export const LocaleProvider = ({ children }: Props) => {
  const {
    cachedLocale,
    preferredLocale,
    browserLocaleIfSupported,
    fallbackLocale,
    loading,
  } = useAndFetchLocale();

  const selectedMarket = loadOverrideMarket();

  const [showMarketPrompt, setShowMarketPrompt] = useState<boolean>(false);
  const [showLocalePrompt, setShowLocalePrompt] = useState<boolean>(false);

  const [isMissingPreferredLocale, setIsMissingPreferredLocale] =
    useState<boolean>(false);

  const activeLocale =
    cachedLocale ||
    preferredLocale ||
    browserLocaleIfSupported ||
    fallbackLocale;

  useEffect(() => {
    setIsMissingPreferredLocale(!loading && !preferredLocale);
  }, [preferredLocale, loading]);

  useEffect(() => {
    initLocale(activeLocale);
  }, [activeLocale]);

  const isLocaleSelectorVisible =
    features.localization && (showLocalePrompt || isMissingPreferredLocale);

  return (
    <LocaleContext.Provider
      value={{
        showLocalePrompt,
        setShowLocalePrompt,
        setShowMarketPrompt,
        activeLocale,
      }}
    >
      <LocaleSelector
        visible={isLocaleSelectorVisible}
        selectedLocale={activeLocale}
        escapable={!isMissingPreferredLocale}
      />
      <MarketSelector
        visible={showMarketPrompt}
        selectedMarket={selectedMarket}
      />
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </LocaleContext.Provider>
  );
};
