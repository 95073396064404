import { Stack } from "components/layout";
import { Text } from "components/typography";
import { CustomerGlobal } from "common/api";
import { t } from "i18n";

interface Props {
  customer: CustomerGlobal;
}

export const CustomerInfo = ({ customer }: Props) => {
  return (
    <Stack vertical spacing={0}>
      {customer.customerType === "Individual" ? (
        <Text bold>
          {customer.firstName} {customer.lastName}
        </Text>
      ) : (
        <Text bold>{customer.organizationName}</Text>
      )}
      <Text>
        {t("new-opportunity.customer-info.customer-type")}:{" "}
        {customer.customerType}
      </Text>
      <Text>
        {t("new-opportunity.customer-info.city")}: {customer.city}
      </Text>
      <Text>
        {t("new-opportunity.customer-info.phone")}: {customer.phoneNumber}
      </Text>
      <Text wordBreakAll>
        {t("new-opportunity.customer-info.email")}: {customer.email}
      </Text>
    </Stack>
  );
};
