import { getLoginToken } from "authorization";

/**
 * Call the Microsoft Graph API and get a JSON object back
 */
export const getJsonFromGraphApi = async (endpoint: string) => {
  const result = await graphCall(endpoint);
  if (result?.status === 200 || result?.status === 201) {
    return await result.json();
  }
};

/**
 * A basic call to the Microsoft Graph API
 * using the authorized user's token.
 */
const graphCall = async (
  endpoint: string,
  contentType = "application/json"
) => {
  const token = await getLoginToken();
  if (!token) {
    return;
  }
  const result = await fetch(`https://graph.microsoft.com/beta/${endpoint}`, {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      "Content-Type": contentType,
      ConsistencyLevel: "eventual",
    }),
  });
  return result;
};
