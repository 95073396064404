import { getUserProfile } from "common";
import { Box, Loading, Stack, Text } from "components";
import {
  useEffect,
  useState,
  ReactNode,
  createContext,
  useContext,
} from "react";
import {
  handleLogInRedirect,
  getActiveAccount,
  logIn,
  setActiveAccount,
  getAuthToken,
  getActiveAccountGroupIds,
} from "./msalClient";
import { isAdminUser, isSalesPerson } from "./msalConfig";
import { UserRole } from "./userRoleHook";

export type Login = {
  userRoles: UserRole[];
  retailUnit: string;
};

const getRolesFromGroups = (groups) => {
  const newUserRoles: UserRole[] = [];

  if (isAdminUser(groups ?? [])) {
    newUserRoles.push("Admin");
  }
  if (isSalesPerson(groups ?? [])) {
    newUserRoles.push("SalesPerson");
  }

  return newUserRoles;
};

const LoginContext = createContext<Login>({
  userRoles: [],
  retailUnit: "",
});
interface Props {
  children: ReactNode;
}
/**
 * A wrapper component that checks the user's MSAL token.
 * Forces a redirect login if no token is available.
 */
export const LoginProvider = ({ children }: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [retailUnit, setRetailUnit] = useState<string>("");
  const [hasAccess, setHasAccess] = useState<boolean | undefined>();
  const isLoggingIn = window.location.hash.includes("#code");

  useEffect(() => {
    (async () => {
      if (!isLoggedIn) {
        const activeAccount =
          (await handleLogInRedirect())?.account ?? getActiveAccount();

        if (activeAccount) {
          try {
            const profile = await getUserProfile();
            if (profile?.onPremisesExtensionAttributes?.extensionAttribute13) {
              setRetailUnit(
                profile.onPremisesExtensionAttributes.extensionAttribute13
              );
            }

            let groups = await getActiveAccountGroupIds(false);
            let groupRoles = getRolesFromGroups(groups);

            // If we fail to get the group from token , try again with a fetch
            if (groupRoles.length === 0) {
              groups = await getActiveAccountGroupIds(true);
              groupRoles = getRolesFromGroups(groups);
            }

            // If the user doesn't have a role they shouldn't have access
            if (groupRoles.length === 0) {
              setHasAccess(false);
              // eslint-disable-next-line no-console
              console.error(
                `Error when logging in: No access from groups (${
                  groups?.length ?? 0
                }).`
              );
            }

            window.sessionStorage.setItem(
              "networkName",
              profile?.mailNickname ?? ""
            );
            setUserRoles(groupRoles);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error("Error when logging in", err);
          }
          setActiveAccount(activeAccount);
          setIsLoggedIn(true);
        } else {
          logIn();
        }
      } else {
        if (hasAccess === undefined) {
          setHasAccess(true);
        }
      }

      // Refresh login token 1/hour
      const interval = setInterval(async () => {
        await getAuthToken();
      }, 59 * 60 * 1000);

      return () => clearInterval(interval);
    })();
  }, [isLoggedIn, isLoggingIn, hasAccess]);

  if (!isLoggedIn) {
    return <Loading isLoading fullscreen text="Logging in" />;
  }

  if (hasAccess === false) {
    return (
      <Box
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        position="absolute"
      >
        <Stack vertical alignItems="center">
          <Text header="h1">You do not have access to this application</Text>
        </Stack>
      </Box>
    );
  }

  return (
    <LoginContext.Provider value={{ userRoles, retailUnit }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);

LoginProvider.displayName = "MsalProvider";
