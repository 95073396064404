import { ReactNode } from "react";
import { css, cx } from "@emotion/css";
import { isArray } from "lodash";
import { Color } from "components/Variables";

interface Props {
  vertical?: boolean;
  spacing?: number;
  alignItems?: "center" | "start" | "stretch" | "end" | "flex-end" | "baseline";
  justifyContent?: "center" | "space-between" | "flex-end" | "space-around";
  /** Make the Stack relatively positioned */
  explicitPosition?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  dontGrow?: boolean;
  fullWidth?: boolean;
  wrap?: boolean;
  maxHeight?: string;
  /** Forcefully apply flex to children, useful for stacking SKAPA components */
  force?: boolean;
  backgroundColor?: Color;
  padding?: number | number[];
  children?: ReactNode;
}

const verticalStyle = (margin: number) => css`
  flex-direction: column;
  & > * {
    margin-bottom: ${margin}px !important;
  }
  & > *:last-child {
    margin-bottom: 0 !important;
  }
`;

const horizontalStyle = (margin: number) => css`
  flex-direction: row;
  & > * {
    margin-right: ${margin}px !important;
  }
  & > *:last-child {
    margin-right: 0 !important;
  }
`;

export const Stack = ({
  vertical,
  width,
  minWidth,
  maxWidth,
  children,
  alignItems,
  justifyContent,
  wrap,
  dontGrow,
  height,
  maxHeight,
  force,
  spacing = 12,
  backgroundColor,
  padding = 0,
  fullWidth,
  explicitPosition,
}: Props) => (
  <div
    className={cx(
      css`
        display: flex;
        align-items: ${alignItems};
        justify-content: ${justifyContent};
        width: ${width};
        min-width: ${minWidth};
        max-width: ${maxWidth};
        flex-wrap: ${wrap ? "wrap" : "inherit"};
        flex-grow: ${dontGrow ? 0 : 1};
        height: ${height || "auto"};
        position: ${explicitPosition ? "relative" : undefined};
        padding: ${isArray(padding)
          ? padding.map((pad) => `${pad}px`).join(" ")
          : `${padding}px`};
      `,
      vertical ? verticalStyle(spacing) : horizontalStyle(spacing),
      force &&
        css`
          > * {
            display: flex;
            flex: 1;
          }
        `,
      maxHeight &&
        css`
          max-height: ${maxHeight};
          overflow-y: auto;
        `,
      fullWidth &&
        css`
          width: 100%;
        `,
      backgroundColor &&
        css`
          background-color: ${backgroundColor};
        `
    )}
  >
    {children}
  </div>
);

Stack.displayName = "Stack";
