import { useLocale } from "common/hooks";
import { SupportedLocale } from "i18n";
import useSWR, { mutate } from "swr";
import * as fetcher from "../fetcher";

export interface Preference {
  language: SupportedLocale;
  emailId: string;
  hfbList: string[];
  buCode: string;
  coworkerMeetingPoint?: string;
}

export interface Config {
  department: Record<string, string>;
  services: Record<number, string>;
  locales: Record<SupportedLocale, string>;
  currency: {
    code: string;
    symbol: string;
  };
  url: {
    boka: string;
  };
  reasons: {
    ClosedLost: Record<string, string>;
    ClosedWon: Record<string, string>;
    Invalid: Record<string, string>;
  };
  customer: {
    requireConsent: boolean;
    requireFollowUpConsent: boolean;
    requirePrivacyPolicy: boolean;
  };
  classificationChangeReason: Record<string, string>;
}

export function useConfig() {
  const locale = useLocale();
  return useSWR<Config>(
    `/config/${locale.toString()}`,
    (_url) => fetcher.opportunity.get("/config"),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 10 * 60 * 1000, // 10 minutes caching
    }
  );
}

export function usePreferences(shortId?: string | null) {
  return useSWR<Preference>(
    shortId ? `?shortId=${shortId}&overrideBuCode=268` : null,
    (url) => fetcher.preference.get(url),
    {
      revalidateOnFocus: false,
      dedupingInterval: 1 * 60 * 1000, // 1 minute caching
    }
  );
}

export const savePreference = async (
  data: {
    id?: string | null | undefined;
    language?: string;
    hfbList?: unknown[];
    buCode?: string;
  },
  shortId?: string | null
) => {
  const res = await fetcher.preference.patch("", data);
  mutate(`?shortId=${shortId}&overrideBuCode=268`);
  return res;
};
