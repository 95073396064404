import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "components/ui/modal";
import { Button } from "components";
import { SelectCustomer } from "./select-customer";
import { NewCustomer } from "./new-customer";
import { ExistingCustomerConsent } from "./existing-customer-consent";
import { NewOpportunity } from "./new-opportunity";
import { Summary } from "./summary";
import {
  Consents,
  Opportunity,
  OpportunityContext,
} from "./OpportunityContext";
import { CustomerGlobal } from "common/api";
import { HashRoute, HashSwitch } from "application/router";
import { t } from "i18n";
import { tracker } from "analytics";
import { useSettings } from "settings/SettingsProvider";

const newOpportunityRoutes = [
  "#/select-customer",
  "#/existing-customer-consent",
  "#/new-opportunity",
  "#/new-customer",
  "#/summary",
];

export const NewSalesOpportunity = () => {
  const history = useHistory();
  const location = useLocation();
  const { feature_flags } = useSettings();
  const [showModal, setShowModal] = useState(false);

  const [customer, setCustomer] = useState<CustomerGlobal | null>(null);
  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);
  const [isExistingCustomer, setIsExistingCustomer] = useState<boolean>(false);
  const [consents, setConsents] = useState<Consents>({
    followUpConsent: false,
    privacyPolicy: false,
  });

  const resetContext = () => {
    setCustomer(null);
    setOpportunity(null);
    setConsents({
      followUpConsent: false,
      privacyPolicy: false,
    });
    setIsExistingCustomer(false);
  };

  const onClose = () => {
    history.push(location.pathname);
  };

  const onButtonClick = () => {
    setShowModal(true);
    tracker.event({
      category: "New Sales Opportunity Creation",
      action: "Clicked on 'Sales opportunity' button",
    });
    history.push({ hash: "/select-customer" });
  };

  useEffect(() => {
    resetContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newOpportunityRoutes.includes(location.hash)) {
      setShowModal(true);
    } else {
      setShowModal(false);
      resetContext();
    }
  }, [location]);

  return (
    <OpportunityContext.Provider
      value={{
        customer,
        opportunity,
        consents,
        isExistingCustomer,
        setCustomer,
        setOpportunity,
        setConsents,
        setIsExistingCustomer,
        resetContext,
      }}
    >
      <Button
        emphasised
        disabled={feature_flags?.disable_create_manual_opportunity === true}
        onClick={onButtonClick}
        text={t("navigation.sales-opportunity.button")}
      />
      <Modal onClose={onClose} visible={showModal}>
        <HashSwitch>
          <HashRoute hash="/select-customer">
            <SelectCustomer />
          </HashRoute>
          <HashRoute hash="/existing-customer-consent">
            <ExistingCustomerConsent />
          </HashRoute>
          <HashRoute hash="/new-opportunity">
            <NewOpportunity />
          </HashRoute>
          <HashRoute hash="/new-customer">
            <NewCustomer />
          </HashRoute>
          <HashRoute hash="/summary">
            <Summary />
          </HashRoute>
        </HashSwitch>
      </Modal>
    </OpportunityContext.Provider>
  );
};
