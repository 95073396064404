import { useLocation, useHistory } from "react-router-dom";
import { ModalHeader, ModalFooter } from "components/ui/modal";
import { Stack } from "components/layout";
import { Text } from "components/typography";
import { CustomerSearch } from "./CustomerSearch";
import { t } from "i18n";
import { tracker } from "analytics";

export const SelectCustomer = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <ModalHeader title={t("new-opportunity.modal.header")} />
      <Stack vertical spacing={24}>
        <Text header="h3">{t("new-opportunity.select-customer.header")}</Text>
        <Text>{t("new-opportunity.select-customer.description")}</Text>
        <CustomerSearch />
      </Stack>
      <ModalFooter
        primaryButtonProps={{
          text: t("new-opportunity.select-customer.add-new-customer.button"),
          onClick: () => {
            tracker.event({
              category: "New Sales Opportunity Creation",
              action: "Clicked on 'Add new customer' button",
            });
            history.push({ hash: "/new-customer" });
          },
        }}
        secondaryButtonProps={{
          text: t("new-opportunity.modal.close"),
          // to close the modal and reset the context state
          link: location.pathname,
        }}
      ></ModalFooter>
    </>
  );
};
