import { useState } from "react";
import circleEllipsis from "@ingka/ssr-icon/paths/ellipsis";
import { Button } from "components";
import { MultipleActions } from "./MultipleActions.component";
import {
  Box,
  Stack,
  LabeledText,
  LocalizedDate,
  Grid,
  ModalSheet,
  Toast,
  Currency,
} from "components";
import {
  Opportunity as opportunityInterface,
  OpportunityPlanner,
} from "common/api";
import { ManageAccordion } from "./ManageAccordion.component";
import { usePlanner } from "common/api/opportunity/planner";
import { PlannerSelect } from "./PlannerSelect.component";
import { ProductList } from "common";
import { t } from "i18n";

interface Props {
  opportunity: opportunityInterface;
  disableEditing: boolean;
}

export const PlannerDrawing = ({ opportunity, disableEditing }: Props) => {
  const planners = opportunity.planners?.plannersInfo;
  const [showPlanner, setShowPlanner] = useState<boolean>(false);
  const firstPlanner = planners && planners[0];
  const [selectedPlanner, setSelectedPlanner] =
    useState<OpportunityPlanner>(firstPlanner);
  const [deletePlanner, setDeletePlanner] = useState<boolean>(false);

  const {
    data: planner,
    isValidating: isLoading,
    error,
  } = usePlanner(opportunity.id, selectedPlanner?.plannerId);

  if (!planner) {
    return null;
  }
  return (
    <>
      {error && (
        <Toast text={t("manage-opportunity.planner-drawing.missing-planner")} />
      )}
      <ManageAccordion
        title={
          planners.length > 0
            ? t("manage-opportunity.planner-drawing.planners-title", {
                count: planners.length,
              })
            : t("manage-opportunity.planner-drawing.title")
        }
      >
        <Box>
          <Stack vertical spacing={30}>
            {planners.length > 0 && (
              <PlannerSelect
                label={t("manage-opportunity.planner-drawing.select-planner")}
                onChange={(val) => {
                  const chosenPlanner = planners?.find(
                    (plannerValue) => plannerValue.plannerId === val
                  );
                  chosenPlanner && setSelectedPlanner(chosenPlanner);
                }}
                selectedPlannerId={selectedPlanner.plannerId}
                planners={planners}
              />
            )}
            <Grid>
              {selectedPlanner?.updatedTime && (
                <LabeledText
                  label={t(
                    "manage-opportunity.planner-drawing.lastModifiedDate"
                  )}
                  text={
                    <LocalizedDate>
                      {selectedPlanner?.updatedTime}
                    </LocalizedDate>
                  }
                />
              )}
              <LabeledText
                label={t("manage-opportunity.planner-drawing.planner-id")}
                text={selectedPlanner?.plannerId}
              />
              <LabeledText
                label={t("manage-opportunity.order-info.type")}
                text={selectedPlanner?.plannerType}
              />
              <LabeledText
                label={t("manage-opportunity.planner-drawing.amount")}
                text={
                  selectedPlanner?.amount && (
                    <Currency
                      currency={opportunity.currency}
                      showSign
                      fractions={2}
                    >
                      {selectedPlanner?.amount}
                    </Currency>
                  )
                }
              />
            </Grid>
            <Stack>
              <Button
                text={t("manage-opportunity.planner-drawing.view-planner")}
                primary
                loading={isLoading}
                disabled={!selectedPlanner}
                onClick={() => {
                  setShowPlanner(true);
                }}
              />
              <Button
                iconOnly
                ssrIcon={circleEllipsis}
                text="Overflow Button"
                onClick={() => {
                  setDeletePlanner(true);
                }}
              />
            </Stack>
          </Stack>
        </Box>
      </ManageAccordion>
      <ModalSheet
        visible={showPlanner}
        onClose={() => setShowPlanner(false)}
        title={`${selectedPlanner?.plannerId} ${selectedPlanner?.plannerType}`}
        closeText={t("planner-modal.close")}
      >
        {planner && (
          <ProductList
            product={planner}
            totalValue={planner.amount}
            currency={opportunity.currency}
          />
        )}
      </ModalSheet>
      <ModalSheet
        visible={deletePlanner}
        onClose={() => setDeletePlanner(false)}
        title={`${selectedPlanner?.plannerId} ${selectedPlanner?.plannerType}`}
        closeText={t("planner-modal.close")}
      >
        <MultipleActions
          onAction={() => setDeletePlanner(false)}
          itemId={selectedPlanner?.plannerId}
          type="planner"
          opportunityId={opportunity.id}
        />
      </ModalSheet>
    </>
  );
};

PlannerDrawing.displayName = "PlannerDrawing";
