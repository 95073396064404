import { T, t } from "i18n";
import InlineMessage from "@ingka/inline-message";
import infoCircle from "@ingka/ssr-icon/paths/information-circle";
import incorrect from "@ingka/ssr-icon/paths/incorrect";
import {
  Opportunity,
  useAssignOpportunity,
  OpportunitiesSearchFilters,
  OpportunitiesSearchOptions,
  useProfile,
} from "common/api";
import { CoworkerSearch, CoWorkerName } from "common";
import { User } from "common/graphApi";
import {
  Box,
  Button,
  Checkbox,
  Line,
  ModalSheet,
  Stack,
  Toast,
  Text,
} from "components";
import { differenceBy, find, xorBy } from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface Props {
  visible: boolean;
  /**
   * The opportunities selected in the opportunities table
   */
  opportunities: Opportunity[];
  filters: OpportunitiesSearchFilters;
  options: OpportunitiesSearchOptions;
  onClose: () => void;
  setOpportunities: (opportunities: Opportunity[]) => void;
}

export const OpportunitiesAssign = ({
  opportunities,
  setOpportunities,
  visible,
  onClose,
  filters,
  options,
}: Props) => {
  const [selectedCoworker, setSelectedCoworker] = useState<User | null>(null);
  const [selectedOpportunities, setSelectedOpportunities] = useState<
    Opportunity[]
  >([]);
  const [hasAssigned, setHasAssigned] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<{ text: string; time: Date } | null>(null);
  const [error, setError] = useState<{ text: string; time: Date } | null>(null);
  const assignOpportunities = useAssignOpportunity({ filters, options });
  const { data: profile } = useProfile();

  const clearState = () => {
    setHasAssigned(false);
    setSelectedCoworker(null);
    setError(null);
    setToast(null);
    setIsLoading(false);
  };

  const selectInitialOppportunities = () => {
    if (visible) {
      setSelectedOpportunities(opportunities);
    }
  };

  const saveAssignment = () => {
    setIsLoading(true);
    assignOpportunities(
      selectedCoworker!.mail!,
      selectedOpportunities.map((opportunity) => opportunity.opportunityId)
    )
      .then(() => {
        const opportunitiesMinusTheSelectedOnes = differenceBy(
          opportunities,
          selectedOpportunities,
          (o) => o.opportunityId
        );
        setOpportunities(opportunitiesMinusTheSelectedOnes);
        setSelectedOpportunities([]);
        setHasAssigned(true);
        setSelectedCoworker(null);
        setToast({
          text: `${selectedOpportunities.length} ${t(
            "assign-opportunities.sales-opportunities-message"
          )} ${
            selectedOpportunities.length > 1
              ? t("assign-opportunities.sales-opportunities-message.were")
              : t("assign-opportunities.sales-opportunities-message.was")
          } ${t(
            "assign-opportunities.sales-opportunities.assigned-to.message"
          )} ${selectedCoworker!.displayName}`,
          time: new Date(),
        });
        if (opportunitiesMinusTheSelectedOnes.length === 0) {
          onClose();
        }
      })
      .catch(() => {
        setError({
          text: `${t("assign-opportunities.message.failed")}`,
          time: new Date(),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const assignToMe = () => {
    setSelectedCoworker({
      displayName: profile?.displayName,
      mail: profile?.mail,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(selectInitialOppportunities, [visible]);
  useEffect(clearState, [visible]);

  return (
    <ModalSheet
      visible={visible}
      title={t("assign-opportunities.header.title")}
      closeText={t("assign-opportunities.close.button")}
      onClose={onClose}
      primaryButtonProps={{
        emphasised: true,
        text: t("assign-opportunities.assign.button"),
        loading: isLoading,
        onClick: saveAssignment,
        disabled: !selectedCoworker || selectedOpportunities.length === 0,
      }}
    >
      {toast && <Toast text={toast.text} time={toast.time} />}

      <Stack vertical spacing={20}>
        {hasAssigned && (
          <InlineMessage
            dismissable
            onDismissClick={() => setHasAssigned(false)}
            ssrIcon={infoCircle}
            variant="informative"
            body={t("assign-opportunities.alert-message.description")}
          />
        )}

        {error && (
          <InlineMessage
            dismissable
            onDismissClick={() => setError(null)}
            ssrIcon={incorrect}
            variant="negative"
            body={error.text}
          />
        )}

        <Stack vertical spacing={16}>
          <Text bold>
            <T id="assign-opportunities.select-coworker.title" />
          </Text>

          <CoworkerSearch
            label={t("assign-opportunities.select-coworker.label")}
            selectedMail={selectedCoworker?.mail!}
            onSelect={(coworker) => {
              setSelectedCoworker(coworker);
            }}
            onReset={() => {
              setSelectedCoworker(null);
            }}
          />
        </Stack>
        <Checkbox
          label={t("assign-opportunities.select-all.checkbox.label")}
          checked={selectedOpportunities.length === opportunities.length}
          subtle
          keepTogether
          onChange={() => {
            setSelectedOpportunities(
              selectedOpportunities.length === opportunities.length
                ? []
                : opportunities
            );
          }}
        />

        <Line color="neutralGrey200" />

        <Stack vertical spacing={0}>
          {opportunities.map((theOpportunity, i) => (
            <OpportunityItem
              key={theOpportunity.opportunityId}
              opportunity={theOpportunity}
              selectedOpportunities={selectedOpportunities}
              setSelectedOpportunities={setSelectedOpportunities}
              odd={!Boolean(i % 2)}
            />
          ))}
        </Stack>

        <Box>
          <Button
            primary
            small
            text={t("new-opportunity.form.opportunity-owner.assign-to-me")}
            onClick={assignToMe}
          />
        </Box>
      </Stack>
    </ModalSheet>
  );
};

interface IOpportunityItemProps {
  opportunity: Opportunity;
  selectedOpportunities: Opportunity[];
  setSelectedOpportunities: Dispatch<SetStateAction<Opportunity[]>>;
  odd: boolean;
}

const OpportunityItem = ({
  opportunity,
  selectedOpportunities,
  setSelectedOpportunities,
  odd,
}: IOpportunityItemProps) => {
  const selected = find(selectedOpportunities, opportunity);
  const toggle = () =>
    setSelectedOpportunities(
      xorBy(selectedOpportunities, [opportunity], (o) => o.opportunityId)
    );

  return (
    <Box onClick={toggle} color={odd ? "bjornGrey" : undefined} padding={20}>
      <Stack alignItems="center">
        <Box width="10%">
          <Checkbox id={opportunity.opportunityId} checked={!!selected} />
        </Box>
        <Stack vertical spacing={2}>
          <Text bold>{opportunity.title}</Text>
          <Text small>{opportunity.opportunityId}</Text>
          <Text small>
            <CoWorkerName>{opportunity.assignedTo}</CoWorkerName>
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
