import { addMinutes, startOfMinute, toDate } from "date-fns";

/**
 * @name eachMinuteOfInterval
 * @category Interval Helpers
 * @summary Return the array of minutes within the specified time interval.
 *
 * @description
 * Return the array of minutes within the specified time interval.
 *
 * ***
 * Copied straight from this PR https://github.com/date-fns/date-fns/pull/2032
 * with some few modification
 * TODO: this code should be removed once that PR is merged in date-fns
 * ***
 *
 * @param {Interval} interval - the interval. See [Interval]{@link docs/types/Interval}
 * @param {Object} [options] - an object with options.
 * @param {Number} [options.step=1] - the step to increment by. The starts of minutes from the hour of the interval start to the hour of the interval end
 * @throws {TypeError} 1 argument required
 * @returns {Date[]} the array with starts of minutes from the hour of the interval start to the hour of the interval end
 * @throws {RangeError} `options.step` must be a number equal or greater than 1
 * @throws {RangeError} The start of an interval cannot be after its end
 * @throws {RangeError} Date in interval cannot be `Invalid Date`
 *
 * @example
 * // Each minute between 14 October 2020, 13:00 and 14 October 2020, 13:03
 * const result = eachMinuteOfInterval({
 *   start: new Date(2014, 9, 14, 13),
 *   end: new Date(2014, 9, 14, 13, 3)
 * })
 * //=> [
 * //   Wed Oct 14 2014 13:00:00,
 * //   Wed Oct 14 2014 13:01:00,
 * //   Wed Oct 14 2014 13:02:00,
 * //   Wed Oct 14 2014 13:03:00
 * // ]
 */
export default function eachMinuteOfInterval(
  interval: Interval,
  options?: {
    step: number;
  }
): Date[] {
  const startDate = startOfMinute(toDate(interval.start));
  const endDate = startOfMinute(toDate(interval.end));

  let startTime = startDate.getTime();
  let endTime = endDate.getTime();

  if (startTime >= endTime) {
    let tempTime = startTime;
    startTime = endTime;
    endTime = tempTime;
  }

  const dates: Date[] = [];

  let currentDate = startDate;

  const step = options && "step" in options ? Number(options.step) : 1;
  if (step < 1 || isNaN(step))
    throw new RangeError(
      "`options.step` must be a number equal or greater than 1"
    );

  while (currentDate.getTime() <= endTime) {
    dates.push(toDate(currentDate));
    currentDate = addMinutes(currentDate, step);
  }

  return dates;
}
