import {
  PlannerDetail,
  OpportunityPlanner,
  DesignSearchResponse,
} from "common/api";

export type Planner = PlannerDetail | OpportunityPlanner;

export type PlannerData = {
  designId: string;
  designUrl?: string;
  designUrlEdit?: string;
  designUrlView?: string;
  thumbnailUrl?: string;
  designName?: string;
  createdDate?: string;
  plannerType?: string;
  lastModifiedDate?: string;
  statusDescription?: string;
  totalprice?: string;
  currencySymbol?: string;
  statusCodeTranslated?: string[];
};

export const comparePlanner =
  (designId: string | undefined) => (p: Planner) => {
    if (designId === undefined) {
      return false;
    }

    if (isPlannerDrawing(p)) {
      return p.designId === designId;
    } else if (isOpportunityPlanner(p)) {
      return p.plannerId === designId;
    }

    return true;
  };

export const getPlannerId = (p: Planner) => {
  if (isPlannerDrawing(p)) {
    return p.designId;
  } else if (isOpportunityPlanner(p)) {
    return p.plannerId;
  }
};

export const plannerIsAdded = (
  selectedPlanner: Planner | undefined,
  addedPlanners: PlannerDetail[],
  addedVPCPlanners: OpportunityPlanner[]
): boolean => {
  if (!selectedPlanner) return false;
  if (isPlannerDrawing(selectedPlanner)) {
    return (
      addedPlanners.find(comparePlanner(selectedPlanner.designId)) !== undefined
    );
  }
  if (isOpportunityPlanner(selectedPlanner)) {
    return (
      addedVPCPlanners.find(comparePlanner(selectedPlanner.plannerId)) !==
      undefined
    );
  }

  return false;
};

export const isPlannerDrawing = (
  planner: Planner | undefined
): planner is PlannerDetail => planner?.hasOwnProperty("designId") ?? false;

export const isOpportunityPlanner = (
  planner: Planner | undefined
): planner is OpportunityPlanner =>
  planner?.hasOwnProperty("plannerId") ?? false;

export const isDesignSearchResponse = (
  res: DesignSearchResponse | OpportunityPlanner
): res is DesignSearchResponse => res.hasOwnProperty("plannerList") ?? false;

export const getPlannerData = (planner?: Planner) => {
  let data: PlannerData | undefined;

  if (isPlannerDrawing(planner)) {
    data = {
      designUrl: planner.designUrl,
      designUrlEdit: planner.designUrlEdit,
      designUrlView: planner.designUrlView,
      thumbnailUrl: planner.thumbnailUrl,
      designName: planner.designName,
      designId: planner.designId,
      createdDate: planner.createdDate,
      plannerType: planner.plannerType,
      lastModifiedDate: planner.lastModifiedDate,
      statusDescription: planner.statusDescription,
      totalprice: planner.totalprice,
      currencySymbol: planner.currencySymbol,
      statusCodeTranslated: planner.statusCodeTranslated,
    };
  } else if (isOpportunityPlanner(planner)) {
    data = {
      thumbnailUrl: getVPCPlannerImage(planner),
      designId: planner.plannerId,
      lastModifiedDate: planner.updatedTime,
      plannerType: planner.plannerType,
      totalprice: String(planner.amount),
    };
  }

  return data;
};

export const getPlannerStatus = (data) => {
  return data?.statusCodeTranslated?.join();
};

export const isVPC = (term: string): boolean => {
  if (term === undefined) return false;
  return !term.includes("@") && (term.length === 6 || term.length === 5);
};
export const formatPrice = (
  priceString: string,
  currencySymbol: string | undefined
) => {
  const price = priceString.match(/\d+/)?.[0];
  if (price === undefined) return "-";
  return currencySymbol + " " + price;
};

export const getVPCPlannerImage = (planner: OpportunityPlanner) => {
  const plannerTypes = [
    "BESTA",
    "PLATSA",
    "STUVA",
    "PAX",
    "NORDLI",
    "BROR",
    "EKET",
    "JONAXEL",
    "BOAXEL",
    "SMASTAD",
    "KALLAX",
    "IVAR",
    "VALLENTUNA",
    "VIMLE",
    "SODERHAMN",
    "LIDHULT",
    "GRONLID",
  ];
  if (
    planner.designImage?.includes("generic") &&
    plannerTypes.includes(planner.plannerType.toUpperCase())
  ) {
    return `https://dev-ipexdesignsearch.ikeadt.com/${planner.plannerType}.png`;
  }

  return (
    planner.designImage ??
    "https://content.dexf.ikea.com/origin/vpc/generic/main_generic_image.png"
  );
};
