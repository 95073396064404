import Select, { Option } from "@ingka/select";
import { t } from "i18n";
import { sortRecordByValue, useLocale } from "common";
import { useCustomerMeetingPoints } from "common/api";

interface MeetingPoint {
  buCode: string;
  buName: string;
}

interface Props {
  selectedBuCode: string;
  onChange(meetingPoint: MeetingPoint): void;
  disabled: boolean;
}

export const CustomerMeetingPointSelector = ({
  selectedBuCode,
  onChange,
  disabled,
}: Props) => {
  const { data: stores } = useCustomerMeetingPoints();
  const locale = useLocale();

  if (!stores) return null;

  return (
    <Select
      id={"customer-meeting-point"}
      label={t("new-opportunity.form.customer-meeting-point.label")}
      hintText={t("new-opportunity.form.customer-meeting-point.placeholder")}
      onChange={(e) => {
        const buCode = e.target.value;
        onChange({
          buCode: stores[buCode] !== undefined ? buCode : null,
          buName: stores[buCode],
        });
      }}
      value={selectedBuCode}
      disabled={disabled}
    >
      {sortRecordByValue(stores, locale.languageCode).map(([id, name]) => (
        <Option value={id} name={name} key={id} />
      ))}
    </Select>
  );
};
