import Skeleton from "@ingka/skeleton";
import { Box, Button, Icon, Stack, Text } from "components";
import { OpeningHours } from "./OpeningHours";
import { useProfile, useStoreDetails } from "common/api";
import { useAllowedStores } from "common/api/coworker/allowedStores";
import { t, T } from "i18n";
import { useLocale } from "common";
import { isAllowedToSetStore } from "../isAllowedToSetStore";

interface Props {
  buCode: string;
  title: string;
  dismissible?: boolean;
  onDismiss?: () => void;
  isSaving?: boolean;
  searchedStore?: string;
  onSetStore?: () => void;
  showSetStoreButton?: boolean;
}

export const StoreDetails = ({
  buCode,
  title,
  dismissible = false,
  onDismiss,
  isSaving,
  searchedStore = "",
  showSetStoreButton = false,
  onSetStore,
}: Props) => {
  const locale = useLocale();
  const { data: storeDetails, error } = useStoreDetails(buCode);
  const { data: profile } = useProfile();
  const { data: allowedStoresList } = useAllowedStores(profile?.mailNickname);
  const { data: searchedStoreDetails } = useStoreDetails(searchedStore);

  if (error) {
    return (
      <Text bold>
        <T id="user.stores.not-found" />
      </Text>
    );
  }
  if (!storeDetails) {
    return (
      <>
        {[50, 20, 20, 20].map((item, idx) => (
          <Skeleton height={`${item}px`} key={idx} />
        ))}
      </>
    );
  }

  // Get language to use, 1) use language setting for user, 2) pick the first one
  const storeLocale =
    Object.keys(storeDetails.StoreAddress).find(
      (l) => l.toLowerCase() === locale.languageCode.toLowerCase()
    ) ??
    Object.keys(storeDetails.StoreAddress)[0] ??
    "en";
  const selectedStoreAddress = storeDetails.StoreAddress[storeLocale];

  return (
    <>
      <Stack vertical alignItems="start" padding={[20, 0, 0, 0]}>
        <Text bold xsmall>
          {title}
        </Text>
        <Stack spacing={20} justifyContent="space-between" fullWidth>
          <Box>
            <Box alignItems="center" padding={[0, 20, 0, 0]}>
              <Icon icon="Store" color="redIdle" size={24} />
            </Box>
            <Box vertical alignItems="start">
              <Text
                bold
                small
                color="neutralGrey900"
              >{`${storeDetails.Name} ${storeDetails.No}`}</Text>
              {selectedStoreAddress && (
                <>
                  <Text
                    small
                    color="neutralGrey700"
                  >{`${selectedStoreAddress.Address1},`}</Text>
                  <Text
                    small
                    color="neutralGrey700"
                  >{`${selectedStoreAddress.CityName}`}</Text>
                </>
              )}
            </Box>
          </Box>
          <Box vertical alignItems="center">
            <Box>
              {dismissible && (
                <Button
                  text={t("user.stores.dismiss-searched-store")}
                  small
                  onClick={onDismiss}
                />
              )}
            </Box>
            <Box padding={[20, 0, 0, 0]}>
              {searchedStoreDetails &&
              isAllowedToSetStore(
                searchedStore,
                allowedStoresList?.allowedStores ?? []
              ) &&
              showSetStoreButton === true ? (
                <Button
                  disabled={isSaving}
                  loading={isSaving}
                  text={t("user.stores.set-store.button")}
                  onClick={onSetStore}
                  primary={true}
                  small
                />
              ) : undefined}
            </Box>
          </Box>
        </Stack>
      </Stack>
      {storeDetails.OpeningHours && (
        <OpeningHours storeUnits={storeDetails.OpeningHours} />
      )}
    </>
  );
};
