import { useLocale } from "common/hooks";
import format from "date-fns/format";
import isValid from "date-fns/isValid";

interface Props {
  /**
   * The format pattern to use when printing the date.
   * @default "P" which displays only the date following the locale format
   * @see: https://date-fns.org/v2.19.0/docs/format
   */
  pattern?: string;
  /**
   * A parsable string or unix timestamp.
   */
  children: string | number;
}
/**
 * Takes a string or number (unix timestamp) as a child
 * and prints the date formatted for the users locale.
 * Returns "-" if no date is provided.
 */
export const LocalizedDate = ({ children, pattern = "P" }: Props) => {
  const locale = useLocale();

  const date = new Date(children);
  if (!date || !isValid(date)) return <>-</>;

  const formattedDate = format(date, pattern, { locale: locale.dateFnsLocale });

  return formattedDate ? <>{formattedDate}</> : <>-</>;
};

LocalizedDate.displayName = "LocalizedDate";
