import { t, T } from "i18n";

const translationKeys = {
  STORE: "appointment.location-type.store",
  REMOTE: "appointment.location-type.remote",
  STORE_REMOTE: "appointment.location-type.store-remote",
  HOME: "appointment.location-type.home",
} as const;

interface Props {
  id: keyof typeof translationKeys;
}

export const AppointmentLocationType = ({ id }: Props) => {
  if (!translationKeys[id]) return <>{id}</>;
  return <T id={translationKeys[id]} />;
};

export const getAppointmentLocationType = (id: keyof typeof translationKeys) =>
  t(translationKeys[id]) ?? id;
